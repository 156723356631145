import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { Filter, DayFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { Views } from 'src/app/custom-classes/View';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { PageSerieEnum } from '../series/series.component';
import { CreateColorComponent } from '../create-color/create-color.component';
export enum PageColorEnum {
  DATA_SERIE = 1,
  BRAND = 2,
}
@Component({
    selector: 'app-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.css'],
    standalone: false
})

export class ColorsComponent {
  filtersToShow: Filter[] = [
    new DayFilter("Fecha de creación").setId(PageColorEnum.DATA_SERIE)
  ];
  f = filter;
  v = ViewPath;
  colors : M_Color[]= [];
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Color>;
  constructor(public apiS: ApiService, private d: MatDialog, public subS: SubscriptionService,
    public sessionS: SessionService, public params: ParamsService) {
  }
  ngOnInit(): void {
    this.apiS.colors().then(res => {
      this.ps.initTable(res);
    })
  }
  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageSerieEnum.BRAND);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  openDialog() { }
  onCreate() { }
  viewClick(event: any): Views {

    return this.v.createColor;
  }
  getIds(event: any): number {

    return event.id;

  }
  codeAccounting(color:M_Color){
    return color.accounting?.name;
  }
  onDialogRef(color:M_Color | undefined){
    return this.d.open(CreateColorComponent,{data:color}).afterClosed().subscribe(res=>{
      this.reloadPage();
    });
    // params.go(viewClick($event),getIds($event)) call color frontend
  }
  reloadPage(){
    this.apiS.colors().then(res=>{
      this.colors=res;
      this.ps.initTable(res);
    })
  }
}
