import { Component, Input } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-year-datapiker',
    templateUrl: './year-datapiker.component.html',
    styleUrls: ['./year-datapiker.component.css'],
    standalone: false
})
export class YearDatapikerComponent {
  @Input() form!: UntypedFormGroup;
  years: number[] = [];
  minDate: Date;
  year_actual = new Date().getFullYear();
  constructor(public formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      date_version: [],
    });
    this.minDate = new Date();
  }
  ngOnInit() {
    // Genera la lista de años al inicializar el componente
    this.years = this.generateYears(1970, 2030);

    if (this.form && this.form.get('date_version')) {
      if (!this.form.get('date_version')!.value) {
        this.form.get('date_version')!.setValue(2024);
      }
    }
  }

  generateYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }
  get valuePicker() {
    return this.form.get('date_version');
  }
}
