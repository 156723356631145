
<div *ngIf="userS.isSuperUser">
  <div class="container admin">
    <div class="pending">
      <div class="card_1">
        <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
          class="top-card normal-padding-top bc_b0" [go]="v.cargataller">
          <app-card-subtitle [first]="true">Plazas disponibles</app-card-subtitle>
          <div class="numText">
            <p class="bigNum" *ngIf="data">{{data.companyPlacesDis < 0 ? 0 : data.companyPlacesDis}}</p>
          </div>
        </app-card>
      </div>
      <div class="card_2">
        <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined" [go]="v.or"
          [param]="{ filter: OPFE.OR_GROUP_STATUS, filtervalue: 1 }" class="top-card normal-padding-top bc_b0">
          <app-card-subtitle [first]="true">A facturar</app-card-subtitle>
          <div class="numText">
            <p class="bigNum"><span money [val]="data?.total_ORToInvoice"></span></p>
          </div>
        </app-card>
      </div>
      <div class="card_3">
        <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined" [go]="v.invoices"
          [param]="{ filter: BPFE.INVOICE_STATE, filtervalue: 0, filter1: BPFE.INVOICE_STATE, filtervalue1: 1 }"
          class="top-card normal-padding-top bc_b0">
          <app-card-subtitle [first]="true">A cobrar</app-card-subtitle>
          <div class="numText">
            <p class="bigNum"><span money [val]="data?.total_ToCollect"></span></p>
          </div>
        </app-card>
      </div>
      <div class="card_4">
        <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
          class="top-card normal-padding-top bc_b3" [go]="v.createOr">
          <app-card-subtitle [first]="true">Acción rápida</app-card-subtitle>
          <div class="numText">
            <p class="bigNum c_b0">Abrir OR</p>
          </div>
        </app-card>
      </div>
    </div>

    <div class="line-chart">
      <app-card [contentLoaded]="data != undefined">
        <app-card-title [first]="true" [noMarginBottom]="true">Previsión de taller</app-card-title>
        <apx-chart *ngIf="previsionChart && inTab" [series]="previsionChart.series!" [chart]="previsionChart.chart!"
          [xaxis]="previsionChart.xaxis!" [stroke]="previsionChart.stroke!" [colors]="previsionChart.colors!"
          [dataLabels]="previsionChart.dataLabels!" [legend]="previsionChart.legend!"
          [markers]="previsionChart.markers!" [grid]="previsionChart.grid!" [fill]="previsionChart.fill!"
          [yaxis]="previsionChart.yaxis!" [title]="previsionChart.title!"></apx-chart>
      </app-card>
    </div>

    <div class="workload" [ngClass]="{'loaded': data != undefined}">
      <app-card [contentLoaded]="data != undefined">
        <app-card-title>Carga de taller</app-card-title>
        <div class="two-apex-charts">
          <!-- Carga taller -->
          <div class="workload-parent-div">
            <apx-chart *ngIf="semiCircleChart && inTab" [series]="semiCircleChart.series!" [chart]="semiCircleChart.chart!"
              [labels]="semiCircleChart.labels!" [grid]="semiCircleChart.grid!"
              [plotOptions]="semiCircleChart.plotOptions!" [responsive]="semiCircleChart.responsive!"
              [colors]="semiCircleChart.colors!" [legend]="semiCircleChart.legend!" [stroke]="semiCircleChart.stroke!"
              [dataLabels]="semiCircleChart.dataLabels!"></apx-chart>
          </div>
        </div>
      </app-card>
    </div>

    <div class="latest-activity" [ngClass]="{'loaded': data != undefined}">
      <app-card [contentLoaded]="data != undefined">
        <app-card-title>Última actividad</app-card-title>
        <app-last-activity *ngIf="data" [allActivity]="data.latestActivity"></app-last-activity>
      </app-card>
    </div>
    <div class="or-table">
      <app-card [contentLoaded]="data != undefined">
        <app-card-title>OR para hoy</app-card-title>
        <app-dashboard-or-table *ngIf="data" [ors]="data.priorityOr"></app-dashboard-or-table>
      </app-card>
    </div>
  </div>

  <!-- <app-pro-tips-button></app-pro-tips-button> -->
</div>


<ng-template #customSkeleton>
  <div class="custom-loader">
    <app-skeleton [width]="180" [height]="50"></app-skeleton>
  </div>
</ng-template>