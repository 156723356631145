import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';

@Component({
    selector: 'app-main-albaran-button',
    templateUrl: './main-albaran-button.component.html',
    styleUrls: ['./main-albaran-button.component.css'],
    standalone: false
})
export class MainAlbaranButtonComponent {
  v = ViewPath;
  @Input({ required: true }) c!: M_Contact;
  @Input() style: "flat" | "menu-option" = "flat";
}

