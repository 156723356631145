<app-close-dialog-button></app-close-dialog-button>
<!-- Dialog content -->
<div mat-dialog-content>
    <app-card [noStyle]="true" [contentLoaded]="loaded">
        <app-card-title [first]="true">Reservas</app-card-title>
        <ng-container *ngIf="reservations.length; else noFaults">
            <ng-container  *ngFor="let reservation of reservations">
                <ng-container *ngIf="reservation && (reservation.quant_reserved! - reservation.quant_delivered! > 0)">

                    <div [ngClass]="{'slash' : false, 'opacity' : false, 'large' : false}" *ngIf="reservation.product">
                        <p class="nmb">
                            <mat-icon class="vam" style="margin-right: 0px;"  smartIcon>{{icon(reservation.product)}}</mat-icon>
                            <span class="ml5">{{reservation.product.name}}</span>
                            <span class="fault-units c_r ml5"> ({{reservation.quant_reserved}} <span plural [val]="[reservation.quant_reserved, 'unidad', 'unidades']"></span>)</span>
                        </p>
                        <p class="c_t2 fss">
                            <span class="fault-document">{{reservation.document}}</span>
                            <span class="fault-separator ml5 mr5">|</span>
                            <span class="fault-date" *ngIf="reservation.created_at">{{reservation.created_at.numMonthFormat() + ' - ' + reservation.created_at.hourFormat()}}</span>
                        </p>
                    </div>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #noFaults>
            <p class="c_t2">No hay reservas</p>
        </ng-template>
    </app-card>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>