<app-card [contentLoaded]="loaded" [noStyle]="true">
    <div class="big-banner">
        <img class="img-1" src="./assets/img/decoration/star_red.png">
        <img class="img-2" src="./assets/img/decoration/planets.png">
        <img class="img-3" src="./assets/img/decoration/big_star_blue.png">
        <img class="img-4" src="./assets/img/decoration/star_green.png">
        <p class="title">Tu taller al siguiente nivel</p>
        <p class="desc">Amplia las capacidades de tu taller con nuestros {{f.integrations ? 'módulos e integraciones' :
            'módulos'}}.</p>
    </div>
    <div class="module-parent">

        <!-- Modules -->
        <div class="df">
            <app-card-title>Módulos</app-card-title>
            <mat-icon class="mla cp" (click)="openExplanation('modules')">help</mat-icon>
        </div>
        <app-module *ngFor="let m of modules" [module]="m"></app-module>

        <div style="height: 25px;"></div>
        <mat-divider></mat-divider>
        <div style="height: 25px;"></div>

        <!-- Integrations -->
        <ng-container *ngIf="f.integrations">
            <div class="df">
                <app-card-title>Integraciones</app-card-title>
                <mat-icon class="mla cp" (click)="openExplanation('integrations')">help</mat-icon>
            </div>
            <app-module *ngFor="let m of integration" [module]="m"></app-module>

            <div local-dev-pre>
                <app-module *ngFor="let fake of fakes" [module]="fake"></app-module>
            </div>
        </ng-container>
        
    </div>
</app-card>