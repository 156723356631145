import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterOption, TagFilter } from '../../../custom-classes/Filter';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'app-tagfilter',
    templateUrl: './tagfilter.component.html',
    styleUrls: [
        './tagfilter.component.css',
        '../../or-labels/or-type-label/or-type-label.component.css',
        '../../or-labels/or-status-label/or-status-label.component.css',
        '../../invoice-status/invoice-status.component.css',
        '../../or-labels/invoice-type.css',
        '../../../components/appointment-status-label/appointment-status-label.component.css',
        '../../../components/order-status-label/order-status-label.component.css',
        '../../../components/albaran-status-label/albaran-status-label.component.css'
    ],
    standalone: false
})
export class TagfilterComponent implements OnInit {
  @Input() filter!: TagFilter;
  @Input() label: boolean = true;
  @Input() quickFilter: boolean = false;
  @Output() change: EventEmitter<number | undefined> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void { }

  getClassName(i: number) {
    return this.filter.isOrTypesTags() ? 'type' + i : this.filter.isOrStatusTags() ? 'status' + i : this.filter.isAppointmentTags() ? 'a_status' + i : this.filter.isInvoiceStatesTags() ? 'i_states' + i : this.filter.isOrderStausTags() ? 'order_status' + i : this.filter.isAlbaranStatus() ? 'albaran_status' + i :'';
  }

  isSvg(kv: KeyValue<number, FilterOption>) {
    return kv.value.icon == 'bizum' || kv.value.icon == 'barcode';
  }
}
