import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { R_Zip } from 'src/app/models/R_Zip';
import { ExtraAddressService } from 'src/app/services/extra-address.service';

@Component({
    selector: 'app-create-edit-sending-adress',
    templateUrl: './create-edit-sending-adress.component.html',
    styleUrls: ['./create-edit-sending-adress.component.css'],
    standalone: false
})
export class CreateEditSendingAdressComponent implements OnInit {

  @Input() formG: FormGroup | undefined;
  @Input() canDelete: boolean = false;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();


  fg!: FormGroup;
  selectedPayment: any;
  loadingZip: boolean = false;
  personalizadoSelected = false;
  arrayZips: R_Zip[] | undefined = [];
  finalsearh = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Contact, public dialogRef: MatDialogRef<CreateEditSendingAdressComponent>,
    private apiS: ApiService, private extraAddressS: ExtraAddressService, public chdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.formG) { this.fg = this.formG }
    else { this.fg = this.extraAddressS.generateAddresFormFroup() }
    this.fg.addControl("client_id", new FormControl(this.data.client_id));
    // this.fg.get('zip')?.valueChanges.subscribe(v => {
    //   this.onZipChange(v);
    //   this.chdRef.detectChanges();
    // })
    // this.fg.get('province')?.disable();
  }

  createAndClose() {
    this.apiS.createExtraAddress(this.fg).then(res => {
      this.dialogRef.close(res);
    })
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

}
