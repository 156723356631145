import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { AddRemoveComponent } from './add-remove/add-remove.component';
import { feature } from 'src/app/utils/FeaturesController';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { MatDialog } from '@angular/material/dialog';
import { DialogReserveComponent } from './dialog-reserve/dialog-reserve.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { Filter, ClassSearcherFilter, SliderFilter, TagFilter, FilterOption } from 'src/app/custom-classes/Filter';
import { getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { endpoints } from 'src/app/constants/Endpoints';

export enum ProductsPageFiltersEnum {
  PRODUCT_TYPE = 1,
}


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css'],
    standalone: false
})
export class ProductsComponent implements OnInit {

  me = ModulesEnum;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Product>;
  @ViewChild(AddRemoveComponent) arc?: AddRemoveComponent;
  v = ViewPath;
  e = endpoints;
  features = feature;
  products: M_Product[] = [];
  filters: Filter[] | undefined = undefined
  refreshReference = false;
  modules = ModulesEnum;
  displayedHeaders: string[] = [];
  displayedColumns: string[] = [];
  specialText: any[] = [];
  specialClass: any[] = [];
  cellZize: any[] = [];
  providerName: string | undefined;

  constructor(private apiS: ApiService, public routerS: RouterService, public params: ParamsService,
    public companyS: CompanyService, public subS: SubscriptionService,
    private d: MatDialog) { }

  filterProducts(object: M_Product, ...filters: Filter[]) {
    var fitleredFilters = filters.filter(f => f.activated);  //xd
    let isCommonFiltersOk = filter(object, ...fitleredFilters);
    if (this.companyS.recambiosModule) {
      let classsearcherOk = true;
      fitleredFilters.forEach((filter) => {
        if (filter instanceof ClassSearcherFilter) {
          classsearcherOk = filter.checkFilter(object.providerUsual?.client_id);
        }
      })
      return isCommonFiltersOk && classsearcherOk;
    }
    return isCommonFiltersOk;
  }

  getProducts() {

    this.apiS.productsTable().then(resp => {
      this.filters = [
        new TagFilter("Categoría", undefined,
          new FilterOption("General", "barcode"),
          new FilterOption("Recambios", "build_circle"),
          new FilterOption("Neumáticos", "tire_repair"),
          new FilterOption("Electrónica", "lightbulb"),
          new FilterOption("Aceite", "water_drop"),
          new FilterOption("Boutique", "checkroom"),
        ).setId(ProductsPageFiltersEnum.PRODUCT_TYPE),
        new SliderFilter("PVP", Math.max(...resp.map(o => o.price)), 'price'),
        this.ps && this.companyS.recambiosModule
          ? new SliderFilter("Disponible", Math.max(...resp.map(o => o.cs_stock)), 'cs_stock')
          : new SliderFilter("Coste", Math.max(...resp.map(o => o.buy_price)), 'buy_price'),
        this.ps && this.companyS.recambiosModule
          ? new SliderFilter("Físico", Math.max(...resp.map(o => o.totalPhysical)), 'totalPhysical')
          : new SliderFilter("Stock", Math.max(...resp.map(o => o.stock)), 'stock'),

        ...(this.companyS.recambiosModule ? [new ClassSearcherFilter("Proveedor habitual", MASTER_PROVIDER, "Proveedor habitual")] : [])
      ];

      this.ps.initTable(resp);

    });
  }

  ngOnInit(): void {
    this.getProducts();
    if (this.companyS.recambiosModule) {
      /* Datos Tabla Productos CON módulo recambios */
      this.displayedHeaders = ['Referencia', 'Nombre', 'PVP', 'PVP + IVA', 'Disponible', 'Proveedor habitual'];
      this.displayedColumns = ['reference', 'name', 'price', 'price_iva', 'totalDisp', 'usualProviderName']
      this.specialText = [undefined, undefined, 'money', 'money', this.getTotalDisp, undefined]
      this.specialClass = [undefined, undefined, undefined, undefined, undefined, undefined]
      this.cellZize = ['big', undefined, undefined, undefined, undefined, undefined]

    } else {
      /* Datos Tabla Productos SIN módulos */
      this.displayedHeaders = ['Referencia ', 'Nombre', 'PVP', 'PVP + IVA', 'Stock', 'Última actualización'];
      this.displayedColumns = ['reference', 'name', 'price', 'price_iva', 'stock', 'updated_at']
      this.specialText = [undefined, undefined, 'money', 'money', 'decimal', undefined]
      this.specialClass = [undefined, undefined, undefined, undefined, undefined, undefined]
      this.cellZize = ['big', 'big', undefined, undefined, 'small', undefined]

    }
  }

  get quickFilter() {
    if (this.filters) {
      let f = this.filters.find(f => f.id == ProductsPageFiltersEnum.PRODUCT_TYPE);
      if (f instanceof TagFilter) { return f };
    }
    return undefined;
  }

  getTotalDisp(product: M_Product): string {
    return product.totalDisp < 0 ? '0' : (product.totalDisp).toString();
  }

  openDialogReserve() {
    this.d.open(DialogReserveComponent, {
      width: '400px',
      maxWidth: 700
    });
  }

  productIcon(b: M_Product) {
    return b.icon;
  }

}
