<form [formGroup]="form" eForm>
    <app-card-subtitle>{{requiredFieldsTitle ? requiredFieldsTitle : 'Datos básicos'}}</app-card-subtitle>
    <div class="onboarding-vehicle-required">
        <div eForm-wrapper>
            <div eForm-section>

                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value, brandsModelSerie)" [value]="selectedType">
                        <mat-option [value]="typeVehicle.bike">Moto</mat-option>
                        <mat-option [value]="typeVehicle.car">Coche</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Matricula</mat-label>
                    <app-async-validator-spinner matSuffix
                        [control]="form.get('license')"></app-async-validator-spinner>
                    <input matInput placeholder="Matricula" [disabled]="licenseBlocked" formControlName="license" />
                    <button *ngIf="licenseBlocked" mat-icon-button matPrefix type="button"
                        (click)="enablePlateEdition(d, this)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                        La matrícula ya existe.
                    </mat-error>
                </mat-form-field>
            </div>

            <div eForm-section>
                <app-brands-models-series #brandsModelSerie [formVnVo]="false" [type]="selectedType" [filterAuto]="true"
                    [notShowSerie]="!companyS.vnvoModule" [notRequiredSerie]="true" [form]="form">
                    <mat-form-field appearance="outline" *ngIf="companyS.vnvoModule">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código versión">
                    </mat-form-field>
                </app-brands-models-series>
            </div>
        </div>
        <!-- Selector retirado -->
        <!-- <div [ngTemplateOutlet]="imageSerieSelector" *ngIf="!companyS.vnvoModule"></div> -->

        <!-- Owner -->
        <div [dn_i]="vehicle != undefined || !showOwnerSelector">
            <app-card-subtitle>Propietario del vehículo</app-card-subtitle>
            <div eForm-section eForm-wrapper class="onboarding-vehicle-owner">
                <app-class-searcher #clientSearcher [masterClass]="M_CLIENT" [createData]="{
                    text : 'Crear cliente',
                    by : 'dialog'
                }">
                </app-class-searcher>
            </div>
        </div>

    </div>

    <p></p>

    <mat-tab-group color="primary" mat-stretch-tabs="true" class="vehicle-tabs" [ngClass]="{'dn' : onlyRequired}">

        <!-- FICHA -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>swap_driving_apps_wheel</mat-icon>
                Ficha
            </ng-template>

            <app-card-subtitle>Ficha</app-card-subtitle>
            <div eForm-wrapper>
                <app-vehicleform-ficha [form]="form" [showChassis]="true" [showKm]="true"
                    [currentVehicle]="vehicle"></app-vehicleform-ficha>
            </div>
        </mat-tab>

        <!-- Motor -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>construction</mat-icon>
                Motor
            </ng-template>
            <app-card-subtitle>Motor</app-card-subtitle>
            <app-vehicleform-motor [form]="form"></app-vehicleform-motor>

        </mat-tab>

        <!-- SELLS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>sell</mat-icon>
                Ventas
            </ng-template>

            <app-card-subtitle>Ventas</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <app-class-searcher #csearcher [masterClass]="client" class="w100" [width100]="true" [form_]="form"
                    [formCname]="'clientInvoice_id'" searchPlaceHolder="Cliente a facturar por defecto"
                    [extendedInfo]="false" [required]="false" [createData]="{
                        text : 'Nuevo cliente / empresa',
                        by : v.createContact,
                    }">
                </app-class-searcher>
            </div>
        </mat-tab>

    </mat-tab-group>
</form>


<!-- NO VNVO MODULE SERIE SEELCTOR -->
<ng-template #imageSerieSelector>
    <div class="df fww jcc">
        <!-- CAR TYPE -->
        <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
            *ngIf="showMe(VT.car)">
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m1.png" text="Microcoche"
                [formValue]="0"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m2.png" text="Urbano"
                [formValue]="1"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m3.png" text="Sedan"
                [formValue]="2"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m4.png" text="Descapotable"
                [formValue]="3"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m5.png" text="Coupé"
                [formValue]="4"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m6.png" text="Deportivo"
                [formValue]="5"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m7.png" text="Monovolúmen"
                [formValue]="6"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m8.png" text="Todo Terreno"
                [formValue]="7"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m9.png" text="SUV"
                [formValue]="8"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m10.png" text="Camioneta"
                [formValue]="9"></app-img-toggle-item>
        </app-image-toggle>

        <!-- MOTO TYPE -->
        <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
            *ngIf="showMe(VT.bike)">
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m1.png" text="Sport"
                [formValue]="0"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m2.png" text="Touring"
                [formValue]="1"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m3.png" text="Trail"
                [formValue]="2"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m4.png" text="Scooter"
                [formValue]="3"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m5.png" text="Offroad"
                [formValue]="4"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m6.png" text="Custom"
                [formValue]="5"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m7.png" text="Naked"
                [formValue]="6"></app-img-toggle-item>
        </app-image-toggle>
    </div>
</ng-template>