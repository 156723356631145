import { Component, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { action_type_recambios_budget } from 'src/app/custom-classes/action_types';
import { SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { M_Action } from 'src/app/models/M_Action';
import { ApiService } from 'src/app/services/Api/api.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { BudgetPageFiltesrEnum, GET_BUDGET_FILTERS } from '../workshop-budget/workshop-budget.component';
import { filter } from 'src/app/custom-classes/MasterFilter';

@Component({
    selector: 'app-recambios-budget',
    templateUrl: './recambios-budget.component.html',
    styleUrls: ['./recambios-budget.component.css'],
    standalone: false
})
export class RecambiosBudgetComponent {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;

  v = ViewPath;
  f = filter;
  filters = GET_BUDGET_FILTERS(true);;

  constructor(public subS: SubscriptionService, public companyS: CompanyService, private apiS: ApiService, public paramsS: ParamsService) {}

  ngOnInit(): void {
    this.load();
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == BudgetPageFiltesrEnum.BUDGET_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  load() {
    this.apiS.action.actions(action_type_recambios_budget).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        let f = this.filters.find(f => f.id == BudgetPageFiltesrEnum.BUDGET_TOTAL);
        if (f instanceof SliderFilter) {
          f.changeMax(Math.max(...resp.map(o => o.total || 0)))
        }
      }
    });
  }

  client(action: M_Action) { return action.client?.getName() }
  preicon(action: M_Action) { return isSomethingMissing(action.faults);}
}
