<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Poseedor temporal</p>

<div mat-dialog-content>
    <p class="c_t2">Solamente estarán habilitados aquellos contactos con una ubicación</p>
    <app-card [contentLoaded]="csearcher.loaded" [noStyle]="true">
        <app-class-searcher [showLeftNumber]="false" #csearcher [masterClass]="client" [allData]="" [width100]="true"
            searchPlaceHolder="Buscar contacto" [extendedInfo]="false" [form_]="form" [formCname]="'client_id'"
             [disableIf]="disabledIf.bind(this)" (onLoad)="loaded = true" 
            [createData]="undefined" [required]="false">
        </app-class-searcher>
    </app-card>
</div>

<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="'close'">Cancelar</button>
    <button color="primary" mat-flat-button
        [disabled]=""
        [mat-dialog-close]="csearcher.selected" cdkFocusInitial>Guardar</button>
</div>