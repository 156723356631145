import { M_Contact } from "./M_Contact";
import { M_Vehicle } from "./Vehicles/M_Vehicle";

export class M_VehicleEntry {
    id: number;
    user: number;
    mov_type: number;
    company_id: number;
    vehicle_id: number;
    quanti_in: number;
    loc_in: number;
    loc_out: number;
    quanti_out: number;
    document: string;
    client_id_in: number;
    client_id_out: number;
    pvp: string;
    coment: string;
    coste: string;
    center_id: number;
    invoice_id: number;
    buy_transac_id: number;
    vehicle: M_Vehicle | undefined;
    seller : M_Contact | undefined;
    created_at: Date;
    updated_at: Date;

    urldpf: string | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.user = d.user;
        this.mov_type = d.mov_type;
        this.company_id = d.company_id;
        this.vehicle_id = d.vehicle_id;
        this.quanti_in = d.quanti_in;
        this.loc_in = d.loc_in;
        this.loc_out = d.loc_out;
        this.quanti_out = d.quanti_out;
        this.document = d.document;
        this.client_id_in = d.client_id_in;
        this.client_id_out = d.client_id_out;
        this.pvp = d.pvp;
        this.coment = d.coment;
        this.coste = d.coste;
        this.center_id = d.center_id;
        this.invoice_id = d.invoice_id;
        this.buy_transac_id = d.buy_transac_id;
        this.vehicle = d.vehicle ? new M_Vehicle(d.vehicle) : undefined;
        this.seller = d.seller ? new M_Contact(d.seller) : undefined;
        this.urldpf = d.urlpdf;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
    }
}
