<div [formGroup]="form">

    <div eForm-section>
        <app-chassis-input *ngIf="showChassis" [form]="form" [currentVehicle]="currentVehicle" [required]="false"></app-chassis-input>
        <mat-form-field appearance="outline" *ngIf="showKm">
            <mat-label>Kilómetros</mat-label>
            <input type="number" matInput formControlName="km">
        </mat-form-field>
    </div>

    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Matriculación</mat-label>
            <input placeholder="dd/mm/aaaa" #pickerInput matInput [matDatepicker]="picker" [max]="minDate"
                (click)="picker.open()" formControlName="license_date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Última ITV</mat-label>
            <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_1" [max]="minDate"
                (click)="picker_1.open()" formControlName="last_itv">
            <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
            <mat-datepicker #picker_1 [min]="minDate"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Próxima ITV</mat-label>
            <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_2" [min]="minDate"
                (click)="picker_2.open()" formControlName="next_itv">
            <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
            <mat-datepicker #picker_2></mat-datepicker>
        </mat-form-field>
    </div>
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Fin de garantía</mat-label>
            <input matInput formControlName="end_warranty" [matDatepicker]="pickerEndWarranty">
            <mat-datepicker-toggle matSuffix [for]="pickerEndWarranty"></mat-datepicker-toggle>
            <mat-datepicker #pickerEndWarranty></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Número de garantía</mat-label>
            <input matInput formControlName="number_warranty" placeholder="Número de garantía">
        </mat-form-field>
    </div>
    <div eForm-section>
        <mat-form-field appearance="outline" class="w100">
            <mat-label>Observaciones</mat-label>
            <textarea matInput formControlName="observations" cdkTextareaAutosize cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5" [placeholder]="'Observaciones'">
        </textarea>
        </mat-form-field>
    </div>
</div>