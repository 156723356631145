import { Component, Input } from '@angular/core';

/**
  <app-advanced-details>

    <app-advanced-title>
        Title
    </app-advanced-title>

    <app-advanced-subtitle>
        Subtitle
    </app-advanced-subtitle>

    <app-advanced-heading>
        Heading
    </app-advanced-heading>

    <app-advanced-main-actions>
        The main button actions
    </app-advanced-main-actions>

    <app-advanced-menu-actions>
        The right menu actions
    </app-advanced-menu-actions>

  </app-advanced-details>
 */

@Component({
    selector: 'app-advanced-details',
    templateUrl: './advanced-details.component.html',
    styleUrls: ['./advanced-details.component.css'],
    standalone: false
})
export class AdvancedDetailsComponent {
  @Input() showButton : boolean = true;
}
