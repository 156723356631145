import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[prevent]',
    standalone: false
})
export class PreventDirective {
  constructor(private el: ElementRef<HTMLElement>) { 
    this.el.nativeElement.onclick = (ev : Event) => {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }
}
