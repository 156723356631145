import { Component } from '@angular/core';

@Component({
    selector: 'app-cancel-subscription',
    templateUrl: './cancel-subscription.component.html',
    styleUrls: ['./cancel-subscription.component.css'],
    standalone: false
})
export class CancelSubscriptionComponent {
  constructor() { }
}
