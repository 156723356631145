import { Component, ComponentRef, DoCheck, HostListener, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { MASTER_VN_VO } from 'src/app/constants/masters';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { getRandomNoVehicleMessage } from 'src/app/constants/constants';
import { ApiService } from 'src/app/services/Api/api.service'; import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { GoFaultService } from 'src/app/services/go-fault.service';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { endpoints } from 'src/app/constants/Endpoints';
import { VehicleSearcherComponent } from '../vehicle-searcher/vehicle-searcher.component';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { CreatePurchaseComponent } from 'src/app/components/create-purchase/create-purchase.component';
import { ClassSearcherComponent } from '../../class-searcher/class-searcher.component';

@Component({
    selector: 'app-vehicle-line-table',
    templateUrl: './vehicle-line-table.component.html',
    styleUrls: ['./vehicle-line-table.component.css'],
    standalone: false
})
export class VehicleLineTableComponent implements DoCheck {

  MASTER_VEHICLE = MASTER_VN_VO;
  e = endpoints;
  v = ViewPath;

  /** Container to add new {@link VehicleSearcherComponent} */
  @ViewChild('searchVehicleContainer', { read: ViewContainerRef }) searchVehicleContainer!: ViewContainerRef;
  /** Array of he current {@link VehicleSearcherComponent} on this component */
  searchers: ComponentRef<VehicleSearcherComponent>[] = [];

  /** Vehicles of the component */
  @Input({ required: true }) vehicles: (M_Vehicle)[] = [];
  /** Can user add new ? */
  @Input() canAddNew: boolean = true;
  /** All class searcher vehicles of the component */
  @Input() vehicles_cs: M_Vehicle[] | undefined = undefined;
  /** Can the user modify this table? */
  @Input() canModifyTable: boolean = true;
  /** Cna the user click on the vehicle title? */
  @Input() clickableTitle: boolean = true;
  /** Add save icon to the vehicle line */
  @Input() showSave: boolean = true;


  /** Table pagination */
  @Input() itemsPerPage: number | undefined = undefined;
  paginationArray: number[] = [];
  currentPage: number = 1;
  totalPages: number = 1;


  /** Component parnet (@Optional injection) */
  parent: CreatePurchaseComponent;
  /** Random message of 'Table with no vehicles' */
  noVehicleMessage = getRandomNoVehicleMessage();
  /** Vehicle id to highlight */
  private highlightVehicleId: number | undefined = undefined;
  /** The screen already scrolled to the 'highlightVehicleId' vehicle? */
  private highlightscrolled: boolean = false;


  /** SHIFT + SPACE shortcut listener */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.shiftKey && (event.key === ' ')) {
      if (this.canModifyTable) {
        if (this.searchers.length) {
          this.searchers[0].instance.focus();
        }
        else {
          this.appendVehicleSearcher();
        }
      }
    }
  }

  constructor(public routerS: RouterService, public companyS: CompanyService, private dialog: MatDialog, public responsiveS: ResponsiveService, private apiS: ApiService, private activeRoute: ActivatedRoute, private goFaultS: GoFaultService,
    /** Inject posible parents */
    @Optional() public createPurchase: CreatePurchaseComponent) {
    this.parent = this.checkAndSetParent();

    console.log("🏍️ Vehicle line table")

    /** Check the query parameters in case we have to highlight a specific vehicle  */
    this.activeRoute.queryParams.subscribe(params => {
      let higlight = params['highlight_vehicle_id'];
      if (higlight) { this.highlightVehicleId = higlight; }
    })
  }

  reset(vehicles: M_Vehicle[]) {
    this.vehicles_cs = vehicles;
    this.searchers.forEach(s => {
      s.destroy();
    })
  }


  ngDoCheck(): void {
    this.updatePagination();
  }

  checkAndSetParent() {
    if (this.createPurchase) {
      return this.createPurchase;
    } else {
      throw new Error("VehicleLineComponent parent not implement '...' or is not one of the optional constructor parents");
    }
  }

  setHighlighted(id: number) {
    this.highlightVehicleId = id;
  }

  isHighlighted(v: M_Vehicle, vehicleRef: HTMLTableRowElement) {
    if (!this.highlightVehicleId || this.highlightscrolled) { return; }
    let ishighlight = v.vehicle_id == this.highlightVehicleId;
    if (!this.highlightscrolled && ishighlight) {

      this.highlightscrolled = true;

      setTimeout(() => {
        vehicleRef.classList.add('highlight-vehicle');
        vehicleRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);

      setTimeout(() => {
        vehicleRef.classList.remove('highlight-vehicle');
      }, 3000); // --> make sure to match the 'highlight-product' animation time
    }
  }

  appendVehicleSearcher() {
    let ref = this.searchVehicleContainer.createComponent(VehicleSearcherComponent);
    ref.instance.ref = ref;
    ref.instance.canAddNew = this.canAddNew;

    ref.instance.onSelectVehicle.subscribe(val => {
      this.highlightVehicleId = undefined; //Don't highlight a product if it has just been added
      this.parent.addVehicle(val);
      this.removeProductSearcher(ref);
    });

    ref.instance.onDestroy.subscribe(v => {
      this.removeProductSearcher(ref);
    })

    this.searchers.push(ref);
  }

  private removeProductSearcher(searcher: ComponentRef<VehicleSearcherComponent>) {
    this.searchers.removeElement(searcher);
    searcher.instance.destroy();
  }


  getTableVehicles(): M_Vehicle[] {
    return this.vehicles;
  }

  getAllVehicles() {
    return this.getTableVehicles();
  }

  getSearchers() {
    let csComponents: ClassSearcherComponent<M_Vehicle>[] = [];
    this.searchers.forEach(s => {
      if (s.instance.classSearcher) {
        csComponents.push(s.instance.classSearcher);
      }
    })
    return csComponents;
  }

  get hasChanges() {
    return false;
  }

  canDeleteVehicle(v: M_Vehicle) {
    return true;
  }

  destroy(v: M_Vehicle) {
    this.parent.removeVehicle(v);
  }

  /** Pagination */
  updatePagination() {
    if (!this.itemsPerPage) { return; }
    this.totalPages = Math.ceil(this.vehicles.length / this.itemsPerPage);
    this.generatePaginationArray();
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
  }

  generatePaginationArray() {
    this.paginationArray = Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

}
