import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { ParamsService } from 'src/app/services/params.service';

@Component({
    selector: 'app-pending-payment-dialog',
    templateUrl: './pending-payment-dialog.component.html',
    styleUrls: ['./pending-payment-dialog.component.css'],
    standalone: false
})
export class PendingPaymentDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Contact, public dialogRef: MatDialogRef<PendingPaymentDialogComponent>, private paramsS : ParamsService) { }
  goInvoice(invoice: M_Invoice) {
    this.paramsS.go(ViewPath.invoiceDetails, invoice.id!);
    this.dialogRef.close();
  }
}
