import { Component } from '@angular/core';

@Component({
    selector: 'app-standard-dialog',
    templateUrl: './standard-dialog.component.html',
    styleUrls: ['./standard-dialog.component.css'],
    standalone: false
})

export class StandardDialogComponent {

}
