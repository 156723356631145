<div class="create-item">
    <app-go-back *ngIf="loaded" [text]="edit ? isProfile ? 'Perfil' : 'Detalles de usuario' : 'Tabla de usuarios'" (click)="goBack()"></app-go-back>
    <app-card [contentLoaded]="loaded">
        <app-card-title>{{edit ? uf.originalUser?.name : 'Crear usuario'}}</app-card-title>

        <app-info-panel-rectangle *ngIf="!edit" [panel]="'info'">
            Por cada usuario se cobrarán <span money [val]="subS.status.typedetails.first_user"></span> adicionales a final de mes.
        </app-info-panel-rectangle>

        <app-userform #uf></app-userform>
        <div class="df jcc mt10">
            <button class="onboarding-company-users-finish" color="primary" [disabled]="!uf.form.valid" mat-flat-button (click)="createEdit()" cdkFocusInitial>
                Guardar
            </button>
        </div>
    </app-card>
</div>
