export enum RolesEnum {

    /** Full acces Eina (Super Users) */
    ADMIN = 1,
    GESTOR = 6,

    /** Not full acces Eina */
    ADVISER = 2,
    MECHANIC = 3,
    COMERCIAL = 4,
    RECAMBISTA = 5,
    NOONE = -1,
}

/** Eina Super Users */
export const superUsers = [RolesEnum.ADMIN, RolesEnum.GESTOR];

/** Most common array of roles for views */
export const roleGroup = {
    superOrAdviser: [...superUsers, RolesEnum.ADVISER],
    superOrComercial: [...superUsers, RolesEnum.COMERCIAL],
    superOrAdviserComercial: [...superUsers, RolesEnum.ADVISER, RolesEnum.COMERCIAL],
    superOrRecambista: [...superUsers, RolesEnum.RECAMBISTA],
    superOrAdviserMechanic: [...superUsers, RolesEnum.ADVISER, RolesEnum.MECHANIC],
    everyoneExcepMechanic: [...superUsers, RolesEnum.ADVISER, RolesEnum.COMERCIAL, RolesEnum.RECAMBISTA],
    workloadAsDashboard: [RolesEnum.MECHANIC],
    bigMamaAsDashboard: [...superUsers, RolesEnum.ADVISER, RolesEnum.COMERCIAL, RolesEnum.RECAMBISTA],
}