import { M_Contact } from 'src/app/models/M_Contact';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllProductProvidersComponent } from './all-product-providers/all-product-providers.component';

@Component({
    selector: 'app-product-providers-row',
    templateUrl: './product-providers-row.component.html',
    styleUrls: ['./product-providers-row.component.css'],
    standalone: false
})
export class ProductProvidersRowComponent {
  MAX_RESULTS = 2;
  @Input({ required: true }) providers!: M_Contact[];

  constructor(private d: MatDialog) { }

  openAllProviders() {
    this.d.open(AllProductProvidersComponent, { data: this.providers, autoFocus: false })
  }
}
