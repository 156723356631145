import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { DiscountMonthlyComponent } from '../toolbar/discount-monthly/discount-monthly.component';

@Component({
    selector: 'app-free-month',
    templateUrl: './free-month.component.html',
    styleUrls: ['../pro-tips/pro-tips-button/pro-tips-button.component.css'],
    standalone: false
})
export class FreeMonthComponent {

  constructor(private userS: UserService, private GA: GoogleAnalyticsService, private d: MatDialog) { }

  openDiscount() {
    if (this.userS.user) {
      this.GA.event('1_mes_gratis_clic', "mes_gratis", (this.userS.user.name + " ha hecho clic en mes gratis."));
      this.d.open(DiscountMonthlyComponent, {
        width: "700px", data: {
          title: "¡Aprovecha esta oferta exclusiva!",
          body: "Comparte el siguiente código promocional con otra empresa y obtengan un mes gratis los dos.",
          value: this.userS.user,
          bottom: "No pierdas esta oportunidad y ayuda a otra empresa a descubrir nuestra excelente plataforma.",
          extra: "¡Ganar es fácil para ambos!"
        }
      });
    }
  }
}
