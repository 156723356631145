import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { convertToParamMap, NavigationEnd, Params, Route, Router } from '@angular/router';
import { ViewInerface, Views } from '../custom-classes/View';
import { SnackService } from './snack.service';
import { UserService } from './EinaMainData/user.service';
import { CompanyService } from './EinaMainData/company.service';
import { environment } from 'src/environments/environment';
import { RolesEnum } from '../enums/RolesEnum';
import { ViewPath } from '../app-routing.module';
import { ModulesEnum } from '../enums/ModulesEnum';
import { ModuleFactory } from '../models/Modules/Factory/ModuleFactory';
import { projectConfiguration } from '../app.module';
import { isSharedAccessView } from './session.service';
import { SETTINGS_TAB } from '../views/settings/settings.component';
import { ERROR_MODULE_NO_ACTIVE, ERROR_NOT_PERMISSION, ERROR_SHARED_ACCES } from '../constants/constants';
import { BaseModule } from '../models/Modules/Factory/BaseModule';

@Injectable({
  providedIn: 'root'
})

/** Servicio para gestionar en todo momento la URL activa */
export class RouterService {
  private history: string[] = []
  public route_ = "";
  public previousRoute = "";

  constructor(public router: Router, private location: Location, private snackS: SnackService, private roleS: UserService, private companyS: CompanyService) {
    /** Cade vez que cambia la URL, la variable, se guarda en la variable 'route_'*/
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.previousRoute = this.route_;
        this.route_ = val.url;
        this.history.push(val.url.toString())
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  /**
   * Comprueba que la ruta actual es la misma que la view que se le pasa por parámetro
   * @param view 
   * @returns true | false
   */
  /**Dropdwn / App.Component */
  is(...view: Views[]) {
    for (let i = 0; i < view.length; i++) {
      if (this.is_(view[i])) {
        return true;
      }
    }
    return false;
  }

  private is_(view: Views): boolean {
    if (this.route_ == '/' + view.path) {
      return true;
    }
    else if (this.route_.split("?")[0] != undefined) {
      if (this.route_.split("?")[0] == '/' + view.path) {
        return true;
      }
    }
    return false;
  }

  /** Funcion para navegar entre pantallas 
    También cambia el título de la página */
  goTo(url: ViewInerface, newTab?: boolean, replaceUrl: boolean = false) {

    if (this.problemToGoTo(url)) { return; }

    if (newTab) {
      window.open(url.path, '_blank');
    }
    else {
      return this.router.navigate([url.path], { replaceUrl: replaceUrl });
      //this.titleService.setTitle(url.title);
    }
    return undefined;
  }

  replaceWith(url: ViewInerface, newTab?: boolean) {
    this.goTo(url, newTab, true);
  }


  /**
   * @deprecated
   * Rediríge al usuario hacia una url concreta
   * 
   * @param url View a la que quiere irse
   * @param param_ estado que se quiere passar a la URL
   * 
   * Para obtener el parametro en la pantalla de destino, usar {@link hasState()} y {@link getState()}
   */
  goToWithState(url: ViewInerface, param_: any) {
    if (this.problemToGoTo(url)) { return; }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url.path], { state: { param: param_ } })
    });
  }

  /**
   * Redirige a un view con query parameters
   * @param url View a la que se quiere ir
   * @param params parametros
   * Ejemplo : goWithQueryParams(views.login, {param1 : "value1" , param2 : "value2"})
   */
  goWithQueryParams(url: ViewInerface, params: Params, newTab?: boolean, replaceUrl: boolean = false) {
    if (this.problemToGoTo(url)) { return; }
    if (newTab) {
      let strParams = this.paramsToString(params);
      window.open(url.path + strParams, '_blank');
    }
    else {
      this.router.navigate([url.path], { queryParams: params, replaceUrl: replaceUrl });
    }
  }

  goWithStateAndQueryParams(url: ViewInerface, param_: any, params: Params) {
    if (this.problemToGoTo(url)) { return; }
    this.router.navigate([url.path], { state: { param: param_ }, queryParams: params })
  }

  problemToGoTo(url: ViewInerface) {
    if (!this.userCanGoByModule(url)) {

      let modules: BaseModule[] = [];
      let missingModule = this.getModulesFromRoute(url);
      missingModule.forEach(m => {
        modules.push(ModuleFactory.createModule({ id: m }));
      })

      let userRole = this.roleS.getRole();
      if (userRole == RolesEnum.ADMIN || userRole == RolesEnum.GESTOR) {
        let missingModules = modules.map(m => m.corename).join(", ");
        this.snackS.show("Módulo de " + missingModules + " no activo. Actívelo en el apartado de 'Módulos'.", "Ir", 5).onAction().subscribe(() => {
          this.goWithQueryParams(ViewPath.settings, { tab: SETTINGS_TAB.MODULES })
        });
      }
      else {
        this.snackS.show(ERROR_MODULE_NO_ACTIVE, "Cerrar");
      }
      return true;
    }
    if (!this.userCanGoByRole(url)) {
      this.snackS.show(ERROR_NOT_PERMISSION);
      return true;
    }
    if (!this.userCanGoBySharedUser(url)) {
      this.snackS.show(ERROR_SHARED_ACCES);
      return true;
    }
    return false;
  }

  /**
   * Mira si existe un estado para la pantalla
   */
  hasState(): boolean {
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()!.extras.state) {
      let param: any = this.router.getCurrentNavigation()!.extras!.state!['param'];
      if (param !== undefined) {
        return true
      }
    }

    return false;
  }

  /**
   * Obtiene el estado actual de la pantalla
   */
  getState() {
    return this.router.getCurrentNavigation()!.extras!.state!['param']
  }

  removeState() {
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()!.extras.state) {
      this.router.getCurrentNavigation()!.extras.state = undefined;
    }
  }

  refresh() {
    window.location.reload();
  }

  back() {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.goTo(projectConfiguration.dashboardView)
    }
  }

  next() {
    this.location.forward();
  }

  /** Format a url with a view and query params
   *  Ejemplo : this.routerS.formatUrl(ViewPath.login, {param1 : "value1" , param2 : "value2"})
   */
  formatUrl(url: ViewInerface, params: Params) {
    return document.location.protocol + "//" +
      window.location.hostname +
      (environment.local ? ':4200' : '') +
      "/" + url.path +
      this.paramsToString(params);
  }

  /**
   * 
   * @param params Parameters
   * @returns string like  "?param1=foo&param2=foo2"
   */
  private paramsToString(params: Params): string {
    let paramsMap = convertToParamMap(params);
    if (paramsMap.keys.length == 0) {
      return ""
    }
    let str = "?"
    paramsMap.keys.forEach((key, index) => {
      if (paramsMap.has(key)) {
        str += key + "=" + paramsMap.get(key)
      }
      if (index != paramsMap.keys.length - 1) {
        str += "&"
      }
    });
    return str;
  }

  getRouteFromView(v: Views) {
    return this.router.config.find(r => r.path == v.path);
  }

  userCanGo(v: Views | Route): boolean {
    return this.userCanGoByRole(v) && this.userCanGoByModule(v) && this.userCanGoBySharedUser(v);
  }

  userCanGoByModule(v: Views | Route): boolean {
    let modules = this.getModulesFromRoute(v);
    if (!modules.length) { return true }
    let userModules = this.companyS.getModules();
    let moduleOk = !modules.length || modules.some(m => userModules.includes(m));
    return moduleOk;
  }

  userCanGoByRole(v: Views | Route): boolean {
    let roles = this.getRolesFromRoute(v);
    if (!roles.length) { return true }
    let userRole = this.roleS.getRole();
    let roleOk = !roles.length || (userRole != undefined && roles.includes(userRole));
    return roleOk;
  }

  userCanGoBySharedUser(v: Views | Route) {
    if (!isSharedAccessView(this)) { return true; }
    return v.path == ViewPath.sharedAccesWorkload.path || v.path == ViewPath.sharedAccesLogin.path;
  }

  getRolesFromRoute(v: Views | Route): number[] {
    let r: Route | undefined = v instanceof Views ? this.getRouteFromView(v) : v;
    if (r && r.data && r.data['roles']) {
      return r.data['roles']
    }
    return []
  }

  getModulesFromRoute(v: Views | Route): ModulesEnum[] {
    let r: Route | undefined = v instanceof Views ? this.getRouteFromView(v) : v;
    if (r && r.data && r.data['modules']) {
      return r.data['modules']
    }
    return []
  }

  hasGuard(route: Route, moduleGuard: any): boolean {
    if (route == null || !route.canActivate) { return false };
    if (route.canActivate) {
      const canActivateGuards = route.canActivate.map((guard: any) => guard.prototype.constructor);
      //console.log("CAN ACTIVATE : " + route.title + " ", canActivateGuards.includes(moduleGuard))
      return canActivateGuards.includes(moduleGuard);
    }
    return false;
  }

  getRouteFromString(route_: string): Route | undefined {
    for (let i = 0; i < this.router.config.length; i++) {
      let currentView = this.router.config[i];
      if ('/' + currentView.path == route_ || currentView.path == route_ && currentView.path != projectConfiguration.errorView.path) {
        // console.log("Detectada la view : (" + currentView.title + ", " + currentView.path + ")");
        return currentView;
      }
      else if (route_.split("?")[0] != undefined && route_.split("?")[0] == '/' + currentView.path && currentView.path != projectConfiguration.errorView.path) {
        // console.log("Detectada la view : (" + currentView.title + ", " + currentView.path + ")");
        return currentView;
      }
    }
    return undefined;
  }

}
