import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[float]',
    standalone: false
})
export class FloatDirective {

  @Input() maxNumber: number | undefined;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private allowedKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];


  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    /** Allowed special keys  */
    if (this.allowedKeys.includes(event.key)) {
      /** Don't execute the rest of code */
      return;
    }

    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(position), event.key == '.' || event.key == ',' ? '.' : event.key].join('');
    const nextNumber = next.getNumber();
    if ((next && !String(next).match(this.regex)) || (this.maxNumber && nextNumber && nextNumber > this.maxNumber)) {
      event.preventDefault();
    }
  }
}