<div class="advanced-details-container" *ngIf="user != undefined || (user == undefined && !loaded); else notfound">
    <app-go-back *ngIf="!isProfile" class="back" [text]="'Listado usuarios'"
        (click)="routerS.goWithQueryParams(v.settings, {tab : ST.TEAM})" [show]="loaded"></app-go-back>
    <div class="Details">
        <app-card [contentLoaded]="loaded" class="bc_b1">
            <app-advanced-details *ngIf="user != undefined">

                <app-advanced-title>
                    <app-circular-letter [user]="user" [showTooltip]="false">
                    </app-circular-letter>
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{user.email}}
                </app-advanced-subtitle>

                <app-advanced-heading>
                    <span *ngIf="user.center">{{user.center.name}} - </span>{{user.roleName}}
                </app-advanced-heading>

                <app-advanced-heading2>
                    Código empresa : {{einaDataS.company.id}}
                </app-advanced-heading2>

                <app-advanced-main-actions>
                    <button mat-flat-button color="primary" class="one-line" (click)="openSchedule()">
                        <mat-icon>schedule</mat-icon>
                        Horario
                    </button>
                    <button mat-flat-button color="primary" class="one-line" (click)="goEditUser()">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </app-advanced-main-actions>

                <app-advanced-menu-actions>

                    <!-- CHANGE PASSWORD -->
                    <div *ngIf="isProfile || userIsMe">
                        <button mat-menu-item (click)="changePasswordDialog()">
                            <span>
                                Cambiar contraseña
                            </span>
                        </button>
                    </div>

                    <!-- ADMIN ACTIONS -->
                    <div super-user *ngIf="!userIsMe">
                        <app-layout-send-email #alm [butontype]="'mat-menu-item'" [temaplte]="'user'" [showIcon]="false"
                            [endpoint]="resendConfirm.bind(this)"></app-layout-send-email>
                        <button mat-menu-item *ngIf="!userIsMe && !user.admin" (click)="deleteS.delete(user)">
                            <span>
                                Eliminar usuario
                            </span>
                        </button>
                    </div>
                </app-advanced-menu-actions>
            </app-advanced-details>
        </app-card>
    </div>

    <div class="calendar-section">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="user != undefined">
                <app-card-title>
                    Calendario
                </app-card-title>
                <app-holidays-schedule-calendar [showUnsaved]="false" [selfSave]="true" [prespective]="'user'"
                    [enabledFor]="enabledCalender"
                    [initData]="{companyH :this.user!.center!.holidays, userH: this.user!.holidays, exceptions :this.user!.exceptions, userID :user!.id}"
                    (onSave)="onSaveUserHolidays($event, user!)">
                </app-holidays-schedule-calendar>
            </div>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el usuario'" [goText]="'Ir al listado de usuarios'"
        [view]="v.settings">
    </app-model-not-found>
</ng-template>