import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { EmailSendComponent } from '../email-send/email-send.component';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';

class EmailSendTemplae {
  public body: string;
  constructor(public btnText: string, public dialogTitle: string, body?: string) {
    this.body = body ? body : "El correo se ha enviado a tu dirección de correo asociada a esta cuenta"
  }
}

@Component({
    selector: 'app-layout-send-email',
    templateUrl: './layout-send-email.component.html',
    styleUrls: ['./layout-send-email.component.css'],
    standalone: false
})

export class LayoutSendEmailComponent implements OnInit {
  @HostBinding('class') classes = 'layout-send-email';
  @Input() butontype : "normal" | "mat-menu-item" = "normal"
  @Input() temaplte: "correo" | "factura" | "or" | "smtp" | "user" | "invoice" | "forgot" | "activate" | "budget" | "resend-or" | "recogida" | "abono" | "albaran" | undefined;
  @Input() disabled: boolean = false;
  @Input() showIcon: boolean = true;
  @Input() endpoint?: () => Promise<any>;
  @ViewChild(MatButton) matButton?: MatButton;
  sended = false;
  est: EmailSendTemplae | undefined;
  constructor(private d: MatDialog, private confirmD: ConfirmDialogService) { }

  ngOnInit(): void {
    switch (this.temaplte) {
      case 'correo':
        this.est = new EmailSendTemplae("Correo de ejemplo", "Correo de ejemplo enviado");
        break;
      case 'budget':
        this.est = new EmailSendTemplae("Reenviar al cliente", "Presupuesto reenviado", "Se ha reenviado el presupuesto al cliente.");
        break;
      case 'resend-or':
        this.est = new EmailSendTemplae("Reenviar OR al cliente", "OR reenviada", "Se ha reenviado la OR al cliente.");
        break;
      case 'factura':
        this.est = new EmailSendTemplae("Factura de ejemplo", "Factura de ejemplo enviada");
        break;
      case 'or':
        this.est = new EmailSendTemplae("OR de ejemplo", "OR de ejemplo enviada");
        break;
      case 'smtp':
        this.est = new EmailSendTemplae("Correo de ejemplo", "Correo de ejemplo enviado", "Comprueba la bandeja de entrada del correo asociado a esta cuenta")
        break;
      case 'user':
        this.est = new EmailSendTemplae("Reenviar correo de confirmación", "Correo reenviado", "Se ha reenviado el correo de confirmación del usuario.")
        break;
      case 'invoice':
        this.est = new EmailSendTemplae("Reenviar factura al cliente", "Factura reenviada", "Se ha reenviado la factura al cliente.")
        break;
      case 'forgot':
        this.est = new EmailSendTemplae("Recuperar contraseña", "Correo enviado", "Revise su bandeja de entrada y siga las instrucciones.")
        break;
      case 'activate':
        this.est = new EmailSendTemplae("Activar usuario", "Correo enviado", "Se ha enviado el correo de activación al usuario.")
        break;
      case 'recogida':
        this.est = new EmailSendTemplae("Mail recogida vehículo", "Correo enviado", "Se ha enviado el correo de recogida al usuario.")
        break;
      case 'abono':
        this.est = new EmailSendTemplae("Reenviar abono al cliente", "Correo enviado", "Se ha reenviado el abono al cliente.")
        break;
      case 'albaran':
        this.est = new EmailSendTemplae("Reenviar albaran al cliente", "Correo enviado", "Se ha reenviado el albaran al cliente.")
        break;
    }
  }

  sendEmail() {
    if (this.endpoint) {
      this.endpoint().then((_res: any) => {
        this.sended = true;
        this.d.open(EmailSendComponent, {
          data: {
            title: this.est?.dialogTitle,
            body: this.est?.body
          }
        })
      })
    }
    else {
      this.confirmD.showError("ERROR AL ENVIAR EL CORREO", "Missing endpoint");
      this.sended = true;
    }

  }

  get tooltipIfSended() {
    let message = "";
    if (!this.sended && this.matButton?.disabled && this.temaplte == 'invoice') {
      message = "El usuario no tiene activada la opción de recibir facturas por correo"
    }
    else if (this.sended) {
      message = 'Correo ya enviado'
    }
    return message;
  }
}
