import { Component } from '@angular/core';
import { RouterService } from 'src/app/services/router.service';
import { SigningTimeService } from 'src/app/services/signing-time.service';

@Component({
    selector: 'app-signing-timer',
    templateUrl: './signing-timer.component.html',
    styleUrls: ['./signing-timer.component.css'],
    standalone: false
})
export class SigningTimerComponent {
  
  constructor(public signingS: SigningTimeService,public routerS:RouterService) { }

  get isWorking() {
    return this.signingS.signingData?.workingTimer.isWorking;
  }

  get isPaused() {
    return this.signingS.signingData?.workingTimer.isPaused;
  }
  get notDataSchedule() {
    return this.signingS.signingData?.schedules.some(schedule =>
      schedule.afternoonEnd !== "" ||
      schedule.afternoonStart !== "" ||
      schedule.morningEnd !== "" ||
      schedule.morningStart !== ""
    );
  }
  restart() {
    this.signingS.getData();
  }

  // get shouldSigning() {
  //   return this.signingS.signingData?.now.shouldSigning;
  // }

  startStop() {
    this.signingS.startStop()
  }

  pauseResume(){
    this.signingS.pauseResume()
  }

}
