export interface Country {
  name?: string;
  alpha2Code: string;
  alpha3Code?: string;
  numericCode?: string;
  callingCode?: string;
  flag?: string
}

export const COUNTRIES_DB_ES: Country[] = [
  {
    name: "Afganistán",
    alpha2Code: "AF",
    alpha3Code: "AFG",
    numericCode: "004",
    callingCode: "+93",
    flag: "https://flagcdn.com/24x18/af.png"
  },
  {
    name: "Alandia",
    alpha2Code: "AX",
    alpha3Code: "ALA",
    numericCode: "248",
    callingCode: "+358",
    flag: "https://flagcdn.com/24x18/ax.png"
  },
  {
    name: "Albania",
    alpha2Code: "AL",
    alpha3Code: "ALB",
    numericCode: "008",
    callingCode: "+355",
    flag: "https://flagcdn.com/24x18/al.png"
  },
  {
    name: "Argelia",
    alpha2Code: "DZ",
    alpha3Code: "DZA",
    numericCode: "012",
    callingCode: "+213",
    flag: "https://flagcdn.com/24x18/dz.png"
  },
  {
    name: "Samoa Americana",
    alpha2Code: "AS",
    alpha3Code: "ASM",
    numericCode: "016",
    callingCode: "+1684",
    flag: "https://flagcdn.com/24x18/as.png"
  },
  {
    name: "Andorra",
    alpha2Code: "AD",
    alpha3Code: "AND",
    numericCode: "020",
    callingCode: "+376",
    flag: "https://flagcdn.com/24x18/ad.png"
  },
  {
    name: "Angola",
    alpha2Code: "AO",
    alpha3Code: "AGO",
    numericCode: "024",
    callingCode: "+244",
    flag: "https://flagcdn.com/24x18/ao.png"
  },
  {
    name: "Anguilla",
    alpha2Code: "AI",
    alpha3Code: "AIA",
    numericCode: "660",
    callingCode: "+1264",
    flag: "https://flagcdn.com/24x18/ai.png"
  },
  {
    name: "Antártida",
    alpha2Code: "AQ",
    alpha3Code: "ATA",
    numericCode: "010",
    callingCode: "+672",
    flag: "https://flagcdn.com/24x18/aq.png"
  },
  {
    name: "Antigua y Barbuda",
    alpha2Code: "AG",
    alpha3Code: "ATG",
    numericCode: "028",
    callingCode: "+1268",
    flag: "https://flagcdn.com/24x18/ag.png"
  },
  {
    name: "Argentina",
    alpha2Code: "AR",
    alpha3Code: "ARG",
    numericCode: "032",
    callingCode: "+54",
    flag: "https://flagcdn.com/24x18/ar.png"
  },
  {
    name: "Armenia",
    alpha2Code: "AM",
    alpha3Code: "ARM",
    numericCode: "051",
    callingCode: "+374",
    flag: "https://flagcdn.com/24x18/am.png"
  },
  {
    name: "Aruba",
    alpha2Code: "AW",
    alpha3Code: "ABW",
    numericCode: "533",
    callingCode: "+297",
    flag: "https://flagcdn.com/24x18/aw.png"
  },
  {
    name: "Australia",
    alpha2Code: "AU",
    alpha3Code: "AUS",
    numericCode: "036",
    callingCode: "+61",
    flag: "https://flagcdn.com/24x18/au.png"
  },
  {
    name: "Austria",
    alpha2Code: "AT",
    alpha3Code: "AUT",
    numericCode: "040",
    callingCode: "+43",
    flag: "https://flagcdn.com/24x18/at.png"
  },
  {
    name: "Azerbaiyán",
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    numericCode: "031",
    callingCode: "+994",
    flag: "https://flagcdn.com/24x18/az.png"
  },
  {
    name: "Bahamas",
    alpha2Code: "BS",
    alpha3Code: "BHS",
    numericCode: "044",
    callingCode: "+1242",
    flag: "https://flagcdn.com/24x18/bs.png"
  },
  {
    name: "Bahrein",
    alpha2Code: "BH",
    alpha3Code: "BHR",
    numericCode: "048",
    callingCode: "+973",
    flag: "https://flagcdn.com/24x18/bh.png"
  },
  {
    name: "Bangladesh",
    alpha2Code: "BD",
    alpha3Code: "BGD",
    numericCode: "050",
    callingCode: "+880",
    flag: "https://flagcdn.com/24x18/bd.png"
  },
  {
    name: "Barbados",
    alpha2Code: "BB",
    alpha3Code: "BRB",
    numericCode: "052",
    callingCode: "+1246",
    flag: "https://flagcdn.com/24x18/bb.png"
  },
  {
    name: "Bielorrusia",
    alpha2Code: "BY",
    alpha3Code: "BLR",
    numericCode: "112",
    callingCode: "+375",
    flag: "https://flagcdn.com/24x18/by.png"
  },
  {
    name: "Bélgica",
    alpha2Code: "BE",
    alpha3Code: "BEL",
    numericCode: "056",
    callingCode: "+32",
    flag: "https://flagcdn.com/24x18/be.png"
  },
  {
    name: "Belice",
    alpha2Code: "BZ",
    alpha3Code: "BLZ",
    numericCode: "084",
    callingCode: "+501",
    flag: "https://flagcdn.com/24x18/bz.png"
  },
  {
    name: "Benín",
    alpha2Code: "BJ",
    alpha3Code: "BEN",
    numericCode: "204",
    callingCode: "+229",
    flag: "https://flagcdn.com/24x18/bj.png"
  },
  {
    name: "Bermudas",
    alpha2Code: "BM",
    alpha3Code: "BMU",
    numericCode: "060",
    callingCode: "+1441",
    flag: "https://flagcdn.com/24x18/bm.png"
  },
  {
    name: "Bután",
    alpha2Code: "BT",
    alpha3Code: "BTN",
    numericCode: "064",
    callingCode: "+975",
    flag: "https://flagcdn.com/24x18/bt.png"
  },
  {
    name: "Bolivia",
    alpha2Code: "BO",
    alpha3Code: "BOL",
    numericCode: "068",
    callingCode: "+591",
    flag: "https://flagcdn.com/24x18/bo.png"
  },
  {
    name: "Bonaire, Saint-Eustache y Saba",
    alpha2Code: "BQ",
    alpha3Code: "BES",
    numericCode: "535",
    callingCode: "+5997",
    flag: "https://flagcdn.com/24x18/bq.png"
  },
  {
    name: "Bosnia y Herzegovina",
    alpha2Code: "BA",
    alpha3Code: "BIH",
    numericCode: "070",
    callingCode: "+387",
    flag: "https://flagcdn.com/24x18/ba.png"
  },
  {
    name: "Botswana",
    alpha2Code: "BW",
    alpha3Code: "BWA",
    numericCode: "072",
    callingCode: "+267",
    flag: "https://flagcdn.com/24x18/bw.png"
  },
  {
    name: "Isla Bouvet",
    alpha2Code: "BV",
    alpha3Code: "BVT",
    numericCode: "074",
    callingCode: "+",
    flag: "https://flagcdn.com/24x18/bv.png"
  },
  {
    name: "Brasil",
    alpha2Code: "BR",
    alpha3Code: "BRA",
    numericCode: "076",
    callingCode: "+55",
    flag: "https://flagcdn.com/24x18/br.png"
  },
  {
    name: "Territorio Británico del Océano Índico",
    alpha2Code: "IO",
    alpha3Code: "IOT",
    numericCode: "086",
    callingCode: "+246",
    flag: "https://flagcdn.com/24x18/io.png"
  },
  {
    name: "Islas Ultramarinas Menores de Estados Unidos",
    alpha2Code: "UM",
    alpha3Code: "UMI",
    numericCode: "581",
    callingCode: "+",
    flag: "https://flagcdn.com/24x18/um.png"
  },
  {
    name: "Islas Vírgenes del Reino Unido",
    alpha2Code: "VG",
    alpha3Code: "VGB",
    numericCode: "092",
    callingCode: "+1284",
    flag: "https://flagcdn.com/24x18/vg.png"
  },
  {
    name: "Islas Vírgenes de los Estados Unidos",
    alpha2Code: "VI",
    alpha3Code: "VIR",
    numericCode: "850",
    callingCode: "+1 340",
    flag: "https://flagcdn.com/24x18/vi.png"
  },
  {
    name: "Brunei",
    alpha2Code: "BN",
    alpha3Code: "BRN",
    numericCode: "096",
    callingCode: "+673",
    flag: "https://flagcdn.com/24x18/bn.png"
  },
  {
    name: "Bulgaria",
    alpha2Code: "BG",
    alpha3Code: "BGR",
    numericCode: "100",
    callingCode: "+359",
    flag: "https://flagcdn.com/24x18/bg.png"
  },
  {
    name: "Burkina Faso",
    alpha2Code: "BF",
    alpha3Code: "BFA",
    numericCode: "854",
    callingCode: "+226",
    flag: "https://flagcdn.com/24x18/bf.png"
  },
  {
    name: "Burundi",
    alpha2Code: "BI",
    alpha3Code: "BDI",
    numericCode: "108",
    callingCode: "+257",
    flag: "https://flagcdn.com/24x18/bi.png"
  },
  {
    name: "Camboya",
    alpha2Code: "KH",
    alpha3Code: "KHM",
    numericCode: "116",
    callingCode: "+855",
    flag: "https://flagcdn.com/24x18/kh.png"
  },
  {
    name: "Camerún",
    alpha2Code: "CM",
    alpha3Code: "CMR",
    numericCode: "120",
    callingCode: "+237",
    flag: "https://flagcdn.com/24x18/cm.png"
  },
  {
    name: "Canadá",
    alpha2Code: "CA",
    alpha3Code: "CAN",
    numericCode: "124",
    callingCode: "+1",
    flag: "https://flagcdn.com/24x18/ca.png"
  },
  {
    name: "Cabo Verde",
    alpha2Code: "CV",
    alpha3Code: "CPV",
    numericCode: "132",
    callingCode: "+238",
    flag: "https://flagcdn.com/24x18/cv.png"
  },
  {
    name: "Islas Caimán",
    alpha2Code: "KY",
    alpha3Code: "CYM",
    numericCode: "136",
    callingCode: "+1345",
    flag: "https://flagcdn.com/24x18/ky.png"
  },
  {
    name: "República Centroafricana",
    alpha2Code: "CF",
    alpha3Code: "CAF",
    numericCode: "140",
    callingCode: "+236",
    flag: "https://flagcdn.com/24x18/cf.png"
  },
  {
    name: "Chad",
    alpha2Code: "TD",
    alpha3Code: "TCD",
    numericCode: "148",
    callingCode: "+235",
    flag: "https://flagcdn.com/24x18/td.png"
  },
  {
    name: "Chile",
    alpha2Code: "CL",
    alpha3Code: "CHL",
    numericCode: "152",
    callingCode: "+56",
    flag: "https://flagcdn.com/24x18/cl.png"
  },
  {
    name: "China",
    alpha2Code: "CN",
    alpha3Code: "CHN",
    numericCode: "156",
    callingCode: "+86",
    flag: "https://flagcdn.com/24x18/cn.png"
  },
  {
    name: "Isla de Navidad",
    alpha2Code: "CX",
    alpha3Code: "CXR",
    numericCode: "162",
    callingCode: "+61",
    flag: "https://flagcdn.com/24x18/cx.png"
  },
  {
    name: "Islas Cocos o Islas Keeling",
    alpha2Code: "CC",
    alpha3Code: "CCK",
    numericCode: "166",
    callingCode: "+61",
    flag: "https://flagcdn.com/24x18/cc.png"
  },
  {
    name: "Colombia",
    alpha2Code: "CO",
    alpha3Code: "COL",
    numericCode: "170",
    callingCode: "+57",
    flag: "https://flagcdn.com/24x18/co.png"
  },
  {
    name: "Comoras",
    alpha2Code: "KM",
    alpha3Code: "COM",
    numericCode: "174",
    callingCode: "+269",
    flag: "https://flagcdn.com/24x18/km.png"
  },
  {
    name: "Congo",
    alpha2Code: "CG",
    alpha3Code: "COG",
    numericCode: "178",
    callingCode: "+242",
    flag: "https://flagcdn.com/24x18/cg.png"
  },
  {
    name: "Congo (Rep. Dem.)",
    alpha2Code: "CD",
    alpha3Code: "COD",
    numericCode: "180",
    callingCode: "+243",
    flag: "https://flagcdn.com/24x18/cd.png"
  },
  {
    name: "Islas Cook",
    alpha2Code: "CK",
    alpha3Code: "COK",
    numericCode: "184",
    callingCode: "+682",
    flag: "https://flagcdn.com/24x18/ck.png"
  },
  {
    name: "Costa Rica",
    alpha2Code: "CR",
    alpha3Code: "CRI",
    numericCode: "188",
    callingCode: "+506",
    flag: "https://flagcdn.com/24x18/cr.png"
  },
  {
    name: "Croacia",
    alpha2Code: "HR",
    alpha3Code: "HRV",
    numericCode: "191",
    callingCode: "+385",
    flag: "https://flagcdn.com/24x18/hr.png"
  },
  {
    name: "Cuba",
    alpha2Code: "CU",
    alpha3Code: "CUB",
    numericCode: "192",
    callingCode: "+53",
    flag: "https://flagcdn.com/24x18/cu.png"
  },
  {
    name: "Curaçao",
    alpha2Code: "CW",
    alpha3Code: "CUW",
    numericCode: "531",
    callingCode: "+599",
    flag: "https://flagcdn.com/24x18/cw.png"
  },
  {
    name: "Chipre",
    alpha2Code: "CY",
    alpha3Code: "CYP",
    numericCode: "196",
    callingCode: "+357",
    flag: "https://flagcdn.com/24x18/cy.png"
  },
  {
    name: "República Checa",
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    numericCode: "203",
    callingCode: "+420",
    flag: "https://flagcdn.com/24x18/cz.png"
  },
  {
    name: "Dinamarca",
    alpha2Code: "DK",
    alpha3Code: "DNK",
    numericCode: "208",
    callingCode: "+45",
    flag: "https://flagcdn.com/24x18/dk.png"
  },
  {
    name: "Yibuti",
    alpha2Code: "DJ",
    alpha3Code: "DJI",
    numericCode: "262",
    callingCode: "+253",
    flag: "https://flagcdn.com/24x18/dj.png"
  },
  {
    name: "Dominica",
    alpha2Code: "DM",
    alpha3Code: "DMA",
    numericCode: "212",
    callingCode: "+1767",
    flag: "https://flagcdn.com/24x18/dm.png"
  },
  {
    name: "República Dominicana",
    alpha2Code: "DO",
    alpha3Code: "DOM",
    numericCode: "214",
    callingCode: "+1809",
    flag: "https://flagcdn.com/24x18/do.png"
  },
  {
    name: "Ecuador",
    alpha2Code: "EC",
    alpha3Code: "ECU",
    numericCode: "218",
    callingCode: "+593",
    flag: "https://flagcdn.com/24x18/ec.png"
  },
  {
    name: "Egipto",
    alpha2Code: "EG",
    alpha3Code: "EGY",
    numericCode: "818",
    callingCode: "+20",
    flag: "https://flagcdn.com/24x18/eg.png"
  },
  {
    name: "El Salvador",
    alpha2Code: "SV",
    alpha3Code: "SLV",
    numericCode: "222",
    callingCode: "+503",
    flag: "https://flagcdn.com/24x18/sv.png"
  },
  {
    name: "Guinea Ecuatorial",
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    numericCode: "226",
    callingCode: "+240",
    flag: "https://flagcdn.com/24x18/gq.png"
  },
  {
    name: "Eritrea",
    alpha2Code: "ER",
    alpha3Code: "ERI",
    numericCode: "232",
    callingCode: "+291",
    flag: "https://flagcdn.com/24x18/er.png"
  },
  {
    name: "Estonia",
    alpha2Code: "EE",
    alpha3Code: "EST",
    numericCode: "233",
    callingCode: "+372",
    flag: "https://flagcdn.com/24x18/ee.png"
  },
  {
    name: "Etiopía",
    alpha2Code: "ET",
    alpha3Code: "ETH",
    numericCode: "231",
    callingCode: "+251",
    flag: "https://flagcdn.com/24x18/et.png"
  },
  {
    name: "Islas Malvinas",
    alpha2Code: "FK",
    alpha3Code: "FLK",
    numericCode: "238",
    callingCode: "+500",
    flag: "https://flagcdn.com/24x18/fk.png"
  },
  {
    name: "Islas Faroe",
    alpha2Code: "FO",
    alpha3Code: "FRO",
    numericCode: "234",
    callingCode: "+298",
    flag: "https://flagcdn.com/24x18/fo.png"
  },
  {
    name: "Fiyi",
    alpha2Code: "FJ",
    alpha3Code: "FJI",
    numericCode: "242",
    callingCode: "+679",
    flag: "https://flagcdn.com/24x18/fj.png"
  },
  {
    name: "Finlandia",
    alpha2Code: "FI",
    alpha3Code: "FIN",
    numericCode: "246",
    callingCode: "+358",
    flag: "https://flagcdn.com/24x18/fi.png"
  },
  {
    name: "Francia",
    alpha2Code: "FR",
    alpha3Code: "FRA",
    numericCode: "250",
    callingCode: "+33",
    flag: "https://flagcdn.com/24x18/fr.png"
  },
  {
    name: "Guayana Francesa",
    alpha2Code: "GF",
    alpha3Code: "GUF",
    numericCode: "254",
    callingCode: "+594",
    flag: "https://flagcdn.com/24x18/gf.png"
  },
  {
    name: "Polinesia Francesa",
    alpha2Code: "PF",
    alpha3Code: "PYF",
    numericCode: "258",
    callingCode: "+689",
    flag: "https://flagcdn.com/24x18/pf.png"
  },
  {
    name: "Tierras Australes y Antárticas Francesas",
    alpha2Code: "TF",
    alpha3Code: "ATF",
    numericCode: "260",
    callingCode: "+",
    flag: "https://flagcdn.com/24x18/tf.png"
  },
  {
    name: "Gabón",
    alpha2Code: "GA",
    alpha3Code: "GAB",
    numericCode: "266",
    callingCode: "+241",
    flag: "https://flagcdn.com/24x18/ga.png"
  },
  {
    name: "Gambia",
    alpha2Code: "GM",
    alpha3Code: "GMB",
    numericCode: "270",
    callingCode: "+220",
    flag: "https://flagcdn.com/24x18/gm.png"
  },
  {
    name: "Georgia",
    alpha2Code: "GE",
    alpha3Code: "GEO",
    numericCode: "268",
    callingCode: "+995",
    flag: "https://flagcdn.com/24x18/ge.png"
  },
  {
    name: "Alemania",
    alpha2Code: "DE",
    alpha3Code: "DEU",
    numericCode: "276",
    callingCode: "+49",
    flag: "https://flagcdn.com/24x18/de.png"
  },
  {
    name: "Ghana",
    alpha2Code: "GH",
    alpha3Code: "GHA",
    numericCode: "288",
    callingCode: "+233",
    flag: "https://flagcdn.com/24x18/gh.png"
  },
  {
    name: "Gibraltar",
    alpha2Code: "GI",
    alpha3Code: "GIB",
    numericCode: "292",
    callingCode: "+350",
    flag: "https://flagcdn.com/24x18/gi.png"
  },
  {
    name: "Grecia",
    alpha2Code: "GR",
    alpha3Code: "GRC",
    numericCode: "300",
    callingCode: "+30",
    flag: "https://flagcdn.com/24x18/gr.png"
  },
  {
    name: "Groenlandia",
    alpha2Code: "GL",
    alpha3Code: "GRL",
    numericCode: "304",
    callingCode: "+299",
    flag: "https://flagcdn.com/24x18/gl.png"
  },
  {
    name: "Grenada",
    alpha2Code: "GD",
    alpha3Code: "GRD",
    numericCode: "308",
    callingCode: "+1473",
    flag: "https://flagcdn.com/24x18/gd.png"
  },
  {
    name: "Guadalupe",
    alpha2Code: "GP",
    alpha3Code: "GLP",
    numericCode: "312",
    callingCode: "+590",
    flag: "https://flagcdn.com/24x18/gp.png"
  },
  {
    name: "Guam",
    alpha2Code: "GU",
    alpha3Code: "GUM",
    numericCode: "316",
    callingCode: "+1671",
    flag: "https://flagcdn.com/24x18/gu.png"
  },
  {
    name: "Guatemala",
    alpha2Code: "GT",
    alpha3Code: "GTM",
    numericCode: "320",
    callingCode: "+502",
    flag: "https://flagcdn.com/24x18/gt.png"
  },
  {
    name: "Guernsey",
    alpha2Code: "GG",
    alpha3Code: "GGY",
    numericCode: "831",
    callingCode: "+44",
    flag: "https://flagcdn.com/24x18/gg.png"
  },
  {
    name: "Guinea",
    alpha2Code: "GN",
    alpha3Code: "GIN",
    numericCode: "324",
    callingCode: "+224",
    flag: "https://flagcdn.com/24x18/gn.png"
  },
  {
    name: "Guinea-Bisáu",
    alpha2Code: "GW",
    alpha3Code: "GNB",
    numericCode: "624",
    callingCode: "+245",
    flag: "https://flagcdn.com/24x18/gw.png"
  },
  {
    name: "Guyana",
    alpha2Code: "GY",
    alpha3Code: "GUY",
    numericCode: "328",
    callingCode: "+592",
    flag: "https://flagcdn.com/24x18/gy.png"
  },
  {
    name: "Haiti",
    alpha2Code: "HT",
    alpha3Code: "HTI",
    numericCode: "332",
    callingCode: "+509",
    flag: "https://flagcdn.com/24x18/ht.png"
  },
  {
    name: "Islas Heard y McDonald",
    alpha2Code: "HM",
    alpha3Code: "HMD",
    numericCode: "334",
    callingCode: "+",
    flag: "https://flagcdn.com/24x18/hm.png"
  },
  {
    name: "Santa Sede",
    alpha2Code: "VA",
    alpha3Code: "VAT",
    numericCode: "336",
    callingCode: "+379",
    flag: "https://flagcdn.com/24x18/va.png"
  },
  {
    name: "Honduras",
    alpha2Code: "HN",
    alpha3Code: "HND",
    numericCode: "340",
    callingCode: "+504",
    flag: "https://flagcdn.com/24x18/hn.png"
  },
  {
    name: "Hong Kong",
    alpha2Code: "HK",
    alpha3Code: "HKG",
    numericCode: "344",
    callingCode: "+852",
    flag: "https://flagcdn.com/24x18/hk.png"
  },
  {
    name: "Hungría",
    alpha2Code: "HU",
    alpha3Code: "HUN",
    numericCode: "348",
    callingCode: "+36",
    flag: "https://flagcdn.com/24x18/hu.png"
  },
  {
    name: "Islandia",
    alpha2Code: "IS",
    alpha3Code: "ISL",
    numericCode: "352",
    callingCode: "+354",
    flag: "https://flagcdn.com/24x18/is.png"
  },
  {
    name: "India",
    alpha2Code: "IN",
    alpha3Code: "IND",
    numericCode: "356",
    callingCode: "+91",
    flag: "https://flagcdn.com/24x18/in.png"
  },
  {
    name: "Indonesia",
    alpha2Code: "ID",
    alpha3Code: "IDN",
    numericCode: "360",
    callingCode: "+62",
    flag: "https://flagcdn.com/24x18/id.png"
  },
  {
    name: "Costa de Marfil",
    alpha2Code: "CI",
    alpha3Code: "CIV",
    numericCode: "384",
    callingCode: "+225",
    flag: "https://flagcdn.com/24x18/ci.png"
  },
  {
    name: "Iran",
    alpha2Code: "IR",
    alpha3Code: "IRN",
    numericCode: "364",
    callingCode: "+98",
    flag: "https://flagcdn.com/24x18/ir.png"
  },
  {
    name: "Irak",
    alpha2Code: "IQ",
    alpha3Code: "IRQ",
    numericCode: "368",
    callingCode: "+964",
    flag: "https://flagcdn.com/24x18/iq.png"
  },
  {
    name: "Irlanda",
    alpha2Code: "IE",
    alpha3Code: "IRL",
    numericCode: "372",
    callingCode: "+353",
    flag: "https://flagcdn.com/24x18/ie.png"
  },
  {
    name: "Isla de Man",
    alpha2Code: "IM",
    alpha3Code: "IMN",
    numericCode: "833",
    callingCode: "+44",
    flag: "https://flagcdn.com/24x18/im.png"
  },
  {
    name: "Israel",
    alpha2Code: "IL",
    alpha3Code: "ISR",
    numericCode: "376",
    callingCode: "+972",
    flag: "https://flagcdn.com/24x18/il.png"
  },
  {
    name: "Italia",
    alpha2Code: "IT",
    alpha3Code: "ITA",
    numericCode: "380",
    callingCode: "+39",
    flag: "https://flagcdn.com/24x18/it.png"
  },
  {
    name: "Jamaica",
    alpha2Code: "JM",
    alpha3Code: "JAM",
    numericCode: "388",
    callingCode: "+1876",
    flag: "https://flagcdn.com/24x18/jm.png"
  },
  {
    name: "Japón",
    alpha2Code: "JP",
    alpha3Code: "JPN",
    numericCode: "392",
    callingCode: "+81",
    flag: "https://flagcdn.com/24x18/jp.png"
  },
  {
    name: "Jersey",
    alpha2Code: "JE",
    alpha3Code: "JEY",
    numericCode: "832",
    callingCode: "+44",
    flag: "https://flagcdn.com/24x18/je.png"
  },
  {
    name: "Jordania",
    alpha2Code: "JO",
    alpha3Code: "JOR",
    numericCode: "400",
    callingCode: "+962",
    flag: "https://flagcdn.com/24x18/jo.png"
  },
  {
    name: "Kazajistán",
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    numericCode: "398",
    callingCode: "+7",
    flag: "https://flagcdn.com/24x18/kz.png"
  },
  {
    name: "Kenia",
    alpha2Code: "KE",
    alpha3Code: "KEN",
    numericCode: "404",
    callingCode: "+254",
    flag: "https://flagcdn.com/24x18/ke.png"
  },
  {
    name: "Kiribati",
    alpha2Code: "KI",
    alpha3Code: "KIR",
    numericCode: "296",
    callingCode: "+686",
    flag: "https://flagcdn.com/24x18/ki.png"
  },
  {
    name: "Kuwait",
    alpha2Code: "KW",
    alpha3Code: "KWT",
    numericCode: "414",
    callingCode: "+965",
    flag: "https://flagcdn.com/24x18/kw.png"
  },
  {
    name: "Kirguizistán",
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    numericCode: "417",
    callingCode: "+996",
    flag: "https://flagcdn.com/24x18/kg.png"
  },
  {
    name: "Laos",
    alpha2Code: "LA",
    alpha3Code: "LAO",
    numericCode: "418",
    callingCode: "+856",
    flag: "https://flagcdn.com/24x18/la.png"
  },
  {
    name: "Letonia",
    alpha2Code: "LV",
    alpha3Code: "LVA",
    numericCode: "428",
    callingCode: "+371",
    flag: "https://flagcdn.com/24x18/lv.png"
  },
  {
    name: "Líbano",
    alpha2Code: "LB",
    alpha3Code: "LBN",
    numericCode: "422",
    callingCode: "+961",
    flag: "https://flagcdn.com/24x18/lb.png"
  },
  {
    name: "Lesotho",
    alpha2Code: "LS",
    alpha3Code: "LSO",
    numericCode: "426",
    callingCode: "+266",
    flag: "https://flagcdn.com/24x18/ls.png"
  },
  {
    name: "Liberia",
    alpha2Code: "LR",
    alpha3Code: "LBR",
    numericCode: "430",
    callingCode: "+231",
    flag: "https://flagcdn.com/24x18/lr.png"
  },
  {
    name: "Libia",
    alpha2Code: "LY",
    alpha3Code: "LBY",
    numericCode: "434",
    callingCode: "+218",
    flag: "https://flagcdn.com/24x18/ly.png"
  },
  {
    name: "Liechtenstein",
    alpha2Code: "LI",
    alpha3Code: "LIE",
    numericCode: "438",
    callingCode: "+423",
    flag: "https://flagcdn.com/24x18/li.png"
  },
  {
    name: "Lituania",
    alpha2Code: "LT",
    alpha3Code: "LTU",
    numericCode: "440",
    callingCode: "+370",
    flag: "https://flagcdn.com/24x18/lt.png"
  },
  {
    name: "Luxemburgo",
    alpha2Code: "LU",
    alpha3Code: "LUX",
    numericCode: "442",
    callingCode: "+352",
    flag: "https://flagcdn.com/24x18/lu.png"
  },
  {
    name: "Macao",
    alpha2Code: "MO",
    alpha3Code: "MAC",
    numericCode: "446",
    callingCode: "+853",
    flag: "https://flagcdn.com/24x18/mo.png"
  },
  {
    name: "Macedonia",
    alpha2Code: "MK",
    alpha3Code: "MKD",
    numericCode: "807",
    callingCode: "+389",
    flag: "https://flagcdn.com/24x18/mk.png"
  },
  {
    name: "Madagascar",
    alpha2Code: "MG",
    alpha3Code: "MDG",
    numericCode: "450",
    callingCode: "+261",
    flag: "https://flagcdn.com/24x18/mg.png"
  },
  {
    name: "Malawi",
    alpha2Code: "MW",
    alpha3Code: "MWI",
    numericCode: "454",
    callingCode: "+265",
    flag: "https://flagcdn.com/24x18/mw.png"
  },
  {
    name: "Malasia",
    alpha2Code: "MY",
    alpha3Code: "MYS",
    numericCode: "458",
    callingCode: "+60",
    flag: "https://flagcdn.com/24x18/my.png"
  },
  {
    name: "Maldivas",
    alpha2Code: "MV",
    alpha3Code: "MDV",
    numericCode: "462",
    callingCode: "+960",
    flag: "https://flagcdn.com/24x18/mv.png"
  },
  {
    name: "Mali",
    alpha2Code: "ML",
    alpha3Code: "MLI",
    numericCode: "466",
    callingCode: "+223",
    flag: "https://flagcdn.com/24x18/ml.png"
  },
  {
    name: "Malta",
    alpha2Code: "MT",
    alpha3Code: "MLT",
    numericCode: "470",
    callingCode: "+356",
    flag: "https://flagcdn.com/24x18/mt.png"
  },
  {
    name: "Islas Marshall",
    alpha2Code: "MH",
    alpha3Code: "MHL",
    numericCode: "584",
    callingCode: "+692",
    flag: "https://flagcdn.com/24x18/mh.png"
  },
  {
    name: "Martinica",
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    numericCode: "474",
    callingCode: "+596",
    flag: "https://flagcdn.com/24x18/mq.png"
  },
  {
    name: "Mauritania",
    alpha2Code: "MR",
    alpha3Code: "MRT",
    numericCode: "478",
    callingCode: "+222",
    flag: "https://flagcdn.com/24x18/mr.png"
  },
  {
    name: "Mauricio",
    alpha2Code: "MU",
    alpha3Code: "MUS",
    numericCode: "480",
    callingCode: "+230",
    flag: "https://flagcdn.com/24x18/mu.png"
  },
  {
    name: "Mayotte",
    alpha2Code: "YT",
    alpha3Code: "MYT",
    numericCode: "175",
    callingCode: "+262",
    flag: "https://flagcdn.com/24x18/yt.png"
  },
  {
    name: "México",
    alpha2Code: "MX",
    alpha3Code: "MEX",
    numericCode: "484",
    callingCode: "+52",
    flag: "https://flagcdn.com/24x18/mx.png"
  },
  {
    name: "Micronesia",
    alpha2Code: "FM",
    alpha3Code: "FSM",
    numericCode: "583",
    callingCode: "+691",
    flag: "https://flagcdn.com/24x18/fm.png"
  },
  {
    name: "Moldavia",
    alpha2Code: "MD",
    alpha3Code: "MDA",
    numericCode: "498",
    callingCode: "+373",
    flag: "https://flagcdn.com/24x18/md.png"
  },
  {
    name: "Mónaco",
    alpha2Code: "MC",
    alpha3Code: "MCO",
    numericCode: "492",
    callingCode: "+377",
    flag: "https://flagcdn.com/24x18/mc.png"
  },
  {
    name: "Mongolia",
    alpha2Code: "MN",
    alpha3Code: "MNG",
    numericCode: "496",
    callingCode: "+976",
    flag: "https://flagcdn.com/24x18/mn.png"
  },
  {
    name: "Montenegro",
    alpha2Code: "ME",
    alpha3Code: "MNE",
    numericCode: "499",
    callingCode: "+382",
    flag: "https://flagcdn.com/24x18/me.png"
  },
  {
    name: "Montserrat",
    alpha2Code: "MS",
    alpha3Code: "MSR",
    numericCode: "500",
    callingCode: "+1664",
    flag: "https://flagcdn.com/24x18/ms.png"
  },
  {
    name: "Marruecos",
    alpha2Code: "MA",
    alpha3Code: "MAR",
    numericCode: "504",
    callingCode: "+212",
    flag: "https://flagcdn.com/24x18/ma.png"
  },
  {
    name: "Mozambique",
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    numericCode: "508",
    callingCode: "+258",
    flag: "https://flagcdn.com/24x18/mz.png"
  },
  {
    name: "Myanmar",
    alpha2Code: "MM",
    alpha3Code: "MMR",
    numericCode: "104",
    callingCode: "+95",
    flag: "https://flagcdn.com/24x18/mm.png"
  },
  {
    name: "Namibia",
    alpha2Code: "NA",
    alpha3Code: "NAM",
    numericCode: "516",
    callingCode: "+264",
    flag: "https://flagcdn.com/24x18/na.png"
  },
  {
    name: "Nauru",
    alpha2Code: "NR",
    alpha3Code: "NRU",
    numericCode: "520",
    callingCode: "+674",
    flag: "https://flagcdn.com/24x18/nr.png"
  },
  {
    name: "Nepal",
    alpha2Code: "NP",
    alpha3Code: "NPL",
    numericCode: "524",
    callingCode: "+977",
    flag: "https://flagcdn.com/24x18/np.png"
  },
  {
    name: "Países Bajos",
    alpha2Code: "NL",
    alpha3Code: "NLD",
    numericCode: "528",
    callingCode: "+31",
    flag: "https://flagcdn.com/24x18/nl.png"
  },
  {
    name: "Nueva Caledonia",
    alpha2Code: "NC",
    alpha3Code: "NCL",
    numericCode: "540",
    callingCode: "+687",
    flag: "https://flagcdn.com/24x18/nc.png"
  },
  {
    name: "Nueva Zelanda",
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    numericCode: "554",
    callingCode: "+64",
    flag: "https://flagcdn.com/24x18/nz.png"
  },
  {
    name: "Nicaragua",
    alpha2Code: "NI",
    alpha3Code: "NIC",
    numericCode: "558",
    callingCode: "+505",
    flag: "https://flagcdn.com/24x18/ni.png"
  },
  {
    name: "Níger",
    alpha2Code: "NE",
    alpha3Code: "NER",
    numericCode: "562",
    callingCode: "+227",
    flag: "https://flagcdn.com/24x18/ne.png"
  },
  {
    name: "Nigeria",
    alpha2Code: "NG",
    alpha3Code: "NGA",
    numericCode: "566",
    callingCode: "+234",
    flag: "https://flagcdn.com/24x18/ng.png"
  },
  {
    name: "Niue",
    alpha2Code: "NU",
    alpha3Code: "NIU",
    numericCode: "570",
    callingCode: "+683",
    flag: "https://flagcdn.com/24x18/nu.png"
  },
  {
    name: "Isla de Norfolk",
    alpha2Code: "NF",
    alpha3Code: "NFK",
    numericCode: "574",
    callingCode: "+672",
    flag: "https://flagcdn.com/24x18/nf.png"
  },
  {
    name: "Corea del Norte",
    alpha2Code: "KP",
    alpha3Code: "PRK",
    numericCode: "408",
    callingCode: "+850",
    flag: "https://flagcdn.com/24x18/kp.png"
  },
  {
    name: "Islas Marianas del Norte",
    alpha2Code: "MP",
    alpha3Code: "MNP",
    numericCode: "580",
    callingCode: "+1670",
    flag: "https://flagcdn.com/24x18/mp.png"
  },
  {
    name: "Noruega",
    alpha2Code: "NO",
    alpha3Code: "NOR",
    numericCode: "578",
    callingCode: "+47",
    flag: "https://flagcdn.com/24x18/no.png"
  },
  {
    name: "Omán",
    alpha2Code: "OM",
    alpha3Code: "OMN",
    numericCode: "512",
    callingCode: "+968",
    flag: "https://flagcdn.com/24x18/om.png"
  },
  {
    name: "Pakistán",
    alpha2Code: "PK",
    alpha3Code: "PAK",
    numericCode: "586",
    callingCode: "+92",
    flag: "https://flagcdn.com/24x18/pk.png"
  },
  {
    name: "Palau",
    alpha2Code: "PW",
    alpha3Code: "PLW",
    numericCode: "585",
    callingCode: "+680",
    flag: "https://flagcdn.com/24x18/pw.png"
  },
  {
    name: "Palestina",
    alpha2Code: "PS",
    alpha3Code: "PSE",
    numericCode: "275",
    callingCode: "+970",
    flag: "https://flagcdn.com/24x18/ps.png"
  },
  {
    name: "Panamá",
    alpha2Code: "PA",
    alpha3Code: "PAN",
    numericCode: "591",
    callingCode: "+507",
    flag: "https://flagcdn.com/24x18/pa.png"
  },
  {
    name: "Papúa Nueva Guinea",
    alpha2Code: "PG",
    alpha3Code: "PNG",
    numericCode: "598",
    callingCode: "+675",
    flag: "https://flagcdn.com/24x18/pg.png"
  },
  {
    name: "Paraguay",
    alpha2Code: "PY",
    alpha3Code: "PRY",
    numericCode: "600",
    callingCode: "+595",
    flag: "https://flagcdn.com/24x18/py.png"
  },
  {
    name: "Perú",
    alpha2Code: "PE",
    alpha3Code: "PER",
    numericCode: "604",
    callingCode: "+51",
    flag: "https://flagcdn.com/24x18/pe.png"
  },
  {
    name: "Filipinas",
    alpha2Code: "PH",
    alpha3Code: "PHL",
    numericCode: "608",
    callingCode: "+63",
    flag: "https://flagcdn.com/24x18/ph.png"
  },
  {
    name: "Islas Pitcairn",
    alpha2Code: "PN",
    alpha3Code: "PCN",
    numericCode: "612",
    callingCode: "+64",
    flag: "https://flagcdn.com/24x18/pn.png"
  },
  {
    name: "Polonia",
    alpha2Code: "PL",
    alpha3Code: "POL",
    numericCode: "616",
    callingCode: "+48",
    flag: "https://flagcdn.com/24x18/pl.png"
  },
  {
    name: "Portugal",
    alpha2Code: "PT",
    alpha3Code: "PRT",
    numericCode: "620",
    callingCode: "+351",
    flag: "https://flagcdn.com/24x18/pt.png"
  },
  {
    name: "Puerto Rico",
    alpha2Code: "PR",
    alpha3Code: "PRI",
    numericCode: "630",
    callingCode: "+1",
    flag: "https://flagcdn.com/24x18/pr.png"
  },
  {
    name: "Catar",
    alpha2Code: "QA",
    alpha3Code: "QAT",
    numericCode: "634",
    callingCode: "+974",
    flag: "https://flagcdn.com/24x18/qa.png"
  },
  {
    name: "Kosovo",
    alpha2Code: "XK",
    alpha3Code: "KOS",
    callingCode: "+383",
    flag: "https://flagcdn.com/24x18/xk.png"
  },
  {
    name: "Reunión",
    alpha2Code: "RE",
    alpha3Code: "REU",
    numericCode: "638",
    callingCode: "+262",
    flag: "https://flagcdn.com/24x18/re.png"
  },
  {
    name: "Rumania",
    alpha2Code: "RO",
    alpha3Code: "ROU",
    numericCode: "642",
    callingCode: "+40",
    flag: "https://flagcdn.com/24x18/ro.png"
  },
  {
    name: "Rusia",
    alpha2Code: "RU",
    alpha3Code: "RUS",
    numericCode: "643",
    callingCode: "+7",
    flag: "https://flagcdn.com/24x18/ru.png"
  },
  {
    name: "Ruanda",
    alpha2Code: "RW",
    alpha3Code: "RWA",
    numericCode: "646",
    callingCode: "+250",
    flag: "https://flagcdn.com/24x18/rw.png"
  },
  {
    name: "San Bartolomé",
    alpha2Code: "BL",
    alpha3Code: "BLM",
    numericCode: "652",
    callingCode: "+590",
    flag: "https://flagcdn.com/24x18/bl.png"
  },
  {
    name: "Santa Helena",
    alpha2Code: "SH",
    alpha3Code: "SHN",
    numericCode: "654",
    callingCode: "+290",
    flag: "https://flagcdn.com/24x18/sh.png"
  },
  {
    name: "San Cristóbal y Nieves",
    alpha2Code: "KN",
    alpha3Code: "KNA",
    numericCode: "659",
    callingCode: "+1869",
    flag: "https://flagcdn.com/24x18/kn.png"
  },
  {
    name: "Santa Lucía",
    alpha2Code: "LC",
    alpha3Code: "LCA",
    numericCode: "662",
    callingCode: "+1758",
    flag: "https://flagcdn.com/24x18/lc.png"
  },
  {
    name: "Saint Martin",
    alpha2Code: "MF",
    alpha3Code: "MAF",
    numericCode: "663",
    callingCode: "+590",
    flag: "https://flagcdn.com/24x18/mf.png"
  },
  {
    name: "San Pedro y Miquelón",
    alpha2Code: "PM",
    alpha3Code: "SPM",
    numericCode: "666",
    callingCode: "+508",
    flag: "https://flagcdn.com/24x18/pm.png"
  },
  {
    name: "San Vicente y Granadinas",
    alpha2Code: "VC",
    alpha3Code: "VCT",
    numericCode: "670",
    callingCode: "+1784",
    flag: "https://flagcdn.com/24x18/vc.png"
  },
  {
    name: "Samoa",
    alpha2Code: "WS",
    alpha3Code: "WSM",
    numericCode: "882",
    callingCode: "+685",
    flag: "https://flagcdn.com/24x18/ws.png"
  },
  {
    name: "San Marino",
    alpha2Code: "SM",
    alpha3Code: "SMR",
    numericCode: "674",
    callingCode: "+378",
    flag: "https://flagcdn.com/24x18/sm.png"
  },
  {
    name: "Santo Tomé y Príncipe",
    alpha2Code: "ST",
    alpha3Code: "STP",
    numericCode: "678",
    callingCode: "+239",
    flag: "https://flagcdn.com/24x18/st.png"
  },
  {
    name: "Arabia Saudí",
    alpha2Code: "SA",
    alpha3Code: "SAU",
    numericCode: "682",
    callingCode: "+966",
    flag: "https://flagcdn.com/24x18/sa.png"
  },
  {
    name: "Senegal",
    alpha2Code: "SN",
    alpha3Code: "SEN",
    numericCode: "686",
    callingCode: "+221",
    flag: "https://flagcdn.com/24x18/sn.png"
  },
  {
    name: "Serbia",
    alpha2Code: "RS",
    alpha3Code: "SRB",
    numericCode: "688",
    callingCode: "+381",
    flag: "https://flagcdn.com/24x18/rs.png"
  },
  {
    name: "Seychelles",
    alpha2Code: "SC",
    alpha3Code: "SYC",
    numericCode: "690",
    callingCode: "+248",
    flag: "https://flagcdn.com/24x18/sc.png"
  },
  {
    name: "Sierra Leone",
    alpha2Code: "SL",
    alpha3Code: "SLE",
    numericCode: "694",
    callingCode: "+232",
    flag: "https://flagcdn.com/24x18/sl.png"
  },
  {
    name: "Singapur",
    alpha2Code: "SG",
    alpha3Code: "SGP",
    numericCode: "702",
    callingCode: "+65",
    flag: "https://flagcdn.com/24x18/sg.png"
  },
  {
    name: "San Martín (parte holandesa)",
    alpha2Code: "SX",
    alpha3Code: "SXM",
    numericCode: "534",
    callingCode: "+1721",
    flag: "https://flagcdn.com/24x18/sx.png"
  },
  {
    name: "República Eslovaca",
    alpha2Code: "SK",
    alpha3Code: "SVK",
    numericCode: "703",
    callingCode: "+421",
    flag: "https://flagcdn.com/24x18/sk.png"
  },
  {
    name: "Eslovenia",
    alpha2Code: "SI",
    alpha3Code: "SVN",
    numericCode: "705",
    callingCode: "+386",
    flag: "https://flagcdn.com/24x18/si.png"
  },
  {
    name: "Islas Salomón",
    alpha2Code: "SB",
    alpha3Code: "SLB",
    numericCode: "090",
    callingCode: "+677",
    flag: "https://flagcdn.com/24x18/sb.png"
  },
  {
    name: "Somalia",
    alpha2Code: "SO",
    alpha3Code: "SOM",
    numericCode: "706",
    callingCode: "+252",
    flag: "https://flagcdn.com/24x18/so.png"
  },
  {
    name: "República de Sudáfrica",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    numericCode: "710",
    callingCode: "+27",
    flag: "https://flagcdn.com/24x18/za.png"
  },
  {
    name: "Islas Georgias del Sur y Sandwich del Sur",
    alpha2Code: "GS",
    alpha3Code: "SGS",
    numericCode: "239",
    callingCode: "+500",
    flag: "https://flagcdn.com/24x18/gs.png"
  },
  {
    name: "Corea del Sur",
    alpha2Code: "KR",
    alpha3Code: "KOR",
    numericCode: "410",
    callingCode: "+82",
    flag: "https://flagcdn.com/24x18/kr.png"
  },
  {
    name: "Sudán del Sur",
    alpha2Code: "SS",
    alpha3Code: "SSD",
    numericCode: "728",
    callingCode: "+211",
    flag: "https://flagcdn.com/24x18/ss.png"
  },
  {
    name: "España",
    alpha2Code: "ES",
    alpha3Code: "ESP",
    numericCode: "724",
    callingCode: "+34",
    flag: "https://flagcdn.com/24x18/es.png"
  },
  {
    name: "Sri Lanka",
    alpha2Code: "LK",
    alpha3Code: "LKA",
    numericCode: "144",
    callingCode: "+94",
    flag: "https://flagcdn.com/24x18/lk.png"
  },
  {
    name: "Sudán",
    alpha2Code: "SD",
    alpha3Code: "SDN",
    numericCode: "729",
    callingCode: "+249",
    flag: "https://flagcdn.com/24x18/sd.png"
  },
  {
    name: "Surinam",
    alpha2Code: "SR",
    alpha3Code: "SUR",
    numericCode: "740",
    callingCode: "+597",
    flag: "https://flagcdn.com/24x18/sr.png"
  },
  {
    name: "Islas Svalbard y Jan Mayen",
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    numericCode: "744",
    callingCode: "+4779",
    flag: "https://flagcdn.com/24x18/sj.png"
  },
  {
    name: "Suazilandia",
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    numericCode: "748",
    callingCode: "+268",
    flag: "https://flagcdn.com/24x18/sz.png"
  },
  {
    name: "Suecia",
    alpha2Code: "SE",
    alpha3Code: "SWE",
    numericCode: "752",
    callingCode: "+46",
    flag: "https://flagcdn.com/24x18/se.png"
  },
  {
    name: "Suiza",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    numericCode: "756",
    callingCode: "+41",
    flag: "https://flagcdn.com/24x18/ch.png"
  },
  {
    name: "Siria",
    alpha2Code: "SY",
    alpha3Code: "SYR",
    numericCode: "760",
    callingCode: "+963",
    flag: "https://flagcdn.com/24x18/sy.png"
  },
  {
    name: "Taiwán",
    alpha2Code: "TW",
    alpha3Code: "TWN",
    numericCode: "158",
    callingCode: "+886",
    flag: "https://flagcdn.com/24x18/tw.png"
  },
  {
    name: "Tayikistán",
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    numericCode: "762",
    callingCode: "+992",
    flag: "https://flagcdn.com/24x18/tj.png"
  },
  {
    name: "Tanzania",
    alpha2Code: "TZ",
    alpha3Code: "TZA",
    numericCode: "834",
    callingCode: "+255",
    flag: "https://flagcdn.com/24x18/tz.png"
  },
  {
    name: "Tailandia",
    alpha2Code: "TH",
    alpha3Code: "THA",
    numericCode: "764",
    callingCode: "+66",
    flag: "https://flagcdn.com/24x18/th.png"
  },
  {
    name: "Timor Oriental",
    alpha2Code: "TL",
    alpha3Code: "TLS",
    numericCode: "626",
    callingCode: "+670",
    flag: "https://flagcdn.com/24x18/tl.png"
  },
  {
    name: "Togo",
    alpha2Code: "TG",
    alpha3Code: "TGO",
    numericCode: "768",
    callingCode: "+228",
    flag: "https://flagcdn.com/24x18/tg.png"
  },
  {
    name: "Islas Tokelau",
    alpha2Code: "TK",
    alpha3Code: "TKL",
    numericCode: "772",
    callingCode: "+690",
    flag: "https://flagcdn.com/24x18/tk.png"
  },
  {
    name: "Tonga",
    alpha2Code: "TO",
    alpha3Code: "TON",
    numericCode: "776",
    callingCode: "+676",
    flag: "https://flagcdn.com/24x18/to.png"
  },
  {
    name: "Trinidad y Tobago",
    alpha2Code: "TT",
    alpha3Code: "TTO",
    numericCode: "780",
    callingCode: "+1868",
    flag: "https://flagcdn.com/24x18/tt.png"
  },
  {
    name: "Túnez",
    alpha2Code: "TN",
    alpha3Code: "TUN",
    numericCode: "788",
    callingCode: "+216",
    flag: "https://flagcdn.com/24x18/tn.png"
  },
  {
    name: "Turquía",
    alpha2Code: "TR",
    alpha3Code: "TUR",
    numericCode: "792",
    callingCode: "+90",
    flag: "https://flagcdn.com/24x18/tr.png"
  },
  {
    name: "Turkmenistán",
    alpha2Code: "TM",
    alpha3Code: "TKM",
    numericCode: "795",
    callingCode: "+993",
    flag: "https://flagcdn.com/24x18/tm.png"
  },
  {
    name: "Islas Turks y Caicos",
    alpha2Code: "TC",
    alpha3Code: "TCA",
    numericCode: "796",
    callingCode: "+1649",
    flag: "https://flagcdn.com/24x18/tc.png"
  },
  {
    name: "Tuvalu",
    alpha2Code: "TV",
    alpha3Code: "TUV",
    numericCode: "798",
    callingCode: "+688",
    flag: "https://flagcdn.com/24x18/tv.png"
  },
  {
    name: "Uganda",
    alpha2Code: "UG",
    alpha3Code: "UGA",
    numericCode: "800",
    callingCode: "+256",
    flag: "https://flagcdn.com/24x18/ug.png"
  },
  {
    name: "Ucrania",
    alpha2Code: "UA",
    alpha3Code: "UKR",
    numericCode: "804",
    callingCode: "+380",
    flag: "https://flagcdn.com/24x18/ua.png"
  },
  {
    name: "Emiratos Árabes Unidos",
    alpha2Code: "AE",
    alpha3Code: "ARE",
    numericCode: "784",
    callingCode: "+971",
    flag: "https://flagcdn.com/24x18/ae.png"
  },
  {
    name: "Reino Unido",
    alpha2Code: "GB",
    alpha3Code: "GBR",
    numericCode: "826",
    callingCode: "+44",
    flag: "https://flagcdn.com/24x18/gb.png"
  },
  {
    name: "Estados Unidos",
    alpha2Code: "US",
    alpha3Code: "USA",
    numericCode: "840",
    callingCode: "+1",
    flag: "https://flagcdn.com/24x18/us.png"
  },
  {
    name: "Uruguay",
    alpha2Code: "UY",
    alpha3Code: "URY",
    numericCode: "858",
    callingCode: "+598",
    flag: "https://flagcdn.com/24x18/uy.png"
  },
  {
    name: "Uzbekistán",
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    numericCode: "860",
    callingCode: "+998",
    flag: "https://flagcdn.com/24x18/uz.png"
  },
  {
    name: "Vanuatu",
    alpha2Code: "VU",
    alpha3Code: "VUT",
    numericCode: "548",
    callingCode: "+678",
    flag: "https://flagcdn.com/24x18/vu.png"
  },
  {
    name: "Venezuela",
    alpha2Code: "VE",
    alpha3Code: "VEN",
    numericCode: "862",
    callingCode: "+58",
    flag: "https://flagcdn.com/24x18/ve.png"
  },
  {
    name: "Vietnam",
    alpha2Code: "VN",
    alpha3Code: "VNM",
    numericCode: "704",
    callingCode: "+84",
    flag: "https://flagcdn.com/24x18/vn.png"
  },
  {
    name: "Wallis y Futuna",
    alpha2Code: "WF",
    alpha3Code: "WLF",
    numericCode: "876",
    callingCode: "+681",
    flag: "https://flagcdn.com/24x18/wf.png"
  },
  {
    name: "Sahara Occidental",
    alpha2Code: "EH",
    alpha3Code: "ESH",
    numericCode: "732",
    callingCode: "+212",
    flag: "https://flagcdn.com/24x18/eh.png"
  },
  {
    name: "Yemen",
    alpha2Code: "YE",
    alpha3Code: "YEM",
    numericCode: "887",
    callingCode: "+967",
    flag: "https://flagcdn.com/24x18/ye.png"
  },
  {
    name: "Zambia",
    alpha2Code: "ZM",
    alpha3Code: "ZMB",
    numericCode: "894",
    callingCode: "+260",
    flag: "https://flagcdn.com/24x18/zm.png"
  },
  {
    name: "Zimbabue",
    alpha2Code: "ZW",
    alpha3Code: "ZWE",
    numericCode: "716",
    callingCode: "+263",
    flag: "https://flagcdn.com/24x18/zw.png"
  }
];