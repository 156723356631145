import { Injectable } from '@angular/core';
import { M_User } from '../../models/M_User';
import { EinaDataService } from './eina-data.service';
import { RolesEnum } from '../../enums/RolesEnum';
import { environment } from 'src/environments/environment';
import { IRoleService } from 'src/app/interfaces/IRoleService';

@Injectable({
  providedIn: 'root'
})
export class UserService implements IRoleService {
  constructor(private einaDataS: EinaDataService) { }
  /** User object */
  get user(): M_User { return this.einaDataS.user; };
  /** Is the user admin ? */
  get isSuperUser(): boolean { return this.user.superUser; }
  /** Is the user admin ? */
  // get userIsAdmin(): boolean { return this.user.admin; }
  /** Is the user admin ? */
  // get userIsGestor(): boolean { return this.user.gestor; }
  /** Is the user adviser ? */
  get userIsAdviser(): boolean { return this.user.adviser; }
  /** Is the user comercial ? */
  get userIsComercial(): boolean { return this.user.comercial; }
  /** Is the user recambista ? */
  get userIsRecambista(): boolean { return this.user.recambista; }
  /** Is the user mechanic ? */
  get userIsMechanic(): boolean { return this.user.mechanic; }
  /** Is the user admin or adviser? */
  get userIsAdminOrAdviser(): boolean { return this.isSuperUser || this.userIsAdviser; }
  /** Return if is the user first login */
  get userFirstLogin(): boolean { return this.user.last_login_at != undefined }
  /** Tracks if the workload dialog (drag tutorial) is already showed */
  get userWorkloadTutorial(): boolean { return this.user.showed_workload }
  /** Mark the user's object as if they have already seen the tutorial */
  set setuserWorkloadTutorial(showed: boolean) { this.user.showed_workload = showed; }
  /** Get the user name */
  get userName(): string { return this.user.name; }
  /** Get the user id */
  get userId(): number { return this.user.id; }
  /** Get the user email */
  get userEmail(): string { return this.user?.email; }


  hasRole(): boolean {
    return this.user != undefined;
  }

  getRole(): RolesEnum | undefined {
    if (!this.user) { return undefined };
    return this.user.role;
  }

  setRole(r: RolesEnum) {
    if (environment.local || environment.dev && this.user != undefined) {
      this.user.role = r;
    }
  }

  isMe(user_id: number | undefined) {
    if (user_id == undefined) { return false }
    return this.userId == user_id;
  }
}
