import { CustomEnum } from "./CustomEnum";

type invoice_type_names = "OR" | "Recambios" | "Abono" | "Comercial";

const types: [number, invoice_type_names][] = [
    [0, "OR"],
    [1, "Recambios"],
    [2, "Abono"],
    [3, "Comercial"]
]

export class invoice_type extends CustomEnum {
    constructor(tipo: invoice_type_names | number) {
        super(types, tipo)
    }
    
    get isRecambios() {
        return this.num == invoice_type_recambios.num;
    }

    get isAbono() {
        return this.num == invoice_type_abono.num;
    }
    get isComercial() {
        return this.num == invoice_type_comercial.num;
    }
}

export const invoice_type_OR = new invoice_type("OR");
export const invoice_type_recambios = new invoice_type("Recambios");
export const invoice_type_abono = new invoice_type("Abono");
export const invoice_type_comercial = new invoice_type("Comercial");