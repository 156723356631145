import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { EditOrComponent } from 'src/app/views/or-create-edit/edit-or/edit-or.component';
import { endpoints } from '../../constants/Endpoints';
import { M_User } from '../../models/M_User';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { MASTER_CLIENT, MASTER_USER_CENTER, MASTER_VECHILE_ALL } from '../../constants/masters';
import { MatSelectChange } from '@angular/material/select';
import { M_Contact } from '../../models/M_Contact';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from '../../models/M_Action';
import { forkJoin } from 'rxjs';
import { M_Appointment } from '../../models/M_Appointment';
import { UserService } from '../../services/EinaMainData/user.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { CreateMasterInDialogService } from 'src/app/services/create-master-in-dialog.service';
import { EstimatedTimeComponent } from '../estimated-time/estimated-time.component';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { MatDialog } from '@angular/material/dialog';
import { OrNotesAndImagesComponent } from 'src/app/views/or-create-edit/or-notes-and-images/or-notes-and-images.component';
import { RaGroupsComponent } from 'src/app/views/or-create-edit/create-or/ra-groups/ra-groups.component';
import { M_RAGroup } from 'src/app/models/M_RAGroup';

@Component({
    selector: 'app-or-form',
    templateUrl: './or-form.component.html',
    styleUrls: ['./or-form.component.css'],
    standalone: false
})
export class OrFormComponent implements OnInit, AfterViewInit {

  client = MASTER_CLIENT;
  vehicle_minify = MASTER_VECHILE_ALL;
  user = MASTER_USER_CENTER;
  @Input() isEdit: boolean = false;
  @Input() loaded: boolean = false;
  /** Appointment to OR dialog */
  @Input() appointment: M_Appointment | undefined;
  @Input() showWokerInput: boolean = true;
  @Input() wrapper: boolean = true;

  form!: UntypedFormGroup;
  e = endpoints;
  v = ViewPath;

  @ViewChild("vehicleSearcher") vehicleSearcher?: ClassSearcherComponent<M_Vehicle>;
  @ViewChild("clientInvoice") clientInvoice?: ClassSearcherComponent<M_Contact>;
  @ViewChild("workerSearcher") workerSearcher?: ClassSearcherComponent<M_User>;
  @ViewChild(OrNotesAndImagesComponent, { static: true }) notesAndImages!: OrNotesAndImagesComponent;
  @ViewChild(RaGroupsComponent) raGroups!: RaGroupsComponent;


  @Output() onVehicle: EventEmitter<M_Vehicle | undefined> = new EventEmitter();
  @Output() onLoad: EventEmitter<any> = new EventEmitter();
  @Output() onSelectOrInit: EventEmitter<any> = new EventEmitter();


  /** Workload calendar */
  workload: M_Action[] = [];
  kmLastReview: string | undefined;
  filteredAction: M_Action[] = [];

  constructor(private fb: UntypedFormBuilder, public routerS: RouterService, public cs: CalendarService,
    @Optional() private editORComponent: EditOrComponent, private apiS: ApiService, public userS: UserService,
    public createMasterS: CreateMasterInDialogService, private d: MatDialog, public cdr: ChangeDetectorRef) {
    this.form = this.fb.group({
      estimated_time_hours: undefined,
      estimated_time_minutes: undefined,
      schedule: [undefined],
      delivery: [undefined],
      km: ['', [Validators.max(999999999)]],
      notes: ['', []],
      comments: [[], []],
      assigned_to: [[], []],
      fuel: [1],
      comercial_type: ['']
    });

    this.form.get('delivery')?.valueChanges.subscribe(val => {
      if (val != undefined) {
        let value = new Date(val);
        this.editORComponent?.onDateChange({ value: value }, "delivery");
      }
      else {
        this.editORComponent?.onDateChange(val, "delivery");
      }
    })

    this.form.get('schedule')?.valueChanges.subscribe(val => {
      if (val) {
        let schedule = new Date(val);
        if (this.editORComponent) {
          this.editORComponent.onDateChange({ value: schedule }, "schedule");
        }

        /** Disable deivery if schedule > delivery */
        var d = this.form.get('delivery')?.value!;
        if (d) {
          var shceduledate = new Date(d);
          if (schedule > shceduledate) {
            this.form.patchValue({ 'delivery': undefined }, { emitEvent: false });
          }
        }
      }
    })
  }

  updateKm(val: number | undefined) {
    this.form.get('km')?.setValue(val);
  }

  ngOnInit(): void {
    /** TODO : Improve this */
    // if (!this.isEdit) {
    //   this.getWorkload();
    // }
  }

  ngAfterViewInit(): void {
    if (this.appointment) {
      this.appointment.tasks.forEach(t => {
        this.raGroups.groups.push(new M_RAGroup(t.task))
      })
      this.form.get('estimated_time_hours')?.setValue(this.appointment.estimated_time_hours);
      this.form.get('estimated_time_minutes')?.setValue(this.appointment.estimated_time_minutes);
    }
  }

  get clientCreatedAppointment() {
    if (this.appointment) {
      this.appointment && this.appointment.isClient
    }
    return true;
  }

  get vehiclesAndWorkersLoaded() {
    let normalLoaded = this.vehicleSearcher?.loaded && this.workerSearcher?.loaded;
    let fromAppointmentLoaded = this.appointment && !this.appointment.isClient && this.workerSearcher?.loaded;
    return normalLoaded || fromAppointmentLoaded;
  }

  get kmError() {
    return this.form.get('km')?.hasError('max') ? "Máximo 9 números" : ""
  }

  get clientInvoicePlaceholder(): string {
    return this.clientInvoice?.selected?.name || 'Cliente a facturar';
  }

  /** Workload calendar on schedule input */
  getWorkload() {
    if (this.workload != undefined) { return; }
    let formValue = this.form.get('schedule')?.value || (!this.isEdit ? new Date() : null);
    if (!formValue) { return };

    const d = new Date(formValue);
    const d1 = new Date(d).minusMonths(1);
    const d2 = new Date(d).plusMonths(1);

    this.apiS.action.schedules(d1, d2, false).then(res => {
      this.workload = res;
    });
  }

  get minValueDelivery() {
    const deliveryDate = new Date(this.form.get('schedule')?.value);
    return deliveryDate;
  }

  getDaysClassWrapper(date: Date): string {
    return this.cs.getsClassCenter(date, this.workload);
  }

  fillFormWithVehicle(n: number, emit: boolean = false) {
    if (!this.isEdit && this.vehicleSearcher) {
      this.vehicleSearcher.select(n, { emitEvent: emit });
    }
  }

  /** Assign the current urser (if ther is no appointment on dialog parameters) */
  autoAssign() {
    if (this.appointment?.worker) {
      this.workerSearcher?.select(this.appointment.worker, { emitEvent: false })
    }
    else if (!this.isEdit) {
      this.workerSearcher?.allData.forEach(d => {
        if (this.userS.isMe(d.id)) {
          this.workerSearcher?.select(d, { emitEvent: false });
        }
      })
    }
  }

  assignUser(u: M_User | undefined) {
    if (this.editORComponent && this.editORComponent.action?.id) {
      this.editORComponent.action.assigned_to = u != undefined ? u.id : undefined;
    }
  }

  fuelChange(event: MatSelectChange) {
    if (this.editORComponent && this.editORComponent.action?.id) {
      this.form.patchValue({ fuel: event.value });
      this.editORComponent.action.fuel = event.value;
    }
  }

  formattedTime() {
    let hours = this.form.get('estimated_time_hours')?.value || 0;
    let minutes = this.form.get('estimated_time_minutes')?.value || 0;
    if (hours || minutes) {
      return new CustomTime(hours, minutes).getText();
    }
    return "";
  }

  openEstimatedTimeDialog() {
    const alreadyOpened = this.d.openDialogs.some(dialog => dialog.componentInstance instanceof EstimatedTimeComponent);
    if (alreadyOpened) { return; }
    this.d.open<EstimatedTimeComponent, { hours: number | undefined, minutes: number | undefined }>(EstimatedTimeComponent, {
      data: {
        hours: this.form.get('estimated_time_hours')?.value,
        minutes: this.form.get('estimated_time_minutes')?.value
      }
    }).afterClosed().subscribe(v => {
      if (v) {
        this.form.patchValue({ 'estimated_time_hours': v.estimated_time_hours })
        this.form.patchValue({ 'estimated_time_minutes': v.estimated_time_minutes })
      }
    })
  }

  get isFormOk() {
    if (this.appointment) { return this.form.valid }
    return this.form.valid && this.vehicleSearcher?.selected != undefined;
  }

  get hasVehicle() {
    if (this.vehicleSearcher) {
      return this.vehicleSearcher?.selected != undefined;
    }
    return true;
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  onCreated(v: M_Vehicle) {
    if (v.km) { this.form.patchValue({ km: v.km }) }
    if (v.isVnVo) { this.form.patchValue({ comercial_type: 2 }) }
  }
  isEquals(y: Date, d: Date): boolean {
    return (
      y.getFullYear() === d.getFullYear() &&
      y.getMonth() === d.getMonth() &&
      y.getDate() === d.getDate()
    );
  }
}
