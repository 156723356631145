<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>
<p mat-dialog-title *ngIf="isOnDialog">{{isEdit ? "Editar concepto":"Crear concepto"}}</p>
<app-go-back *ngIf="!isOnDialog" (click)="routerS.goTo(v.concepts);"></app-go-back>
<div class="advanced-details-container create-item" mat-dialog-content
    [ngClass]="{'not-dialog':!isOnDialog,'ondialog':isOnDialog}">

    <app-card [contentLoaded]="loaded" [noStyle]="isOnDialog">
        <form [formGroup]="form" eForm>

            <div eForm-section>
                <mat-button-toggle-group [value]="CTP.NORMAL" formControlName="concept_type">
                    <mat-button-toggle [value]="CTP.NORMAL" (click)="notDiscount('N')">
                        Normal
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CTP.DESCUENTO" (click)="isDiscount()">
                        Descuento
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CTP.COMPRA" (click)="notDiscount('C')">
                        Compra
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <app-card-subtitle>Datos básicos</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field class="example-full-width">
                        <mat-label>Nombre del concepto</mat-label>
                        <input matInput #comment formControlName="name" class="styl" maxlength="140"
                            placeholder="Nombre concepto">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline" *ngIf="form.get('concept_type')?.value != CTP.COMPRA">
                        <mat-label>PVP</mat-label>
                        <input type="number" matInput placeholder="PVP" formControlName="price"
                            (input)="adjustPriceValue()">
                        <mat-error *ngIf="form.get('price')?.hasError('required')">
                            El valor introducido no es correcto
                        </mat-error>
                        <mat-error *ngIf="form.get('price')?.hasError('max')">Número demasiado grande</mat-error>
                        <mat-error *ngIf="form.get('price')?.hasError('min')">
                            El precio de venta debe ser positivo.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" [formGroup]="form"
                        *ngIf="form.get('concept_type')?.value != CTP.DESCUENTO">
                        <mat-label>Coste</mat-label>
                        <input type="number" matInput placeholder="Coste" formControlName="cost">
                        <mat-error *ngIf="this.form.get('cost')?.hasError('min')">El valor debe ser mayor o igual que
                            0</mat-error>
                        <mat-error *ngIf="this.form.get('cost')?.hasError('max')">Número demasiado grande</mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="df aic" [ngClass]="{'not_display': form.get('concept_type')?.value === CTP.DESCUENTO}">
                <app-card-subtitle>Exento IVA</app-card-subtitle>
                <mat-slide-toggle [color]="'accent'" class="ml10" #conceptToggle formControlName="exento"
                    [matTooltip]="responsiveS.w < 900 ? 'No se aplicará el IVA en el concepto.' : ''"></mat-slide-toggle>
            </div>
            <div eForm-section eForm-wrapper *ngIf="form.get('concept_type')?.value != CTP.DESCUENTO">

                <mat-form-field *ngIf="isExento" appearance="outline">
                    <mat-label>Tipo de exento</mat-label>
                    <mat-select formControlName="exempt_type" [value]="0">
                        <mat-option [value]="0">Suplido</mat-option>
                        <mat-option [value]="1">Operaciones con Area Exentas de IVA</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IVA</mat-label>
                    <mat-select formControlName="tax" #iva [value]="21">
                        <mat-option *ngIf="conceptToggle.checked" [value]="0">0%</mat-option>
                        <mat-option [value]="4">4%</mat-option>
                        <mat-option [value]="10">10%</mat-option>
                        <mat-option [value]="21">21%</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <app-card-subtitle>Otros datos</app-card-subtitle>
            <div eForm-wrapper eForm-section *ngIf="features.accountingGroups">
                <app-accounting-group-selector [form]="form" [type]="'C'"></app-accounting-group-selector>
            </div>
            <div class="checkbox-section">
                <mat-checkbox formControlName="in_invoice" #checkInv
                    [matTooltip]="responsiveS.w < 900 ? 'Aplicar en factura.' : ''">
                    <p class="nmb">No Aplicar en factura</p>
                    <mat-hint *ngIf="checkInv.checked">No se reflectará en la suma de la factura.</mat-hint>
                </mat-checkbox>
                <mat-checkbox formControlName="record_historic"
                    [matTooltip]="responsiveS.w < 900 ? 'Se aplica como histórico el vehículo.' : ''">
                    <p class="nmb">Aplicar como histórico.</p>
                </mat-checkbox>
                <!-- version v2 -->
                <!-- <mat-checkbox formControlName="registration_tax" #checkTax
                    [matTooltip]="responsiveS.w < 900 ? 'Aplicar impuesto de matriculación' : ''">
                    <p class="nmb">Aplicar impuesto de matriculación</p>
                    <mat-hint *ngIf="checkTax.checked">Se aplicarà el tipo de porcentaje que se haya seleccionado en el
                        vehículo.</mat-hint>
                </mat-checkbox> -->
            </div>
            <div class="df jcc" *ngIf="!isOnDialog">
                <div [ngTemplateOutlet]="createBtn"></div>
            </div>

        </form>
    </app-card>
</div>
<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="createBtn"></div>
</div>

<ng-template #createBtn>
    <button [disabled]="!form.valid" mat-flat-button color="primary" (click)="create()">
        Guardar
    </button>
</ng-template>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el concepto'" [goText]="'Ir al listado de conceptos'"
        [view]="v.concepts">
    </app-model-not-found>
</ng-template>