<!-- DOCUMENTATION BUTTON-->
<button *ngIf="showDocumentationButton" mat-mini-fab color="primary" [matBadge]="numberOfPDF ? numberOfPDF :''"
    [matBadgeColor]="'accent'" [disabled]="numberOfPDF == 0" [matMenuTriggerFor]="documentationMenu">
    <mat-icon [svgIcon]="'file-pdf'" class="c_w"></mat-icon>
</button>


<!-- MAT MENU -->
<mat-menu #documentationMenu="matMenu">

    <!-- INVOICES -->
    <ng-container *ngIf="invoice">
        <button mat-menu-item color="blue" *ngIf="invoice && invoice.token"
            (click)="previewS.showPreview('I', invoice.token, undefined, invoice.id)">
            <span class="pdf-label"></span>
            {{invoice.isAbono? 'Abono ': 'Factura'}}
        </button>
    </ng-container>

    <!-- OR -->
    <ng-container *ngIf="action && !action.isBudget()">
        <ng-container *ngIf="action && action.urlpdf.length">
            <button mat-menu-item *ngFor="let doc of action.urlpdf"
                (click)="previewS.showPreview(doc.type, doc.token, undefined, action.action_id)">
                <span class="pdf-label"></span>
                {{doc.docName}}
            </button>
        </ng-container>
        <button mat-menu-item color="blue" *ngIf="action && action.hasClosedGroups()"
            (click)="previewS.showPreview('AOR', this.action!.token)">
            <span class="pdf-label"></span>
            <span>Albarán de OR</span>
        </button>
        <ng-container *ngFor="let g of action?.getGroupsByInvoice()">
            <button mat-menu-item *ngIf="g.token"
                (click)="previewS.showPreview('I', g.token, undefined, g.invoice_id);">
                <span class="pdf-label"></span>
                {{g.invoice_name ? g.invoice_name : 'Factura sin título'}}
            </button>
        </ng-container>
    </ng-container>

    <!-- BUDGET  -->
    <ng-container *ngIf="action && action.isBudget()">
        <button [disabled]="!action.hasGroups()" mat-menu-item *ngIf="action && (action.urlpdf.length || action.token)"
            (click)="previewS.showPreview('B', action.urlpdf.length > 0 ? action.urlpdf[0].token : action.token, undefined, action.action_id)">
            <span class="pdf-label"></span>
            Presupuesto
        </button>
    </ng-container>


    <!-- COMERCIAL BUDGET  -->
    <ng-container *ngIf="cBudget && cBudget.status.pending">
        <button mat-menu-item (click)="previewS.showPreview('CB', cBudget.token, undefined, cBudget.id)">
            <span class="pdf-label"></span>
            Presupuesto
        </button>
    </ng-container>

    <!-- ALBARÁN -->
    <ng-container *ngIf="albaran && albaran.products.length">
        <button mat-menu-item color="blue" *ngFor="let doc of albaran.urlpdf"
            (click)="previewS.showPreview(doc.type, doc.token, undefined, albaran.id)">
            <span class="pdf-label"></span>
            {{doc.docName}}
        </button>
    </ng-container>

    <!-- Purchase-->
    <ng-container *ngIf="purchase && purchase.pdf_fc">
        <button mat-menu-item *ngIf="purchase" (click)="showRegisteredBuyPreview(purchase)">
            Registro compra
            <mat-icon>picture_as_pdf</mat-icon></button>
    </ng-container>

    <!-- VN VO -->
    <ng-container *ngIf="vehicle && (vehicle.vehicle_entry || vehicle.buy_transac)">
        <button mat-menu-item *ngIf="vehicle.vehicle_entry && vehicle.vehicle_entry.urldpf"
            (click)="compraVentaPreview(vehicle)">
            Ver compra venta
            <mat-icon>picture_as_pdf</mat-icon></button>
        <button mat-menu-item *ngIf="vehicle.buy_transac && vehicle.buy_transac.pdf_fc"
            (click)="showRegisteredBuyPreview(vehicle.buy_transac)">
            Registro compra
            <mat-icon>picture_as_pdf</mat-icon></button>
    </ng-container>

</mat-menu>