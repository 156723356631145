import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: "root"
})

@Component({
    selector: 'app-loading-panel',
    template: `
            <div mat-dialog-content id="loadingPanel">
                <img class="rotate-center loading-panel-icon" [src]="'./assets/img/logos/eina_logo_small.png'">
            </div>`,
    styleUrls: ['./loading-panel.css'],
    standalone: false
})

/**
 * [Servicio]
 * Muestra, en pantalla completa, un panel de carga.
 * El panel de carga se muestra automáticamente en cada petición http.
 * Ésto se conigue mediante la classe {@link httpListenerService}
 */
export class LoadingPanelService implements OnInit {

  alredyShow: boolean = false;
  forceShow = false;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<LoadingPanelService>) { }
  ngOnInit(): void { }

  /**Muestra el panel de carga. Se añade disableClose:true para evitar que se pueda cerrar el panel */
  show(foceshow: boolean = false) {
    if (foceshow) { this.forceShow = true; }
    if (!this.alredyShow) {
      this.alredyShow = true;
      this.dialogRef = this.dialog.open(LoadingPanelService, { disableClose: true, panelClass: 'core-loading-panel' },);
    }
  }

  /**Esconde el panel de carga */
  hide(forcehide: boolean = false) {
    if (this.forceShow && !forcehide) { return; }
    if (this.dialogRef instanceof MatDialogRef) {
      this.dialogRef.close();
      this.alredyShow = false;
      this.forceShow = false;
    }
  }
}
