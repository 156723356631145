import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParamsService } from 'src/app/services/params.service';

@Component({
    selector: 'app-main-manual-movement-button',
    templateUrl: './main-manual-movement-button.component.html',
    styleUrls: ['./main-manual-movement-button.component.css'],
    standalone: false
})
export class MainManualMovementButtonComponent {
  @Input({ required: true }) p!: M_Product;
  @Input() style: "flat" | "menu-option" = "flat";
  v = ViewPath;
  constructor(public paramsS : ParamsService){}
}
