import { Component } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { getAccentColor, getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';

@Component({
    selector: 'app-missing-company-info',
    templateUrl: './missing-company-info.component.html',
    styleUrls: ['./missing-company-info.component.css'],
    standalone: false
})
export class MissingCompanyInfoComponent {
  R = RolesEnum;
  accent = getAccentColor;
  primary = getPrimaryColor;

  constructor(private companyS: CompanyService, private routerS: RouterService) { }

  goCompany() { this.routerS.goTo(ViewPath.settings) }

  get showComponent() {
    return this.companyS.companyMissingInfo;
  }

}
