<p class="tac nmb">Trabajado hoy</p>

<div class="df jcc aic mb5">
    <p class="tac c_t2 fss nmb">{{signingS.elapsed}}</p>
    <!-- <mat-icon class="ml5 c_o vam" [filled]="true" *ngIf="signingS.signingData?.now?.shouldSigning">warning</mat-icon> -->
</div>



<mat-progress-bar mode="determinate" [value]="signingS.workedPercentage" class="time-bar"></mat-progress-bar>
<div class="df aic mt10">
    <button [disabled]="signingS.isPaused" mat-flat-button color="primary" class="w100" *ngIf="signingS.canStop"
        (click)="startStop(); $event.stopPropagation()">Parar</button>
    <button [disabled]="signingS.isPaused" mat-flat-button color="primary" class="w100" *ngIf="signingS.canStart"
        (click)="startStop(); $event.stopPropagation()">Empezar</button>
    <button mat-icon-button (click)="pauseResume()" *ngIf="signingS.canPauseResume">
        <mat-icon *ngIf="signingS.canPause">pause_circle</mat-icon>
        <mat-icon *ngIf="signingS.canResume">resume</mat-icon>
    </button>
</div>
<div *ngIf="!notDataSchedule">
    <app-info-panel-rectangle [panel]="'warn'">
        <span class="fw600">Falta configurar el horario: </span>
        Rellena la información de tu perfil para poder fichar.
    </app-info-panel-rectangle>
</div>