import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { VehicleLineTableComponent } from '../vehicle-line-table/vehicle-line-table.component';

@Component({
    selector: 'app-vehicle-searcher',
    templateUrl: './vehicle-searcher.component.html',
    styleUrls: ['./vehicle-searcher.component.css'],
    standalone: false
})
export class VehicleSearcherComponent implements AfterViewInit {
  v = ViewPath;

  @ViewChild(ClassSearcherComponent, { static: true }) classSearcher!: ClassSearcherComponent<M_Vehicle>;
  @Input({ required: true }) ref!: ComponentRef<VehicleSearcherComponent>;
  @Output() onSelectVehicle: EventEmitter<M_Vehicle> = new EventEmitter();
  @Output() onDestroy: EventEmitter<VehicleSearcherComponent> = new EventEmitter();

  /** All class searcher vehicles of the component */
  @Input() vehicles_cs: M_Vehicle[] | undefined = undefined;
  canAddNew : boolean = true;

  constructor(@Inject(VehicleLineTableComponent) public vehicleTable: VehicleLineTableComponent, public routerS: RouterService, private chdRef: ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {
    this.focus();
  }

  disabledIf(ve: M_Vehicle) {
    return this.vehicleTable.getTableVehicles().filter(v => v.vehicle_id == ve.vehicle_id).length != 0
  }

  focus() {
    if (this.classSearcher) {
      this.classSearcher.focus();
      this.chdRef.detectChanges();
    }
  }

  destroy() {
    this.ref.destroy();
  }

}
