import { Component, Input } from '@angular/core';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';

@Component({
    selector: 'app-vn-vo-label',
    templateUrl: './vn-vo-label.component.html',
    styleUrls: ['./vn-vo-label.component.css'],
    standalone: false
})
export class VnVoLabelComponent {
  @Input({required : true}) type! : ComercialVehicleType;
}
