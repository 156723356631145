import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { PS_HIGHLIGHT } from 'src/app/constants/constants';
import { M_Action } from 'src/app/models/M_Action';
import { M_Fault } from 'src/app/models/M_Fault';
import { M_Reservation } from 'src/app/models/M_Reservation';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
    selector: 'app-info-reserve-orders',
    templateUrl: './info-reserve-orders.component.html',
    styleUrls: ['./info-reserve-orders.component.css'],
    standalone: false
})
export class InfoReserveOrdersComponent {
  @Input() reservations: M_Reservation[] = [];
  @Input() action!: M_Action ;
  @Input() showLastCircle: boolean = true;
  v = ViewPath;
  constructor(public companyS:CompanyService,private routerS : RouterService){}
  goto(fault:M_Fault){
   if(fault.order){
    this.routerS.goWithQueryParams(this.v.orders, {[PS_HIGHLIGHT] : fault.order.id});
   }
  
}
}
