import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MASTER_BRAND_BRANDMODEL, MASTER_CLIENT_MINIFIY, MASTER_MODEL_BRANDMODEL, MASTER_SERIE_BRANDMODEL } from '../../constants/masters';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { M_Brand } from '../../models/M_Brand';
import { M_Serie } from '../../models/M_Serie';
import { M_Model } from '../../models/M_Model';
import { ViewPath } from '../../app-routing.module';
import { UntypedFormGroup } from '@angular/forms';
import { MasterStorageService } from '../class-searcher/master-storage-service';
import { BehaviorSubject, combineLatest, startWith, map, Observable } from 'rxjs';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { VehicleType } from '../../enums/VehicleType';

@Component({
    selector: 'app-brands-models-series',
    templateUrl: './brands-models-series.component.html',
    styleUrls: ['./brands-models-series.component.css'],
    standalone: false
})
export class BrandsModelsSeriesComponent {
  client = MASTER_CLIENT_MINIFIY;
  brand = MASTER_BRAND_BRANDMODEL;
  serie = MASTER_SERIE_BRANDMODEL;
  model = MASTER_MODEL_BRANDMODEL;
  v = ViewPath;
  private loadedSubject = new BehaviorSubject<boolean>(false);
  loadedBrandsModels$?: Observable<boolean>;
  private originalBrands: M_Brand[] = []; // Para almacenar el array original de alldata
  private filterValue: number = 1;
  @Input() filterAuto:boolean=false;
  @Input() brands?: M_Brand[] = [];
  @Input() formVnVo?: boolean = false;
  @Input() notRequiredSerie?: boolean = false;
  @Input() notShowModel?: boolean = false;
  @Input() notShowBrand?: boolean = false;
  @Input() notShowSerie?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() models?: M_Model[] = [];
  @Input() type? : VehicleType;

  @Input() form?: UntypedFormGroup;
  @ViewChild('csearcherB') brandSearcherComponent?: ClassSearcherComponent<M_Brand>
  @ViewChild('csearcherS') serieSearcherComponent?: ClassSearcherComponent<M_Serie>
  @ViewChild('csearcherM') modelSearcherComponent?: ClassSearcherComponent<M_Model>
  @Output() onSelectModelSearcher: EventEmitter<M_Model> = new EventEmitter();
  @Output() onSelectBrandSearcher: EventEmitter<M_Brand> = new EventEmitter();
  @Output() onSelectSerieSearcher: EventEmitter<M_Serie> = new EventEmitter();
  constructor(private companyS: CompanyService, private ms: MasterStorageService) {

  }
  ngOnChanges() {
    // Garantiza que siempre haya un array, incluso si el padre pasa un valor inesperado.
    if (!this.models) {
      this.models = [];
    }
  }

  onBrandSelect(selectedBrand: M_Brand) {
    if (selectedBrand?.itemId) {
      if (this.models && this.models?.length > 0) {
        this.modelSearcherComponent?.updateOptions(this.models);
      } else {
        this.ms.getModelsByBrand(selectedBrand.itemId)
          .then((models: M_Model[]) => {
            // Llenar los datos en el buscador de modelos
            if (models) {
              this.modelSearcherComponent?.updateOptions(models);
            }
          })
          .catch(error => {
            //console.error('Error al obtener modelos:', error);
          });
      }
      this.ms.getSerieByBrand(selectedBrand.itemId).then((series: M_Serie[]) => {
        if (series) {
          this.serieSearcherComponent?.updateOptions(series);
        }
      })
      this.onSelectBrandSearcher.emit(selectedBrand);
    } else {
      // Si no hay marca seleccionada, limpiar opciones de modelos
      this.modelSearcherComponent?.updateOptions([]);
      this.serieSearcherComponent?.updateOptions([]);
    }
  }
  onBrandRemove(selectedBrand: M_Brand) {
    this.modelSearcherComponent?.updateOptions([]);
    this.modelSearcherComponent?.remove();
    this.modelSearcherComponent?.refresh();
    this.serieSearcherComponent?.updateOptions([]);
    this.serieSearcherComponent?.remove();
  }
  onSerieSelect(selectedSerie: M_Serie) {
    if (selectedSerie?.itemId) {
      this.ms.getModelsBySerie(selectedSerie.itemId, selectedSerie.brand_id).then((models: M_Model[]) => {
        // Llenar los datos en el buscador de modelos
        if (models) {
          this.modelSearcherComponent?.remove();
          this.modelSearcherComponent?.updateOptions(models);
        }
      }).catch(error => {
        console.error('Error al obtener modelos:', error);
        this.modelSearcherComponent?.updateOptions([]);
      });
      this.onSelectSerieSearcher.emit(selectedSerie);
    }
    else {
      this.modelSearcherComponent?.updateOptions([]);
    }
  }
  onSerieRemove(removeSelect: M_Serie) {
    if (removeSelect?.brand_id) {
      this.ms.getModelsByBrand(removeSelect.brand_id)
        .then((models: M_Model[]) => {
          // Llenar los datos en el buscador de modelos
          if (models) {
            this.modelSearcherComponent?.remove();
            this.modelSearcherComponent?.updateOptions(models);
          }
        })
        .catch(error => {
          console.error('Error al obtener modelos:', error);
        });
    } else {
      this.modelSearcherComponent?.remove();
      this.modelSearcherComponent?.updateOptions([]);
    }
  }
  onSelectModel(m: M_Model) {

    this.onSelectModelSearcher.emit(m);
  }
  get isLoaded$() {
    return this.loadedSubject.asObservable();
  }
  filterBrands() {
    if (this.brandSearcherComponent) {

      const allBrands = [...this.originalBrands];

      const filteredBrands = allBrands.filter(brand => {
          return brand.type === this.filterValue; 
      });
     
      this.brandSearcherComponent.updateOptions(filteredBrands);
    }
  }
  onFilterValueChange(value: VehicleType) {
    this.filterValue  = value;
    this.filterBrands();  // llama al metodo de filtrado
  }
  applyBrandFilter() {
    if (this.brandSearcherComponent) {
      let filteredBrands: M_Brand[];

      if (this.filterValue === VehicleType.bike) {
        filteredBrands = this.originalBrands.filter(brand => brand.isBike);  // Motos
      } else if (this.filterValue === VehicleType.car) {
        filteredBrands = this.originalBrands.filter(brand => brand.isCar);  // Coches
      } else {
        filteredBrands = [...this.originalBrands];  // si no hay filtro, mostramos todas
      }

      // actualizamos las opciones en el componente de marcas
      this.brandSearcherComponent.updateOptions(filteredBrands);
    }
  }
  applyDefaultFilter() {
   //cuando se llama filtra por default de la compañia
   if(this.type != undefined){
    this.filterValue = this.type;
   }else{
    if(this.companyS.company.userCenter?.workshopConfig){
      this.filterValue = this.companyS.company.userCenter?.workshopConfig?.default_vehicle;
    }else{
      //pordefecto
      this.filterValue = VehicleType.bike;
    }
   }
    this.applyBrandFilter();
  }
  ngAfterViewInit() {
    // Aseguramos que los componentes tienen datos
    if (this.brandSearcherComponent) {
      //emite el evento observable del classSearcher
      this.brandSearcherComponent.dataLoaded.subscribe(() => { 

        // una vez que los datos esten disponibles hacemos la copia
        if (this.brandSearcherComponent) {
          this.originalBrands = [...this.brandSearcherComponent.allData]; // copiamos en un array el get original de la request
          if(this.filterAuto){ // si tienen el input puesto pueden filtrar por moto o por coche
            this.applyDefaultFilter(); // funcion por defecto del filtraje
          }
        }
      });
    }
    if (this.brandSearcherComponent && this.modelSearcherComponent && this.serieSearcherComponent) {
      this.loadedBrandsModels$ = combineLatest([
        this.brandSearcherComponent.loadedChange.pipe(startWith(false)),
        this.modelSearcherComponent.loadedChange.pipe(startWith(false)),
        this.serieSearcherComponent.loadedChange.pipe(startWith(false))
      ]).pipe(
        map(([brandLoaded, modelLoaded, serieLoaded]) => {
          // Retorna true solo si todos están cargados
          return brandLoaded && modelLoaded && serieLoaded;
        })
      );

      // Ahora nos suscribimos a este observable para actualizar el estado de la carga
      this.loadedBrandsModels$.subscribe(isLoaded => {
        this.loadedSubject.next(isLoaded); // Emitimos el valor de carga a través del BehaviorSubject
      });
    }
  }
  get isVnVo() {
    return this.companyS.vnvoModule;
  }
  get showSerie() {
    if (this.companyS.vnvoModule) {
      if (this.notShowSerie) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }
  
  get isDisable() {
    return this.disabled ? true : false;
  }

  get loaded() {
    return this.brandSearcherComponent?.loaded &&
      this.serieSearcherComponent?.loaded &&
      this.modelSearcherComponent?.loaded;
  }
}
