import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';

/**
 * [Component]
 * Muestra un mensaje conforme una acción ha funcionado correctamente.
 * @title Título del diálogo
 * @message Mensaje del diálogo
 * @buttonText Texto del botón
 * @buttonAction Acción del botón
 * 
 * Para abrir éste diálogo : 
 * this.dialog.open(TerminosCondicionesComponent, {autoFocus: false})
 */

export interface Data {
  message: string;
}

@Component({
    styles: ['.redB {background-color : var(--r); color: white} .primB{background-color : var(--p); color: white}'],
    selector: 'app-success-dialog',
    template: ` <h1 mat-dialog-title>TÉRMINOS Y CONDICIONES</h1>
              <div mat-dialog-content>
                <div *ngFor="let section of allSections; let last = last">
                    <p class="semi-bold">{{section.title}}</p>
                    <div *ngFor="let p of section.content">
                      <p>{{p}}</p>
                    </div>
                    <mat-divider *ngIf="!last" style="margin-bottom: 15px;"></mat-divider>
                </div>
              </div>
              <div mat-dialog-actions [align]="'center'">
                <button [mat-dialog-close] mat-raised-button [mat-dialog-close]="false" class="redB">Rechazar</button>
                <button [mat-dialog-close] mat-raised-button [mat-dialog-close]="true"  class="primB">Aceptar</button>
              </div>`,
    standalone: false
})

export class TerminosCondicionesComponent implements OnInit{
  allSections : TermSection[] = [];
  section1 : TermSection = new TermSection(
    "Terminos y condiciones en el momento de la firma de la recepción activa.",
    "a)	El cliente tiene derecho a la elaboración de un presupuesto previo, mediante la presente firma el usuario renuncia a la elaboración de presupuesto previo, y autoriza a realizar los trabajos necesarios para la reparación del vehículo y/o servicios solicitados conforme a lo reflejado en este resguardo de depósito.",
    "b)	Que el personal del taller realice los desplazamientos necesarios con el vehículo para la correcta diagnosis y prueba del mismo, declarando expresamente que el seguro de su vehículo está en vigor.",
    "c)	La utilización de piezas de repuesto , conjuntos o equipos reconstruidos, en los términos establecidos en el art, 9.0 del decreto 9/2003, haciéndose responsable el taller del buen estado de los mismos.",
    "d)	La utilización de piezas de repuesto, conjuntos o equipos usados no especificos, en los términos establecidos en el art. 10.d del decreto 9/2003, haciéndose responsable el taller del buen estado de los mismos, y de la correcta adaptación de las piezas no especificas.",
    "e)	El cliente renuncia a retirar las piezas, elementos, y conjuntos sustituidos de su vehículo, con motivo de la reparación del mismo.",
    "f)	El cliente acepta que sus datos sean almacenados de forma temporal para ser tratados para los fines que este proceso contempla."
  )
  constructor(public dialog: MatDialog){
    this.allSections.push(this.section1)
  }
  ngOnInit(): void {}
}

class TermSection{
  content : string[] = [];
  constructor(public title : string, ...content : string[] ){
    this.content = content;
  }
}