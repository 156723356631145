import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-ivachanger',
    templateUrl: './ivachanger.component.html',
    styleUrls: ['./ivachanger.component.css'],
    standalone: false
})
export class IvachangerComponent implements OnInit {

  iva : UntypedFormControl;
  constructor(public dialogRef: MatDialogRef<IvachangerComponent>) { 
    this.iva = new UntypedFormControl(21, Validators.required);
  }

  ngOnInit(): void {
  }

  change() {
    if(this.iva.valid) {
      this.dialogRef.close(this.iva.value)
    }
  }

}
