import { M_Contact } from './M_Contact';
import { M_Breakdown } from './M_Breakdown';
import { M_LastMovements } from './M_LastMovement';
import { calculateTotalsBreakdown, M_TotalsBreakdown } from '../services/totals-calculator.service';
import { IPageStructureItem } from '../interfaces/IPageStructureItem';
import { IInvoiceLabel } from '../interfaces/IInvoiceLabel';
import { invoice_states, invoice_states_partial, invoice_states_payed, invoice_states_pending } from '../custom-classes/invoice_states';
import { invoice_type, invoice_type_abono, invoice_type_comercial, invoice_type_OR, invoice_type_recambios } from '../custom-classes/invoice_types';
import { or_types } from '../custom-classes/or_types';
import { getArrayOf } from '../utils/FunctionUtils';
import { match } from '../services/search.service';


export class M_Invoice implements IPageStructureItem, IInvoiceLabel {
  id: number | undefined;
  /** TODO  */
  company_scope_id: number | undefined;
  type: number | undefined;
  client_id: number;
  template_id: number = 1;
  user_id: number = 1;
  iva?: number;
  title: string;
  footer: string;
  comment: string;
  custom = 0;
  present = 0;
  payment = 'card';
  state: invoice_states;
  client: M_Contact | undefined;
  created_at: Date | undefined;
  token: string | undefined;
  isDraft: boolean;
  aboned: boolean;
  last_movement: M_LastMovements[] = [];
  type_invoice: invoice_type | undefined = undefined;
  type_or: or_types | undefined = undefined;
  abono_of: M_Invoice | undefined = undefined;
  aboned_to: M_Invoice | undefined = undefined;
  private _pendingImport: number | undefined;
  breakdown: M_Breakdown;
  internal: boolean;
  destroyed: boolean = false;
  created: boolean = false;

  total: number;
  subtotal: number;
  cli_tax_free: boolean;
  iva_21: number;
  subtotal_iva_21: number;
  iva_10: number;
  subtotal_iva_10: number;
  iva_04: number;
  subtotal_iva_4: number;
  subtotal_iva_0: number


  constructor(d: any) {

    this.id = d.id;
    this.type = d.type;
    this.company_scope_id = d.invoice_id;
    this.client_id = d.client_id;
    this.template_id = d.template_id;
    this.aboned = d.has_payout;
    this.user_id = d.user_id;
    this.title = d.title;
    this.footer = d.footer;
    this.custom = d.custom;
    this.present = d.present;
    this.payment = d.payment;
    this.state = d.state == 'created' ? invoice_states_pending : d.state == 'partial' ? invoice_states_partial : invoice_states_payed;
    this.client = d.client ? new M_Contact(d.client) : undefined;
    this.iva = d.iva ? d.iva : undefined;
    this.created_at = d.created_at ? new Date(d.created_at) : undefined;
    this.token = d.token ? d.token : undefined;
    this.isDraft = d.draft ? d.draft : false;
    this.abono_of = d.abono_of ? new M_Invoice(d.abono_of) : undefined;
    this.aboned_to = d.aboned_to ? new M_Invoice(d.aboned_to) : undefined;
    this.comment = d.comment;
    this.internal = d.internal;

    /** Fixed breakdown */
    this.total = d.total;
    this.subtotal = d.subtotal;
    this.cli_tax_free = d.cli_tax_free;
    this.iva_21 = d.iva_21;
    this.subtotal_iva_21 = d.subtotal_iva_21;
    this.iva_10 = d.iva_10;
    this.subtotal_iva_10 = d.subtotal_iva_10;
    this.iva_04 = d.iva_04;
    this.subtotal_iva_4 = d.subtotal_iva_4;
    this.subtotal_iva_0 = d.subtotal_iva_0;

    if (d.last_movement) {
      this.last_movement = getArrayOf(M_LastMovements, d.last_movement);
    }
    this.breakdown = new M_Breakdown(d.lines);
    let t = d.type;
    if (t != undefined && typeof t == "number") {
      this.generateType(t);
    }

  }

  get itemId() {
    return this.id;
  }


  get invoice_type_or_type() {
    return [this.type_invoice, this.type_or];
  }

  generateType(t: number) {
    if (typeof t == 'number') {
      if (t == 0 || t == 1 || t == 2 || t == 3) {
        this.type_invoice = invoice_type_OR;
        this.type_or = new or_types(t);
      } else if (t == 4) {
        this.type_invoice = invoice_type_recambios;
      } else if (t == 5) {
        this.type_invoice = invoice_type_abono;
      } else if (t == 6) {
        this.type_invoice = invoice_type_comercial;
      }
    }
  }

  type_filter() {
    let filter_arrat_type: number[] = [];

    if (this.state.isPending) {
      filter_arrat_type.push(0);
    }
    if (this.state.isPartial) {
      filter_arrat_type.push(1);
    }
    if (this.state.isPayed) {
      filter_arrat_type.push(2);
    }
    return filter_arrat_type;
  }

  get isAbono() {
    return this.type_invoice?.isAbono;
  }

  get pending_import() {
    if (!this.total) { return 0 }
    let totalaux = this.total;
    this.last_movement.forEach((l) => {
      if (this.isAbono) {
        totalaux = l.import - totalaux;
      }
      else {
        totalaux -= l.import;
      }
    });
    this._pendingImport = Math.abs(totalaux.castDecimals(2));
    return this._pendingImport;
  }

  set SetPending_import(value: number) {
    this._pendingImport = value;
  }

  get isTotalPay() {
    if (this.state.isPending) {
      return false;
    }
    return this.pending_import == 0;
  }

  defaultSearchFilter(text: string) {
    text = text.toLocaleLowerCase();
    return match(
      text,
      this.company_scope_id ? this.company_scope_id.toString() : '',
      this.client_id ? this.client_id.toString() : '',
      this.client ? this.client.getName() : '',
      this.total ? this.total.toString() : '',
      this.state ? '' : '',
      this.created_at ? this.created_at.dayMonthYearFormat() : ''
    );
  }

  get tagLabel() {

    if (this.isInternoInvoice) { return "TRAMITADO" }

    return this.isAbono
      ? this.state.isPending
        ? 'PENDIENTE'
        : this.state.isPartial
          ? 'PARCIAL'
          : 'PAGADO'
      : this.state.isPending
        ? 'PENDIENTE'
        : this.state.isPartial
          ? 'PARCIAL'
          : 'COBRADA';
  }

  get icon() {
    if (this.type_invoice) {
      return this.type_invoice.isRecambios ? 'receipt_long'
        : this.type_invoice.isComercial ? 'garage'
          : 'currency_exchange';
    }
    return 'build';
  }

  getTotalBreakdown(fixed: boolean, viewClient?: M_Contact): M_TotalsBreakdown {
    if (!fixed) {
      let breakdown = calculateTotalsBreakdown(this.breakdown.all, viewClient || this.client)
      return breakdown;
    }
    /** Get the saved values from backend */
    else {
      const b = new M_TotalsBreakdown([], undefined, false);
      b.total = this.total;
      b.subtotal = this.subtotal;
      b.client_excent_iva = this.cli_tax_free;
      if (this.subtotal_iva_0) { b.iva_rows.push({ iva: 0, subtotal: this.subtotal_iva_0, total: this.subtotal_iva_0 }) }
      if (this.iva_04) { b.iva_rows.push({ iva: 4, subtotal: this.subtotal_iva_4, total: this.iva_04 }) }
      if (this.iva_10) { b.iva_rows.push({ iva: 10, subtotal: this.subtotal_iva_10, total: this.iva_10 }) }
      if (this.iva_21) { b.iva_rows.push({ iva: 21, subtotal: this.subtotal_iva_21, total: this.iva_21 }) }
      return b;
    }
  }

  get isInternoInvoice() {
    let internalOR = this.type_or != undefined && this.type_or.interno;
    let internalAbono = this.isAbono && this.internal;
    return internalOR || internalAbono;
  }

  get someProductRequested() {
    return this.breakdown.products.some(p => p.isRequested)
  }
}