import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from '../../models/M_Contact';
import { M_Reservation } from '../../models/M_Reservation';
import { M_Product } from '../../models/Products/M_Product';
import { ParamsService } from '../../services/params.service';
import { ManualReservationComponent, manualReservationData } from '../manual-reservation/manual-reservation.component';
import { M_Fault } from '../../models/M_Fault';
import { ApiService } from 'src/app/services/Api/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { GoFaultService } from '../../services/go-fault.service';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
    selector: 'app-fault-product-table',
    templateUrl: './fault-product-table.component.html',
    styleUrls: ['./fault-product-table.component.css'],
    standalone: false
})
export class FaultProductTableComponent {

  @Input() faults!: M_Fault[];
  @Input() product: M_Product | undefined;
  @Input() client: M_Contact | undefined;
  @Output() onGoFault: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<M_Fault> = new MatTableDataSource<M_Fault>([]);
  displayedColumns: string[] = [];
  v = ViewPath;

  constructor(private apiS: ApiService, private d: MatDialog, public responsiveS: ResponsiveService,
    public routerS: RouterService, public paramS: ParamsService, private confirmD: ConfirmDialogService,
    private goFaultS: GoFaultService) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.faults;
    this.updateDisplayedColumns()
  }

  updateDisplayedColumns(): void {
    if (this.responsiveS.w < 500) {
      if (this.client != undefined) {
        this.displayedColumns = ['reference'];
      } else {
        this.displayedColumns = ['document'];
      }

    } else if (this.responsiveS.w < 750) {
      if (this.client != undefined) {
        this.displayedColumns = ['reference', 'quantity'];
      } else {
        this.displayedColumns = ['document', 'quantity'];
      }

    } else {
      if (this.client != undefined) {
        this.displayedColumns = ['reference', 'quantity', 'created_at', 'Eliminar'];
      } else {
        this.displayedColumns = ['document', 'quantity', 'created_at', 'Eliminar'];
      }
    }
  }

  get displayedColumnsByScreenSize() {
    return this.responsiveS.w > 500 ? this.displayedColumns : this.displayedColumns.slice(0, this.displayedColumns.length - 1);
  }

  canDeleteFault(f: M_Fault) {
    return f.isManual && !f.requested;
  }

  deleteFault(fault: M_Fault) {
    this.confirmD.show({
      title: "Eliminar falta",
      body: "¿Está seguro de que quieres eliminar la falta?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteFault(fault.id).then(res => {
          this.faults.removeElement(fault);
          this.ngAfterViewInit();
        })
      }
    })
  }

  goCreateProduct() {
    if (this.client != undefined) {
      this.d.open<ManualReservationComponent, manualReservationData>(ManualReservationComponent, { data: { client: this.client }, autoFocus: false })
        .afterClosed().subscribe(res => { });
    } else {
      this.d.open<ManualReservationComponent, manualReservationData>(ManualReservationComponent,
        { data: { product: this.product }, autoFocus: false }).afterClosed().subscribe(res => {
          if (res instanceof M_Reservation) {
            this.product?.reservations.push(res);
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = this.faults;
          }
        });
    }
  }

  goDocument(res: M_Reservation) {
    if (this.client != undefined) {
      this.routerS.goWithQueryParams(ViewPath.createOrder, {});
    }
    else {
      let dest = res.getDestView;
      let master_id = res.getMasterId;
      if (dest && master_id) {
        let paramName = this.paramS.getStateNameByView(dest); // get the param name ("or", "budget"...)
        this.routerS.goWithQueryParams(dest, { [paramName]: master_id })
      }
    }
  }

  goByFult(f: M_Fault) {
    let go = this.goFaultS.go(f, false);
    if (go) { this.onGoFault.emit(); }
  }

}
