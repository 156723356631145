<div class="preview-container">
    <div class="close" prevent *ngIf="data.preview.canClose == undefined || data.preview.canClose == true">
        <button (click)="dRef.close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="donwload" [ngClass]="{'unique' : data.preview.canClose == false}" prevent
        *ngIf="data.preview.minifyDownload == true">
        <button (click)="download()" [disabled]="pdfComponent.url == undefined" mat-icon-button>
            <mat-icon>download</mat-icon>
        </button>
    </div>
    <div class="preview-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="relative">
            <mat-icon class="drag-indicator">drag_indicator</mat-icon>
        </div>
        <div class="df jcc" *ngIf="data.preview.minifyDownload == undefined || data.preview.minifyDownload == false">
            <app-card [noStyle]="true">

                <div class="df" style="gap: 10px">
                    <button *ngIf="data.pdf.token" mat-stroked-button (click)="download()" class="mt10 mb10"
                        [disabled]="pdfComponent.url == undefined">
                        <mat-icon>download</mat-icon>
                        Descargar PDF
                    </button>

                    <button *ngIf="data.pdf.token && data.pdf.preview_type == 'I'" mat-stroked-button
                        [disabled]="pdfComponent.url == undefined" (click)="downloadXML()" class="mt10 mb10">
                        <mat-icon>download</mat-icon>
                        Descargar XML
                    </button>
                </div>


                <!-- COMPANY PREVIEWS -->
                <div *ngIf="data.pdf.company_data">
                    <p mat-dialog-title class="tac">Vista previa</p>
                    <p mat-dialog-subtitle class="tac" *ngIf="data.pdf.preview_type">{{nameByType}}</p>
                </div>

            </app-card>
        </div>
        <div *ngIf="this.data.pdf.preview_type == 'RA' && data.preview.canClose == false" class="ra_actions">
            <p mat-dialog-title>OR generada</p>
            <div class="df fdr">
                <button mat-button mat-dialog-close (click)="goOrView()">Ir listado OR</button>
                <button color="primary" mat-flat-button mat-dialog-close (click)="goOr()">Trabajar en OR</button>
            </div>

        </div>
    </div>

    <app-pdf [pdf_data]="data.pdf" [params]="this.data.params"></app-pdf>
    <div *ngIf="this.data.pdf.preview_type == 'I' && this.data.invoice && this.data.invoice.total != 0"
        class="ra_actions">
        <div class="df fdr mt20">
            <button class="mr10" mat-button mat-dialog-close (click)="goInvoiceView(data.invoice.id)">Ir listado Facturas</button>
            <button color="primary" class="onboarding-invoice-payment" mat-flat-button mat-dialog-close (click)="goCobrarInvoice()">Cobrar</button>
        </div>

    </div>
    <div *ngIf="this.data.pdf.preview_type == 'CV' && this.data.params != undefined"
        class="ra_actions">
        <div class="df fdr mt20">
            <button class="mr10" color="primary" mat-flat-button mat-dialog-close>Ok</button>
        </div>

    </div>
    <div *ngIf="this.data.pdf.preview_type == 'A'"
        class="ra_actions">
        <div class="df fdr mt20">
            <button class="mr10" mat-button mat-dialog-close (click)="goInvoiceView(data.invoice.id)">Ir listado Facturas</button>
            <button color="primary" class="onboarding-invoice-payment" mat-flat-button mat-dialog-close (click)="goCobrarAbono()">Pagar</button>
        </div>

    </div>
    <div *ngIf="this.data.pdf.preview_type == 'OR'" class="ra_actions">
        <div class="df fdr mt20">
            <button class="mr10" mat-button mat-dialog-close (click)="goInvoiceView(data.pdf.id)">Ir listado Facturas</button>
            <button color="primary" *ngIf="data.preview.showORcharge" mat-flat-button mat-dialog-close (click)="goCobrarInvoice()">Cobrar</button>
        </div>

    </div>
</div>