<app-page-structure [masterClass]="'vehicle'" [autocompleteID]="'vehicle'" pageTitle="Vehículos"
    searchLabel="Buscar vehículo" listTitleText="Listado de vehículos" noDataCreateNew="Crear un nuevo vehículo"
    [data]="vehicles" [displayedHeader]=" ['Matrícula',   'Marca',    'Modelo', 'Propietario', 'Última reparación']"
    [displayedColumns]="['license',     'brand_name',    'model_name',  'client', 'last_reception' ]"
    [specialText]="     [undefined,      undefined,  undefined,  getOwner,  undefined]"
    [specialClass]="    [undefined,      undefined,  undefined,  undefined,  undefined]" [preIcon]="[preIcon]"
    [filters]="filters" [filter]="f" (onclickRow)="params.go(v.vehicleDetails, $event.vehicle_id)"
    [exportExcel]=" {singular : 'vehículo', plural : 'vehículos',name:'vehicles'}"
    [quickFilter]="quickFilter" [createButton]="
        {
            text : 'Crear vehículo' ,
            icon : 'wheel_outline',
            view : v.createvehicle,
            cssClass:'onboarding-create-vehicle'
        }">
</app-page-structure>