import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModulesExplanationComponent } from '../modules-explanation/modules-explanation.component';

@Component({
    selector: 'app-integrations-explanation',
    templateUrl: './integrations-explanation.component.html',
    styleUrls: ['./integrations-explanation.component.css'],
    standalone: false
})
export class IntegrationsExplanationComponent {
  constructor(public dRef: MatDialogRef<ModulesExplanationComponent>){}
}
