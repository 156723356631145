import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { BookMarkEnum } from '../../enums/BookMarkEnum';

@Component({
    selector: 'app-sotrehouse-bookmark',
    templateUrl: './sotrehouse-bookmark.component.html',
    styleUrls: ['./sotrehouse-bookmark.component.css'],
    standalone: false
})
export class SotrehouseBookmarkComponent {
  @Output() onBookmark: EventEmitter<BookMarkEnum> = new EventEmitter();
  @ViewChild(MatMenu, { static: true }) menu!: MatMenu;
  bookmarkEnum = BookMarkEnum;
  constructor() { }
  updateBookmark(bookMark: BookMarkEnum) { this.onBookmark.emit(bookMark); }
}
