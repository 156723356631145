import { Component, Input } from '@angular/core';
import { albaran_status } from '../../custom-classes/albaran_status';

@Component({
    selector: 'app-albaran-status-label',
    templateUrl: './albaran-status-label.component.html',
    styleUrls: ['./albaran-status-label.component.css'],
    standalone: false
})
export class AlbaranStatusLabelComponent {
  @Input() showText: boolean = false;
  @Input() pointer = false;
  @Input() circularStatus: boolean = false;
  @Input() status!: albaran_status | undefined;
  @Input() menuIconOpen: boolean = false;
  @Input() showIcon: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.status == undefined) {
      this.status = new albaran_status("Cerrado");
    }
  }

  get iconByStatus() {
    return this.status?.num == 0 ? "shcedule" : this.status?.num == 1 ? "schedule" : this.status?.num == 2 ? 'receipt_long': "done"
  }
}
