import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { MenuComponent } from '../menu/menu.component';
import * as LogRocket from 'logrocket';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { feature } from '../../utils/FeaturesController';
import { OrTimerService } from '../../services/or-time.service';
import { UserService } from '../../services/EinaMainData/user.service';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';
import { INotification } from 'src/app/interfaces/INotification';
import { roleGroup } from 'src/app/enums/RolesEnum';
import { M_User } from 'src/app/models/M_User';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { ProfilePictureService } from 'src/app/services/profile-picture.service';
import { SnackService } from 'src/app/services/snack.service';
import { SigningTimeService } from 'src/app/services/signing-time.service';
import { HubspotService } from 'src/app/services/hubspot.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    standalone: false
})

export class ToolbarComponent implements OnInit {
  f = feature;
  v = ViewPath;
  q: number = 0;
  @Input() mc!: MenuComponent;
  @Input() onboarding!: OnboardingComponent;
  @Input({ required: true }) showMenuButton!: boolean;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public subS: SubscriptionService, public routerS: RouterService, public sessionS: SessionService,
    public apiS: ApiService, public userS: UserService, public timerS: OrTimerService, private chdRef: ChangeDetectorRef,
    private profilePicrureS: ProfilePictureService, private snackS: SnackService, private signingS: SigningTimeService,
    public hubspotS: HubspotService) { }

  ngOnInit(): void {

    this.timerS.makeCall().then(() => {
      this.chdRef.detectChanges();
    });

    this.signingS.getData();

    if (feature.logRocket) {
      LogRocket.identify(this.userS.userId!.toString(), {
        name: this.userS.userName,
        email: this.userS.userEmail
      });
    }

  }

  goProfile() {
    this.routerS.goTo(this.v.profile);
  }

  notificationAction(not: INotification) {
    // if (not instanceof ...) {
    // }
  }

  showBigMamaByRoleAndView() {
    let role = this.userS.getRole();
    if (!role) { return true; }
    if (roleGroup.bigMamaAsDashboard.includes(role) && this.routerS.is(ViewPath.dashboard)) { return false };
    return true;
  }

  uploadImage(data: [cf: CustomFile, u: M_User]) {
    this.apiS.uploadImage(data[0], "profile").then(_res => {
      this.snackS.show("¡Foto de perfil actualizada! 📸")
    })
    this.profilePicrureS.onChangeProfilePicture(data);
  }

  get greetingPhrase() {
    const d = new Date();
    const name = this.userS.userName;
    let h = d.getHours()
    if (h >= 6 && h <= 12) { return `¡Buenos días ${name}!` }
    else if (h >= 13 && h <= 19) { return `¡Buenas tardes ${name}!` }
    else if (h >= 20 && h <= 5) { return `Buenas noches ${name}!` }
    return name;
  }
}
