<!-- WEEK OR -->
<div (click)="onGoOR.emit()" class="week-or-card">
  <!-- <div class="header or">
    <div class="header-contents">
      <mat-icon class="mr5">build</mat-icon>
      <p class="week-text workload-overflow-text fsm mr10 fw500">{{item.title_to_show}}</p>
      <button prevent mat-icon-button [matMenuTriggerFor]="actions" class="mla more-vert">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div> -->
  <mat-chip class="small-chip c_w">{{item.title_to_show}}</mat-chip>
  <div class="body mt10">
    <p class="fsm fw600 ttu overflow-text">{{item.vehicleName()}}</p>
    <p class="fss fw600 overflow-text">{{item.clientName()}}</p>
  </div>
  <div class="middle-contents mt10">
    <p class="c_t2 max2lines fsxs">
      <span *ngFor="let g of item.groups; let last = last">
        {{g.getTitle()}}{{!last ? ', ' : ''}}
      </span>
    </p>
  </div>
  <div class="bottom-section">
    <div class="bottom-section-content">
      <div class="df aic w100">

        <div class="df aic cp" prevent [matMenuTriggerFor]="selsectuser" style="margin-top: auto;">
          <app-circular-letter class="mr5" [small]="true" [showFullName]="false" *ngIf="item.assigned != undefined"
            [grayOut]="!onFilters" [user]="item.assigned" [showTooltip]="false">
          </app-circular-letter>
          <mat-icon class="mr5" *ngIf="item.assigned == undefined">account_circle</mat-icon>
        </div>

        <div class="df aic mla">

          <p *ngIf="item.delivery && item.schedule && item.delivery.isEquals(item.schedule) && item.status.open"
            class="fw500 nmb fss mr5_i">
            ¡Se entrega hoy!
          </p>
          <p *ngIf="item.delivery && item.schedule && !item.delivery.isEquals(item.schedule) && item.delivery < item.schedule && item.status.open"
            class="c_r fw500 nmb fss mr5_i">
            Fuera de plazo
          </p>

          <app-or-status-label [circularStatus]="true" style="transform: scale(0.8); margin-left: -5px;"
            [status]="item.status">
          </app-or-status-label>

          <!-- <app-or-type-label [wrapOn]="1" [small]="true" [tipo]="item.type" [showText]="false">
            </app-or-type-label>
            <p prevent [matMenuTriggerFor]="groupMenu" [matMenuTriggerData]="{groups : item.groups}"
              class="cp fss nmb blue_text_action" style="margin-left: auto;; margin-right: 12px;">
              ({{item.groups.length}})</p> -->
        </div>
      </div>
    </div>
  </div>
</div>


<mat-menu #selsectuser>
  <lib-or-change-worker [day]="day" [item]="item" [canAssign]="canAssign" [allUsers]="allUsers"
    (onAssignUser)="onAssignUser.emit($event)"></lib-or-change-worker>
</mat-menu>

