import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { phoneValidator } from '../../validators/phone-validator';
import { BaseInput } from './base-input';


/**
 * [Component]
 * Input para introducir un teléfono.
 * Si se introduce un valor que no es un teléfono, se muestra un error automáticamente.
 */

@Component({
    selector: 'app-phone-input',
    styleUrls: [],
    template: ` <div class="{{class_}}" [formGroup]="form">
              <mat-form-field  appearance="outline">
                  <mat-label>{{label ? label : 'Teléfono'}}</mat-label>
                  <input matInput type="number" 
                        formControlName="{{formCName}}" 
                        [required]="required" 
                        [attr.autocomplete]="disableAutocomplete ? 'off' : null"
                        [placeholder]="placeHolder? placeHolder : 'Introduce el teléfono'">
                  <mat-error *ngIf="hasErrors()">Introduce un teléfono válido</mat-error>
              </mat-form-field>
            </div>`,
    standalone: false
})
export class PhoneInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'phoneInput ' + this.classSelector;
  constructor(changeDetectorr: ChangeDetectorRef) {
    super(changeDetectorr)
  }

  override ngOnInit(): void {
    this.control!.setValidators( this.required? [Validators.required, phoneValidator(this.required)] : [phoneValidator(this.required)])
  }

}
