import { RolesEnum } from "../enums/RolesEnum";
import { ConfirmData } from "../services/confirm-dialog.service";
import { randomNumber } from "../utils/FunctionUtils";
import { M_Schedule } from "../models/M_Schdeule";
import { M_SigningTime } from "../models/Signing/M_SigningTime";
import { Country } from "../components/country-selector/COUNTRIES_DB_ES";

export const PS_HIGHLIGHT = "ps-highlight-item";

export const DEFAULT_IVA = 21;

export const max_buy_price = 999999;
export const buy_price_ant = 999999;
export const max_price = 999999;
export const min_price = -999999;
export const max_facturable_product = 999999999999;

// export const HARDCODED_TAX = 21;
export const CODE_PROMOTIONAL = "30GRATIS";
export const COUNTRY_ESP: Country = {
    alpha2Code: "ES",
    alpha3Code: "ESP",
    callingCode: "+34",
    name: "España",
    numericCode: "724",
    flag: "https://flagcdn.com/24x18/es.png"
}

/** https://www.sigaus.es/factura-del-taller */
export const SIGAUS_PRICE_LITER = 0.05


/** https://www.signus.es/tarifa-ecovalor/ */
export interface Signus {
    id: number,
    price: number
    name: string
}

export const calendarEnabled = [RolesEnum.ADMIN, RolesEnum.GESTOR, RolesEnum.ADVISER];

export const SIGNUS_N1: Signus = { id: 1, name: "N1", price: 0.73 };
export const SIGNUS_N2: Signus = { id: 2, name: "N2", price: 1.64 };
export const SIGNUS_N3: Signus = { id: 3, name: "N3", price: 5.88 };
export const SIGNUS_N4: Signus = { id: 4, name: "N4", price: 11.76 };
export const SIGNUS_N5: Signus = { id: 5, name: "N5", price: 14.33 };
export const SIGNUS_N6: Signus = { id: 6, name: "N6", price: 19.66 };
export const SIGNUS_N7: Signus = { id: 7, name: "N7", price: 23.88 };
export const SIGNUS_N8: Signus = { id: 8, name: "N8", price: 52.94 };

export const SIGNUS_S1: Signus = { id: 9, name: "S1", price: 12.59 };
export const SIGNUS_S2: Signus = { id: 10, name: "S2", price: 25.33 };
export const SIGNUS_S3: Signus = { id: 11, name: "S3", price: 37.82 };
export const SIGNUS_S4: Signus = { id: 12, name: "S4", price: 51.92 };
export const SIGNUS_S5: Signus = { id: 13, name: "S5", price: 66.82 };
export const SIGNUS_S6: Signus = { id: 14, name: "S6", price: 81.96 };
export const SIGNUS_S7: Signus = { id: 15, name: "S7", price: 101.82 };
export const SIGNUS_S8: Signus = { id: 16, name: "S8", price: 124.81 };
export const SIGNUS_S9: Signus = { id: 17, name: "S9", price: 153.06 };
export const SIGNUS_S10: Signus = { id: 18, name: "S10", price: 186.37 };
export const SIGNUS_S11: Signus = { id: 19, name: "S11", price: 233.67 };
export const SIGNUS_S12: Signus = { id: 20, name: "S12", price: 513.88 };
export const SIGNUS_S13: Signus = { id: 21, name: "S13", price: 1136.80 };
export const SIGNUS_S14: Signus = { id: 22, name: "S14", price: 2026.04 };

export const SIGNUS_ARRAY = [

    SIGNUS_N1,
    SIGNUS_N2,
    SIGNUS_N3,
    SIGNUS_N4,
    SIGNUS_N5,
    SIGNUS_N6,
    SIGNUS_N7,
    SIGNUS_N8,

    SIGNUS_S1,
    SIGNUS_S2,
    SIGNUS_S3,
    SIGNUS_S4,
    SIGNUS_S5,
    SIGNUS_S6,
    SIGNUS_S7,
    SIGNUS_S8,
    SIGNUS_S9,
    SIGNUS_S10,
    SIGNUS_S11,
    SIGNUS_S12,
    SIGNUS_S13,
    SIGNUS_S14,
]

export function getSignusById(id: number): Signus {
    if (id > 0 && id <= SIGNUS_ARRAY.length) {
        return SIGNUS_ARRAY[id - 1];
    }
    return SIGNUS_N1;
}


/** Appointments dialog */

/** Mark appointment as done (Workload and appointments side details) */
export const APPD_markAsDone: ConfirmData = {
    title: "Marcar como hecha",
    body: "¿Estás seguro de que quieres marcar esta cita como hecha?"
}

/** Mark appointment as canceled (Workload and appointments side details) */
export const APPD_markAsCanceled: ConfirmData = {
    title: "Cancelar cita",
    body: "¿Estás seguro de que quieres marcar esta cita como cancelada?",
    type: "danger"
}

export const APPD_markAsPending: ConfirmData = {
    title: "Marcar como pendiente",
    body: "¿Estás seguro de que quieres marcar esta cita como pendiente?",
    type: "warn"
}


/** No faults messages */
export const NO_FAULTS_MESSAGES = [
    "¡Nada de faltas! ¡Tu inventario está impecable! 📦",
    "¡Cero faltas! ¡Tu stock está en perfecto estado! 🛒",
    "¡Ninguna falta de stock! ¡Eres un maestro del almacenamiento! 🏆",
    "¿Faltas? ¡Ninguna! ¡Tu stock es digno de admiración! 📋",
    "¡Cero faltas! ¡Tu almacén es un modelo de eficiencia! 📦",
    "¡Sin faltas! ¡Tu stock está en su mejor momento! 💪",
]

export function getRandomNoFaultMessage(): string {
    return NO_FAULTS_MESSAGES[randomNumber(0, NO_FAULTS_MESSAGES.length - 1)];
}


/** No product messages */
export const NO_PRODUCT_MESSAGES = [
    "¡Hora de añadir un producto! 👇🏻",
    "¡Es momento de empezar con un nuevo producto! 🆕",
    "¡La lista de productos está vacía! ¡Añade uno ya! 💡",
    "¡Vamos, a llenar la tabla con tus productos! 💥",
    "¡Prepárate para añadir un producto a la tabla! 🛠️",
    "¡Hora de acción! Añade un producto ahora mismo. ⚙️",
    "¡No dejes la tabla vacía! ¡Añade un producto! 🔩",
    "¡No pierdas más tiempo! ¡Añade un producto a la lista! 📝"
]

export function getRandomNoProductMessage(): string {
    return NO_PRODUCT_MESSAGES[randomNumber(0, NO_PRODUCT_MESSAGES.length - 1)];
}


/** No product messages */
export const NO_VEHICLE_MESSAGES = [
    "¡Hora de añadir un vehículo! 🚗",
    "¡Hora de añadir un vehículo! 🏍️",
    "¡Es momento de empezar con un nuevo vehículo! 🆕",
    "¡La lista de vehículos está vacía! ¡Añade uno ya! 💡",
    "¡Vamos, a llenar la tabla con tus vehículos! 💥",
    "¡Prepárate para añadir un vehículo a la tabla! 🚙",
    "¡Hora de acción! Añade un vehículo ahora mismo. ⚙️",
    "¡No dejes la tabla vacía! ¡Añade un vehículo! 🛠️",
    "¡No pierdas más tiempo! ¡Añade un vehículo a la lista! 📝"
]

export function getRandomNoVehicleMessage(): string {
    return NO_VEHICLE_MESSAGES[randomNumber(0, NO_VEHICLE_MESSAGES.length - 1)];
}


/** No data messages */
export const NO_DATA_MESSAGE = [
    "Ni una tuerca a la vista 🔩",
    "El espacio está vacío 🔨",
    "Sin datos por ahora 📄",
    "Nada que mostrar 🔍"
]

export function getRandomNoDataMessageMessage(): string {
    return NO_DATA_MESSAGE[randomNumber(0, NO_DATA_MESSAGE.length - 1)];
}


/** Big mamma message */
export const BIGMAMMAMESAGE = [
    "¿Por dónde empezamos?",
    "¿Qué necesitas hacer hoy?",
    "¿Cómo podemos ayudarte?",
    "¿Cuál es tu próxima tarea?",
    "¿Qué quieres revisar primero?",
    "Empieza a simplificar tu día.",
    "¿Qué te gustaría resolver ahora?",
    "Todo listo, ¿qué sigue?",
    "Tu próximo movimiento comienza aquí.",
]

export function getRandomBigMammaMessage(): string {
    return BIGMAMMAMESAGE[randomNumber(0, BIGMAMMAMESAGE.length - 1)];
}



/** Calendar holidys */
export const CALENDAR_LOADING = 'loadingDay ';
export const CALENDAR_COMPANY = 'companyDay ';
export const CALENDAR_MINI_COMPANY = 'mini-companyDay ';
export const CALENDAR_USER_HOLIDAY = 'userDay ';
export const CALENDAR_MINI_SCHEDULE = 'mini-schedule ';



/** Errors on changing view */
export const ERROR_MODULE_NO_ACTIVE = "Módulo no activo. Contacte con el administrador/a 🔒";
export const ERROR_NOT_PERMISSION = "Sin permisos para ir a la pantalla ⛔";
export const ERROR_SHARED_ACCES = "No puedes acceder a esta pantalla desde el acceso compartido 👥";


export function getIconByDate() {
    const d = new Date();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    // Año Nuevo
    if (month === 1 && day === 1) { return "🎉"; }
    // Día de Reyes
    if (month === 1 && day === 6) { return "🎁"; }
    // Inicio de Primavera
    if (month === 3 && day === 20) { return "🌼"; }
    // Inicio de Verano
    if (month === 6 && day === 21) { return "☀️"; }
    // San Juan
    if (month === 6 && day === 24) { return "🧨"; }
    // Inicio de Otoño
    if (month === 9 && day === 23) { return "🍂"; }
    // Halloween
    if (month === 10 && day === 31) { return "🎃"; }
    // Inicio de Invierno
    if (month === 12 && day === 21) { return "❄️"; }
    // Navidad
    if (month === 12 && day === 25) { return "🎅🏻"; }
    // Nochevieja
    if (month === 12 && day === 31) { return "🍾"; }
    return "";
}



/** SIGNGING */

/** Default signing time */
export function defaultSigningTime(){
    return new M_SigningTime({
        day: new Date(),
        morning_start: undefined,
        morning_end: undefined,
        afternoon_start: undefined,
        afternoon_end: undefined,
    })
}

/** Default schedule */
export function defaultSchedule() {
    return [
        new M_Schedule({}), // Monday
        new M_Schedule({}), // Tuesday
        new M_Schedule({}), // Wednesday
        new M_Schedule({}), // Thursday
        new M_Schedule({}), // Friday
        new M_Schedule({}), // Saturday
        new M_Schedule({}), // Sunday
    ]
}

      // let s = [
      //   new M_Schedule({}), // Monday
      //   new M_Schedule({}), // Tuesday
      //   new M_Schedule({
      //     morningStart: "10:00",
      //     morningEnd: "11:00",
      //   }), // Wednesday
      //   new M_Schedule({
      //     morningStart: "10:00",
      //     morningEnd: "11:00",
      //   }), // Thursday
      //   new M_Schedule({}), // Friday
      //   new M_Schedule({}), // Saturday
      //   new M_Schedule({}), // Sunday 
      // ];
      // resolve(
      //   new M_Signing({
      //     now: new M_SigningTime({
      //       day: new Date(),
      //       morningStart: undefined,
      //       morningEnd: undefined,
      //       afternoonStart: undefined,
      //       afternoonEnd: undefined,
      //       schedule: s
      //     }),
      //     schedule: s,
      //     history: []
      //   }))