import { Component, Input } from '@angular/core';

@Component({
    selector: 'to-do, app-to-do',
    templateUrl: './to-do.component.html',
    styleUrls: ['./to-do.component.css'],
    standalone: false
})

/** Component that shows a message, by default : 🚧 En desarrollo 🚧 
 * This component only shows on dev and pre environments
*/
export class ToDoComponent {
  @Input() txt : string | undefined;
}
