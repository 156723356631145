import { Component, Input } from '@angular/core';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Invoice } from 'src/app/models/M_Invoice';

@Component({
    selector: 'app-invoice-totals-table',
    templateUrl: './invoice-totals-table.component.html',
    styleUrls: ['./invoice-totals-table.component.css'],
    standalone: false
})
export class InvoiceTotalsTableComponent {
  
  @Input({ required: true }) invoice!: M_Invoice;
  @Input() viewClient?: M_Contact;

  /** Get the numbers from the values seved on backend */
  @Input({ required: true }) fixedTotals: boolean = false;
  @Input() alignment!: "right" | undefined;
}
