import { Component } from '@angular/core';

@Component({
    selector: 'app-section-title',
    template: `<ng-content></ng-content>`,
    standalone: false
})
export class SectionTitleComponent {

}
