import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { AddTimeComponent, IAddTime } from 'src/app/components/add-time/add-time.component';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { getQuantityFromTime, hourToTime } from 'src/app/constants/time-picker-constants';
import { M_BaseProduct } from 'src/app/models/Products/M_BaseProduct';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { EditOrderComponent } from '../../edit-order/edit-order.component';
import { StorehouseEntrySectionComponent } from '../../create-storehouse-entry/storehouse-entry-section/storehouse-entry-section.component';
import { AddCommentComponent } from 'src/app/components/add-comment/add-comment.component';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { SnackService } from 'src/app/services/snack.service';

@Component({
    selector: 'app-add-remove',
    templateUrl: './add-remove.component.html',
    styleUrls: ['./add-remove.component.css'],
    animations: [
        trigger('openCloseSide', [state('open', style({ opacity: 1, pointerEvents: "all" })),
            state('closed', style({ opacity: 0, marginLeft: -30, pointerEvents: "none" })),
            transition('open => closed', [animate('0.2s')]),
            transition('closed => open', [animate('0.2s')]),
        ])
    ],
    standalone: false
})
export class AddRemoveComponent implements OnInit {

  @ViewChild('tooltip') tooltip!: MatTooltip;

  @Output() onSave: EventEmitter<number> = new EventEmitter();
  @Output() onChange: EventEmitter<"add" | "remove" | "custom"> = new EventEmitter();
  @Output() onInputFocusOut: EventEmitter<any> = new EventEmitter();
  @Output() onModification: EventEmitter<number> = new EventEmitter();

  @Input() product?: M_BaseProduct | undefined;
  @Input() showSaveIcon = true;
  @Input() initValue: number = 0;
  @Input() form!: UntypedFormGroup;
  @Input() formCName!: string;
  @Input() label?: string;
  @Input() isTime?: boolean = false;
  @Input() minValue: number | undefined = 0;
  @Input() maxValue: number | undefined = undefined;
  @Input() forceMinMaxValue: boolean = false;
  @Input() showLabel?: boolean = true;
  @Input() hint?: string | number | undefined;
  @Input() hintclass?: string | undefined;
  @Input() canModify: boolean = true;
  @Input() apparence: "outline" | "fill" = 'outline';
  @Input() required: boolean = true;
  @Input() addRemoveOutside: boolean = true;
  @Input() formClass: string = '';
  @Input() stockMinimo: boolean = false;

  showSave = false;
  tooltipVisible = false;
  Customproduct : M_CustomProduct | undefined;
  constructor(private chdRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private d: MatDialog, public subS: SubscriptionService, @Optional() private productLineTable: ProductLineTableComponent, private snackS: SnackService) { }

  ngOnInit(): void {
    if (!this.form && !this.formCName) {
      this.form = this.fb.group({
        stock: [0, this.required ? [] : []]
      });
      this.formCName = "stock";
    }

    if (!this.canModify) {
      this.form.get(this.formCName)?.disable();
    }

    if (this.formControl && this.product) {
      this.formControl.valueChanges.subscribe(v => {
        this.product!.quantity = this.formControl.value;
      })
    }
  }

  ngAfterViewInit() {
    if (isNaN(this.initValue) || this.initValue == undefined) {
      this.initValue = this.minValue ? this.minValue : 1;
    }
    this.formControl.patchValue(this.initValue)
  }

  ngOnChanges() {
    if (this.form) {
      let control = this.form.get(this.formCName);

      if (control) {
        if (this.canModify && control.disabled) {
          control.enable();
        }
        else if (!this.canModify && control.enabled) {
          control.disable();
        }
      }
    }
  }

  add(event: Event | undefined) {
    if (event) { event.stopPropagation(); }
    this.formControl.patchValue(this.formControl.value + 1);
    this.showSave = true;
    this.onChange.emit("add")
    this.onModification.emit(this.getValue())
  }

  remove(event: Event | undefined) {
    if (event) { event.stopPropagation(); }
    if (!this.disableMinus) {
      this.formControl.patchValue(this.formControl.value - 1);
      this.showSave = true;
      this.onChange.emit("remove")
      this.onModification.emit(this.getValue())
    }
  }

  get disableMinus() {
    let val = this.formControl.value;
    return val != undefined && this.minValue != undefined && val <= this.minValue;
  }

  get disableMax() {
    let val = this.formControl.value;
    return val != undefined && this.maxValue != undefined && val >= this.maxValue;
  }

  getValue() {
    return this.formControl.value;
  }

  save() {
    if (this.showSave) {
      this.showSave = false;
      this.onSave.emit(this.getValue())
    }
  }

  setNewTotal(e: any) {
    this.formControl.patchValue(Number(e.target.value));
    this.showSave = true;
  }

  setValue(v: number) {
    this.formControl.patchValue(v);
    this.showSave = true;
  }


  emitFocusEvent() {
    if (this.forceMinMaxValue) {
      let introducedVal = this.getValue();
      /** New changes to the input */
      if (this.minValue != undefined && introducedVal < this.minValue) {
        introducedVal = this.minValue;
        this.snackS.show("Valor mínimo : " + this.minValue)
        this.formControl.patchValue(introducedVal);
        this.onModification.emit(this.getValue())
      }
      else if (this.maxValue != undefined && introducedVal > this.maxValue) {
        introducedVal = this.maxValue;
        this.snackS.show("Valor máximo : " + this.maxValue)
        this.formControl.patchValue(introducedVal);
        this.onModification.emit(this.getValue())
      }
      /** Normal focus out */
      else {
        this.formControl.patchValue(introducedVal);
        this.onInputFocusOut.emit();
      }

    }
  }

  detectLimit(e: any) {
    if (e.target.value > 999) {
      this.tooltipVisible = true;
      this.chdRef.detectChanges();
      this.tooltip.show();
      let val: string = e.target.value.toString().slice(0, -1);
      this.formControl.patchValue(Number(val));
    }
    else {
      this.onChange.emit("custom");
      this.tooltipVisible = false;
    }

    if (Number(e.target.value)) {
      this.showSave = true;
    }

    this.onModification.emit(this.getValue())
  }

  /*
  preventMinusStock(apc : AddProductComponent){
    let stck = apc.getProductStock();
    if (stck < 0) {
      this.formControl.patchValue(this.formControl.value - Math.abs(stck));
    }
  }*/

  get formControl() {
    return this.form.get(this.formCName)!
  }

  hourToTime(): string {
    return hourToTime(this.getValue());
  }

  timeToHour(time: string) {
    this.setValue(getQuantityFromTime(new CustomTime(time)));
  }

  onChangeTime(e: any) {
    if (e.target.value) {
      this.timeToHour(e.target.value)
      this.onModification.emit(this.getValue())
    }
  }

  openTimeDialog() {

    if (!this.canModify || !this.product) { return; }

    let ct = new CustomTime(this.hourToTime());
    let currentPriceHour = this.product.price;
    if(this.product instanceof M_CustomProduct){
      this.Customproduct =  this.product;
    }
    this.d.open<AddTimeComponent, IAddTime>(AddTimeComponent, {
      data:
      {
        ct: ct,
        action: this.productLineTable && this.productLineTable.dragGroup ? this.productLineTable.dragGroup.dg.action : undefined,
        showPriceHour: true,
        priceHour: currentPriceHour,
        product: this.Customproduct
      }, autoFocus: false
    }).afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        /** Sync frontent with the new price */
        this.product!.copyCoreAttributes(res);
        this.product!.line_hasChanges = true;
        this.setValue(res.quantity);
      }
    })
  }
  get isComment() {
    return this.product instanceof M_CustomProduct && this.product.isComment;
  }
  openCommentDialog() {

    if (!this.canModify) { return; }
    let currentComment;
    if (this && this.product && this.product instanceof M_CustomProduct) {
      currentComment = this.product?.comment;
    }

    this.d.open(AddCommentComponent, {  width: "500px",data: this.product, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        /** Sync frontent with the new price */
        this.product!.copyCoreAttributes(res);
        this.product!.line_hasChanges = true;
        this.setValue(res.quantity);
      }
    })
  }
  showProgressBar(p: M_BaseProduct | undefined): boolean {
    if (this.productLineTable) {
      if (this.productLineTable.parent instanceof EditOrderComponent || this.productLineTable.parent instanceof StorehouseEntrySectionComponent){
        if (!p) { return false; }
        if (p instanceof M_Product) { return p.pend_quant != 0; }
      }
    }
    return false;
  }

  get progress() {
    if (!this.product || this.product instanceof M_CustomProduct) { return 0 }
    else if (this.product instanceof M_Product && this.product.pend_quant != 0) {
      return (this.product.quantity / this.product.pend_quant) * 100
    }
    return 0;
  }

}
