import { Component } from '@angular/core';

@Component({
    selector: 'app-advanced-main-actions',
    styleUrls: ['./advanced-details.component.css'],
    template: `
  <div class="advanced-details-main-actions"><ng-content></ng-content></div>
`,
    standalone: false
})
export class AdvancedMainActionsComponent {

}
