import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-app-contents',
    template: `
  <app-subscription-days-left #subscriptionBanner></app-subscription-days-left>
  <div class="contents" [ngClass]="subscriptionBanner.subS.showBanner ? 'top-left-no-radius' : ''">
    <ng-content></ng-content>
  </div>
  <app-footer></app-footer>`,
    styleUrls: ['./app-contents.component.css'],
    standalone: false
})

export class AppContentsComponent {
  @HostBinding('class') classes = 'app-contents';
  constructor() { }
}
