/**
 * How to get the array objects on the backend ? 
 * 
 * Array :
 * $data = json_decode($request->fooArray, true);
 * foreach ($data as $dataitem) {
 *  ...
 * }
 */

export function toFormData<T>(formValue: any) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
        let value = (<any>formValue)[key];
        if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
            // value.forEach((item, index) => {
            //     if (typeof item === "object") {
            //         if (item instanceof Date) {
            //             formData.append(`${key}[${index}]`, item.dataBaseFormat());
            //         }
            //         else {
            //             formData.append(`${key}[${index}]`, JSON.stringify(item));
            //         }
            //     } else {
            //         formData.append(`${key}[${index}]`, item);
            //     }
            // });
        } else if (value && typeof value === "object") {
            if (value instanceof Date) {
                formData.append(key, value.dataBaseFormat());
            }
            else {
                formData.append(key, JSON.stringify(value));
            }
        } else if (value != null) {
            formData.append(key, value);
        }
    }
    return formData;
}