import { Component, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { VehicleformComponent } from 'src/app/components/vehicleform/vehicleform.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { Title } from '@angular/platform-browser';
import { M_Model } from 'src/app/models/M_Model';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';

@Component({
    selector: 'app-create-vehicle',
    templateUrl: './create-vehicle.component.html',
    styleUrls: ['./create-vehicle.component.css'],
    standalone: false
})
export class CreateVehicleComponent extends ParameterStateComponent {

  M_CLIENT = MASTER_CLIENT;
  @ViewChild(VehicleformComponent, { static: true }) vehicleForm!: VehicleformComponent;
  @ViewChild(ClassSearcherComponent, { static: true }) searcherForm!: ClassSearcherComponent<M_Vehicle>;

  v = ViewPath;
  c: M_Contact | undefined;
  ve: M_Vehicle | undefined;
  state: "alta_vehiculo" | "create" | "edit" | undefined;
  isVehicle: boolean = false;
  isClient: boolean = false;
  constructor(private fs: FormService, routerS: RouterService, private paramS: ParamsService, private apiS: ApiService,
    route: ActivatedRoute, public params: ParamsService, private snackS: SnackService, public dialog: MatDialog, private titleS: Title,
    @Optional() public dialogRef: MatDialogRef<CreateVehicleComponent>) {
    super(routerS, route, ["client", "vehicle"]);
  }

  override noParams() {
    this.setUpCreation();
  }

  override onParam(k: string, v: any) {
    if (k == "client") {
      this.titleS.setTitle("Dar de alta vehículo")
      this.apiS.getClientById(v).then(res => {
        this.c = res;
        this.state = "alta_vehiculo";
        this.vehicleForm.form.get('battery_1_no')!.disable();
        this.vehicleForm.form.get('battery_2_no')!.disable();
      })
    }
    else if (k == "vehicle") {
      this.titleS.setTitle("Editar vehículo")
      this.apiS.getVehicleById(v).then(res => {
        this.vehicleForm.form.patchValue({
          serie: res?.serie,
          model: res?.model ? res?.model : res?.model_name,
          brand: res?.brand,
          brand_id: res?.brand?.id,
          serie_id: res?.serie?.id
        })
        this.ve = res;
        this.state = "edit";
        this.setupEdit();
      })
    }
    else {
      this.setUpCreation();
    }
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  getTitle() {
    switch (this.state) {
      case "alta_vehiculo":
        return "Dar de alta un vehículo"
      case "create":
        return "Crear un vehículo"
      case "edit":
        return "Editar vehículo"
    }
    return ""
  }

  getButton() {
    switch (this.state) {
      case "alta_vehiculo":
        return "Vincular nuevo vehículo"
      case "create":
        return "Crear vehículo"
      case "edit":
        return "Guardar"
    }
    return ""
  }

  /** Here, the default cration vehicle type */
  setUpCreation() {
    this.vehicleForm.form.get('battery_1_no')!.disable();
    this.vehicleForm.form.get('battery_2_no')!.disable();
    this.state = "create";
  }

  setupEdit() {
    if (this.ve) {
      this.vehicleForm.patchFormWithVehicle(this.ve);
    }
  }

  create() {
    //this.vehicleForm.form.patchValue({ serie: this.vehicleForm.serieToggle.value() })
    let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());

    if (this.state == "create") {
      /** Si se linka el veículo con un cliente ya existente */
      if (this.vehicleForm.ownerSearcher?.selected) {
        if (this.vehicleForm.form.valid) {
          if (this.vehicleForm.brandsModelSerie?.modelSearcherComponent?.selected instanceof M_Model) {
            let m = this.vehicleForm.brandsModelSerie.modelSearcherComponent?.selected
            this.vehicleForm.form.patchValue({ model: m, model_name: m.name, model_id: m.id });
          }
          if (this.vehicleForm.brandsModelSerie?.brandSearcherComponent?.selected instanceof M_Brand) {
            let b = this.vehicleForm.brandsModelSerie.brandSearcherComponent?.selected
            this.vehicleForm.form.patchValue({ brand: b, brand_name: b.name, brand_id: b.id });
          }
          if (this.vehicleForm.brandsModelSerie?.serieSearcherComponent?.selected instanceof M_Serie) {
            let s = this.vehicleForm.brandsModelSerie.serieSearcherComponent?.selected
            this.vehicleForm.form.patchValue({ serie: s, serie_name: s.name, serie_id: s.id });
          }
          this.apiS.vehicle.createVehicleWithClientId(new M_Vehicle(this.vehicleForm.form.getRawValue()), this.vehicleForm.ownerSearcher.selected).then(res => {
            if (!this.isOnDialog) {
              this.params.go(this.v.vehicleDetails, res.id)
              this.snackS.show("¡Vehículo creado y vinculado con éxito!")
            }
            else {
              vehicle.vehicle_id = res.id;
              vehicle.client = this.vehicleForm.ownerSearcher?.selected
              this.dialogRef.close(vehicle);
            }
          });
        }
      }
    }

    /**Editamos solo la moto, sin cliente */
    else if (this.state == "edit") {
      if (this.fs.isOk(this.vehicleForm.form)) {
        if (this.vehicleForm.brandsModelSerie?.modelSearcherComponent?.selected instanceof M_Model) {
          let m = this.vehicleForm.brandsModelSerie.modelSearcherComponent?.selected;
          vehicle.model = m;
          vehicle.model_name = m.name;
          vehicle.model_id = m.id;
        }
        if (this.vehicleForm.brandsModelSerie?.brandSearcherComponent?.selected instanceof M_Brand) {
          let b = this.vehicleForm.brandsModelSerie.brandSearcherComponent.selected;
          vehicle.brand = b;
          vehicle.brand_name = b.name;
          vehicle.brand_id = b.id;
        }
        if (this.vehicleForm.brandsModelSerie?.serieSearcherComponent?.selected instanceof M_Serie) {
          let s = this.vehicleForm.brandsModelSerie.serieSearcherComponent.selected;
          vehicle.serie = s;
          vehicle.serie_name = s.name;
          vehicle.serie_id = s.id;
        }
        this.apiS.vehicle.editVehicle(vehicle).then(_resp => {
          vehicle.vehicle_id = _resp.id;
          this.params.go(this.v.vehicleDetails, vehicle.vehicle_id);
          this.snackS.show("¡Vehículo editado con éxito!")
        })
      }
    }

    /** Dar de alta un vehículo a un cliente especifico */
    else if (this.state == "alta_vehiculo") {
      if (this.fs.isOk(this.vehicleForm.form)) {
        vehicle.client_id = this.c?.client_id;
        this.apiS.vehicle.editVehicle(vehicle).then(_resp => {
          this.params.go(this.v.contactDetails, this.c!.client_id)
          this.snackS.show("¡Vehículo añadido con éxito!")
        })
      }
    }
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.paramS.go(ViewPath.vehicleDetails, this.ve.vehicle_id);
  }

  get buttonDisabled() {
    let isOk = this.vehicleForm.form.valid && this.vehicleForm.ownerSearcher?.selected;
    return !isOk;
  }
}