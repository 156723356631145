import { Component } from '@angular/core';
import { TextWithLink } from '../../custom-classes/TextWithLink';
import { CheckVersionService } from 'src/app/services/check-version.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: false
})
export class FooterComponent {
  privacity = new TextWithLink("Política de privacidad", "https://www.sinigual.com/politica-de-privacidad");
  advice = new TextWithLink("Aviso legal", "https://www.sinigual.com/aviso-legal");
  d = new Date().getFullYear();
  constructor(public chekcVersionS : CheckVersionService) { }
}
