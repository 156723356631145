import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { M_Purchase } from '../../models/M_Purchase';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { ViewPath } from '../../app-routing.module';
import { MatDialog } from '@angular/material/dialog';
import { PreviewService } from '../../services/preview.service';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { PayAllInvoiceDialogComponent } from '../../views/invoices/pay-check-control/pay-all-invoice-dialog.component';
import { TypePaymentService } from '../../services/type-payment-service';
import { M_LastMovements } from '../../models/M_LastMovement';
import { invoice_states, invoice_states_paid, invoice_states_partial, invoice_states_pending } from '../../custom-classes/invoice_states';
import { InvoicePayementFormComponent } from '../invoice-payement-form/invoice-payement-form.component';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { GenericBuyVehicleService } from '../../services/generic-buy-vehicle.service';

@Component({
    selector: 'app-purchase-details',
    templateUrl: './purchase-details.component.html',
    styleUrls: ['./purchase-details.component.css'],
    standalone: false
})

export class PurchaseDetailsComponent extends ParameterStateComponent {

  v = ViewPath;
  loaded = false;

  purchase: M_Purchase | undefined;
  vehicles: M_Vehicle[] = [];

  @ViewChildren(InvoicePayementFormComponent) formChildren?: QueryList<InvoicePayementFormComponent>;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public previewS: PreviewService,
    private chdRef: ChangeDetectorRef, public d: MatDialog, public typePaymentS: TypePaymentService, private confirmDialog: ConfirmDialogService) {
    super(routerS, route, ["purchase"])
  }

  override onParam(_k: string, v: any) {
    this.apiS.getPurchaseById(v).then(res => {
      if (res instanceof M_Purchase) {
        this.purchase = res;
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }

  get isPaid() {
    return this.purchase?.state == invoice_states_paid;
  }

  gotoPay() {
    this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog', data: { invoice: undefined, last_movement: undefined, pending: true, typePayment: this.typePaymentS.typePaymentArray, purchase: this.purchase } }).afterClosed().subscribe(res => {
      if (res instanceof M_LastMovements) {
        this.purchase?.last_movement.push(res);
      }
    });
  }

  deleteLastPayment(last_movement: M_LastMovements) {
    this.confirmDialog.show({
      title: "¿Seguro que quieres eliminar el cobro?",
      body: "Se eliminara el cobro de la factura compra",
      confirmTxt: "Eliminar",
      showCancel: true,
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteLastPayment(last_movement).then(res => {
          this.purchase!.last_movement.removeElement(last_movement);
        })
        if (this.purchase?.last_movement.length == 1) {
          this.pendingChip(invoice_states_pending);
        }
        this.partialChip(invoice_states_partial);
        this.formChildren?.forEach(element => {
          element.resetForm();
        });
      }
    });

  }

  pendingChip(status: invoice_states) {
    if (status == this.purchase!.state) { return; }
    this.apiS.changeStateInvoice(this.purchase!, status).then(res => {
      this.purchase!.state = invoice_states_pending;
      this.purchase!.last_movement = [];
    });
  }

  partialChip(status: invoice_states) {
    if (status == this.purchase!.state) { return; }
    this.apiS.changeStateInvoice(this.purchase!, status).then(res => {
      this.purchase!.state = invoice_states_partial;
    });
  }

  get subTotal() {
    let subtotal = 0;
    if (this.purchase && this.purchase.total && this.purchase.tax) {
      subtotal = this.purchase.total - this.purchase.taxSum;
    }
    return subtotal;
  }

}
