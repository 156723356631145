import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { DemoVideoComponent } from './demo-video/demo-video.component';
import { RouterService } from 'src/app/services/router.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css'],
    standalone: false
})
export class LandingComponent implements OnInit {
  
  v = ViewPath;

  constructor(public routerS : RouterService, private d : MatDialog) { }

  ngOnInit(): void {
  }

  openVideo(){
    this.d.open(DemoVideoComponent, {autoFocus : false});
  }

}
