import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomFile } from '../../../custom-classes/CustomFile';

const PLACEHOLDER = new CustomFile("./assets/img/multiple-image-upload/camera.png", undefined);

@Component({
    selector: 'app-single-img',
    templateUrl: './single-img.component.html',
    styleUrls: ['./single-img.component.css'],
    standalone: false
})
export class SingleImgComponent {
  source: CustomFile = PLACEHOLDER;
  @Input() selected: boolean = false;
  @Output() onDeleteImg: EventEmitter<CustomFile> = new EventEmitter();

  @ViewChild("singleImage") imageElement?: ElementRef<HTMLImageElement>;

  ngOnChanges(changes: SimpleChanges) {
    if (this.selected) {
      this.imageElement?.nativeElement.scrollIntoView();
    }
  }

  /** This don't delete the previous image */
  initializeImage(img: CustomFile) {
    this.source = img;
  }

  setImage(img: CustomFile) {    
    /** If the previous source has id... */
    if (this.source.id != undefined) {
      this.onDeleteImg.emit(this.source);
    }
    /** Set the new image */
    this.source = img;
  }

  get hasImage() {
    return this.source.src != PLACEHOLDER.src;
  }

  get canRemove() {
    return this.hasImage && this.selected;
  }

  removeImage() {
    this.onDeleteImg.emit(this.source);
    this.source = PLACEHOLDER;
  }
}
