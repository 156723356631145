<div class="df aic gap5 card-wrapper-fit" [ngClass]="clickable ? 'rectangle-clickable' : 'rectangle-no-clickable'"
    [matTooltip]="tooltip" [matTooltipPosition]="'above'">

    <mat-icon class="temporatlOwner" *ngIf="temporalOwner" [matTooltip]="'Poseedor temporal'">
        passkey
    </mat-icon>

    <div [go]="!clickable ? undefined : v.contactDetails" [param]="client.client_id" [newTab]="true" mat-ripple
        [matRippleDisabled]="!clickable" class="df aic" style="height: 40px;">

        <mat-icon class="vam">
            {{client.client_id ? client.icon : 'no_accounts'}}
        </mat-icon>


        <div class="user-name" *ngIf="!onlyIconOn || (onlyIconOn && onlyIconOn < responsiveS.w)">
            {{client.getName()}}
        </div>
    </div>

    <div class="icon-buttons rectange" *ngIf="!onlyIconOn || (onlyIconOn && onlyIconOn < responsiveS.w)">
        <app-contact-buttons>
            <app-whatsapp [phone]="client.phone"></app-whatsapp>
            <app-email-button [email]="client.email"></app-email-button>
            <app-phone-button [phone]="client.phone"></app-phone-button>
        </app-contact-buttons>
    </div>

</div>