import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Filter } from '../../custom-classes/Filter';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';

/**
 * Component that have multiple childs 'FilterComponents'
 * Necessaty to provide a funcion for a filtering of type (b: any, filter: string) => boolean
 */

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.css'],
    standalone: false
})
export class FiltersComponent implements OnInit {

  @Input() filters: Filter[] = [];

  /** Page structure of weberp */
  @Input() ps: any;

  @Output() onfiltersDone: EventEmitter<Filter[]> = new EventEmitter();


  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void { }


  get activeFilters() {
    return this.filters.filter(f=> f.activated == true)
  }

  openFilters() {
    this._bottomSheet.open(FilterDialogComponent, { data: this.filters, disableClose:true}).afterDismissed().subscribe(res => {
      this.filters = res;
      this.applyFilters();
    })
  }

  applyFilters(){
    if (this.ps) {
      if (this.filters.length == 0) {
        this.ps.applyScreenFilter()
      }
      else {
        this.ps.applyScreenFilter(...this.filters);
      }
      this.onfiltersDone.emit(this.filters);
    }
    else{
      this.onfiltersDone.emit(this.filters);
    }
  }

}
