
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { templateTypedoc } from '../models/M_TemplateField';
import { PDF_DATA } from '../components/pdf/pdf.component';
import { TEMPLATE_DIALOG_DATA, TemplateFieldsDialog } from '../components/template-fields-dialog/template-fields-dialog.component';
import { TemplateSteps } from '../enums/TemplateSteps';
import { VehicleType } from '../enums/VehicleType';
import { M_ActiveReception } from '../models/M_ActiveReception';

export interface BUY_PDF_PARAMS {
  client_id: number | undefined,
  vehicle_id: number | undefined,
  vehicle_mov_id : number | undefined;
  docum_prov_num: string | undefined;
  mode: string | undefined;
  token: string | undefined;
  type_new: number | undefined;
}

/**
 * Service for showing the template fields of a document
 */
@Injectable({
  providedIn: 'root'
})

export class TemplateFieldsService {

  constructor(private d: MatDialog) { }

  /** Show the steps and template fields before closing a invoice, or, abono...
   * @param preview_type "B" | "RA" | "OR" | "I" | "A" "CV"
   * @param token Object token.
  */
  showTemplateSteps(preview_type: templateTypedoc, id?: number, token?: string, vehicle?: VehicleType, ra?: M_ActiveReception, buyPdfParams?: BUY_PDF_PARAMS) {
    console.log("Preview type: ", preview_type)
    console.log("Token of object:", token)
    console.log("ID:", id)
    console.log("params:", buyPdfParams)

    if (buyPdfParams != undefined) {
      let dialogRef = this.d.open<TemplateFieldsDialog, { pdf: PDF_DATA, dialog: TEMPLATE_DIALOG_DATA, params?: BUY_PDF_PARAMS }>(TemplateFieldsDialog, {
        maxWidth: '800px',
        maxHeight: '90vh',
        height: '90%',
        width: '90%',
        data: {
          pdf: {
            preview_type: preview_type,
            id: id,
            token: token,
            noIdObject: ra
          },
          dialog: {
            steps: this.getStepsByDocument(preview_type),
            vehicleType: vehicle
          },
          params: {
            vehicle_id: buyPdfParams.vehicle_id,
            client_id: buyPdfParams.client_id,
            docum_prov_num: buyPdfParams.docum_prov_num,
            mode: buyPdfParams.mode,
            vehicle_mov_id: buyPdfParams.vehicle_mov_id,
            token: buyPdfParams.token,
            type_new: buyPdfParams.type_new
          }
        },
        autoFocus: false,
        disableClose: true,
      });
      return dialogRef;
    }
    else {

      let dialogRef = this.d.open<TemplateFieldsDialog, { pdf: PDF_DATA, dialog: TEMPLATE_DIALOG_DATA, params?: BUY_PDF_PARAMS }>(TemplateFieldsDialog, {
        maxWidth: '800px',
        maxHeight: '90vh',
        height: '90%',
        width: '90%',
        data: {
          pdf: {
            preview_type: preview_type,
            id: id,
            token: token,
            noIdObject: ra
          },
          dialog: {
            steps: this.getStepsByDocument(preview_type),
            vehicleType: vehicle
          }
        },
        autoFocus: false,
        disableClose: true,
      });
      return dialogRef;
    }



  }

  getStepsByDocument(preview_type: templateTypedoc): TemplateSteps[] {
    let templateSteps: TemplateSteps[] = [];
    switch (preview_type) {
      case 'B':
        templateSteps = [TemplateSteps.FIELDSWORKSHOP, TemplateSteps.PREVIEW, TemplateSteps.FIELDSCLIENT]
        break;
      case 'RA':
        templateSteps = [TemplateSteps.FIELDSWORKSHOP, TemplateSteps.FIELDSCLIENT]
        break;
      case 'CV':
        templateSteps = [TemplateSteps.PREVIEW, TemplateSteps.FIELDSCLIENT]
        break;
      case 'OR':
        break;
      case 'I':
        break;
      case 'A':
    }
    return templateSteps;
  }
}