import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { templateTypedoc } from '../models/M_TemplateField';
import { PREVIEW_DATA, PreviewDialog, company_data } from '../components/preview-dialog/preview-dialog-component';
import { IPurchaseData, PDF_DATA } from '../components/pdf/pdf.component';
import { M_Invoice } from '../models/M_Invoice';
import { ConfirmDialogService } from './confirm-dialog.service';

/**
 * Service for showing a previsualization of invoice, ra, or....
 */
@Injectable({
  providedIn: 'root'
})

export class PreviewService {
  constructor(private d: MatDialog, private confirmD: ConfirmDialogService) { }

  /** Show a preview of any pdf of the web
   * @param preview_type "B" | "RA" | "OR" | "I" | "A" | "AN" | "AOR" | "AC" | "FL" "CV"
   * @param token If passed, it's assumed that is a real pdf.
   * @param company_data  If passed, it's assumed that is a preview of company configuration tab.´
  */
  showPreview(preview_type: templateTypedoc, token?: string, company_data?: company_data, id?: any, canClose?: boolean, minifyDownload?: boolean, invoice_bill?: M_Invoice, showOrCharge?: boolean, purchaseData?: IPurchaseData) {
    console.log("🔍 Preview type: ", preview_type)
    console.log("🔍 Token of object:", token)
    console.log("🔍 Company data:", company_data)
    console.log("🔍 ID:", id)
    console.log("🔍 PARAMS:", purchaseData);

    if (preview_type == undefined || (token == undefined && company_data == undefined)) {
      this.confirmD.showError("Algo salió mal", "No hay suficientes datos para mostrar la previsualización")
    }

    else {
      if (purchaseData != undefined) {
        if (token == 'FC') {
          purchaseData.token = 'FC';
        }
        this.d.open<PreviewDialog, { pdf: PDF_DATA, preview: PREVIEW_DATA, invoice: M_Invoice | undefined, params?: IPurchaseData }>(PreviewDialog, {
          maxWidth: '800px',
          maxHeight: '90vh',
          height: '90%',
          width: '90%',
          panelClass: 'preview-dialog',
          data: {
            pdf: {
              preview_type: preview_type,
              token: token ? token : undefined,
              company_data: company_data ? company_data : undefined,
              id: id ? id : undefined,
            },
            preview: {
              canClose: canClose,
              minifyDownload: minifyDownload,
              showORcharge: showOrCharge
            },
            invoice: undefined,
            params: {
              vehicle_id: purchaseData.vehicle_id,
              client_id: purchaseData.client_id,
              vehicle_mov_id : purchaseData.vehicle_mov_id,
              price: purchaseData.price,
              tax: purchaseData.tax,
              buy_transac_id : purchaseData.buy_transac_id,
              comment: purchaseData.comment,
              num_purchase: purchaseData.num_purchase,
              type: purchaseData.type,
              docum_prov_num: purchaseData.docum_prov_num,
              mode: purchaseData.mode,
              token: purchaseData.token,
            }
          },
          autoFocus: false,
          disableClose: purchaseData.mode == "view"
        });
      } else {
        this.d.open<PreviewDialog, { pdf: PDF_DATA, preview: PREVIEW_DATA, invoice: M_Invoice | undefined }>(PreviewDialog, {
          maxWidth: '800px',
          maxHeight: '90vh',
          height: '90%',
          width: '90%',
          panelClass: 'preview-dialog',
          data: {
            pdf: {
              preview_type: preview_type,
              token: token ? token : undefined,
              company_data: company_data ? company_data : undefined,
              id: id ? id : undefined,
            },
            preview: {
              canClose: canClose,
              minifyDownload: minifyDownload,
              showORcharge: showOrCharge
            },
            invoice: invoice_bill
          },
          autoFocus: false,
          disableClose: preview_type == "RA"
        });
      }

    }
  }
}