import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { downloadBlob } from 'src/app/utils/FunctionUtils';

@Component({
    selector: 'app-pdf-simple-preview',
    templateUrl: './pdf-simple-preview.component.html',
    styleUrls: [
        './pdf-simple-preview.component.css',
        '../../../../app/components/preview-dialog/preview-dialog-component.css',
        '../../../../app/components/pdf/pdf.component.css'
    ],
    standalone: false
})
export class PdfSimplePreviewComponent {

  f?: Uint8Array;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomFile, public dRef: MatDialogRef<PdfSimplePreviewComponent>) {
    this.fileToUint8Array(this.data.file).then(res => {
      this.f = res;
    })
  }

  fileToUint8Array(file?: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {

      if (!file) { reject(undefined) }

      const reader = new FileReader();

      // Read file as an ArrayBuffer
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(new Uint8Array(reader.result));
        } else {
          reject(new Error("Failed to read file as ArrayBuffer."));
        }
      };

      reader.onerror = () => {
        reject(new Error(`Error reading file: ${reader.error}`));
      };

      if (file) {
        reader.readAsArrayBuffer(file);
      }

    });
  }

  download() {
    downloadBlob(this.data.file!, this.data.file!.name);
  }
}
