import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_GroupedVehicle } from 'src/app/models/Vehicles/M_GroupedVehicle';

@Component({
    selector: 'app-grouped-vehicle-dialog',
    templateUrl: './grouped-vehicle-dialog.component.html',
    styleUrls: ['./grouped-vehicle-dialog.component.css'],
    standalone: false
})
export class GroupedVehicleDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: M_GroupedVehicle, public dRef: MatDialogRef<GroupedVehicleDialogComponent>) { }
}
