import { Calls } from "../enums/Calls";
import { Endpoint } from "../custom-classes/Endpoint";

export const endpoints = {

    globalSearch:                       new Endpoint(Calls.globalSearch, { showLoading: false }),

    login:                              new Endpoint(Calls.login, { appendHeader: false }),
    sharedAccesLogin:                   new Endpoint(Calls.sharedAccesLogin, { appendHeader: false }),
    logout:                             new Endpoint(Calls.logout),
    checkVersion:                       new Endpoint(Calls.checkVersion, { appendHeader: false }),

    validateEmail:                      new Endpoint(Calls.validateEmail, { appendHeader: false }),
    updatePassword:                     new Endpoint(Calls.updatePassword, { appendHeader: false }),
    getInvoice:                         new Endpoint(Calls.getInvoice, { showLoading: false }),
    getUsersById:                       new Endpoint(Calls.getUsersById, { showLoading: false }),

    dashboard:                          new Endpoint(Calls.dashboard, { showLoading: false }),

    uploadImage:                        new Endpoint(Calls.uploadImage, { showLoading: false }),
    deleteImage:                        new Endpoint(Calls.deleteImage, { showLoading: false }),
    deleteById:                         new Endpoint(Calls.deleteId, { showLoading: false }),

    profile:                            new Endpoint(Calls.profile, { showLoading: false }),
    forgotPassword:                     new Endpoint(Calls.forgotPassword),

    updatePass:                         new Endpoint(Calls.updatePass),
    resendConfirmation:                 new Endpoint(Calls.resendConfirmation),
    pickUpMail:                         new Endpoint(Calls.pickUpMail),
    requestPDF:                         new Endpoint(Calls.requestPDF),
    getAppointmentId:                   new Endpoint(Calls.getAppointmentId, { showLoading: false }),
    appointment:                        new Endpoint(Calls.appointment, { showLoading: false }),
    assignUserAppointment :             new Endpoint(Calls.assignUserAppointment, {showLoading : false}),
    showFirstDay:                       new Endpoint(Calls.showFirstDay, { showLoading: false }),
    getBookedHours:                     new Endpoint(Calls.getBookedHours, { showLoading: false }),
    getBookedHoursByCompanyId:          new Endpoint(Calls.getBookedHoursByCompanyId, { appendHeader: false, showLoading: false }),
    getHolidays:                        new Endpoint(Calls.getHolidays, { showLoading: false }),
    getHolidaysRider:                   new Endpoint(Calls.getHolidaysRider, { appendHeader: false, showLoading: false }),
    appointemtsRange:                   new Endpoint(Calls.appointemtsRange, { showLoading: false }),
    getCitaByDay:                       new Endpoint(Calls.getCitaByDay, { showLoading: false }),
    setHolidays:                        new Endpoint(Calls.setHolidays, { showLoading: false }),
    setHolidaysCompanies:               new Endpoint(Calls.setHolidaysCompanies, { showLoading: false }),
    getTaskAppointment:                 new Endpoint(Calls.getTaskAppointment, { showLoading: false }),
    createAppointmentClient:            new Endpoint(Calls.createAppointmentClient, { showLoading: true }),
    createAppointmentClientSide:        new Endpoint(Calls.createAppointmentClientSide, { appendHeader: false, showLoading: true }),
    changeAppointmentDate:              new Endpoint(Calls.changeAppointmentDate),
    editAppointmentClient:              new Endpoint(Calls.editAppointmentClient),
    updateAppointmentClient:            new Endpoint(Calls.updateAppointmentClient),
    checkHolidays:                      new Endpoint(Calls.checkHolidays, { showLoading: false, customError: true }),
    configCompanyAppointment:           new Endpoint(Calls.configCompanyAppointment, { showLoading: false }),
    updateConfigAppointment:            new Endpoint(Calls.updateConfigAppointment),
    getCompanyInfoClientSide:           new Endpoint(Calls.getCompanyInfoClientSide, { appendHeader: false, showLoading: false }),
    getAppointmentsSide:                new Endpoint(Calls.getAppointmentsSide, { appendHeader: false, showLoading: false }),
    clients:                            new Endpoint(Calls.clients, { showLoading: false }),
    c_clients:                          new Endpoint(Calls.c_clients, { showLoading: false }),
    providers:                          new Endpoint(Calls.providers, { showLoading: false }),
    clientAdmincenters:                 new Endpoint(Calls.clientAdmincenters, { showLoading: false }),
    clientsminify:                      new Endpoint(Calls.clientsminify, { showLoading: false }),
    createUpdateClient:                 new Endpoint(Calls.createUpdateClient),
    getClientById:                      new Endpoint(Calls.getClientById, { showLoading: false }),
    addExtraAddress:                    new Endpoint(Calls.addExtraAddress),


    checkExist:                         new Endpoint(Calls.checkPlateDni, { showLoading: false }),

    
    vehicles:                           new Endpoint(Calls.vehicles, { showLoading: false }),
    vehiclesminify:                     new Endpoint(Calls.allVehiclesMinify, { showLoading: false }),

    clientsAdvances:                    new Endpoint(Calls.clientsAdvances, { showLoading: false }),
    createUpdateVehicle:                new Endpoint(Calls.createUpdateVehicle),
    searchClinets:                      new Endpoint(Calls.clients),
    getVehicleById:                     new Endpoint(Calls.getVehicletById),
    linkVehicle:                        new Endpoint(Calls.linkVehicle),
    sellVehicleClient:                  new Endpoint(Calls.buyVehicleClient),
    clientsForVehicles:                 new Endpoint(Calls.clientsForVehicles, { showLoading: false }),

    productsTable:                      new Endpoint(Calls.productsTable, { showLoading: false }),
    products:                           new Endpoint(Calls.products, { showLoading: false }),
    productsOnlyProviders:              new Endpoint(Calls.productsOnlyProviders, { showLoading: false }),
    storehouses:                        new Endpoint(Calls.storehouses, { showLoading: false }),
    checkDuplicatedStoreHouseDocu:      new Endpoint(Calls.checkDuplicatedStoreHouseDocu, { showLoading: false }),
    storehouseCUD:                      new Endpoint(Calls.storehouseCUD, { showLoading: false }),
    deleteStorehouse:                   new Endpoint(Calls.deleteStorehouse, { showLoading: false }),
    deleteLocation:                     new Endpoint(Calls.deleteLocation, { showLoading: false }),
    createProduct:                      new Endpoint(Calls.createProduct),
    editProductProviderDiscount:        new Endpoint(Calls.editProductProviderDiscount),

    getProductById:                     new Endpoint(Calls.getProductById, { showLoading: false }),
    editStock:                          new Endpoint(Calls.editStock),
    productProvider:                    new Endpoint(Calls.productProvider),
    alternativeProducts:                new Endpoint(Calls.alternativeProducts),
    faults:                             new Endpoint(Calls.faults, { showLoading: false }),
    manualFault:                        new Endpoint(Calls.manualFault, { showLoading: false }),
    deleteFault:                        new Endpoint(Calls.deleteFault, { showLoading: false }),
    stockMove:                          new Endpoint(Calls.stockMove, { showLoading: false }),
    getProductItemQuants:               new Endpoint(Calls.getProductItemQuants, { showLoading: false }),
    getDiscountGroupById:               new Endpoint(Calls.getDiscountGroupById, { showLoading: false }),
    createEditDiscountGroup:            new Endpoint(Calls.createEditDiscountGroup),
    deleteDiscountGroup:                new Endpoint(Calls.deleteDiscountGroup),
    discountGroups:                     new Endpoint(Calls.discountGroups),
    ratesArticlesRecalc:                new Endpoint(Calls.ratesArticlesRecalc),


    company:                            new Endpoint(Calls.company),
    createCompany:                      new Endpoint(Calls.createCompany, { appendHeader: false, showLoading: true }),
    updateCompany:                      new Endpoint(Calls.updateCompany),
    deleteCompany:                      new Endpoint(Calls.deleteCompany),
    welcomeCompany:                     new Endpoint(Calls.welcomeCompany),
    emailPromo:                         new Endpoint(Calls.emailPromo),
    saveColors:                         new Endpoint(Calls.saveColors),
    saveFooters:                        new Endpoint(Calls.saveFooters),
    saveCompanyFiles:                   new Endpoint(Calls.saveCompanyFiles),
    smtp:                               new Endpoint(Calls.smtp),
    testEmail:                          new Endpoint(Calls.testEmail),
    presentationMail:                   new Endpoint(Calls.presentationMail),
    payedInvoice:                       new Endpoint(Calls.payedInvoice),
    pdfExample:                         new Endpoint(Calls.pdfExample, { showLoading: false }),
    getLocationByZip:                   new Endpoint(Calls.getLocationByZip, { showLoading: false, customError: true }),
    getFieldsByCCAA:                    new Endpoint(Calls.getFieldsByCCAA, { showLoading: false, customError: true }),
    users:                              new Endpoint(Calls.users, { showLoading: false }),
    usersCenters:                       new Endpoint(Calls.usersCenters, { showLoading: false }),
    createUser:                         new Endpoint(Calls.createUser),
    updateUser:                         new Endpoint(Calls.updateUser),
    updateUserName:                     new Endpoint(Calls.updateUserName),

    /** Centers */
    getUserCenter:                      new Endpoint(Calls.getUserCenter),
    saveCenter:                         new Endpoint(Calls.saveCenter),

    bills:                              new Endpoint(Calls.bills, { showLoading: false }),
    bill:                               new Endpoint(Calls.bill),
    createBill:                         new Endpoint(Calls.createBill),
    saveInvoice:                        new Endpoint(Calls.saveInvoice),
    delProdInvoice:                     new Endpoint(Calls.delProdInvoice),
    payedBill:                          new Endpoint(Calls.payedBill),
    confirmInvoice:                     new Endpoint(Calls.confirmInvoice),
    discardInvoice:                     new Endpoint(Calls.discardInvoice),
    downloadInvoices:                   new Endpoint(Calls.downloadInvoices),
    templatePreview:                    new Endpoint(Calls.templatePreview),
    hasDraft:                           new Endpoint(Calls.hasDraft, { showLoading: false }),
    lastInvoiceDate:                    new Endpoint(Calls.lastInvoiceDate, { showLoading: false }),

    actionTypes:                        new Endpoint(Calls.actionTypes),
    actions:                            new Endpoint(Calls.actions, { showLoading: false }),
    createOR:                           new Endpoint(Calls.createOR),
    addClientVehicleAction:             new Endpoint(Calls.addClientVehicleAction),
    getAction:                          new Endpoint(Calls.getAction, { showLoading: false }),
    addEditGroup:                       new Endpoint(Calls.addEditGroup),
    addTask:                            new Endpoint(Calls.addTask),
    assignAction:                       new Endpoint(Calls.assignAction),
    assignClient:                       new Endpoint(Calls.assignClient),
    editDelivery:                       new Endpoint(Calls.editDelivery),
    updateSchedule:                     new Endpoint(Calls.updateSchedule),
    addEditTasks:                       new Endpoint(Calls.addEditTasks),
    schedules:                          new Endpoint(Calls.schedules),
    rmGroup:                            new Endpoint(Calls.rmGroup),
    rmTask:                             new Endpoint(Calls.rmTask),
    rmAction:                           new Endpoint(Calls.rmAction),
    invoiceOR:                          new Endpoint(Calls.invoiceOR),
    sortTask:                           new Endpoint(Calls.sortTask),
    changeTask:                         new Endpoint(Calls.changeTask),
    editKm:                             new Endpoint(Calls.editKm),
    editNotes:                          new Endpoint(Calls.editNotes),
    editTitle:                          new Endpoint(Calls.editTitle),
    closeGroup:                         new Endpoint(Calls.closeGroup),
    openGroup:                          new Endpoint(Calls.openGroup),
    reorderActions:                     new Endpoint(Calls.reorderActions),
    editAction:                         new Endpoint(Calls.editAction),
    invoiceState:                       new Endpoint(Calls.stateInvoice),
    showTypePayment:                    new Endpoint(Calls.showTypePayment),
    CreateLastPayment:                  new Endpoint(Calls.CreateLastPayment),
    CreateAdvancesClient:               new Endpoint(Calls.CreateAdvancesClient),
    DeleteAdvancesClient:               new Endpoint(Calls.DeleteAdvancesClient),
    EditAdvancesClient:                 new Endpoint(Calls.EditAdvancesClient),
    DeleteMovementInvoice:              new Endpoint(Calls.DeleteMovementInvoice),
    DeleteTypePayment:                  new Endpoint(Calls.DeleteTypePayment),
    createBudget:                       new Endpoint(Calls.createBudget),
    closeBudget:                        new Endpoint(Calls.closeBudget),
    copyAction:                         new Endpoint(Calls.copyAction),

    /** Recambios budget */
    createRecambiosBudget:              new Endpoint(Calls.createRecambiosBudget),

    importStorage:                      new Endpoint(Calls.importStorage),
    importClients:                      new Endpoint(Calls.importClients),
    importTarifas:                      new Endpoint(Calls.importTarifas),

    /**ORDERS */
    getOrder:                           new Endpoint(Calls.getOrder, { showLoading: false }),
    getOrders:                          new Endpoint(Calls.getOrders, { showLoading: false }),
    createOrder:                        new Endpoint(Calls.createOrder),
    editOrder:                          new Endpoint(Calls.editOrder),
    saveGroupDetails:                   new Endpoint(Calls.saveGroupDetails),
    deleteOrderProduct:                 new Endpoint(Calls.deleteOrderProduct),
    changeSended:                       new Endpoint(Calls.changeSended),

    /** Reservations */
    manualReservation:                  new Endpoint(Calls.manualReservation),
    getReservation:                     new Endpoint(Calls.getReservation),


    /** Notifications */
    getNotifications:                   new Endpoint(Calls.getNotifications, { showLoading: false }),
    updateNotifications:                new Endpoint(Calls.updateNotifications),
    readAllNotifications:               new Endpoint(Calls.readAllNotifications),

    /** Abono */
    abono:                              new Endpoint(Calls.abono),

    /** Albaranes */
    albaranes:                          new Endpoint(Calls.albaranes),
    albaranById:                        new Endpoint(Calls.albaranById),
    createAlbaran:                      new Endpoint(Calls.createAlbaran),
    saveAlbaranInfo:                    new Endpoint(Calls.saveAlbaranInfo),
    saveAlbaranProducts:                new Endpoint(Calls.saveAlbaranProducts),
    removeAlbaranProduct:               new Endpoint(Calls.removeAlbaranProduct),
    changeAlbaranStatus:                new Endpoint(Calls.changeAlbaranStatus),
    changeAlbaranType:                  new Endpoint(Calls.changeAlbaranType),
    deleteAlbaran:                      new Endpoint(Calls.deleteAlbaran),
    invoiceAlbaran:                     new Endpoint(Calls.invoiceAlbaran),

    /** Report erorr */
    toJira:                             new Endpoint(Calls.toJira, { appendHeader: true, showLoading: false }),
    reportError:                        new Endpoint(Calls.reportError, { appendHeader: false, showLoading: false }),


    /** Templates url */
    invoicePDF:                         new Endpoint(Calls.invoice, { showLoading: false }),
    eInvoicePDF:                        new Endpoint(Calls.eInvoice, { showLoading: false }),
    budgetPDF:                          new Endpoint(Calls.budgetPDF, { showLoading: false }),
    raPDF:                              new Endpoint(Calls.ra, { showLoading: false }),
    createAdvancePdf:                   new Endpoint(Calls.createAdvancePdf, { showLoading: false }),
    ORalbaranPDF:                       new Endpoint(Calls.ORalbaranPDF, { showLoading: false }),
    albaranPDF:                         new Endpoint(Calls.albaranPDF, { showLoading: false }),
    storehousePDF:                      new Endpoint(Calls.storehousePDF, { showLoading: false }),
    comercialBudgetPDF:                 new Endpoint(Calls.comercialBudgetPDF, { showLoading: false }),

    /** No id Objects URL */
    previewRA:                          new Endpoint(Calls.previewRA, { showLoading: false }),
    previewCV:                          new Endpoint(Calls.previewCV, { showLoading: false }),
    previewFC:                          new Endpoint(Calls.previewFC, { showLoading: false }),


    /** Brand and models */
    brands:                             new Endpoint(Calls.brand, { showLoading: false }),
    models:                             new Endpoint(Calls.models, { showLoading: false }),
    brandModels:                        new Endpoint(Calls.brand_models, { showLoading: false }),
    brandSeries:                        new Endpoint(Calls.brand_series, { showLoading: false }),

    /** Paycomet */
    payComet:                           new Endpoint(Calls.payComet, { showLoading: false, customError: true }),
    payCometInvoices:                   new Endpoint(Calls.payCometInvoices, { showLoading: false, customError: true }),
    checkSubscription:                  new Endpoint(Calls.checkSubscription, { showLoading: false, customError: true }),
    paymentByCompany:                   new Endpoint(Calls.paymentByCompany, { showLoading: false }),

    /** Templates */
    getFieldsFromDoc:                   new Endpoint(Calls.getFieldsFromDoc, { showLoading: false }),
    saveTemplateFields:                 new Endpoint(Calls.saveTemplateFields),
    saveTemplateImage:                  new Endpoint(Calls.saveTemplateImage),

    createReserve:                      new Endpoint(Calls.createReserve),

    /** Massive invoice */
    getForMassiveInvoiceOR:             new Endpoint(Calls.getForMassiveInvoiceOR),
    getForMassiveInvoiceDNote:          new Endpoint(Calls.getForMassiveInvoiceDNote),
    invoiceMassiveOR:                   new Endpoint(Calls.invoiceMassiveOR),
    invoiceMassiveDNote:                new Endpoint(Calls.invoiceMassiveDNote),


    /** Fichage */
    currentlyWorking:                   new Endpoint(Calls.currentlyWorking),
    startTime:                          new Endpoint(Calls.startTime),
    endTime:                            new Endpoint(Calls.endTime),
    addManualTime:                      new Endpoint(Calls.addManualTime),
    deleteTimeHistory:                  new Endpoint(Calls.deleteTimeHistory),

    /** Sotehouse entry */
    getEntryIntoStore:                  new Endpoint(Calls.getEntryIntoStore),
    entryIntoStore:                     new Endpoint(Calls.entryIntoStore),


    /** VN and VO */
    vehiclesC:                          new Endpoint(Calls.vehiclesC, {showLoading : false}),
    vehicleC:                           new Endpoint(Calls.vehicleC),
    sellVehicle:                        new Endpoint(Calls.sellVehicle),
    storeBuyTransac:                    new Endpoint(Calls.storeBuyTransac, { showLoading: true }),
    addStock:                           new Endpoint(Calls.addStock),
    getAgents:                          new Endpoint(Calls.getAgents),
    updateModel:                        new Endpoint(Calls.updateModel),
    getModel:                           new Endpoint(Calls.getModel),
    createUpdateOption:                 new Endpoint(Calls.createUpdateOption),
    getOptionsByModel:                  new Endpoint(Calls.getOptionsByModel),
    createUpdateConcept:                new Endpoint(Calls.createUpdateConcept),
    getConcepts:                        new Endpoint(Calls.getConcepts, { showLoading: false }),
    getConceptById:                     new Endpoint(Calls.getConceptById, { showLoading: false }),
    createComercialBudget:              new Endpoint(Calls.createComercialBudget),
    getComercialBudget:                 new Endpoint(Calls.getComercialBudget),
    getComercialBudgets:                new Endpoint(Calls.getComercialBudgets),
    closeComercialBudget:               new Endpoint(Calls.closeComercialBudget),
    deleteConmercialBudget:             new Endpoint(Calls.deleteConmercialBudget),
    getPurchases:                       new Endpoint(Calls.getPurchases),
    getBalance:                         new Endpoint(Calls.getBalance),
    getPurchaseById:                    new Endpoint(Calls.getPurchaseById, { showLoading: false }),
    getStockBrandAndModel:              new Endpoint(Calls.getStockBrandAndModel, { showLoading: false }),
    getStockforBrand:                   new Endpoint(Calls.getStockforBrand, { showLoading: false }),
    getColors:                          new Endpoint(Calls.getColors, { showLoading: false }),
    getSeries:                          new Endpoint(Calls.getSeries, { showLoading: false }),
    getBrands:                          new Endpoint(Calls.getBrands, { showLoading: false }),
    getSerieById:                       new Endpoint(Calls.getSerieById, { showLoading: false }),
    getBrandById:                       new Endpoint(Calls.getBrandById, { showLoading: false }),
    getColorById:                       new Endpoint(Calls.getColorById, { showLoading: false }),
    getModelById:                       new Endpoint(Calls.getModelById, { showLoading: false }),
    createUpdateSerie:                  new Endpoint(Calls.createUpdateSerie, { showLoading: false }),
    createUpdateColor:                  new Endpoint(Calls.createUpdateColor, { showLoading: false }),
    createUpdateModel:                  new Endpoint(Calls.createUpdateModel, { showLoading: false }),
    deleteSerie:                        new Endpoint(Calls.DeleteSerie, { showLoading: false }),
    deleteColor:                        new Endpoint(Calls.DeleteColor, { showLoading: false }),
    getAccounting:                      new Endpoint(Calls.getAccounting, { showLoading: false }),
    pendBuyProdvider:                   new Endpoint(Calls.pendBuyProdvider, { showLoading: false }),

    /** Accounting groups */
    getAccountingGroups:                new Endpoint(Calls.getAccountingGroups, { showLoading: false }),
    getAccountingGroup:                 new Endpoint(Calls.getAccountingGroup, { showLoading: false }),
    saveAccountingGroup:                new Endpoint(Calls.saveAccountingGroup, { showLoading: false }),
    deleteAccountingGroup:              new Endpoint(Calls.deleteAccountingGroup, { showLoading: false }),
    createAccountingGroup:              new Endpoint(Calls.createAccountingGroup, { showLoading: false }),
    exportToMTR :                       new Endpoint(Calls.exportToMTR, { showLoading: false }),

    /** Exports */
    exportInvoices:                     new Endpoint(Calls.exportInvoices, { showLoading: false }),
    exports:                            new Endpoint(Calls.exports, { showLoading: false }),

    /** Reports */
    icis :                              new Endpoint(Calls.icis, { showLoading: false }),
    companiesCentersUsers :             new Endpoint(Calls.companiesCentersUsers, {showLoading : true}),
    /** Schedule and Singning */
    getUserSchedule:                    new Endpoint(Calls.getScheduleUser, { showLoading: false }),
    updateUserSchedule:                 new Endpoint(Calls.updateUserSchedule, { showLoading: false }),
    myPresence:                         new Endpoint(Calls.myPresence, { showLoading: false }),
    startStopSigning:                   new Endpoint(Calls.startStopSigning, { showLoading: false, customError : true }),
    pauseResume:                        new Endpoint(Calls.pauseResume, { showLoading: false, customError : true }),
    createEditMyPresence:               new Endpoint(Calls.createEditMyPresence, { showLoading: false, customError : true }),

    /** Eina data  */
    einaData:                           new Endpoint(Calls.einaData),

    /** Modules */
    modules:                            new Endpoint(Calls.modules),
    moduleById:                         new Endpoint(Calls.moduleById),
    recanviosClient:                    new Endpoint(Calls.recanviosClient),
    toggleModule:                       new Endpoint(Calls.toggleModule),
    saveMTR:                            new Endpoint(Calls.saveMTR),
    createUpdateWorkers:                new Endpoint(Calls.createUpdateWorkers),
    exportPasosTaller:                  new Endpoint(Calls.exportPasosTaller),

    /** Dev test */
    changeRoleTest:                     new Endpoint(Calls.changeRoleTest),

}
