import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Register } from 'src/app/models/M_Register';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    standalone: false
})
export class RegisterComponent implements OnInit {
  public form: UntypedFormGroup;
  v = ViewPath;
  registerDone = false;
  @ViewChild(MatCheckbox) checkBoxTerms!: MatCheckbox;
  constructor(private routerS: RouterService, private formBuilder: UntypedFormBuilder, private formService: FormService, private apiS: ApiService, public d: MatDialog, private confirmS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      // password: ['', [Validators.required]],
      // password_confirmation: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.form.patchValue({ password_confirmation: '' })
  }

  ngAfterContentInit(): void {
    this.form.patchValue({ password_confirmation: '' })
  }

  createCompany() {
    if (this.formService.isOk(this.form)) {
      this.apiS.noauth.createCompany(new M_Register(this.form.value)).then(_res => {
        this.registerDone = true;
        this.confirmS.show({
          title: "¡Usuario creado con éxito!",
          body: "Te hemos enviado un correo electrónico para verificar tu correo.",
          confirmTxt: "¡Genial!",
          showCancel: false
        }).afterClosed().subscribe(res => {
          this.routerS.goWithQueryParams(this.v.registerCompleted, { email: this.form.get('email')?.value });
        })
      });
    }
  }

  openTermsAndConditions(e: Event) {
    e.preventDefault();
    this.d.open(TermsAndConditionsComponent, { autoFocus: false }).afterClosed().subscribe(
      res => {
        if (res) {
          this.checkBoxTerms.checked = true;
        }
      }
    )
  }

  goLogin() {
    this.routerS.goTo(this.v.login);
  }

}
