import { IPageStructureItem } from "src/app/interfaces/IPageStructureItem";
import { M_Vehicle } from "./M_Vehicle";
import { M_Brand } from "../M_Brand";
import { M_Model } from "../M_Model";

export class M_GroupedVehicle implements IPageStructureItem {

    itemId: number | undefined = undefined;
    brand: M_Brand;
    model: M_Model;
    vehicles: M_Vehicle[] = [];
    bName: string = "";
    mName: string = "";
    disp: number = 0;
    reserved: number = 0;
    constructor(brand: M_Brand, model: M_Model) {
        this.brand = brand;
        this.model = model;
        this.bName = this.brand.name;
        this.mName = this.model.name;
        this.calcReserve();
    }

    addVehicle(v: M_Vehicle) {
        this.vehicles.push(v);
        this.calcReserve();
    }

    defaultSearchFilter(text: string): boolean {
        return this.vehicles.some(v => v.defaultSearchFilter(text));
    }
    calcReserve() {
        this.disp = this.vehicles.length - this.reserved;
        this.reserved = this.vehicles.reduce((sum, current) => sum + (current.isReserved ? 1 : 0), 0);
    }

}