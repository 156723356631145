import { Component, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-stepper-step-indicator',
    templateUrl: './stepper-step-indicator.component.html',
    styleUrls: ['./stepper-step-indicator.component.css'],
    standalone: false
})
export class StepperStepIndicatorComponent {
  @Input({ required: true }) stepper!: MatStepper;
  go(i: number) {
    if (this.stepper) {
      this.stepper.selectedIndex = i;
    }
  }
}
