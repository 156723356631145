import { Component, Input } from '@angular/core';
import { M_Fault } from '../../models/M_Fault';
import { MatDialogRef } from '@angular/material/dialog';
import { GoFaultService } from '../../services/go-fault.service';

@Component({
    selector: 'app-fault',
    templateUrl: './fault.component.html',
    styleUrls: ['./fault.component.css'],
    standalone: false
})
export class FaultComponent {
  @Input({ required: true }) fault!: M_Fault;
  @Input() slash: boolean = false;
  @Input() opacity: boolean = false;
  @Input() large: boolean = false;
  @Input() goFault: boolean = false;

  constructor(public dialogRef: MatDialogRef<FaultComponent>, private goFaultS: GoFaultService) { }

  goByFault() {
    if (!this.goFault) { return; }
    this.goFaultS.go(this.fault, false);
    this.dialogRef.close();
  }

}
