import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_TemplateField, templateTypedoc } from 'src/app/models/M_TemplateField';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { SnackService } from 'src/app/services/snack.service';
import { DocumentationContentComponent } from './documentation-content/documentation-content.component';
import { COMPANY_SUBTABS, SETTINGS_TAB, SettingsComponent } from '../../settings.component';
import { FormControl } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { RouterService } from 'src/app/services/router.service';

/** Footers  */
export type T_Footers = {
  footer2: string | undefined,
  footer3: string | undefined,
  footer4: string | undefined,
  footer5: string | undefined,
}

/** Save documentation structure */
export type T_SaveDocumentation = {
  footer2: string | undefined,
  footer3: string | undefined,
  footer4: string | undefined,
  footer5: string | undefined,
  template_fields: M_TemplateField[]
}

@Component({
    selector: 'app-documentation-config',
    templateUrl: './documentation-config.component.html',
    styleUrls: ['./documentation-config.component.css'],
    standalone: false
})
export class DocumentationConfigComponent {

  @Output() changeTab: EventEmitter<number> = new EventEmitter();
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  formData = new FormData();
  /** The configuration tabs */
  @ViewChild("budget") budgetConfig!: DocumentationContentComponent;
  @ViewChild("ra") raConfig!: DocumentationContentComponent;
  @ViewChild("or") orConfig!: DocumentationContentComponent;
  @ViewChild("invoices") invoicesConfig!: DocumentationContentComponent;
  @ViewChild("compraventa") compraventaConfig!: DocumentationContentComponent;
  allDocuments: (DocumentationContentComponent)[] = [];
  documentFc = new FormControl<templateTypedoc>('B');

  constructor(@Inject(SettingsComponent) public parent: SettingsComponent, private snackS: SnackService, private apiS: ApiService,
    public companyS: CompanyService, private routerS: RouterService) { }

  get showSaveDot() {
    return this.budgetConfig?.pending && this.documentFc.value == "B" ||
      this.raConfig?.pending && this.documentFc.value == "RA" ||
      this.orConfig?.pending && this.documentFc.value == "OR" ||
      this.invoicesConfig?.pending && this.documentFc.value == "I" ||
      this.compraventaConfig?.pending && this.documentFc.value == "CV"
  }

  initTab(data: { templates: M_TemplateField[], footers: T_Footers }) {
    this.allDocuments.push(this.budgetConfig, this.raConfig, this.orConfig, this.invoicesConfig, this.compraventaConfig);
    this.fillTemplateFields(data.templates);
    this.fillFooters(data.footers);
  }


  fillTemplateFields(templateFields: M_TemplateField[]) {
    this.budgetConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "B"));
    this.raConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "RA"));
    this.orConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "OR"));
    this.invoicesConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "I"));
    this.compraventaConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "CV"));
  }

  /** Fill all footers on screen. The filling order, depends on the footer position in the html*/
  private fillFooters(footers: T_Footers) {
    this.budgetConfig.setFooter(footers.footer2);
    this.raConfig.setFooter(footers.footer3);
    this.orConfig.setFooter(footers.footer4);
    this.invoicesConfig.setFooter(footers.footer5);
    this.compraventaConfig.setFooter(footers.footer5);
  }

  /** Cehck if some footer has pending changes */
  saveFooterDisabled() {
    return this.allDocuments?.map(f => f).every(f => f.pending == false);
  }
  get isCompraventaPending() {
    return this.compraventaConfig ? this.compraventaConfig.pending : false;
  }
  /** Set 'pending = false' on all footers */
  resetPendings() {
    this.allDocuments?.forEach(f => {
      f.pending = false;
      f.templateComponent.cleanForm();
    })
  }

  /** True o fale depending of the pending changes to save (footers and colors) */
  saveAllDisabled() {
    return this.saveFooterDisabled();
  }

  /** Save colors and footers */
  saveLayout() {
    
    const a = this.apiS.saveFootersAndTemplateFields(this.getViewValues());
    const b = this.apiS.saveCompanyFiles(this.getFileTemplateFields());

    forkJoin([a, b]).subscribe(res => {
      this.resetPendings();
      this.updateTemplateId(res[0]);
      this.updateTemplateFilesId(res[1]);
      this.snackS.show("Configuración guardada con éxito");
    })
  }

  getViewValues(): T_SaveDocumentation {
    let value: T_SaveDocumentation = {
      footer2: this.budgetConfig.getFooter(),
      footer3: this.raConfig.getFooter(),
      footer4: this.orConfig.getFooter(),
      footer5: this.invoicesConfig.getFooter(),
      template_fields: this.getTemplateFields(),
    }
    return value;
  }


  /** Get all template fields (NON FILE TYPE FIELDS) */
  getTemplateFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getModels());
    })
    return template_fields;
  }

  /** Get all template fields (ONLY FILE TYPE) */
  getFileTemplateFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getFilesModels());
    })
    return template_fields;
  }
  /** Get all template fields (FILE AND NON FILE TYPES) */
  getAllFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getAllModels());
    })
    return template_fields;
  }

  /**
   * Update non-file fields
  */
  updateTemplateId(res: M_TemplateField[]) {
    let currentTemplate = this.getTemplateFields();
    currentTemplate.forEach((t, index) => {
      if (res[index]) {
        t.value_id = res[index].value_id;
        t.design_id = res[index].design_id;
      }
    })
  }
  handleFileSelected(file: File | null) {

  }
  /**
   * Update file fields
   */
  updateTemplateFilesId(res: M_TemplateField[]) {
    let currentTemplate = this.getFileTemplateFields();
    currentTemplate.forEach((t, index) => {
      if (res[index]) {
        t.value_id = res[index].value_id;
        t.design_id = res[index].design_id;
        t.adv_value = res[index].adv_value; // Update always the adv_value!
      }
    })
  }


  goGeneral() {
    this.routerS.goWithQueryParams(ViewPath.settings, { "tab": SETTINGS_TAB.COMPANY, "subtab": COMPANY_SUBTABS.COMPANY });
  }

}
