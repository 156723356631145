import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { M_Action } from 'src/app/models/M_Action';
import { M_Albaran } from 'src/app/models/M_Albaran';
import { ApiService } from 'src/app/services/Api/api.service';
import { ClassSearcherFilter, DayFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { PreviewService } from 'src/app/services/preview.service';
import { OR_TYPE_FILTER } from 'src/app/constants/SharedFilters';

export type massiveResponse = {
  error: { message: string }[],
  invoiced: {
    invoice: M_Invoice;
  }[]
}

type massiveTypes = "or" | "albaran";

export interface IMassiveType {
  type: massiveTypes;
  client_id?: number;
}

export enum MassiveEnum {
  MASSIVE_CLIENT = 100,
  MASSIVE_DATE = 101
}

/**
   this.d.open<MassiveInvoiceComponent, IMassiveType>(MassiveInvoiceComponent, {
      data :  {
        type: "",
        client_id : number?,
      },
      autoFocus: false
    })
 */

@Component({
    selector: 'app-massive-invoice',
    templateUrl: './massive-invoice.component.html',
    styleUrls: ['./massive-invoice.component.css'],
    standalone: false
})
export class MassiveInvoiceComponent {

  client = MASTER_CLIENT;
  dataLoaded = false;
  billable: (M_GroupTask | M_Albaran)[] = [];
  filtered: (M_GroupTask | M_Albaran)[] = [];
  selecteds: (M_GroupTask | M_Albaran)[] = [];

  f = filter;

  clientFilter = new ClassSearcherFilter("Cliente", this.client, "Cliente").setId(MassiveEnum.MASSIVE_CLIENT);
  dateFilter = new DayFilter("Fecha").setId(MassiveEnum.MASSIVE_DATE);
  OR_TYPE_FILTER = OR_TYPE_FILTER;

  filters = [this.clientFilter, this.dateFilter, this.OR_TYPE_FILTER(true ,false)]

  apiResponse: massiveResponse | undefined;

  constructor(private apiS: ApiService, public dRef: MatDialogRef<MassiveInvoiceComponent>, @Inject(MAT_DIALOG_DATA)
  public data: IMassiveType, private previewS: PreviewService) {
    if (this.data.client_id) {
      this.clientFilter.selected_id = this.data.client_id;
    }
    this.getData();
  }

  get pageLoaded() {
    return this.dataLoaded && this.clientFilter.loaded;
  }

  get title() {
    return this.data.type == "albaran" ? "albaranes" : "OR";
  }

  getData() {
    if (this.data.type == "or") {
      this.apiS.getForMassiveInvoiceOR().then(res => {
        this.afterGetData(res);
      })
    }
    else if (this.data.type == "albaran") {
      this.apiS.getForMassiveInvoiceDNote().then(res => {
        this.afterGetData(res);
      })
    }
  }

  private afterGetData(data: (M_GroupTask | M_Albaran)[]) {
    this.billable = data;
    this.filtered = data;
    this.filter();
    this.dataLoaded = true;
  }

  filter() {
    this.unselectAll();
    this.filtered = [];
    this.billable.forEach(item => {
      if (this.f(item, ...this.filters)) {
        this.filtered.push(item);
      }
    })
  }

  get checkDisabled() {
    let ok = this.selecteds.length;
    return !ok;
  }

  invoiceMassive() {
    let ids: { id: number }[] = this.selecteds.map(a => ({ id: a.id! }));
    if (this.data.type == "or") {
      this.apiS.invoiceMassiveOr(ids).then(res => {
        this.apiResponse = res;
      })
    }
    else if (this.data.type == "albaran") {
      this.apiS.invoiceMassiveDNote(ids).then(res => {
        this.apiResponse = res;
      })
    }
  }

  get hasApiResponse() {
    return this.apiResponse != undefined;
  }

  successClose() {
    this.dRef.close(true);
  }


  selectUnselect() {
    if (this.allSelecteds) {
      this.unselectAll();
    }
    else {
      this.selectAll();
    }
  }

  get allSelecteds() {
    return this.selecteds.length == this.filtered.length
  }

  get someSelecteds() {
    return this.selecteds.length != 0 && !this.allSelecteds;
  }

  selectAll() {
    this.selecteds = [...this.filtered];
  }

  unselectAll() {
    this.selecteds = [];
  }

  addRemoveSelected(a: M_GroupTask | M_Albaran) {
    if (!this.isSelected(a)) {
      this.selecteds.push(a);
    }
    else {
      this.selecteds.removeElement(a);
    }
  }

  isSelected(a: M_GroupTask | M_Albaran) {
    return this.selecteds.includes(a);
  }


  pendingGroupsTotal(or: M_Action) {
    return or.closedTotal - or.totalInternosClosed
  }

  isOR(item: any): item is M_GroupTask {
    return item instanceof M_GroupTask;
  }

  isAlbaran(item: any): item is M_Albaran {
    return item instanceof M_Albaran;
  }

  previewInvoice(token: string | undefined) {
    if (token){
      this.previewS.showPreview("I", token);
    }
  }

}
