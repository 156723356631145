import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, finalize, Observable, tap, throwError } from "rxjs";
import { CustomLog } from "../custom-classes/CustomLog";
import { CustomErrorHandler } from "../custom-classes/CustomErrorHandler";
import { DeveloperService } from "../services/developer.service";
import { EndpointService } from "../services/endpoint.service";
import { SessionService } from "../services/session.service";
import { ConfirmDialogService } from "../services/confirm-dialog.service";
import { LoadingPanelService } from "./LoadingPanel/loading-panel.service";
import { Endpoint } from "../custom-classes/Endpoint";
import { environment } from "src/environments/environment";
import { projectConfiguration } from "../app.module";
import { feature } from "../utils/FeaturesController";

/** 
 * Intercepts the http calls.
 * 
 * `Loading panel` : Shows at the start of the request and hides it at the end.
 * `Authorization header` : Included automatically if the endpoint requires it.
 * 
 * `Errors` : If the call returns an error :
 * - DEV AND PRE : Show the full stacktrace on a dialog.
 * - PRO : If the error has a message, only show the message. Otherwise, show a  generic error.
 *  
 */

@Injectable()
export class httpListenerService implements HttpInterceptor {
    constructor(private endpointS: EndpointService,
        private confirmD: ConfirmDialogService,
        private loadingPanel: LoadingPanelService,
        private sessionService: SessionService,
        private errorS: CustomErrorHandler,
        private developerS: DeveloperService) { }


    isDeployInfoEndpoint(req: HttpRequest<any>) {
        return req.url.includes('/assets/deploy-info.json');
    }

    /** Don't show loading if the route is assets/icons/something.svg
     * Example :http://localhost:4200/assets/icons/foo.svg
    */
    isSvgEndpoint(req: HttpRequest<any>) {
        let isSvg = req.url.includes("/assets/icons/") && req.url.includes(".svg");
        let isFlag = req.url.includes("assets/svg-country-flags/svg") && req.url.includes(".svg");
        return isSvg || isFlag;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.isDeployInfoEndpoint(req)) {
            return next.handle(req);
        }

        let endp: Endpoint | undefined = this.endpointS.getEnpointFromUrl(req.url);

        if ((endp && endp.showLoading) || (endp == undefined && !this.isSvgEndpoint(req))) {
            console.log(endp != undefined ? "Interceptado el endpoint : " + endp.url : "El endpoint interceptado no se reconoce")
            this.loadingPanel.show();
        }

        let authReq: HttpRequest<any>;
        let finalreq: HttpRequest<any>;

        //Miramos si es necessario añadir el header de autorización
        if ((endp != undefined && endp.appendHeader)) {
            authReq = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.sessionService.getToken()
                }
            });
            // console.log("Añadiendo el header de autorización al endpoint : " + endp.url)
        }
        else {  //En caso de que no sea necesario el header de autorización 
            authReq = req.clone();
        }

        if (req.method == "POST") {
            if (this.sessionService.isSharedAccessView) {
                req = this.addSharedUser(authReq);
                authReq = this.addSharedUser(authReq);
            }

            finalreq = authReq.clone({})

        }
        else {
            finalreq = authReq.clone();
        }

        return next.handle(finalreq).pipe(
            finalize(() => this.loadingPanel.hide()))
            .pipe(
                /**Gestión de errores de las peticiones http */
                catchError((error: HttpErrorResponse) => {
                    this.loadingPanel.hide();
                    console.log(error);
                    var coreErrorCodes = this.getErrorCode(error);
                    const noAuth = this.isNoSessionError(error);

                    /** Show the panel only if the endpoint is undefined or the endpoint has the customError to false */
                    if (endp == undefined || endp.customError == false) {
                        /** NO SESSION */
                        if (noAuth) {
                            let redirect = true;
                            if (endp != undefined && !endp.appendHeader) { redirect = false; }
                            if (redirect) { this.sessionService.clearEinaAndGoLanding(true); }
                        }
                        /** ERROR CODES */
                        else if (coreErrorCodes != undefined) {
                            /** We KNOW what error is, this is becaouse we put 'Ha surgido un error' */
                            this.confirmD.showError("Ha surgido un error", coreErrorCodes.errorMessage);
                            if (coreErrorCodes?.errorCode == 1) {
                                let redirect = true;
                                if (endp != undefined && !endp.appendHeader) { redirect = false; }
                                if (redirect) { this.sessionService.clearEinaAndGoLanding(true); }
                            }
                        }
                        /**TIMEOUT */
                        else if (this.isTimeOutError(error)) {
                            this.confirmD.showError("Alcanzado tiempo de espera máximo", "Revise su conexión a internet");
                        }
                        else {
                            /** Show genereic HTTP error */
                            this.confirmD.showGenericHttpError(error);
                        }
                    }

                    if (!noAuth && this.sessionService.hasSession()) {
                        if (feature.reportLogs) { this.errorS.report(new CustomLog("error", JSON.stringify(error))) }
                        if (feature.toJira) { this.errorS.toJira(document.title, window.location.href, new Date().shortSecondsFormat(), finalreq, error); }
                    }
                    this.loadingPanel.hide()
                    return throwError(() => new Error(this.confirmD.getMessageError(error)));
                }
                )
            )
    }

    private addSharedUser(req: HttpRequest<any>) {
        let sharedReq: HttpRequest<any> = req.clone();
        const currentUser = this.sessionService.sharedUserId;

        if (!currentUser) { return req; }

        if (sharedReq.body instanceof FormData) {
            sharedReq.body.append("shared_user", currentUser.toString());
        }
        else if (typeof sharedReq.body === 'object' && sharedReq.body !== null) {
            const modifiedBody = {
                ...sharedReq.body,
                shared_user: currentUser
            };
            sharedReq = sharedReq.clone({ body: modifiedBody });
        }
        return sharedReq;
    }

    private getErrorCode(resp: HttpErrorResponse): { errorCode: number, errorMessage: string } | undefined {
        var error_code = resp.error.error_code;
        if (projectConfiguration.errorCodes) {
            let error_message = projectConfiguration.errorCodes.get(error_code);
            if (typeof error_code == "number" && error_message) {
                return { errorCode: error_code, errorMessage: error_message };
            }
        }
        return undefined;
    }

    private isNoSessionError(error: HttpErrorResponse) {
        return error.error.message === this.sessionService.NO_SESSION;
    }

    private isTimeOutError(error: HttpErrorResponse) {
        return error.error.type && error.error.type == "timeout";
    }
}