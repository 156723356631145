import { Component} from '@angular/core';

/**
 * Fotter of app-card component
 */
@Component({
    selector: 'app-contact-buttons',
    templateUrl: './contact-buttons.component.html',
    styleUrls: ['./contact-buttons.component.css'],
    standalone: false
})
export class ContactButtonsComponent{
  constructor() { }
}
