import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { FiltersComponent } from 'src/app/components/filters/filters.component';
import { TagFilter, FilterOption, Filter } from 'src/app/custom-classes/Filter';
import { invoice_type } from 'src/app/custom-classes/invoice_types';
import { M_Product_Invoice } from 'src/app/models/M_Product_Invoice';
import { M_Product_Move } from 'src/app/models/M_Product_Move';
import { ParamsService } from 'src/app/services/params.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
    selector: 'app-productmove',
    templateUrl: './productmove.component.html',
    styleUrls: ['./productmove.component.css', "../product-details.component.css"],
    animations: [
        trigger('appearAnim', [
            state('toAppear', style({
                transform: 'scale(0.5)',
                opacity: '0'
            })),
            state('appear', style({
                transform: 'scale(1)',
                opacity: '1'
            })),
            transition('toAppear => appear', animate(300))
        ])
    ],
    standalone: false
})
export class ProductmoveComponent implements OnInit {

  @Input() pm!: M_Product_Move;
  @Input() product_invoice: M_Product_Invoice[] = [];
  @Input() product_move: M_Product_Move[] = [];
  @Input() allmoves: (M_Product_Move | M_Product_Invoice)[] = [];
  @ViewChild(FiltersComponent) filter_html!: FiltersComponent;
  appear: "appear" | "toAppear" = "toAppear";
  v = ViewPath;
  dataMostrada: (M_Product_Move | M_Product_Invoice)[] = [];
  data: (M_Product_Move | M_Product_Invoice)[] = [];
  dataFiltrada: (M_Product_Move | M_Product_Invoice)[] = [];
  textoBoton: string = 'Ver más';
  textoTitle: string = 'IMPORTADO';
  statusFilter: TagFilter = new TagFilter(
    'Tipo', invoice_type, new FilterOption('Entrada almacén', 'archive'),
    new FilterOption('Salida almacén', 'move_down'),
    new FilterOption('Asociados a facturas', 'receipt_long'),
    new FilterOption('Asociados a OR', 'build')
  ).setId(1);
  action_filters: Filter[] = [this.statusFilter];
  constructor(private chdRef: ChangeDetectorRef, public params: ParamsService, private routerS: RouterService, public responsiveS: ResponsiveService) {
  }
  get displayedColumns() {
    return this.responsiveS.w > 514
      ? ['Ornumber', 'status', 'delivery', 'type']
      : ['Ornumber', 'delivery', 'type'];
  }
  anim(): void {
    this.appear = "toAppear";
    this.chdRef.detectChanges();
    this.appear = "appear";
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.appear = "appear"
    this.chdRef.detectChanges();
    this.data.push(...this.allmoves);
    this.dataMostrada = this.data.slice(0, 5);
  }

  isProductMove(val: M_Product_Move | M_Product_Invoice): val is M_Product_Move {
    return val instanceof M_Product_Move;
  }
  isProductInvoice(val: M_Product_Move | M_Product_Invoice): val is M_Product_Invoice {
    return val instanceof M_Product_Invoice;
  }
  isAbono(val: M_Product_Move | M_Product_Invoice) {
    if (val.type == 'abono') {
      return true;
    }
    return false;
  }
  isAddOrRemove(val: M_Product_Move | M_Product_Invoice): string {
    let resultado = '';
    if (val instanceof M_Product_Move) {
      if (val.type == "import") {
        if (val.quantity < 0) {
          return "Salida"
        }
        return val.isAdd() ? 'Importado' : 'Salida';
      }
      if (val.type == "manual")

        return val.isAdd() ? 'Entrada' : 'Salida';
    }
    else if (val instanceof M_Product_Invoice) {
      let resultado = '';
      if (val.type == "action") {

        if (val.isAdd()) {
          return 'Importado';
        } else {
          return 'Salida';
        }
      }
      else if (val.type == "abono") {
        if (val.isAdd()) {
          return 'Importado';
        } else {
          return 'Salida';
        }
      }
      else if (val.type == "invoice") {
        if (val.isAdd()) {
          return 'Importado';
        } else {
          return 'Salida';
        }
      }
      else {
        return resultado;
      }
    }
    return resultado;
  }
  shouldDisplayProduct(element: M_Product_Move | M_Product_Invoice): boolean {
    return this.isProductMove(element) || !this.isProductInvoice(element);
  }
  getClassForElement(element: M_Product_Move | M_Product_Invoice): string {
    let class_html = 'rem';
    if (element instanceof M_Product_Move) {
      if (element.quantity < 0) {
        class_html = 'rem';
      } else {
        class_html = element.isAdd() ? 'add' : 'rem';
      }
    } else if (element instanceof M_Product_Invoice) {
      if (element.type == "action") {
        if (element.details == 'add') {
          class_html = 'add';
        }
      }
      if (element.type == "abono") {
        if (element.details == 'add') {
          class_html = 'add';
        }
      }
    }
    return class_html;
  }
  getSymbolForElement(element: M_Product_Move | M_Product_Invoice): string {
    let symbol = '-';
    if (element instanceof M_Product_Move) {
      if (element.quantity < 0) {
        symbol = '';
      } else {
        symbol = element.isAdd() ? '+' : '-';
      }
    } else if (element instanceof M_Product_Invoice) {
      if (element.type == "action") {
        if (element.details == 'add') {
          symbol = '+';
        }
      }
      if (element.type == "abono") {
        if (element.details == 'add') {
          symbol = '+';
        }
      }
    }
    return symbol;
  }

  goItem(element: M_Product_Invoice | M_Product_Move) {
    if (element instanceof M_Product_Invoice) {
      if (element.type == "action") {
        this.routerS.goWithQueryParams(this.v.editOr, { or: element.invoice_id });
      } else {
        this.params.go(ViewPath.invoiceDetails, element.invoice_id);
      }
    }
  }
  getIconName(element: M_Product_Move | M_Product_Invoice): string {
    if (this.isAbono(element)) {
      return 'currency_exchange';
    } else if (this.isAddOrRemove(element) === 'Importado') {
      return 'build';
    } else if (this.isAddOrRemove(element) === 'Entrada') {
      return 'save_alt';
    } else if (this.isAddOrRemove(element) === 'Salida' && !(element instanceof M_Product_Invoice)) {
      return 'move_down';
    } else {
      return 'receipt_long';
    }
  }
  mostrarMasFilas() {
    if (this.dataMostrada.length === 0) {
      this.dataMostrada = this.data.slice(0, 5); // Mostrar las primeras 5 filas
      this.textoBoton = 'Ver más';
    } else if (this.dataMostrada.length === this.data.length) {
      this.dataMostrada = this.data.slice(0, 5); // Mostrar las primeras 5 filas nuevamente
      this.textoBoton = 'Ver más';
    } else {
      this.dataMostrada = this.data; // Mostrar todas las filas
      this.textoBoton = 'Ocultar';
    }
  }
  applySearchFilter(searchValue: string) {
    this.dataMostrada = this.data.filter((element: M_Product_Move | M_Product_Invoice) => {
      return element.defaultSearchFilter(searchValue.toString());
    });
  }

  refreshAction(filters: Filter[]) {

    this.dataFiltrada = [];
    if (!this.filter_html.activeFilters.length) {
      this.dataMostrada = this.data.slice(0, 5);
    } else {
      this.data.forEach((i) => {
        filters.forEach((e) => {
          if (e instanceof TagFilter && e.id == 1) {
            if (i instanceof M_Product_Move) {
              let isOk = e.checkFilter(i.isAdd() ? [0] : [1]);
              if (isOk) {
                this.dataFiltrada.push(i);
              }
            } else {
              let isOk = e.checkFilter([i.type == "action" ? 3 : 2]);
              if (isOk) {
                this.dataFiltrada.push(i);
              }
            }
          }
        });
      });
      this.dataMostrada = this.dataFiltrada;
    }
  }

}
