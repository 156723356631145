import { Component, Input } from '@angular/core';

/**
  <app-section>
     <app-section-title>Example title</app-section-title>
     <app-section-content>Example content</app-section-content>
  </app-section>
 */
@Component({
    selector: 'app-section',
    template: `
  <div class="card-wrapper mt20">
    <p class="fw600 nmb" #title>
      <ng-content select="app-section-title"></ng-content> <!-- Proyección del título -->
      <mat-icon dropDown [content]="content" [trigger]="title" [starClosed]="!starOpened" class="vam">arrow_drop_up</mat-icon>
    </p>
    <div #content>
      <ng-content select="app-section-content"></ng-content> <!-- Proyección del contenido -->
    </div>
  </div>
  `,
    standalone: false
})
export class SectionComponent {
  @Input() starOpened : boolean = false;
  constructor() { }
}
