import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Action } from '../../models/M_Action';
import { M_Contact } from '../../models/M_Contact';
import { M_Product } from '../../models/Products/M_Product';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { UserService } from '../../services/EinaMainData/user.service';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';
import { SearchService } from 'src/app/services/search.service';
import { Views } from 'src/app/custom-classes/View';
import { getIconByDate } from 'src/app/constants/constants';
import { actionResults, helpResults } from './bigMamaAuxiliarResults';
import { MatDialog } from '@angular/material/dialog';


const MIN_LEN = 3;

export class ResultOption {
  constructor(public icon: string, public label: string, public action: () => void, public sublabel?: string) { }
}

class ResultOptionGroup {
  constructor(public groupId: groupEnum, public options: ResultOption[]) { }
}

enum groupEnum {
  USERS = "Contactos",
  VEHICLES = "Vehículos",
  PRODUCTS = "Productos",
  OR = "Órdenes de reparación",
  BUDGETS = "Presupuestos",
  ACTION = "Acciones",
  HELP = "Ayuda",
}

@Component({
    selector: 'app-big-mama',
    templateUrl: './big-mama.component.html',
    styleUrls: ['./big-mama.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BigMamaComponent implements OnInit {
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input') input!: ElementRef<HTMLElement>;
  myControl = new UntypedFormControl('');
  resultOptionsGroup: ResultOptionGroup[] = [];
  actions: ResultOption[];
  help: ResultOption[];
  v = ViewPath;
  loading = false;
  lastSearch: string = "";
  focus = false;
  iconByDate = "";

  constructor(private apiS: ApiService, private routerS: RouterService, private paramsS: ParamsService, private searchS: SearchService,
    public responsiveS: ResponsiveService, public userS: UserService, private companyS: CompanyService, private d: MatDialog) {
    this.iconByDate = getIconByDate();
    this.actions = actionResults(this.routerS);
    this.help = helpResults(this.routerS, this.d);
  }

  ngOnInit() { }

  userCanGo(v: Views) {
    return this.routerS.userCanGo(v);
  }

  validateInput(event: InputEvent) {
    const allowedPattern = /^[a-zA-Z0-9 áÁéÉíÍóÓúÚàÀèÈìÌòÒùÙñÑüÜ:]+$/;
    const input = event.data;
    if (!input || allowedPattern.test(input)) { return;}
    event.preventDefault();
  }

  search(searched: string) {
    if (searched.length < MIN_LEN) { return }

    this.lastSearch = searched;

    if (searched == "") {
      this.clearGroups();
      this.loading = false;
    }
    else if (!this.loading) {
      this.loading = true;
      this.apiS.globalSearch(searched).then(resp => {
        this.loading = false;
        if (searched != this.lastSearch) {
          this.search(this.lastSearch);
        }

        this.clearGroups();


        if (resp.clients && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.clients.length; i++) {
            let c = new M_Contact(resp.clients[i])
            this.addOption(groupEnum.USERS, new ResultOption(c.icon, c.getName(), () => this.paramsS.go(this.v.contactDetails, c.client_id), c.nif))
          }
        }
        if (resp.vehicles && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.vehicles.length; i++) {
            let v = new M_Vehicle(resp.vehicles[i])
            this.addOption(groupEnum.VEHICLES, new ResultOption(v.icon, v.getName(), () => this.paramsS.goVehicleDetails(v), v.license))
          }
        }
        if (resp.products && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.products.length; i++) {
            let p = new M_Product(resp.products[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption(p.icon, p.name, () => this.paramsS.go(this.v.productDetails, p.product_id!), p.smartRef(this.companyS.recambiosModule)))
          }
        }
        if (resp.or) {
          for (let i = 0; i < resp.or.length; i++) {
            let or = new M_Action(resp.or[i]);
            //todo --> or.title_id || or.action_id.toString()
            this.addOption(groupEnum.OR, new ResultOption("build", or.title_id || or.action_id.toString(), () => this.paramsS.go(this.v.editOr, or.id!), or.vehicle ? or.vehicle.license || or.vehicle.chassis : "Sin vehículo asigando"))
          }
        }
        if (resp.budget && this.userS.userIsAdminOrAdviser) {
          for (let i = 0; i < resp.budget.length; i++) {
            let budget = new M_Action(resp.budget[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption("calculate", budget.id.toString(), () => this.paramsS.go(this.v.createEditWorkshopBudget, budget.id!), budget.vehicle ? budget.vehicle.license : "Sin vehículo asigando"))
          }
        }
        /** Actions and help */
        this.addAuxiliarResults(searched);
      })
    }
  }



  /** Se evita que en el input salga [object Object] al hacer click en una opción de 'autocompletado' */
  getOptionText(_v: any) {
    return ""
  }

  addOption(g: groupEnum, ro: ResultOption) {
    let groupFound = false;
    for (let i = 0; i < this.resultOptionsGroup.length; i++) {
      if (this.resultOptionsGroup[i].groupId == g) {
        this.resultOptionsGroup[i].options.push(ro);
        groupFound = true;
      }
    }
    if (!groupFound) {
      this.resultOptionsGroup.push(new ResultOptionGroup(g, [ro]))
    }
  }

  addAuxiliarResults(searched: string) {
    for (let i = 0; i < this.actions.length; i++) {
      if (this.searchS.match(searched, this.actions[i].label)) {
        this.addOption(groupEnum.ACTION, this.actions[i])
      }
    }
    for (let i = 0; i < this.help.length; i++) {
      if (this.searchS.match(searched, this.help[i].label)) {
        this.addOption(groupEnum.HELP, this.help[i])
      }
    }
  }

  clearGroups() {
    this.resultOptionsGroup = [];
  }

  optionClick(o: ResultOption) {
    o.action();
    this.clearGroups();
    setTimeout(() => {
      this.input.nativeElement.blur();
    })
  }

  hasResults() {
    return this.resultOptionsGroup.length != 0;
  }

  showNoData() {
    return this.resultOptionsGroup.length == 0 && this.myControl.value && this.myControl.value.length >= MIN_LEN;
  }

}
