import { Component, OnInit } from '@angular/core';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';

@Component({
    selector: 'app-drag-tutorial',
    templateUrl: './drag-tutorial.component.html',
    styleUrls: ['../../../components/welcome/welcome.component.css'],
    standalone: false
})
export class DragTutorialComponent implements OnInit {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;

  constructor() { }
  
  ngOnInit(): void { }

}
