import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BeforeDeleteGroupComponent } from 'src/app/components/drag/before-delete-group/before-delete-group.component';
import { OrFormComponent } from 'src/app/components/or-form/or-form.component';
import { M_Action } from 'src/app/models/M_Action';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { M_User } from 'src/app/models/M_User';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ActionCreateEditParentComponent } from '../or-create-edit-parent/or-create-edit-parent.component';
import { InvoiceDialogComponent } from './invoice-dialog/invoice-dialog.component';
import { PreviewService } from 'src/app/services/preview.service';
import { ViewPath } from 'src/app/app-routing.module';
import { IExitSaveChanges } from 'src/app/interfaces/IExitSaveChanges';
import { DragComponent } from 'src/app/components/drag/drag.component';
import { TrasnferActionComponent, transferData } from 'src/app/components/trasnfer-action/trasnfer-action.component';
import { OrNotesAndImagesComponent } from '../or-notes-and-images/or-notes-and-images.component';
import { OrService } from 'src/app/services/or.service';
import { OrTimerService } from 'src/app/services/or-time.service';
import { AddTimeOrDialogComponent } from './add-time-or-dialog/add-time-or-dialog.component';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { M_Product } from 'src/app/models/Products/M_Product';
import { getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { SessionService } from 'src/app/services/session.service';
import { or_status_invoiced } from 'src/app/custom-classes/or_states';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { endpoints } from 'src/app/constants/Endpoints';
import { ApiService } from 'src/app/services/Api/api.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { WorkerSelectorComponent } from 'src/app/components/worker-selector/worker-selector.component';
import { TimeLapseEnum } from 'src/app/models/M_Timelapse';
import { M_Center } from 'src/app/models/M_Center';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
    selector: 'app-edit-or',
    templateUrl: './edit-or.component.html',
    styleUrls: ['./edit-or.component.css', '../create-edit-or-styeles.css'],
    standalone: false
})
export class EditOrComponent extends ActionCreateEditParentComponent implements OnInit, IExitSaveChanges {
  primary = getPrimaryColor;
  @ViewChild(OrFormComponent, { static: false }) formComponent?: OrFormComponent;
  @ViewChild(OrNotesAndImagesComponent) notesAndImages!: OrNotesAndImagesComponent;
  @ViewChild(DragComponent) dragComponent?: DragComponent;
  e = endpoints;
  firmaEdited = false;
  allInvoiced = false;
  unsavedChanges = false;
  workerChange = false;
  R = RolesEnum;
  TLE = TimeLapseEnum;

  /** Dont modify */
  forceExit = false;

  responsive = {
    header: 775,
    invoiceButton: 410,
    timelapse: 1000, // timelapse to section
    time: 710, // worker to section
    rectangle: 1050, // Rectangle only icon
  }

  constructor(apiS: ApiService, routerS: RouterService, route: ActivatedRoute, public chdRef: ChangeDetectorRef, public responsiveS: ResponsiveService,
    public cs: CalendarService, d: MatDialog, public router: Router, private confirmDialogS: ConfirmDialogService, public subS: SubscriptionService,
    public previewS: PreviewService, public orS: OrService, public userS: UserService, public sessionS: SessionService, private orTimeS: OrTimerService,
    public canInvoiceS: CanInvoiceService, public companyS: CompanyService) {
    super(apiS, routerS, route, ["or"], d, confirmDialogS);
    this.autoRefeshTime();
  }


  ngOnInit(): void {}

  hasSomeInterno() {
    return this.dragComponent?.action?.groups.some(g => g.type.interno);
  }

  autoRefeshTime() {
    setTimeout(() => {
      this.chdRef.detectChanges();
      this.autoRefeshTime();
    }, 1000)
  }

  saveChangesBeforeExit(): void {
    this.saveScreen(this.dragComponent);
  }

  showSaveExitDialog() {

    if (!this.subS.hasProPermissions) {
      return false;
    }

    let somechanges = false;
    let dragHasChanges = false; //Children group chanes
    let editOrFormHasChanges = this.unsavedChanges; //This form changes
    if (this.dragComponent) {
      dragHasChanges = this.dragComponent.hasChanges();
    }
    somechanges = dragHasChanges || editOrFormHasChanges;
    return !this.action ? false : somechanges && !this.action.deleted;
  }

  override fillViewWithAction(a: M_Action) {

    if (this.formComponent) {
      this.formComponent.form.patchValue(a, { emitEvent: false });
      if (a.assigned_to) { this.formComponent.workerSearcher?.select(a.assigned_to, { emitEvent: false }); }
      if (a.schedule) { this.formComponent.form.patchValue({ "schedule": a.schedule.datePickerFormat() }, { emitEvent: false }); }
      if (a.delivery) { this.formComponent.form.patchValue({ "delivery": a.delivery.datePickerFormat() }, { emitEvent: false }); }
      if (a.hasImages()) { this.notesAndImages.fileupload.setImagesFromDatabase(a.images) }
      this.chdRef.detectChanges();

      /** Unsaved changes on general info */
      this.formComponent.form.valueChanges.subscribe(v => {
        if (!a.allInvoiced()) {
          this.unsavedChanges = true;
        }
      })

      this.formComponent.workerSearcher?.onSelect.subscribe(v => {
        if (!a.allInvoiced()) {
          this.unsavedChanges = true;
        }
      })
      // Load calendar
      this.formComponent.getWorkload();
      // this.notesAndImages.noteCompoenent.setValue(a.notes ? a.notes : "");
    }

    if (a.allInvoiced()) {
      this.disableScreen();
    }

  }

  get minValueDelivery() {
    return new Date(this.formComponent?.form.get('schedule')?.value);
  }

  removeImage(cf: CustomFile) {
    if (cf.database_id) {
      this.apiS.deleteImage(cf.database_id);
    }
  }

  isTodayHoliday(): boolean {
    const today = new Date();
    return this.companyS.userCenter.holidays.some((holiday) => holiday.isEquals(today));
  }

  onDateChange(e: any, date_type: "schedule" | "delivery") {
    if (this.action) {
      if (date_type == "delivery") {
        if (e == undefined) {
          this.action.delivery = e;
        }
        else {
          let target = new Date(e.value);
          this.action.delivery = target;
        }
      }
      else {
        if (e == undefined) {
          this.action.schedule = e;
        }
        else {
          let target = new Date(e.value);
          this.action.schedule = target;
        }
      }
    }
  }

  onWokrerChange(w: M_User) {
    if (this.action) {
      this.action!.assigned_to = w.id;
    }
  }

  editGeneralInfo() {
    if (this.action) {
      this.action.notes
      this.apiS.action.editGeneralOrInfo(
        this.action.id,
        this.action?.km,
        this.action.assigned_to,
        this.action.schedule,
        this.action.delivery,
        this.formComponent?.form.get('notes')?.value,
        this.action.fuel,
        this.formComponent?.form.get('estimated_time_hours')?.value,
        this.formComponent?.form.get('estimated_time_minutes')?.value)
        .then(res => {
          this.unsavedChanges = false;
        })
    }
  }

  trasnferTo(d: DragComponent, to: "or" | "budget") {
    if (!this.action?.isDone()) {
      d.saveAll().then(res => {
        this.openTransferTo(to);
      })
    }
    else {
      this.openTransferTo(to);
    }
  }

  openTransferTo(to: "or" | "budget") {
    this.d.open<TrasnferActionComponent, transferData, boolean>
      (TrasnferActionComponent,
        {
          autoFocus: false,
          disableClose: true,
          data: {
            action: this.action!,
            from: "or",
            to: to
          }
        })
  }

  openInvoiceDialog(preSelected?: M_GroupTask) {

    //let invoiceDefault = this.action!.vehicle?.clientInvoice?.client_id || this.action!.client?.client_id;
    //let sameClient = invoiceDefault == this.action!.client?.client_id;

    this.d.open(InvoiceDialogComponent,
      {
        data: {
          groups: this.action?.groups.filter(group => group.isClosed()),
          preSelected: preSelected,
          action: this.action,
          comp: this.dragComponent?.groupsComponents?.first,
        }, autoFocus: false
      })
      .afterClosed().subscribe(res => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            var gt = res[i];
            if (gt instanceof M_GroupTask) {
              gt.changeStatus(or_status_invoiced);
              this.chdRef.detectChanges();
            }
          }
          this.allInvoicedDialog();
        }

      })
  }

  openDialog(g?: M_GroupTask) {
    this.openInvoiceDialog(g);
  }

  /** If the rest of groups are invoiced, show an alert */
  checkOthersInvoiced(g: M_GroupTask) {
    this.confirmDialogS.show({
      title: "Eliminar intervención",
      body: "¿Está seguro de que quiere eliminar la intervención?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        if (this.isTheLastGroupNoInvoiced()) {
          this.d.open(BeforeDeleteGroupComponent).afterClosed().subscribe(res => {
            if (res == true) {
              this.removeGroup(g).then(res => {
                this.allInvoicedDialog();
              });
            }
          })
        }
        else {
          this.removeGroup(g);
        }
      }
    })
  }

  isTheLastGroupNoInvoiced() {
    if (this.action) {
      return this.action.groups.length - this.action.groups.filter(g => g.isInvoiced()).length == 1 && this.action.groups.length != 1;
    }
    return false;
  }

  removeGroup(g: M_GroupTask) {
    return this.apiS.action.rmGroup(g.id).then(_res => {
      if (this.action) {
        this.action.groups.removeElement(g);
        this.action.refreshStatus();
        this.action.refreshType();
        /** End current worker timer */
        if (this.action.status.pending || this.action.status.invoiced) {
          const isworking = this.action?.isOperatorWorking(this.userS.userId);
          if (isworking) { this.action?.closeTime(isworking!.id); this.orTimeS.end(this.action); }
        }
      }
    })
  }

  allInvoicedDialog() {
    if (this.action?.allInvoiced()) {
      this.action.refreshStatus();
      this.disableScreen();
      this.saveScreen();
      this.confirmDialogS.show({
        title: "¡Orden de reparación finalizada!",
        body: "Has facturado todos las intervenciones de la orden de reparación",
        confirmTxt: "Ok",
        cancelText: "Ir listado OR"
      }).afterClosed().subscribe(res => {
        if (res == false) {
          this.routerS.goTo(this.v.or)
        }
      })
    }
  }

  disableScreen() {
    this.allInvoiced = true;
    this.formComponent?.form.disable({ emitEvent: false });
    if (this.formComponent?.workerSearcher) {
      this.formComponent.workerSearcher.canChange = false;
      this.formComponent.workerSearcher.disable();
    }
  }

  saveScreen(drag?: DragComponent) {

    if (this.unsavedChanges) {
      this.editGeneralInfo();
    }
    if (drag && drag.hasChanges()) {
      drag.saveAll()
    }

    this.workerChange = false;
  }

  updateTitle(v: string) {
    if (v != this.action!.title) {
      this.apiS.editTitle(this.action!.id, v).then(_res => {
        this.action!.title = v;
      });
    }
  }

  goOrs() {
    this.routerS.goTo(ViewPath.or);
  }

  startStopTime() {
    this.orTimeS.startStop(this.action)
    this.chdRef.detectChanges();
  }

  addTime() {
    let workload = this.formComponent?.workload;
    this.d.open(AddTimeOrDialogComponent, { data: { or: this.action, workers: this.formComponent?.workerSearcher?.allData, assigned: this.action?.assigned, center: this.companyS.userCenter, workload }, autoFocus: false, restoreFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof Error) {
        //this.showRefreshObligation(res.message)
      }
    })
  }

  changeWorker(users: M_User[], selected: M_User | undefined) {
    let initialId = selected?.id;
    this.d.open(WorkerSelectorComponent, { data: { users: users, selected: selected }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_User && res.id != initialId) {
        this.formComponent?.workerSearcher?.select(res);
        if (initialId && initialId != res.id) {
          this.workerChange = true;
        }
      }
    })
  }


  isOperatorWorking() {
    return this.action?.isOperatorWorking(this.userS.userId);
  }

  get allProducts() {
    let prods: M_Product[] = [];
    if (!this.action) { return [] }
    this.action.groups.forEach(g => {
      g.products.forEach(groupProduct => {
        if (groupProduct instanceof M_Product) {
          prods.push(groupProduct)
        }
      })
    })
    return prods;
  }


  async pickUpMail() {
    if (this.action && this.action.client) {
      const { id, client: { name, email }, company_id, vehicle } = this.action;
      if (id && name && email && company_id && vehicle) {
        this.apiS.pickUpMail(id, name, email, company_id, vehicle);
      }
    }
  }

  goWokrload() {
    if (this.action) {
      this.routerS.goWithQueryParams(ViewPath.cargataller, {
        section: 'or',
        id: this.action.id,
        date: this.action.schedule,
      })
    }
  }

}
