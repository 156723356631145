import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { COUNTRIES_DB_ES } from '../components/country-selector/COUNTRIES_DB_ES';

@Injectable({
  providedIn: 'root'
})
export class SvgIconsService {

  allIcons: string[] = [];
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,) { }

  /** Add a svg icon to the matIconRegistry. The svg needs to be at : ""./assets/icons/foo.svg" */
  addSvgIcons(...icons: string[]) {
    this.allIcons = icons;
    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/" + icon + ".svg"));
    })
    this.registerCountries();
  }

  exists(icon: string) {
    return this.allIcons.includes(icon);
  }

  registerCountries() {
    for (const country of COUNTRIES_DB_ES) {
      const countryAlpha2Code = country.alpha2Code.toLowerCase();
      try {
        this.matIconRegistry.addSvgIcon(
          countryAlpha2Code,
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            `assets/svg-country-flags/svg/${countryAlpha2Code}.svg`
          )
        );
      } catch (err) {
        console.error("Error: icon not found for " + countryAlpha2Code, err);
      }
    }
  }
}