<app-page-structure [masterClass]="'product'" pageTitle="Productos" [autocompleteID]="'products'"
    searchLabel="Buscar producto" listTitleText="Listado de productos" noDataCreateNew="Crear un nuevo producto"
    [data]="[]" [displayedHeader]="displayedHeaders" [displayedColumns]="displayedColumns" [specialText]="specialText"
    [specialClass]="specialClass" [cellZize]="cellZize" [preIcon]="[productIcon]" [filters]="filters"
    [rightMenu]="rightMenu" [filter]="filterProducts.bind(this)"
    (onclickRow)="params.go(v.productDetails, $event.product_id)" [quickFilter]="quickFilter"
    [exportExcel]=" {singular : 'producto', plural : 'productos',name:'products'}" [createButton]="
        {
            text : 'Crear producto',
            icon : 'barcode', 
            view : v.createProduct, 
            cssClass : 'onboarding-create-product'
        }">
</app-page-structure>


<ng-template #rightMenu>
    <button recambios mat-menu-item (click)="routerS.goTo(v.storeHouseEntry)">
        <mat-icon>save_alt</mat-icon>
        Entrada de almacén
    </button>
    <button *ngIf="features.groupDiscount" recambios mat-menu-item (click)="routerS.goTo(v.discountGroups)">
        <mat-icon>heap_snapshot_multiple</mat-icon>
        Grupos de descuento
    </button>
    <button recambios mat-menu-item (click)="openDialogReserve()">
        <mat-icon>class</mat-icon>
        Reservas
    </button>
    <button mat-menu-item (click)="routerS.goTo(v.tarifas)">
        <mat-icon>sim_card_download</mat-icon>
        Importar tarifas
    </button>
</ng-template>