import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Order } from 'src/app/models/M_Order';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
    selector: 'app-edit-order',
    templateUrl: './edit-order.component.html',
    styleUrls: ['./edit-order.component.css'],
    standalone: false
})

export class EditOrderComponent extends ParameterStateComponent implements IProductLineTableComponent {

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean {return false;}

  orderFailedToLoad = false;
  loaded = false;
  v = ViewPath;
  order: M_Order | undefined;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, private snacks: SnackService) {
    super(routerS, route, ["order"]);
  }
  
  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    if (p.instanceofCustom()) { return; }
    this.order?.products.push(p);
  }

  addTime(time: M_CustomProduct): void { throw new Error("Can't add time on order edition"); }
  getClientDiscount(p: M_Product | M_CustomProduct): number | null { return null; }

  removeProduct(p: M_Product | M_CustomProduct): void {
    if (!this.order || p instanceof M_CustomProduct) { return; }
    if (p.line_id == undefined) { this.order.remove(p); }
    else {
      this.apiS.deleteOrderProduct(this.order.id, p.line_id).then(res => {
        this.order!.remove(p);
      })
    }
  }

  override onParam(param: string, value: string): void {
    if (param == "order") {
      this.apiS.order(value.getNumber()).then(res => {
        this.order = res;
        this.loaded = true;
      }).catch(e => {
        this.loaded = true;
        this.orderFailedToLoad = true;
      })
    }
  }

  editOrder() {
    if (!this.order) { return; }
    this.apiS.editOrder(this.order).then(res => {
      this.order!.products.forEach(p => p.line_hasChanges = false);
      this.snacks.show("¡Pedido guardado con éxito!");
      this.routerS.goTo(ViewPath.orders);
    })
  }

  getClient(): M_Contact | undefined {
    return this.order?.provider;
  }
  
  changeSend(order: M_Order) {
    this.apiS.changeSendedOrder(order);
  }

}
