import { Component, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CentersFilter } from 'src/app/custom-classes/Filter';

@Component({
    selector: 'app-center-filter',
    templateUrl: './center-filter.component.html',
    styleUrls: ['./center-filter.component.css'],
    standalone: false
})
export class CenterFilterComponent {
  @Input() filter!: CentersFilter;
  constructor() { }
  ngOnInit(): void { }

  emitChange(selected: MatSelectChange) {
    let finalVal = selected.value;
    this.filter.selected = finalVal;
  }
}
