import { FileType } from "../enums/FileType";

export class CustomFile {
    constructor(public src: string, public file: File | undefined, public id?: number, public database_id?: number, public loaded: boolean = true, public reference?: string) { }
    getType(): FileType {
        if (this.file != undefined) {
            if (this.file.type.includes('image')) {
                return FileType.image;
            }
            else if (this.file.type.includes('video')) {
                return FileType.video;
            }
            else if (this.file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || this.file.type == "text/csv") {
                return FileType.excel;
            }
            else if (this.file.type == "text/plain") {
                return FileType.txt;
            }
            else if (this.file.type == "application/pdf") {
                return FileType.PDF;
            }
            else {
                return FileType.document;
            }
        }
        else {
            /** Get type from base64 file */
            let split = this.src.split(";")
            if (split.length > 1) {
                if (split.includes("image")) {
                    return FileType.image;
                }
                else if (split.includes("video")) {
                    return FileType.video
                }
                else if (split.includes("text/csv") || split.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                    return FileType.excel;
            }
            /**Get type from exteinsion (the src is a url) */
            else {
                let split = this.src.split(".")
                if (split.length > 1) {
                    let extension = split[split.length - 1];
                    extension = extension.toLocaleLowerCase();
                    if (extension == "png" || extension == "jpg" || extension == "jpeg" || extension == "webp") {
                        return FileType.image;
                    }
                    else if (extension == "mp4" || extension == "avi") {
                        return FileType.video;
                    }
                    else if (extension == "csv" || extension == "xlsx") {
                        return FileType.excel;
                    }
                    else if (extension == "txt") {
                        return FileType.txt;
                    }
                    else {
                        return FileType.document;
                    }
                }
            }
            return FileType.document;
        }

    }

    getFile() {
        if (this.file == undefined) {
            this.file = this.base64ToFile(this.src);
        }
        return this.file;
    }

    getFileName() {
        if (this.file) {
            return this.file.name;
        }
        else if (this.reference) {
            return this.reference
        }
        else if (this.src) {
            /*let split = this.src.split('/');
            return split[split.length - 1];*/
            return 'Archivo'
        }
        return "Archivo"
    }

    base64ToFile(dataurl: string) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)![1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], 'file', { type: mime });
    }
}