import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[eForm-section]',
    standalone: false
})
export class EinaFormSectionDirective {

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('eina-form-section');
  }

}
