import { environment } from "src/environments/environment";

/** Maintain control of which functionalities should be shown or hidden */
export const feature = {

    /** Translations */
    translations: environment.local || environment.dev,

    /** Show developer helprer */
    developerHelper: environment.local || environment.dev || environment.preproduction,

    /** Frontend documentation */
    forntendDocu: environment.local || environment.dev,

    /** Open dialogs like 'WelcomeComponent' or 'DragTutorialComponent' */
    openDialogs: environment.local || environment.dev,

    /** Colors */
    colors: false,

    /** Import tariff */
    importTariff: false,

    /** Global searcher */
    bigMama: true,

    /** Tracking user actions */
    logRocket: environment.production,

    integrations: false,

    accountingGroups: false,

    groupDiscount: false,

    /** HubSpot */
    hubSpot: environment.dev || environment.production,

    /** Report errors to Jira */
    reportLogs: environment.production,

    /** Report errors to Jira */
    toJira: environment.production
}