import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EngineType } from 'src/app/enums/EngineType';

@Component({
    selector: 'app-vehicleform-motor',
    templateUrl: './vehicleform-motor.component.html',
    styleUrls: ['./vehicleform-motor.component.css'],
    standalone: false
})
export class VehicleformMotorComponent {

  ET = EngineType;
  @Input({required : true}) form! : UntypedFormGroup;
}
