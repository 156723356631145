<div class="advanced-details-container purchase-details">
    <app-go-back class="back" [text]="'Listado Compras'" [v]="v.purchases" [show]="loaded"></app-go-back>
    <div class="Details">
        <app-card [contentLoaded]="loaded" class="bc_b1">
            
            <app-advanced-details *ngIf="purchase != undefined" [showButton]="false">
                <app-advanced-title>
                    <div class="df aic gap5">
                        {{purchase.num_purchase}}
                        <app-invoice-status [showText]="false" [status]="purchase.state"
                            [obj]="purchase.tagLabel"></app-invoice-status>
                    </div>
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{purchase.typeName}}
                </app-advanced-subtitle>

                <app-advanced-heading>
                    {{purchase.created_at?.dayMonthYearFormat()}}
                </app-advanced-heading>

                <app-advanced-main-actions>
                    <button mat-flat-button color="primary" class="one-line" (click)="gotoPay()" [disabled]="isPaid">
                        <mat-icon>paid</mat-icon>
                        Pagar
                    </button>
                    <app-documentation-pdf-menu [purchase]="purchase"></app-documentation-pdf-menu>
                </app-advanced-main-actions>
            </app-advanced-details>

            <app-advanced-details-footer *ngIf="purchase" [showTitle]="false">
                <app-footer-right>
                    <div class="df div_contact">
                        <div class="contact_container">
                            <app-contact-client-rectangle [client]="purchase.contact"
                                *ngIf="purchase.contact;"></app-contact-client-rectangle>
                        </div>
                        <div><app-vehicle-rectangle *ngIf="purchase.vehicle"
                                [vehicle]="purchase.vehicle"></app-vehicle-rectangle></div>
                    </div>
                </app-footer-right>
            </app-advanced-details-footer>
        </app-card>
    </div>
    <div class="payments">
        <app-card class="card-pay" [contentLoaded]="loaded">
            <app-card-subtitle [first]="true">Pagos</app-card-subtitle>
            <app-invoice-movements [movements]="purchase?.last_movement!" [isTotalPay]="true"
                (onDeleteMovement)="deleteLastPayment($event)" [showLastCircle]="false">
            </app-invoice-movements>
        </app-card>
    </div>
    <div class="totals">
        <app-card [contentLoaded]="loaded">
            <app-card-subtitle [first]="true">Desglose</app-card-subtitle>
            <div class="total">
                <strong>Total: <span class=" ml5 fw500" money [val]="purchase?.total"></span></strong>
            </div>
        </app-card>
    </div>
