import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { InvoiceDialogComponent } from '../invoice-dialog.component';

@Component({
    selector: 'app-group-card-invoice-group',
    templateUrl: './group-card-invoice-group.component.html',
    styleUrls: ['./group-card-invoice-group.component.css'],
    standalone: false
})
export class GroupCardInvoiceGroupComponent implements OnInit {

  @Input() g!: M_GroupTask;
  @Input() disabled: boolean = false;
  @Input() preSelected?: boolean;
  @Input() invoiceClient! : M_Contact | undefined;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatCheckbox) checkbox?: MatCheckbox;
  disabledMessage = "Solo se pueden facturar intervenciones del mismo tipo"


  constructor(@Inject(InvoiceDialogComponent) public parent : InvoiceDialogComponent) { }

  ngOnInit(): void {
    if (this.g.getTotalBreakdown(this.invoiceClient).total < 0){
      this.preSelected = undefined;
      this.disabledMessage = "No se pueden facturar intervenciones con un total negativo" 
      this.disabled = true;
    }
  }

  ngOnChanges(){   
  }

  ngAfterViewInit(){
    if (this.preSelected != undefined && this.preSelected && this.checkbox) {
      this.setSelected(true);
    }
  }

  unSelect() {
    this.setSelected(false);
   
  }

  select() {
    
    if (!this.disabled) {
      this.setSelected(true);

    }
  }

  switchSelected() {
    if (!this.checkbox || this.disabled) { return; }
    this.checkbox.toggle();
    this.OnChange.emit();
  }

  setSelected(selected: boolean) {
    if (!this.checkbox) { return; }
    this.checkbox.checked = selected;
    this.OnChange.emit();
  }

  get selected() {
    if (!this.checkbox) { return; }
    return this.checkbox.checked;
  }

}
