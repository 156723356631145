import { Component, Input } from '@angular/core';
import { SotreHouseIcon } from '../../enums/SotreHouseLord';

@Component({
    selector: 'app-storehouse-lord-icon',
    templateUrl: './storehouse-lord-icon.component.html',
    styleUrls: ['./storehouse-lord-icon.component.css'],
    standalone: false
})
export class StorehouseLordIconComponent {
  lordIconsEnum = SotreHouseIcon;
  @Input({ required: true }) lordIcon!: SotreHouseIcon;
  @Input() iconClass?: string;
  constructor() { }
}
