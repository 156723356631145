import { Component, Input, OnInit } from '@angular/core';
import { CustomLog } from '../../custom-classes/CustomLog';
import { DeveloperService } from '../../services/developer.service';

@Component({
    selector: 'lib-error-viewer',
    templateUrl: './error-viewer.component.html',
    styleUrls: ['./error-viewer.component.css'],
    standalone: false
})
export class ErrorViewerComponent implements OnInit {

  @Input() er!: CustomLog[];
  constructor(private developerS: DeveloperService) { }
  ngOnInit(): void { }
  clear() { this.developerS.onClearError.next(true) }
}
