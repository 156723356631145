import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CheckBoxFilter } from '../../../custom-classes/Filter';

@Component({
    selector: 'app-checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    styleUrls: ['./checkbox-filter.component.css'],
    standalone: false
})
export class CheckBoxFilterComponent {
  @Input() filter! : CheckBoxFilter;
  constructor(private chdRef: ChangeDetectorRef) { }
  clear() {
    this.filter.checked = null;
    this.chdRef.detectChanges();
  }
}
