import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { M_Center } from 'src/app/models/M_Center';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
    selector: 'app-centers',
    templateUrl: './centers.component.html',
    styleUrls: ['./centers.component.css'],
    standalone: false
})
export class CentersComponent {

  @ViewChild(MatTabGroup, { static: true }) tabsGroup!: MatTabGroup;
  loaded = false;
  companyCenters: M_Center[] = []

  constructor(public companyS: CompanyService, public responsiveS: ResponsiveService, private chdRef: ChangeDetectorRef) { }

  createCenter(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }


  initTab(centers: M_Center[]) {
    this.companyCenters = centers;
    this.chdRef.detectChanges();
    this.tabsGroup.selectedIndex = 0;
    this.loaded = true;
  }


  onCreateCenter(c: M_Center) {
    this.companyCenters.push(c)
    this.chdRef.detectChanges();
    const tabsLength = this.tabsGroup._tabs.length; 
    this.tabsGroup.selectedIndex = tabsLength - 2;
  }

}
