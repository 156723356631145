import { Component } from '@angular/core';

@Component({
    selector: 'app-advanced-subtitle',
    template: `<ng-content></ng-content>`,
    standalone: false
})
export class AdvancedSubtitleComponent {

}
