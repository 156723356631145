import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { plateValidator, softPlateValidator } from '../../validators/plate-validator';
import { BaseInput } from './base-input';


/**
 * [Component]
 * Input para introducir una matrícula.
 * Si se introduce un valor que no es una matrícula, se muestra un error automáticamente.
 */

@Component({
    selector: 'app-plate-input',
    styleUrls: [],
    template: `<div class="{{class_}}" [formGroup]="form">
              <mat-form-field appearance="outline">
                  <mat-label>{{label ? label : 'Matrícula'}}</mat-label>
                  <input  matInput 
                          formControlName="{{formCName}}" 
                          [required]="required" 
                          [disabled]="disabled" 
                          [attr.autocomplete]="disableAutocomplete ? 'off' : null"
                          [placeholder]="placeHolder? placeHolder : 'Introduce la matrícula'"
                          (input)="changeToUppercase()"
                          (focusout)="onFocusOut.emit()">
                  <mat-error *ngIf="hasErrors()">
                          {{isLowerCase() ? 
                          'La matrícula no puede contener minúsculas' : 
                          'Introduce una matrícula correcta'}}    
                  </mat-error>
              </mat-form-field>
            </div>`,
    standalone: false
})
export class PlateInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'plateInput ' + this.classSelector;
  /** If the input allow more o less combinations */
  @Input() strict: boolean = true;
  constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  override ngOnInit(): void {
    this.forceUppercase = true;

    if (this.required) {
      this.control?.addValidators(Validators.required);
    }

    if (this.strict) {
      this.control?.setValidators(plateValidator());
    }
    else {
      this.control?.addValidators(softPlateValidator())
    }

  }

  isLowerCase() {
    return this.control?.getError('lowercase');
  }
}
