<div *ngIf="userRole" class="big-mamma-suggestions">
    <div [ngSwitch]="userRole">

        <!-- ADMIN -->
        <div *ngSwitchCase="RE.ADMIN">
            <div [ngTemplateOutlet]="adminOrGestor"></div>
        </div>

        <!-- GESTOR -->
        <div *ngSwitchCase="RE.GESTOR">
            <div [ngTemplateOutlet]="adminOrGestor"></div>
        </div>

        <!-- ADMIN OR GESTOR TEMPLATE -->
        <ng-template #adminOrGestor>

            <button mat-stroked-button color="primary" [go]="v.invoices"
                [param]="{ filter: BPFE.INVOICE_STATE, filtervalue: 0, filter1: BPFE.INVOICE_STATE, filtervalue1: 1 }">
                <mat-icon>receipt_long</mat-icon>
                Facturas por cobrar
            </button>

            <span [ngTemplateOutlet]="facturasPorPagar"></span>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon>contacts</mat-icon>
                Clientes con facturas pendientes
            </button> -->

            <span [ngTemplateOutlet]="orHoy"></span>

            <button mat-stroked-button color="primary" [go]="v.or" [param]="{ filter: OPFE.OR_STATUS, filtervalue: 0 }">
                <mat-icon>build</mat-icon>
                OR por cerrar
            </button>

            <button mat-stroked-button color="primary" [go]="v.settings" [param]="{ tab : SETT.MODULES }">
                <mat-icon>view_in_ar</mat-icon>
                {{f.integrations? 'Módulos e integraciones' : 'Módulos'}}
            </button>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon>cake</mat-icon>
                Clientes que esta semana es cumpleaños
            </button> -->

            <app-free-month></app-free-month>

        </ng-template>

        <!-- ADVISER -->
        <div *ngSwitchCase="RE.ADVISER">


            <span [ngTemplateOutlet]="orHoy"></span>

            <button mat-stroked-button color="primary" [go]="v.appointments"
                [param]="{ filter: APPFE.APPOINTMENT_DAY, filtervalue: 0 }">
                <mat-icon>event</mat-icon>
                Citas para hoy
            </button>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                OR fuera de plazo
            </button> -->

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                OR con stock comprometido
            </button> -->

            <button mat-stroked-button color="primary" [go]="v.cargataller">
                <mat-icon>equalizer</mat-icon>
                Consultar carga de taller
            </button>

            <button mat-stroked-button color="primary" [go]="v.createappointment">
                <mat-icon>event</mat-icon>
                Crear cita
            </button>

            <!-- Not permissions-->
            <!-- <button mat-stroked-button color="primary" [go]="v.createRecambiosBudget">
                <mat-icon>calculate</mat-icon>
                Crear presupuesto
            </button> -->

            <button mat-stroked-button color="primary" [go]="v.createOr">
                <mat-icon>build</mat-icon>
                Crear OR
            </button>

            <button mat-stroked-button color="primary" [go]="v.or"
                [param]="{ filter: OPFE.OR_GROUP_STATUS, filtervalue: 1 }">
                <mat-icon>build</mat-icon>
                OR por facturar
            </button>

            <span [ngTemplateOutlet]="facturasRecambiosPorCobrar"></span>

            <span [ngTemplateOutlet]="facturasPorPagar"></span>

            <button mat-stroked-button color="primary" [go]="v.balance" [param]="{ filter: 2, filtervalue: 0}">
                <mat-icon>balance</mat-icon>
                Balance de hoy
            </button>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Clientes con motos que van a pasar ITV próximo mes
            </button> -->

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Clientes con mas de un año sin venir a taller
            </button> -->

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Clientes que esta semana es su cumpleaños
            </button> -->

        </div>

        <!-- COMERCIAL -->
        <div *ngSwitchCase="RE.COMERCIAL">

            <button mat-stroked-button color="primary" [go]="v.garage"
                [param]="{ groupBy : 2, filter: 6, filtervalue: 0, filter1: 6, filtervalue1: 30}">
                <mat-icon>garage</mat-icon>
                De 0 a 30 días en stock
            </button>

            <button mat-stroked-button color="primary" [go]="v.garage"
                [param]="{ groupBy : 2, filter: 6, filtervalue: 30, filter1: 6, filtervalue1: 60}">
                <mat-icon>garage</mat-icon>
                De 30 a 60 días en stock
            </button>

            <button mat-stroked-button color="primary" [go]="v.garage"
                [param]="{ groupBy : 2, filter: 6, filtervalue: 60, filter1: 6, filtervalue1: 180}">
                <mat-icon>garage</mat-icon>
                De 60 a 180 días en stock
            </button>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon>garage</mat-icon>
                Vehículos con mas de 180 días en stock
            </button> -->

            <!-- Los presupuestos de comercial no se pueden facturar -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Presupuestos cerrados sin facturar
            </button> -->

            <span [ngTemplateOutlet]="facturasRecambiosPorCobrar"></span>

            <span [ngTemplateOutlet]="facturasPorPagar"></span>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Clientes que esta semana es su cumpleaños
            </button> -->

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Clientes que se les ha vendido una moto en el último año
            </button> -->

        </div>

        <!-- RECAMBISTA -->
        <div *ngSwitchCase="RE.RECAMBISTA">

            <button mat-stroked-button color="primary" [go]="v.albaranes" [param]="{filter :0, filtervalue : 0}">
                <mat-icon>assignment</mat-icon>
                Albaranes por cerrar
            </button>

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Productos con faltas
            </button> -->

            <!-- Not possible -->
            <!-- <button mat-stroked-button color="primary">
                <mat-icon></mat-icon>
                Albaranes con stock comprometido
            </button> -->

            <button mat-stroked-button color="primary" [go]="v.createRecambiosBudget">
                <mat-icon>calculate</mat-icon>
                Crear presupuesto
            </button>

            <button mat-stroked-button color="primary" [go]="v.createEditAlbaran">
                <mat-icon>assignment</mat-icon>
                Crear albarán
            </button>

            <button mat-stroked-button color="primary" [go]="v.createOrder">
                <mat-icon>local_shipping</mat-icon>
                Crear pedido
            </button>

            <button mat-stroked-button color="primary" [go]="v.createStoreHouseEntry">
                <mat-icon>archive</mat-icon>
                Crear entrada de almacén
            </button>

            <button mat-stroked-button color="primary" [go]="v.orders" [param]="{filter :0, filtervalue : 0}">
                <mat-icon>local_shipping</mat-icon>
                Pedidos pendientes de entrar
            </button>

            <button mat-stroked-button color="primary" [go]="v.albaranes" [param]="{filter :0, filtervalue : 1}">
                <mat-icon>assignment</mat-icon>
                Albaranes por facturar
            </button>


            <span [ngTemplateOutlet]="facturasRecambiosPorCobrar"></span>

            <span [ngTemplateOutlet]="facturasPorPagar"></span>

        </div>

        <!-- DEFAULT CASE -->
        <div *ngSwitchDefault></div>

    </div>
</div>




<!-- Shared buttons -->
<ng-template #facturasRecambiosPorCobrar>
    <button mat-stroked-button color="primary" [go]="v.invoices"
        [param]="{ filter: BPFE.INVOICE_STATE, filtervalue: 0, filter1: BPFE.INVOICE_STATE, filtervalue1: 1, filter2 : 4, filtervalue2 : 0 }">
        <mat-icon>receipt_long</mat-icon>
        Facturas de recambios por cobrar
    </button>
</ng-template>

<ng-template #facturasPorPagar>
    <button mat-stroked-button color="primary" [go]="v.purchases"
        [param]="{ filter: 1, filtervalue: 0, filter1: 1, filtervalue1: 1 }">
        <mat-icon>shopping_basket</mat-icon>
        Facturas por pagar
    </button>
</ng-template>

<ng-template #orHoy>
    <button mat-stroked-button color="primary" [go]="v.or" [param]="{ filter: OPFE.OR_DELIVEY, filtervalue: 0 }">
        <mat-icon>build</mat-icon>
        OR para hoy
    </button>
</ng-template>