<app-page-structure #ps [pageTitle]="'Marcas'" [masterClass]="undefined" [autocompleteID]="'marca'"
    searchLabel="Buscar marca" listTitleText="Listado de marcas" [data]="[]"
    [displayedHeader]=" ['Nombre',      'Tipo vehículo',    'Fecha creación']"
    [displayedColumns]="['name',        'type_',            'created_at']" 
    [specialText]="     [ undefined,    typeName,           undefined]"
    [preIcon]="         [ typeVehicle,  undefined,          undefined]"
    [quickFilter]="quickFilter"
    [filter]="f" [filters]="filtersToShow"
    [isNoPointerTable]="true"
    (onclickRow)="onDialogRef($event)"
    [goBackCustom]="{
        text:'Listado vehículos',
        view:v.garage
    }"
     [createButton]="
        {
            text : 'Crear marca' , 
            icon : 'style',
            tooltip: 'Todavía no disponible 🕒',
            disabled:true
        }"  [quickFilter]="quickFilter"
    (onClickCreateButton)="onDialogRef($event)">
</app-page-structure>