<div class="container advanced-details-container" *ngIf="workloadData != undefined">

    <div class="top">

        <!-- Normal workload-->
        <ng-container *ngIf="!sessionS.isSharedAccessView; else multiUser">
            <!-- LINE 1-->
            <div class="first-line df jcsb w100">
                <div>
                    <app-view-title>Carga de taller</app-view-title>
                    <app-card-subtitle [noMarginBottom]="true" class="first-line-title"
                        [first]="true">{{bigRefByDay(matSelect.value)}}</app-card-subtitle>
                    <p *ngIf="companyS.userCenter" class="c_t2 center-name-title">{{companyS.userCenter.name}}</p>
                </div>
                <div class="first-line-day df aic">
                    <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, false)"
                        [matTooltip]="getTooltipByFilter(matSelect.value, false)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <mat-form-field appearance="outline" class="periodFilter onboarding-workload-change-view">
                        <mat-select #matSelect [value]="selectedPeriod" (valueChange)="onSelectedPeriodChange($event)">
                            <mat-option [value]="pe.WEEK">Semana</mat-option>
                            <mat-option [value]="pe.MONTH">Mes</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button (click)="arrowNextByFilter(matSelect.value, true)"
                        [matTooltip]="getTooltipByFilter(matSelect.value, true)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>

            <!-- LINE 2-->
            <div class="second-line df aic jcsb w100">
                <div class="df aic">
                    <mat-form-field appearance="outline" class="second-line-searcher">
                        <mat-label>Buscar</mat-label>
                        <input #searchInput matInput placeholder="Buscar"
                            (keyup)="applySearchFilter(searchInput.value)">
                        <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
                        <app-autocomplete (click)="applySearchFilter(searchInput.value)">
                        </app-autocomplete>
                    </mat-form-field>
                    
                </div>
                <!-- <div class="custom-legend notInfo">
                    <mat-icon class="vim"  [filled]="true">info</mat-icon>
                    <p>
                        <mat-icon class="c_b custom-icon" [filled]="true">fiber_manual_record</mat-icon>
                        Festivo empresa
                    </p>
                </div> -->
                <div class="second-line-filters">
                    <div class="df">
                        <app-tagfilter *ngIf="workloadData.data.switch" [label]="false" [filter]="sectionFilter"
                            (change)="changeView($event == 0)">
                        </app-tagfilter>
                        <app-filters class="onboarding-workload-filters" [filters]="currentFilters"></app-filters>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Shared user workload-->
        <ng-template #multiUser>
            <div class="df fdc aic jcc">
                <button class="mla" mat-flat-button color="primary" (click)="sessionS.logOut()">
                    <mat-icon>logout</mat-icon>
                    Salir
                </button>
                <div class="mt10 df w100 jcc">
                    <div class="who-are-you">
                        <app-view-title>¿Quién eres?</app-view-title>
                        <app-circular-letter [contentLoaded]="shared_user != undefined" [checkIsMe]="false"
                            (click)="changeSharedUser(signingComponent)" class="cp" [showChangeIcon]="true"
                            [showTooltip]="false" [showFullName]="false" [big]="true"
                            [user]="shared_user"></app-circular-letter>
                    </div>
                    <div class="signing-container">
                        <app-card [noStyle]="true" [contentLoaded]="shared_user != undefined">

                            <div eForm-wrapper
                                [ngClass]="{'mb10' : wtimer.timerS.refOr != undefined, 'dn_i' : wtimer.timerS.refOr == undefined}">
                                Trabajando en:
                                <app-working-or-timer #wtimer [goAction]="false"
                                    (onclickTimer)="onClickTimer($event)"></app-working-or-timer>
                            </div>

                            <div eForm-wrapper>
                                <app-signing-timer #signingComponent></app-signing-timer>
                                <app-today-signing-breakdown class="todaybreakdowncomponent df jcc"
                                    [signingData]="signingComponent.signingS.signingData"></app-today-signing-breakdown>
                            </div>

                        </app-card>
                    </div>
                </div>

                <div class="df jcc aic">
                    <button mat-icon-button (click)="arrowNextByFilter(pe.WEEK, false)"
                        [matTooltip]="getTooltipByFilter(pe.WEEK, false)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    Semana
                    <button mat-icon-button (click)="arrowNextByFilter(pe.WEEK, true)"
                        [matTooltip]="getTooltipByFilter(pe.WEEK, true)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                    <app-filters [filters]="currentFilters"></app-filters>
                </div>

            </div>

        </ng-template>

    </div>

    <!-- CALENDAR -->
    <div class="calendar">
        <app-drag-and-drop-grid #dadg (onModifyAppointment)="onModifyAppointment.emit($event)"
            (onDragFinished)="onDragFinished.emit()" (deliveryWarn)="deliveryWarn.emit($event)"
            [periodEnum]="matSelect?.value || pe.WEEK" [refDay]="refDay"></app-drag-and-drop-grid>
    </div>
</div>

<mat-menu #create="matMenu">
    <button *ngIf="workloadData && workloadData.data.or.views.createOr" mat-menu-item
        (click)="routerS.goTo(workloadData.data.or.views.createOr)">OR</button>
</mat-menu>