import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MasterStorageService } from './master-storage-service';
import { IClassSearcher } from 'src/app/interfaces/IClassSearcher';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { M_User } from 'src/app/models/M_User';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { CreateMasterInDialogService } from 'src/app/services/create-master-in-dialog.service';
import { Views } from 'src/app/custom-classes/View';
import { RouterService } from 'src/app/services/router.service';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ContactEnum } from 'src/app/enums/ContactEnum';
import { Params } from '@angular/router';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';
import { M_Model } from 'src/app/models/M_Model';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';

/** Max options to show */
const MAX_OPTIONS = 100;

export interface ClassSearcherForm {
  value: FormControl<IClassSearcher<any> | undefined | null>;
  hidden: FormControl<IClassSearcher<any> | undefined | null>;
}

@Component({
    selector: 'app-class-searcher',
    templateUrl: './class-searcher.component.html',
    styleUrls: ['./class-searcher.component.css'],
    standalone: false
})
export class ClassSearcherComponent<T extends IClassSearcher<T>> implements OnInit, OnChanges {


  MAX_OPTIONS = MAX_OPTIONS;
  CT = ComercialVehicleType;

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) autoComplete!: MatAutocomplete;

  loadedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**Event emitters*/
  @Output() onCreated: EventEmitter<T> = new EventEmitter();
  @Output() onSelect: EventEmitter<T> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onLoad: EventEmitter<any> = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<void>();
  @Input({ required: true }) createData!:
    {
      text: string,
      by: "dialog" | Views | [Views, Params],
      forceType?: ContactEnum
    } | undefined;

  @Input({ required: true }) masterClass!: T;
  @Input() width100 = true;
  @Input() searchPlaceHolder: string = "Buscar";
  @Input() noSelectionPlaceHolder: string = "Ningún item selecionado";
  @Input() required: boolean = true;
  @Input() selectedOnLoad: number | { id: number | undefined, event?: { emitEvent: boolean } } | undefined;
  @Input() canChange = true;
  @Input() hint: string | { hint: string, class: string } | undefined;
  @Input() showLeftNumber = true;
  @Input() separator: "-" | "|" | undefined;
  @Input() disabled = false;
  @Input() formInitEmit = true;
  @Input() idNumber: number | undefined;
  @Input() idNumberSerie: number | undefined;
  @Input() disableIf?: (obj: T) => boolean;

  @Input() form_?: UntypedFormGroup;
  @Input() formCname?: string;
  @Input() cssClass?: string;

  /** Set custom data to the class searcher */
  @Input() customData: T[] | undefined = undefined;

  allData: T[] = []
  options: T[] = [];
  selected: T | undefined;
  loaded = false;
  form: FormGroup<ClassSearcherForm>;

  constructor(private ms: MasterStorageService, public companyS: CompanyService, public createMasterS: CreateMasterInDialogService, private routerS: RouterService) {

    this.form = new FormGroup<ClassSearcherForm>({
      value: new FormControl<IClassSearcher<T> | undefined | null>(undefined, this.customValidator()),
      hidden: new FormControl<IClassSearcher<T> | undefined | null>(undefined), // No need for validators if not provided
    })

    /** Form Test */
    this.form.get('value')?.valueChanges.subscribe(v => {
      if (this.form_ && this.formCname) {
        this.form_.get(this.formCname)!.patchValue(v?.itemId)
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) { this.disable() }
      else { if (!this.selected) { this.form.enable(); } }
    }
  }

  ngOnInit(): void {
    if (this.required) {
      this.form.addValidators(Validators.required);
    }
    if (this.customData == undefined) {
      this.getData().then(res => {
        this.initDataAndOptions(res);
        this.dataLoaded.emit();
        this.onLoad.emit();
      })
    }
    else {
      this.initDataAndOptions(this.customData);
      this.dataLoaded.emit();
      this.onLoad.emit();
    }
  }

  /** Init the options array */
  private initDataAndOptions(data: T[]) {
    this.allData = data;
    this.options = data;
    this.loaded = true;
    this.loadedChange.emit(this.loaded);
    if (this.valueOnLoad) {
      if (typeof this.valueOnLoad == "number") { this.select(this.valueOnLoad, { emitEvent: this.formInitEmit }); }

      else { this.select(this.valueOnLoad.id, this.valueOnLoad.event); }
      this.selectedOnLoad = undefined;
    }
    else if (this.input && this.input.nativeElement.value) {
      this.refresh(this.input.nativeElement.value)
    }
    this.sortDisableds();
  }

  /** Enpoint call to the endpoint passed by param */
  private getData() {
    return new Promise<any[]>(resolve => {
      this.ms.getMaster(this.masterClass).then(res => {
        resolve(res);
      })
    })
  }
  /** When the user change the selection with the input */
  onSelectionChange(event: MatOptionSelectionChange<T>) {
    const option = event.source.value;
    if (!this.getDisableIF(option) && event.isUserInput) {
      this.select(option);
    }
  }
  /** Set a master object by ID */
  private selectById(id: number, event?: { emitEvent: boolean }) {
    if (id == undefined) { return; }
    if (this.loaded) {
      this.allData.forEach(option => {
        if (option.itemId == id) {
          this.select(option, event);
        }
      })
    }
    else {
      this.selectedOnLoad = {
        id: id,
        event: event
      }
    }
  }

  /** Select option */
  select(option: T | number | undefined, event?: { emitEvent: boolean }) {

    let emit = event  == undefined ? true : event.emitEvent;

    if (option == undefined) { return }
    /** Strings happens... Parse the string to number */
    if (typeof option == "string") {
      console.warn("🔎 String ? Number please");
      let o = option as string;
      let optionToNumber = o.getNumber();
      if (optionToNumber) {
        option = optionToNumber
      }
    }
    if (typeof option == "number") {
      
        this.selectById(option, event);
    }
    else {
      console.log("🔎 Selected: ", option.getInputText());
      this.selected = option;
      this.form.patchValue({
        "value": option
      }, { emitEvent: emit })      
      this.disable();
      if (emit) {
        this.emitSelect(option);
      }
      this.sortDisableds();
    }
  }

  /** Remove the selected value */
  remove() {
    console.log("🔎 Remove");
    this.form.enable();
    this.selected = undefined;
    this.form.patchValue({
      'value': undefined
    })
    this.autoComplete.options.forEach(option => option.deselect());
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
    this.refresh(); 
    this.onRemove.emit(undefined);
  }

  /** Disable the input */
  disable() {
    this.form.get('value')!.disable()
  }

  /** Disable a option according to 'disableIf' parameter funcion */
  getDisableIF(obj: T) {
    if (this.disableIf) {
      return this.disableIf(obj)
    }
    return false;
  }

  focus() {
    this.input.nativeElement.focus();
  }

  sortDisableds() {
    this.options.sort((a, b) => {
      let adisabled = this.getDisableIF(a);
      let bdisabled = this.getDisableIF(b);
      return (adisabled === bdisabled) ? 0 : adisabled ? 1 : -1;
    })
  }

  /** The displayed text on the input value */
  getInputText(val: T) {
    return val == undefined ? "" : val.getInputText();
  }

  refresh(val?: string) {
    this.options = []
    if (val) {
      for (let i = 0; i < this.allData.length; i++) {
        let currentObj = this.allData[i];
        if (currentObj.defaultSearchFilter(val)) {
          this.options.push(currentObj);
        }
      }
    }
    else {
      this.options = this.allData;
    }
  }

  /** Input validator */
  customValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasError = this.required ? !this.selected : (control.value != undefined && control.value != "" && !this.selected);
      return hasError ? { required: { value: control.value } } : null;
    };
  }

  isUser(v: any): v is M_User {
    return v instanceof M_User;
  }

  isVechile(v: any): v is M_Vehicle {
    return v instanceof M_Vehicle;
  }

  addAndSelectNewOption(v: T) {
    this.allData.push(v);
    this.select(v);
  }

  emitSelect(obj: T) {
    if (this.masterClass.onlyCopies) {
      /** If not this way, problems with quantity */
      var emitCopyOf = this.masterClass.createNew(obj)
      this.onSelect.emit(emitCopyOf);
    }
    else {
      this.onSelect.emit(obj);
    }

  }

  /** Create new T function */
  createNew(event: MatOptionSelectionChange<T>) {
    if (!event.isUserInput) { return; }
    if (this.createData) {
      const by = typeof this.createData.by;
      if (typeof by != "string") {
        if (Array.isArray(by)) {
          this.routerS.goWithQueryParams(by[0], by[1]);
        }
        else {
          this.routerS.goTo(by);
        }
      }
      else {
        if (this.masterClass instanceof M_Contact) {
          this.createMasterS.createClient(this as any, this.createData.forceType)?.afterClosed().subscribe(res => {
            if (res instanceof M_Contact) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Vehicle) {
          this.createMasterS.createVehicle(this as any)?.afterClosed().subscribe(res => {
            if (res instanceof M_Vehicle) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Concept) {
          this.createMasterS.createConcept(this as any)?.afterClosed().subscribe(res => {
            if (res instanceof M_Concept) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Product) {
          this.createMasterS.createProduct(this as any)?.afterClosed().subscribe(res => {
            if (res instanceof M_Product) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Brand) {
          this.createMasterS.openCreateBrandDialog(this as any)?.afterClosed().subscribe(res => {
            if (res instanceof M_Brand) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Serie) {
          this.createMasterS.openCreateSerieDialog(this as any, this.idNumber ? this.idNumber : undefined)?.afterClosed().subscribe(res => {
            if (res instanceof M_Serie) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else if (this.masterClass instanceof M_Model) {
          this.createMasterS.openCreateModel(this as any, this.idNumber ? this.idNumber : undefined, this.idNumberSerie ? this.idNumberSerie : undefined)?.afterClosed().subscribe(res => {
            if (res instanceof M_Model) {
              this.onCreated.emit(res as any);
            }
          })
        }
        else {
          console.warn("🔎 Create by dialog not implemented")
        }
      }
    }
  }
  public updateOptions(data: T[]) {
    this.initDataAndOptions(data);
  }
  /** Current form value */
  get formValue() {
    let realFormValue: number | undefined = undefined
    if (this.form_ && this.formCname) {
      let aux = this.form_.get(this.formCname)?.value
      if (typeof aux == "number") {
        realFormValue = aux;
      }
      try {
        if (aux && "itemId" in aux) {
          realFormValue = aux.itemId;
        }
      }
      catch (e: any) { }

    }
    return realFormValue;
  }

  /** The selectedOnLoad or the current form value */
  get valueOnLoad() { return this.formValue || this.selectedOnLoad; }
  get getHint() { return this.hint ? typeof this.hint == "string" ? this.hint : this.hint.hint : ""; }
  get getHintClass() { if (this.hint && typeof this.hint != "string") { return this.hint.class }; return ""; }

}