import { Component, Input, OnInit } from '@angular/core';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { ApiService } from 'src/app/services/Api/api.service';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { ViewPath } from 'src/app/app-routing.module';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { chassisAsyncValidator } from '../../validators/chassisAsyncValidator';

@Component({
    selector: 'app-chassis-input',
    templateUrl: './chassis-input.component.html',
    styleUrls: ['./chassis-input.component.css'],
    standalone: false
})
export class ChassisInputComponent implements OnInit {

  MAX_LENGTH = 17;

  v = ViewPath;
  @Input({ required: true }) form!: UntypedFormGroup;
  @Input({ required: true }) currentVehicle: M_Vehicle | undefined;
  @Input({ required: true }) required!: boolean;

  constructor(private apiS: ApiService, private existsS: AlreadyExistsService) { }

  ngOnInit(): void {
    let control = this.control;
    if (control) {
      if (this.required) { control.addValidators(Validators.required); }
      control.addValidators(Validators.pattern('[A-Z0-9]{17}$|^[A-Z0-9]{7}'));
      if (!control.asyncValidator) {
        control.addAsyncValidators(chassisAsyncValidator(() => this.getCurrentVehicle(), this.apiS, this.existsS))
      }
    }
  }

  getCurrentVehicle() {
    return this.currentVehicle;
  }

  get control() {
    return this.form.get('chassis');
  }

  get controlLen() {
    let v = this.control?.value;
    if (typeof v == "number") { return v.toString().length }
    if (typeof v == "string") { return v.length }
    return 0;
  }

  upperCase() {
    const value = this.control?.value.toUpperCase();
    this.control?.setValue(value, { emitEvent: false });
  }

}
