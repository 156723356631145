import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomFile } from '../../custom-classes/CustomFile';

/** Dialog that show a image preview*/
@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.css'],
    standalone: false
})
export class ImagePreviewComponent {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code == "ArrowRight") { this.next() }
    else if (event.code == "ArrowLeft") { this.previous() }
  }

  /** The image container */
  @ViewChild('imgContainer') imgContainer!: ElementRef;
  /** The image of the preview */
  @ViewChild('img') img!: ElementRef;

  /** The scale increment amount */
  readonly scaleIncrement = 0.5;
  /** Max zoom times (4 times) */
  readonly maxScale = 3;
  /** Min zoom out times (1 time) */
  readonly minScale = 0.5;

  /** Index of the current image */
  index = 0;
  /** Current scale */
  scale: number = 1;
  /** If the user is scrolling the image */
  scrolling = false;
  /** The scrolling start position of the user */
  dragStart = { x: 0, y: 0 }
  /** Native init sacle of the image */
  initSacle: { x: number, y: number | string } | undefined;

  constructor(public dialogRef: MatDialogRef<ImagePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: { selected: string | CustomFile, all: string[] | CustomFile[] },
    private chdRef: ChangeDetectorRef) {
    this.getStartingIndex();
  }

  /** Defines which image the user clicked and get the index */
  getStartingIndex() {
    for (let i = 0; i < this.data.all.length; i++) {
      if (this.data.all[i] == this.data.selected) {
        this.index = i;
      }
    }
  }

  /** Next image preview */
  next(e?: Event) {
    if (this.data.all.length > 1) {
      this.resetScaleStuff();
      if (this.index == this.data.all.length - 1) {
        this.index = 0;
      }
      else {
        this.index++;
      }

      this.chdRef.detectChanges();

      if (e) { this.preventDefault(e); }
    }
  }

  /** Previous image preview */
  previous(e?: Event) {
    if (this.data.all.length > 1) {
      this.resetScaleStuff();
      if (this.index == 0) {
        this.index = this.data.all.length - 1;
      }
      else {
        this.index--;
      }

      this.chdRef.detectChanges();

      if (e) { this.preventDefault(e); }
    }
  }

  /** When image is loaded, saves his initial height and width */
  saveInitImageSize() {
    if (this.img) {
      var imgScale = { x: this.img.nativeElement.width, y: this.img.nativeElement.height }
      if (imgScale.x > window.innerWidth) {
        this.initSacle = { x: window.innerWidth - 15, y: 'auto' }
      }
      else {
        this.initSacle = imgScale;
      }
    }
  }

  getYScale() {
    return typeof this.initSacle?.y == "number" ? this.initSacle.y * this.scale + "px;" : "auto;"
  }

  /** Reset all things related to scale */
  resetScaleStuff() {
    this.scale = 1;
    if (this.img) {
      this.initSacle = undefined;
      this.img.nativeElement.style.removeProperty('height');
      this.img.nativeElement.style.removeProperty('width');
    }
    this.chdRef.detectChanges();
  }

  /** When the user start scrolling the image */
  onGabStart(e: MouseEvent) {
    this.dragStart = { x: e.screenX, y: e.screenY }
    this.scrolling = true;
    this.preventDefault(e);
  }

  /** While the user is scrolling the image */
  onGabScrolling(event: MouseEvent) {
    var currenPos = { x: event.screenX, y: event.screenY }
    if (this.scrolling) {
      this.imgContainer.nativeElement.scrollLeft += (this.dragStart.x - currenPos.x) / 10;
      this.imgContainer.nativeElement.scrollTop += (this.dragStart.y - currenPos.y) / 10;
    }
  }

  /** When the user ends scrolling the image */
  onGrabEnd(e: Event) {
    this.scrolling = false;
    this.preventDefault(e);
  }

  /** Returns if the image container is overflowed by the image */
  isOverflown() {
    if (this.imgContainer) {
      var continer = this.imgContainer.nativeElement;
      return continer.scrollHeight > continer.clientHeight || continer.scrollWidth > continer.clientWidth;
    }
    return false;
  }

  /** Get the image by the index variable */
  getImage() {
    var img = this.data.all[this.index];
    return img instanceof CustomFile ? img.src : img;
  }

  /** Close the image preview */
  close(e: Event) {
    if (!this.scrolling) {
      this.dialogRef.close();
    }
    this.preventDefault(e);
  }

  /** Zoom in the image preview */
  zoomIn(e: Event) {
    if (!this.isMaxZoom) {
      this.scale += this.scaleIncrement;
    }
    this.preventDefault(e);
  }

  preventDefault(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

  get isMaxZoom() {
    return this.scale >= this.maxScale;
  }

  /** Zoom out the image preview */
  zoomOut(e: Event) {
    if (!this.isMinZoom) {
      this.scale -= this.scaleIncrement;
    }
    e.stopPropagation();
    e.preventDefault();
  }

  get isMinZoom() {
    return this.scale <= this.minScale;
  }

}
