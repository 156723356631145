import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-dialog-set',
    templateUrl: './dialog-set.component.html',
    styleUrls: ['./dialog-set.component.css'],
    standalone: false
})
export class DialogSetComponent {
  parentSubject: Subject<string> = new Subject();
  cardAnimation(value: string) {
    this.parentSubject.next(value);
  }
}
