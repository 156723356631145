<div class="product-line-parent-div"> <!-- Provisional responsive -->
    <table class="scrollable-table eina-table" [ngClass]="{'no-pointer' : !canModifyTable}">
        <thead>
            <tr>
                <th *ngIf="showSave" class="unsaved-action sticky-col"></th> <!-- Unsaved chagnes dot -->
                <th class="ref-header sticky-col" [ngClass]="{'is-first-row' :!showSave}">Marca</th>
                <th>Modelo</th>
                <th>Chassis</th>
                <th>Matrícula</th>
                <th>Coste</th>
                <th class="small-action"></th> <!-- Delete -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of vehicles | slice: (itemsPerPage ? (currentPage - 1) * itemsPerPage : 0) : (itemsPerPage ? currentPage * itemsPerPage : vehicles.length)"
                #vehicleRow [ngClass]="{'' : isHighlighted(vehicle, vehicleRow) }">

                <!-- Unsaved chagnes dot -->
                <td *ngIf="showSave" class="sticky-col unsaved-action">
                    <mat-icon class="unsavedDot" *ngIf="false" [filled]="true">circle</mat-icon>
                </td>

                <!-- Brand -->
                <td class="ref-header sticky-col" [ngClass]="{'is-first-row' :!showSave}">
                    <app-vn-vo-label *ngIf="vehicle.comercialType" [type]="vehicle.comercialType"></app-vn-vo-label>
                    <span *ngIf="vehicle.brand || vehicle.brand_name; else noData">
                        {{vehicle.brand?.name || vehicle.brand_name}}
                    </span>
                </td>

                <!-- Moduel -->
                <td>
                    <span *ngIf="vehicle.model  || vehicle.model_name; else noData">
                        {{vehicle.model?.name || vehicle.model_name}}
                    </span>
                </td>

                <!-- Chassis -->
                <td>
                    <span *ngIf="vehicle.chassis; else noData">
                        {{vehicle.chassis}}
                    </span>
                </td>

                <!-- Plate -->
                <td>
                    <span *ngIf="vehicle.license; else noData">
                        {{vehicle.license}}
                    </span>
                </td>

                <!-- Cost -->
                <td>
                    <!-- <span money [val]="vehicle.cost"></span> -->

                    <mat-form-field appearance="outline" class="small-form-field">
                        <input matInput [(ngModel)]="vehicle.cost" type="number">
                        <span matSuffix>€</span>
                    </mat-form-field>

                </td>

                <!-- Delete -->
                <td *ngIf="canModifyTable" class="cell-delete">
                    <button (click)="destroy(vehicle)" mat-icon-button [disabled]="!canDeleteVehicle(vehicle)">
                        <mat-icon [filled]="true">delete</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="pagination-controls" *ngIf="itemsPerPage && totalPages > 1">
        <button mat-icon-button (click)="prevPage()" [disabled]="currentPage === 1" class="btn-nav">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="page-info">{{currentPage}} de {{totalPages}}</span>
        <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages" class="btn-nav">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

</div>

<!-- This div contains all the actions or information that are not part of the table.-->
<div class="eina-table-bottom">

    <!-- Add vehicle searcher container -->
    <div #searchVehicleContainer></div>

    <!-- No vehicle message -->
    <ng-container *ngIf="canModifyTable && !vehicles.length && !searchers.length; else noVehicles">
        <div>
            <p class="c_t2">{{noVehicleMessage}}</p>
            <app-ctrl-space-shortcut [text]="'Atajo para agregar vehículo'"
                [combination]="'Mayús+Espacio'"></app-ctrl-space-shortcut>
        </div>
    </ng-container>

    <ng-template #noVehicles>
        <div *ngIf="!canModifyTable && !vehicles.length">
            <p class="c_t2">Sin vehiculos</p>
        </div>
    </ng-template>

    <!-- Table actions -->
    <div *ngIf="canModifyTable" class="table-line-bottom-acations">
        <div class="onboarding-invoice-add-vehicle">
            <button (click)="appendVehicleSearcher()" mat-stroked-button color="primary">
                <mat-icon [svgIcon]="'wheel_outline'" class="c_p"></mat-icon>
                Añadir vehiculo
            </button>
        </div>
    </div>
</div>

<ng-template #noData>
    <span class="c_t2">Sin datos</span>
</ng-template>