import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Contact } from 'src/app/models/M_Contact';

@Component({
    selector: 'app-all-product-providers',
    templateUrl: './all-product-providers.component.html',
    styleUrls: ['./all-product-providers.component.css'],
    standalone: false
})
export class AllProductProvidersComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public providers: M_Contact[]) { }
}
