import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Directive({
    selector: '[lessOr]',
    standalone: false
})
export class LessOrDirective implements OnInit {

  @Input('lessOr') lessOr!: number;

  constructor(private el: ElementRef<HTMLElement>, private responsiveS: ResponsiveService) {
    responsiveS.onResize.subscribe(val => {
      this.setDisplay(val <= this.lessOr)
    })
  }

  ngOnInit(): void {
    this.setDisplay(this.responsiveS.w <= this.lessOr);
  }

  setDisplay(display: boolean | undefined) {
    if (!display) {
      this.el.nativeElement.classList.add("dn_i")
    }
    else {
      this.el.nativeElement.classList.remove("dn_i")
    }
  }
}
