import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-panel-rectangle',
    templateUrl: './info-panel-rectangle.component.html',
    styleUrls: ['./info-panel-rectangle.component.css'],
    standalone: false
})
export class InfoPanelRectangleComponent {
  @Input({ required: true }) panel!: "warn" | "info";

  get icon() {
    switch (this.panel) {
      case 'warn':
        return "warning"
      case 'info':
        return "info"
    }
  }
}
