import { M_Contact } from "../models/M_Contact";
import { M_Product } from "../models/Products/M_Product";
import { M_User } from "../models/M_User";
import { M_Vehicle } from "../models/Vehicles/M_Vehicle";
import { M_Concept } from "../models/M_Concept";
import { ContactEnum } from "../enums/ContactEnum";
import { M_Serie } from "../models/M_Serie";
import { M_Model } from "../models/M_Model";
import { M_Brand } from "../models/M_Brand";

/** INTANCES FOR THE CLASS SERACHER COMPONENT */

/** CLIENT */
export const MASTER_CLIENT = new M_Contact({})
export const MASTER_CLIENT_MINIFIY = new M_Contact({})
export const MASTER_SERIE_BRANDMODEL = new M_Serie({})
export const MASTER_MODEL_BRANDMODEL = new M_Model({})
export const MASTER_BRAND_BRANDMODEL = new M_Brand({})
MASTER_CLIENT.minify = true;

/** CLIENT with Active Sales */
export const MASTER_C_CLIENT = new M_Contact({})
MASTER_C_CLIENT.activeSales = true

/** PROVIDER */
export const MASTER_PROVIDER = new M_Contact({})
MASTER_PROVIDER.type = ContactEnum.PROVIDER;

/** NORMAL VEHICLES */
export const MASTER_VECHILE = new M_Vehicle({});

/** ALL VEHICLES (NORMAL + VN/VO) */
export const MASTER_VECHILE_ALL = new M_Vehicle({});
MASTER_VECHILE_ALL.minify = true;

/** VN/VO VEHICLES */
export const MASTER_VN_VO = new M_Vehicle({});
MASTER_VN_VO.vnvoCall = true;

/** PRODUCT */
export const MASTER_PRODUCT = new M_Product({})

/** PRODUCTS WITH PROVIDERS */
export const MASTER_PRODUCT_PROVIDERS = new M_Product({})
MASTER_PRODUCT_PROVIDERS.onlyProviders = true;

/** USER */
export const MASTER_USER = new M_User({});
export const MASTER_USER_CENTER = new M_User({});
MASTER_USER_CENTER.onlyCenterUsers = true;

/** CONCEPTS */
export const MASTER_CONCEPT = new M_Concept({});