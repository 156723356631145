<form [formGroup]="form" eForm [dn_i]="isVOCreateForm">
    <app-card-subtitle [first]="true">{{requiredFieldsTitle ? requiredFieldsTitle : 'Datos básicos'}}</app-card-subtitle>
    <div class="onboarding-vn-vo-vehicle-required">
        <div eForm-wrapper>

            <ng-container *ngIf="!isVOCreateForm" [ngTemplateOutlet]="comercialSelector"></ng-container>

            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value, brandsModelSerie)">
                        <mat-option [value]="VT.bike">Moto</mat-option>
                        <mat-option [value]="VT.car">Coche</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container [ngTemplateOutlet]="plate" *ngIf="isVo"></ng-container>
                <app-chassis-input *ngIf="isVn" [form]="form" [currentVehicle]="vehicle"
                    [required]="false"></app-chassis-input>

            </div>

            <div eForm-section>
                <app-brands-models-series #brandsModelSerie [formVnVo]="true" [type]="selectedType" [filterAuto]="true"
                    [notRequiredSerie]="true" [form]="form">
                    <mat-form-field appearance="outline">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código versión">
                    </mat-form-field>
                </app-brands-models-series>
            </div>

            <div eForm-section> <mat-form-field appearance="outline">
                    <mat-label>PVP</mat-label>
                    <input type="number" min="0" matInput formControlName="price">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Coste compra</mat-label>
                    <input type="number" min="0" matInput formControlName="cost">
                </mat-form-field>
            </div>

            <div eForm-section>
                <mat-form-field appearance="outline" *ngIf="!isVo">
                    <mat-label>Impuesto matriculación</mat-label>
                    <mat-select formControlName="registration_tax">
                        <mat-option [value]="RTE.Zero"> {{registrationText(RTE.Zero)}}</mat-option>
                        <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                        <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                        <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                        <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IVA</mat-label>
                    <mat-select formControlName="tax" #iva [value]="21">
                        <mat-option [value]="0">0%</mat-option>
                        <mat-option [value]="4">4%</mat-option>
                        <mat-option [value]="10">10%</mat-option>
                        <mat-option [value]="21">21%</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            
            <div class="wrapper-of-wrapper df jcse">
                <ng-container *ngIf="form.get('price')?.value && form.get('cost')?.value; else putPrices">
                    <p class="nmb fw600">Margen bruto: <span class="fw500" [ngClass]="{'c_r' : benefit < 0}" money [val]="benefit"></span> </p>
                    <p class="nmb fw600">IEDMT: <span class="fw500" money [val]="iedmtOnPB"></span></p>
                    <p class="nmb fw600">IVA: <span class="fw500" money [val]="ivaOnPB"></span></p>
                </ng-container>
            </div>

            <ng-template #putPrices>
                <p class="c_t2 nmb">Establece el PVP y el coste para ver el margen bruto</p>
            </ng-template>

        </div>

        <ng-container *ngIf="isVn && isCreate">
            <app-card-subtitle>Propietario del vehiculo</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <app-class-searcher [showLeftNumber]="false" #ownerProvider [masterClass]="client" [allData]=""
                    [width100]="true" searchPlaceHolder="Propietario o proveedor" [extendedInfo]="false" [form_]="form"
                    [formCname]="'client_id'" [required]="isVn && isCreate" [createData]="{
                        text : 'Nuevo contacto',
                        by : v.createContact
                    }">
                </app-class-searcher>
            </div>
        </ng-container>

    </div>

    <p></p>

    <mat-tab-group color="primary" mat-stretch-tabs="true" class="vehicle-tabs" [ngClass]="{'dn' : onlyRequired}">
        <!-- CONCEPTS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>playlist_add_check_circle</mat-icon>
                Conceptos <span *ngIf="getConcepts().length"> ({{getConcepts().length}})</span>
            </ng-template>
            <app-card-subtitle>Conceptos</app-card-subtitle>
            <app-concepts-line-table [concepts]="getConcepts()" [canModifyTable]="!isOnDialog" [isDialog]="isOnDialog"
                [showSave]="false" [isInfo]="true"></app-concepts-line-table>
        </mat-tab>

        <!-- FICHA -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>swap_driving_apps_wheel</mat-icon>
                Ficha
            </ng-template>
            <app-card-subtitle>Datos opcionales</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>
                    <ng-container [ngTemplateOutlet]="plate" *ngIf="isVn"></ng-container>
                </div>
                <app-vehicleform-ficha [form]="form" [showKm]="isVo" [showChassis]="isVo"
                    [currentVehicle]=""></app-vehicleform-ficha>
            </div>
        </mat-tab>


        <!-- MOTOR -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>construction</mat-icon>
                Motor
            </ng-template>
            <app-card-subtitle>Motor</app-card-subtitle>
            <app-vehicleform-motor [form]="form"></app-vehicleform-motor>
        </mat-tab>

    </mat-tab-group>
</form>

<!-- VO creation form -->
<div #VOCreateTemplate [dn_i]="!isVOCreateForm">

    <app-card-subtitle>Datos básicos</app-card-subtitle>

    <form eForm [formGroup]="newVOform">
        <div eForm-wrapper>
            <ng-container *ngIf="isVOCreateForm" [ngTemplateOutlet]="comercialSelector"></ng-container>
            <div eForm-section>
                <app-class-searcher class="w100" #VOClientSearcher [masterClass]="M_VEHICLE"
                    searchPlaceHolder="Buscar vehículo o propietario" [disableIf]="" [form_]="newVOform"
                    (onSelect)="newVOform.get('client_id')?.patchValue($event.client?.client_id)"
                    [formCname]="'vehicle_id'" [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'"
                    [specialRow]="''" [required]="true" [createData]="{
                        text : 'Nuevo vehículo',
                        by : 'dialog'
                }">
                </app-class-searcher>
            </div>
            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Coste compra</mat-label>
                    <input type="number" min="0" matInput formControlName="cost">
                </mat-form-field>
            </div>
        </div>
    </form>

    <!-- If is on dialog (comercial budget to stock), show the concepts-->
    <app-card-subtitle>Conceptos</app-card-subtitle>
    <app-concepts-line-table [concepts]="getConcepts()" [canModifyTable]="!isOnDialog" [isDialog]="isOnDialog"
        [showSave]="false" [isInfo]="true"></app-concepts-line-table>

</div>


<ng-template #comercialSelector>
    <div eForm-section [formGroup]="form">
        <mat-button-toggle-group [value]="CVT.VN" formControlName="comercialType" class="alternative-color">
            <mat-button-toggle [value]="CVT.VN">
                Vehículo nuevo
                <app-vn-vo-label [type]="CVT.VN"></app-vn-vo-label>
            </mat-button-toggle>
            <mat-button-toggle [value]="CVT.VO">
                Vehículo de ocasión
                <app-vn-vo-label [type]="CVT.VO"></app-vn-vo-label>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</ng-template>

<ng-template #plate>
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>Matricula</mat-label>
            <app-async-validator-spinner matSuffix [control]="form.get('license')"></app-async-validator-spinner>
            <input matInput placeholder="Matricula" [disabled]="licenseBlocked" formControlName="license" />
            <button *ngIf="licenseBlocked" mat-icon-button matPrefix type="button"
                (click)="enablePlateEdition(d, this)">
                <mat-icon>edit</mat-icon>
            </button>
            <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                La matrícula ya existe.
            </mat-error>
        </mat-form-field>
    </form>
</ng-template>