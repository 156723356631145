import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Comentario, CommentManager } from '../../custom-classes/Comentarios';
import { ComentariosMotoComponent } from './comentarios-moto/comentarios-moto.component';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';

@Component({
    selector: 'app-comentarios',
    templateUrl: './comentarios.component.html',
    styleUrls: ['./comentarios.component.css'],
    standalone: false
})
export class ComentariosComponent implements OnInit {

  @Input()  moto! : ComentariosMotoComponent;
  @Input() public label!: string | undefined;
  @Input() public placeHolder!: string | undefined;
  @Input() maxPerComment?: number;
  @Input() showEnter?: boolean;
  cm = CommentManager;
  /** El formulario del componente padre */
  @Input() public form!: UntypedFormGroup;
  /**Si se 'pulsa' el enter o la coma, se añade un comentario tipo 'chip' */
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  /**Array que contiene todos los comentarios introducidos por el usuario*/
  comments: Comentario[] = []
  addOnBlur = true;
  @Output() public onCommentsChange: EventEmitter<string[]> = new EventEmitter();

  /**Sujeto para que el component 'add-compent-drop-down.component' sea notificado cada vez que varia la array de comentarios */
  subject = new Subject<Comentario[]>()

  constructor(public dialog: MatDialog, private confirmD: ConfirmDialogService) { }
  ngOnInit(): void {
  }

  /**Añade un comentario tipo 'chip' */
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      if (this.maxLength(value)) {
        this.confirmD.showError("Superado el límite de comentarios", "Ha superado la longitud máxima permitida en los comentarios")
      }
      else if (this.maxSingleComment(value)) {
        this.confirmD.showError("Longitud máxima superada", "Cada comentario puede tener como máximo "+ this.maxPerComment +" caracteres");
      }
      else {
        this.comments.push(new Comentario(value, []));
      }
    }
    event.chipInput!.clear();
    this.subject.next(this.comments); // --> Notificamos el evento de cambio
    this.onCommentsChange.emit(this.getCommentsAsArray());
  }

  maxLength(v: string) {
    let totalLength = 0;
    for (let i = 0; i < this.comments.length; i++) {
      totalLength += this.comments[i].text.length;
    }

    /** 900 ES EL LÍMITE DE LONGITUD QUE PERMITE LOS COMENTARIOS */
    return totalLength + v.length > 900
  }

  maxSingleComment(v: string) {
    if (this.maxPerComment) {
      return v.length > this.maxPerComment;
    }
    return false;
  }

  addNewComment(comment: Comentario) {
    this.comments.push(comment);
    this.subject.next(this.comments);
    this.onCommentsChange.emit(this.getCommentsAsArray());
  }

  initCommentArray(comments: Comentario[], markAsFilled : boolean = false) {
    this.comments = comments;
    this.comments.forEach(c =>{
      c.filledFromDatabase = markAsFilled;
    })
    this.subject.next(this.comments);
    this.onCommentsChange.emit(this.getCommentsAsArray());
  }


  /**Se elimina un comentario tipo 'chip' */
  removeComment(comment: Comentario): void {
    const index = this.comments.indexOf(comment);
    if (index >= 0) {
      this.comments.splice(index, 1);
    }
    this.subject.next(this.comments);
    this.onCommentsChange.emit(this.getCommentsAsArray());
  }

  /**Obtiene todos los comentarios como una array de strings */
  getAllCommentsFormatted() {
    return this.cm.getAllCommentsFormatted([...this.comments, ...this.moto.selecteds]);
  }

  getCommentsAsArray() {
    return this.cm.getCommentsAsArray([...this.comments, ...this.moto.selecteds]);
  }

  getCommentsForSiniwin() {
    const all = [...this.comments, ...this.moto.selecteds];
    let formattedComments = '';
    for (let i = 0; i < all.length; i++) {
      let commentTxt = this.splitComment30(all[i].text);
      if (i != all.length - 1) {
        formattedComments += '#' + commentTxt + '#,';
      }
      else {
        formattedComments += '#' + commentTxt + '#';
      }

    }
    return formattedComments;
  }

  splitComment30(comment: string, contador?: number): string {
    let increment = 30;
    let cont = contador ? contador : 30;
    if (comment[cont] != undefined) {
      comment = this.insert(cont, comment, "#,");
      cont += increment + 1;
    }
    if (comment[cont] != undefined) {
      return this.splitComment30(comment, cont)
    }
    return comment;
  }

  insert(index: number, val: string, char: string) {
    if (index > 0) {
      return val.substring(0, index) + char + val.substr(index);
    }

    return val + char;
  }

}
