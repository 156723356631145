import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-title',
    template: '<ng-content></ng-content>',
    standalone: false
})
export class FooterTitleComponent {

}
