<app-card [noStyle]="true">
    <mat-stepper linear #stepper class="hidden-stepper-header welcome-stepper" style="max-width: 781px !important;">

        <!-- 1 -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¡Bienvenido a Eina!</p>
                    <div mat-dialog-content>
                        <p>Muchas gracias por registrarte. </p>
                        <p>Vamos a mostrarte los <span class="fw600">puntos principales de Eina</span> y te dejamos
                            tranquilo para que pruebes con profundidad.</p>
                    </div>
                </div>
                <div class="side-img">
                    <img src="./assets/img/logos/eina_logo_small.png">
                </div>
                <p mat-dialog-title class="phone">¡Bienvenido a Eina!</p>
            </div>

            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- 2 -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>El equipo de Sinigual esta para ayudarte</p>
                    <div mat-dialog-content>

                        <p class="df aic gap10">
                            <button mat-mini-fab color="primary" class="issues">
                                <mat-icon [filled]="false">support_agent</mat-icon>
                            </button>
                            <span>
                                Consulta las <span class="fw600">ayudas activas</span> para comprender todo Eina.
                            </span>
                        </p>

                        <p class="df aic gap10">
                            <button class="help onboarading-help-toolbar" mat-mini-fab color="primary">
                                <mat-icon [filled]="false">help_outline</mat-icon>
                            </button>
                            <span>
                                Si tienes dudas y quieres <span class="fw600">hablar con nosotros</span> hazlo a través
                                de
                                el botón de ayuda.
                            </span>
                        </p>

                    </div>
                </div>
                <p mat-dialog-title class="phone">El equipo de Sinigual esta para ayudarte</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- 3 -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>Periodo de prueba gratuito</p>
                    <div mat-dialog-content>
                        <p mat-dialog-subtitle>¡Hoy empieza tu período de prueba de 14 días!</p>
                        <p>Termina el día {{calculateDay()}}</p>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/ciqarrsc.json" trigger="loop"
                        colors="primary:{{primary()}},secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#1663c7"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">Periodo de prueba gratuito</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- 4 -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>Último punto importante y ya podrás empezar</p>
                    <div mat-dialog-content>
                        <p>Para <span class="fw600">generar facturas</span> correctamente primer tienes que
                            configurar
                            los datos de empresa.</p>
                        <p>Para hacer cualquier acción, necesitaras dar de alta <span class="fw600">clientes
                                vehículos y productos</span>, ¡empieza
                            por allí para construir todo en Eina!</p>
                        <button color="primary" (click)="openHelp(); updateCompanyData()" mat-flat-button
                            mat-dialog-close>¡Empezar!</button>
                    </div>
                </div>
                <div class="side-icon">
                    <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in" trigger="loop" delay="2000"
                        colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{accent()}},senary:#3a3347"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">Último punto importante y ya podrás empezar</p>
            </div>
        </mat-step>

    </mat-stepper>

    <app-stepper-step-indicator [stepper]="stepper"></app-stepper-step-indicator>

</app-card>