import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnumFilter } from '../../../custom-classes/Filter';

@Component({
    selector: 'app-enum-filter',
    templateUrl: './enum-filter.component.html',
    styleUrls: ['./enum-filter.component.css'],
    standalone: false
})
export class EnumFilterComponent implements OnInit {
  @Input() filter!: EnumFilter;

  constructor() { }

  ngOnInit(): void {}

  emitChange(selected : MatSelectChange) {
    let finalVal = selected.value;
    if (selected.value == undefined){
      finalVal = -1;
    }
    this.filter.selectedOption = finalVal;
  }

}
