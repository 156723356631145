import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HubspotService } from 'src/app/services/hubspot.service';
interface Food {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css'],
    standalone: false
})

export class TestComponent {
  constructor(public hubSpotS : HubspotService){}
}