import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { StoreHouseCreateDialogComponent } from 'src/app/components/store-house-create-dialog/store-house-create-dialog.component';
import { BookMarkEnum } from 'src/app/enums/BookMarkEnum';
import { M_StoreHouse } from 'src/app/models/M_StoreHouse';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
    selector: 'app-storehouses',
    templateUrl: './storehouses.component.html',
    styleUrls: ['./storehouses.component.css'],
    standalone: false
})
export class StorehousesComponent {

  storehouses: M_StoreHouse[] = [];
  v = ViewPath;
  bookmarkEnum = BookMarkEnum;

  constructor(private apiS: ApiService, private d: MatDialog, private companyS : CompanyService) { }

  initStorehouses(sh : M_StoreHouse[]) {
    this.storehouses = sh;
  }

  updateBookmark(sh: M_StoreHouse, bookmarkColor: BookMarkEnum) {
    if (sh.bookmark == bookmarkColor) { return; }
    sh.bookmark = bookmarkColor;
    this.apiS.updateSotreHouse(sh);
  }

  editStoreHouse(sh: M_StoreHouse) {
    this.d.open(StoreHouseCreateDialogComponent, { autoFocus: false, data: sh }).afterClosed().subscribe(res => {
      if (res && res instanceof M_StoreHouse) {
        let index = this.storehouses.findIndex(storehouse => storehouse.id == sh.id);
        this.storehouses[index] = res;
      }
      else if (res.deleted && res.deleted instanceof M_StoreHouse){
        let index = this.storehouses.findIndex(storehouse => storehouse.id == res.deleted.id);
        this.storehouses.removeIndex(index);
        this.companyS.removeStoreHouse(res);
      }
    })
  }

  createStoreHouse() {
    this.d.open(StoreHouseCreateDialogComponent, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res && res instanceof M_StoreHouse) {
        this.storehouses.push(res);
      }
    })
  }
}
