<div class="advanced-details-container">

    <div class="title">
        <app-go-back class="title" *ngIf="true" [text]="'Listado presupuestos'" (click)="goBudgets()"
            [show]="true"></app-go-back>
        <app-view-title *ngIf="!isEdit">Crear presupuesto de comercial</app-view-title>
    </div>

    <div class="cbudget">
        <app-card [contentLoaded]="loaded" class="bc_b1">

            <!-- NOT Created-->
            <div *ngIf="!cBudget">
                <app-card-title>
                    Información general
                </app-card-title>
                <div [ngTemplateOutlet]="formTemplate"></div>
                <div class="df jcc">
                    <button mat-flat-button color="primary" [disabled]="!isCreateEditEnabled" (click)="createEdit()">
                        Guardar
                    </button>
                </div>
            </div>

            <!-- Created -->
            <div *ngIf="cBudget">
                <app-advanced-details>

                    <app-advanced-title>
                        <div class="df aic">
                            <span class="mr5">{{titleBudget}}</span>
                            <app-or-status-label [status]="cBudget.status"></app-or-status-label>
                            <app-reserve-budget [reserve]="cBudget.reserve"></app-reserve-budget>
                        </div>
                    </app-advanced-title>

                    <app-advanced-subtitle>
                        Creado el {{cBudget.created_at?.dayMonthYearFormat()}}
                    </app-advanced-subtitle>

                    <app-advanced-main-actions>
                        <!-- Not created or opened-->
                        <ng-container *ngIf="isOpenOrUdefined">
                            <button subscribed-user mat-raised-button [disabled]="!cBudget || !form.valid"
                                color="orange" *ngIf="cBudget" (click)="closeBudget()">
                                <mat-icon>lock</mat-icon>
                                Cerrar
                            </button>
                        </ng-container>

                        <!-- Closed -->
                        <ng-container *ngIf="cBudget && isClosed">

                            <!-- To stock -->
                            <button subscribed-user mat-raised-button [color]="'primary'" (click)="toStock()"
                                *ngIf="cBudget.vehicle == undefined">
                                Comprar
                            </button>

                            <!-- Invoice -->
                            <button subscribed-user mat-raised-button (click)="invoice(cBudget.vehicle)"
                                *ngIf="!cBudget.invoice && cBudget.vehicle" color="primary">
                                Vender
                            </button>

                            <!-- Already invoiced -->
                            <button subscribed-user mat-button (click)="goToInvoice(cBudget.invoice)"
                                *ngIf="cBudget.invoice">
                                Ver factura
                            </button>

                        </ng-container>
                        <app-documentation-pdf-menu class="mla" [cBudget]="cBudget"></app-documentation-pdf-menu>
                    </app-advanced-main-actions>

                    <app-advanced-menu-actions>
                        <button mat-menu-item (click)="deleteComercialB()">
                            <mat-icon>delete</mat-icon> Eliminar
                        </button>
                    </app-advanced-menu-actions>

                </app-advanced-details>

                <app-advanced-details-footer>
                    <app-footer-title>Ver más datos</app-footer-title>
                    <app-footer-right>
                        <div class="df gap5">
                            <app-contact-client-rectangle
                                *ngIf="clientSearcherComponent && clientSearcherComponent.selected"
                                [client]="clientSearcherComponent.selected"></app-contact-client-rectangle>
                            <app-vehicle-rectangle *ngIf="cBudget.vehicle"
                                [vehicle]="cBudget.vehicle"></app-vehicle-rectangle>
                        </div>
                    </app-footer-right>
                    <app-footer-content>
                        <app-section>
                            <app-section-title>Información general</app-section-title>
                            <app-section-content>
                                <div [ngTemplateOutlet]="formTemplate"></div>
                            </app-section-content>
                        </app-section>
                    </app-footer-content>
                </app-advanced-details-footer>

            </div>
        </app-card>
    </div>

    <div class="conceptsProds">
        <app-card [contentLoaded]="loaded" [locked]="loaded && (!hasSelectedClient || cBudget == undefined)">

            <!-- CONCEPTOS -->
            <app-card-title>Conceptos</app-card-title>
            <app-concepts-line-table [concepts]="getConcepts()" [showSave]="true" [isInfo]="false"
                [canModifyTable]="!isClosed && cBudget != undefined"></app-concepts-line-table>

            <div class="mt20"></div>

            <!-- PRODUCTOS -->
            <app-card-title>Productos adicionales</app-card-title>
            <app-product-line-table [products]="getProducts()" [lineTypes]="['product', 'custom','time','comment']"
                [extendedInfoConfig]="{showColumn:true, actions:['manual-fault','remove-reservation']}"
                [canModifyTable]="!isClosed && cBudget != undefined">
            </app-product-line-table>
        </app-card>
    </div>

</div>


<!-- FORM -->
<ng-template #formTemplate>
    <form [formGroup]="form" eForm>
        <div eForm-wrapper>
            <div eForm-section>
                <app-class-searcher #clientSearcher [form_]="form" [formCname]="'client_id'" [width100]="true"
                    [masterClass]="master_client" [canChange]="isOpenOrUdefined && !isReserve"
                    searchPlaceHolder="Buscar cliente" [extendedInfo]="false" [createData]="{
                        text : 'Nuevo cliente',
                        by : 'dialog'
                    }">
                </app-class-searcher>
            </div>
            <div *ngIf="!this.cBudget?.vehicle">
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="type_vehicle" (selectionChange)="onTypeChange($event.value, brandsModelSerie)" [value]="selectedType">
                            <mat-option [value]="typeVehicle.bike">Moto</mat-option>
                            <mat-option [value]="typeVehicle.car">Coche</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div eForm-section>
                    
                    <app-brands-models-series [form]="form"  #brandsModelSerie [notRequiredSerie]="true"  [filterAuto]="true" [type]="selectedType" [disabled]="isClosed"
                        (onSelectModelSearcher)="patchPrice($event)"></app-brands-models-series>
                </div>

                <!-- Reserve brand and model select -->
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>{{sinStock == false ? "Lista de stock": "Sin stock"}}</mat-label>
                        <mat-select formControlName="vehicle" (selectionChange)="stateChange($event)"
                            [disabled]="sinStock && cBudget?.id != undefined">
                            <mat-option disabled *ngIf="stockVehicle != undefined">
                                <div class="header df">
                                    <span>Disponible: {{ stockVehicle.quant_available}} </span>
                                    <span class="reser_span"> Reservado: {{ stockVehicle.quant_reserved }}</span>
                                    <span class="reser_span"> Cantidad: {{ stockVehicle.quantity }}</span>
                                </div>
                            </mat-option>

                            <!-- No stock message -->
                            <mat-option
                                *ngIf="!stockVehicle?.available_vehicles || stockVehicle?.available_vehicles?.length === 0"
                                disabled>
                                No hay stock disponible
                            </mat-option>

                            <!-- Available vehicles -->
                            <mat-option *ngFor="let vehicle of stockVehicle?.available_vehicles" [value]="vehicle">
                                {{ vehicle?.nameOption }}
                            </mat-option>

                            <mat-select-trigger>
                                <div class="selected-value">
                                    <ng-container *ngIf="stockVehicle?.available_vehicles!.length > 0">
                                        {{ selectedVehicle?.nameOption }}
                                    </ng-container>
                                </div>
                            </mat-select-trigger>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div eForm-section> <mat-checkbox formControlName="reserve" [(ngModel)]="isChecked"
                    *ngIf="selectedVehicle">Reservar vehículo</mat-checkbox></div>

            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Precio</mat-label>
                    <input type="number" min="0" matInput formControlName="price">
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>


<app-floating-save-button (click)="createEdit()" [show]="isEditEnabled"></app-floating-save-button>



<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el presupuesto'" [goText]="'Ir al listado de presupuestos'"
        [view]="v.comercialBudget">
    </app-model-not-found>
</ng-template>