<div class="advanced-details-container reports-tabs">
    <app-view-title>Informes</app-view-title>
    <mat-tab-group [color]="'primary'" mat-stretch-tabs="false" (selectedIndexChange)="onChangeTab($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                Análisis gráfico
            </ng-template>

            <app-generic-reports [showTitle]="false" [config]="{
            showReport : {
                stock : false,
                sells : false,
                ors : true,
            },
            dataOf: 'einaCompany'
        }">
            </app-generic-reports>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Visión general
            </ng-template>
            <app-workload-dashboard></app-workload-dashboard>
        </mat-tab>
    </mat-tab-group>
</div>