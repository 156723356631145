import { Component, Input, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ViewPath } from "src/app/app-routing.module";
import { BrandsModelsSeriesComponent } from "src/app/components/brands-models-series/brands-models-series.component";
import { MASTER_CLIENT, MASTER_CLIENT_MINIFIY, MASTER_VECHILE, MASTER_VECHILE_ALL } from "src/app/constants/masters";
import { ComercialVehicleType } from "src/app/enums/ComercialVehicleType";
import { RegistrationTaxEnum, registrationText } from "src/app/enums/RegistrationTaxEnum";
import { VehicleType } from "src/app/enums/VehicleType";
import { IConceptLineTableComponent } from "src/app/interfaces/IConceptLineTableComponent";
import { M_Concept } from "src/app/models/M_Concept";
import { M_Vehicle } from "src/app/models/Vehicles/M_Vehicle";
import { AlreadyExistsService } from "src/app/services/already-exists.service";
import { ApiService } from "src/app/services/Api/api.service";
import { CompanyService } from "src/app/services/EinaMainData/company.service";
import { RouterService } from "src/app/services/router.service";
import { ConceptsLineTableComponent } from "../../concepts-line-table/concepts-line-table.component";
import { ImageToggleComponent } from "../../image-toggle/image-toggle.component";
import { calcBenefit, calcPB, calcPVP, createVehicleForm, enablePlateEdition, genericValueChange, onTypeChange } from "../vehicleGenericLogic";
import { buyFormControls, BuySimpleFormComponent } from "../../buy-simple-form/buy-simple-form.component";
import { ICreateVehicle } from "src/app/interfaces/ICreateVehicle";
import { ClassSearcherComponent } from "../../class-searcher/class-searcher.component";

@Component({
    selector: 'app-vn-vo-form',
    templateUrl: './vn-vo-form.component.html',
    styleUrls: ['./vn-vo-form.component.css'],
    standalone: false
})
export class VnVoFormComponent implements ICreateVehicle, IConceptLineTableComponent {

  M_VEHICLE = MASTER_VECHILE;
  M_CLIENT = MASTER_CLIENT;
  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;
  VT = VehicleType;
  selectedType: VehicleType;
  onTypeChange = onTypeChange;
  enablePlateEdition = enablePlateEdition;
  registrationText = registrationText;
  calcPVP = calcPVP;
  calcPB = calcPB;
  calcBenefit = calcBenefit;

  /** Show only the requiered fields */
  @Input() onlyRequired = false;
  @Input() requiredFieldsTitle?: string

  @ViewChild('brandsModelSerie') brandsModelSerie?: BrandsModelsSeriesComponent;
  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  /** Create VO */
  @ViewChild('VOClientSearcher') VOClientSearcher?: ClassSearcherComponent<M_Vehicle>


  v = ViewPath;
  public form: UntypedFormGroup;
  public newVOform: UntypedFormGroup;


  vehicle: M_Vehicle | undefined
  freePlateEedit = false;
  provisionalConcepts: M_Concept[] = [];


  constructor(private fb: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, public d: MatDialog,
    @Optional() public dialogRef: MatDialogRef<any>, public companyS: CompanyService) {
    if (this.companyS.company.userCenter?.workshopConfig) {
      this.selectedType = this.companyS.company.userCenter?.workshopConfig?.default_vehicle;
    } else {
      //por defecto
      this.selectedType = VehicleType.bike;
    }
    /** Form generation */
    this.form = createVehicleForm(this.fb, this.apiS, this.existsS, this.companyS, () => this.getCurrentVehicle());
    this.form.get('price')?.addValidators(Validators.required);
    this.form.get('cost')?.addValidators(Validators.required);
    this.form.get('client_id')?.addValidators(Validators.required);

    /** VO create form */
    this.newVOform = this.fb.group({
      ...buyFormControls(fb).controls,
      vehicle_id: ['', Validators.required],
      comment: ['Contrato inicial'],
      type: [2],
      buy_transac_id: [''],
      mode: ['view'],
      token: ['CV']
    });

    this.newVOform.get('cost')?.addValidators(Validators.required)

    genericValueChange(this.form);

    /** Set up comercial vehicle form */
    this.form.get('comercialType')?.valueChanges.subscribe(v => {
      if (this.isCreate) {
        this.requiredIf(this.form.get('client_id'), "vn", "vo");
      }
      this.requiredIf(this.form.get('client_origin'), "vo");
      this.requiredIf(this.form.get('registration_tax'), "vn");
      this.requiredIf(this.form.get('license'), 'vo');
      this.requiredIf(this.form.get('brand_id'), 'vn', 'vo');
      this.requiredIf(this.form.get('model_id'), 'vn', 'vo');
      this.requiredIf(this.form.get('chassis'), "vn");
    })

    this.form.get('comercialType')?.setValue(this.CVT.VN);

  }

  get isCreate() { return !this.isEdit; }
  get isEdit() { return this.vehicle != undefined; }

  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  getConcepts() {
    if (this.vehicle) { return this.vehicle.concepts };
    return this.provisionalConcepts;
  }

  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  /** Patch the current form with a vehicle.*/
  patchFormWithVehicle(vehicle: M_Vehicle) {
    let c = this.form.get('client_id')

    this.vehicle = vehicle;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
      this.selectedType = this.vehicle.type;
    }
    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }
    /** On edit, seller is not required */
    let seller = vehicle.vehicle_entry?.seller;
    if (seller) { c?.setValue(seller.client_id) }
    c?.removeValidators(Validators.required);
    c?.updateValueAndValidity();
    if (this.brandsModelSerie) {
      this.onTypeChange(vehicle.type, this.brandsModelSerie);
    }
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  /** !! */
  get loaded() {
    return true;
  }

  get isVn() {
    return this.form.get('comercialType')?.value == this.CVT.VN;
  }

  get isVo() {
    return this.form.get('comercialType')?.value == this.CVT.VO;
  }

  requiredIf(control: AbstractControl<any, any> | null, ...is: ("vn" | "vo")[]) {
    if (!control) { return; }
    if (this.vehicleIs(...is)) { control.addValidators(Validators.required); }
    else { control.removeValidators(Validators.required); }
    control.updateValueAndValidity();
  }

  vehicleIs(...is: ("vn" | "vo")[]) {
    let ok = true;
    if (is.includes("vn")) { ok = this.isVn; if (ok) { return true; } }
    if (is.includes("vo")) { ok = this.isVo; if (ok) { return true; } }
    return ok;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get isVOCreateForm() {
    return this.isCreate && this.isVo;
  }

  get isFormValid() {
    return this.isVOCreateForm ? this.newVOform.valid : this.form.valid;
  }

  get iedmtOnPB() {
    let v = this.form.get('registration_tax')?.value || 0;
    if (v == 0) { return 0; }
    return this.calcPB_ * (v / 100);
  }

  get ivaOnPB() {
    let v = this.form.get('tax')?.value || 0;
    if (v == 0) { return 0; }
    return this.calcPB_ * (v / 100);
  }

  get calcPB_() {
    return calcPB(this.form.get('price')?.value, this.form.get('tax')?.value, this.form.get('registration_tax')?.value);
  }

  get benefit() {
    return calcBenefit(this.form.get('price')?.value, this.form.get('cost')?.value, this.form.get('tax')?.value, this.form.get('registration_tax')?.value);
  }

}