import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cancelation-and-return-policy',
    templateUrl: './cancelation-and-return-policy.component.html',
    styleUrls: ['./cancelation-and-return-policy.component.css'],
    standalone: false
})
export class CancelationAndReturnPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
