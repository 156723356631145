import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookMarkEnum, getBookMarkColorStyle } from '../../enums/BookMarkEnum';
import { SotreHouseIcon } from '../../enums/SotreHouseLord';
import { M_StoreHouse } from '../../models/M_StoreHouse';
import { ApiService } from 'src/app/services/Api/api.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
    selector: 'app-sotre-house-form',
    templateUrl: './sotre-house-form.component.html',
    styleUrls: ['./sotre-house-form.component.css'],
    standalone: false
})
export class SotreHouseFormComponent {
  @Input() sh: M_StoreHouse | undefined;
  loaded: boolean = false;
  edit: boolean = false;
  form: FormGroup;
  getBookMarkColorStyle = getBookMarkColorStyle;
  @ViewChildren('locationInputs') newLocationInput: QueryList<ElementRef> = new QueryList();


  constructor(private fb: FormBuilder, private apiS: ApiService, private confirmD: ConfirmDialogService) {
    this.form = this.fb.group({
      id: ['', this.edit ? Validators.required : []],
      bookmark: [BookMarkEnum.PRIMARY, Validators.required],
      lordicon: [SotreHouseIcon.DEFAULT, Validators.required],
      deleted: [0],
      name: ['', Validators.required],
      tel: [''],
      contact: [''],
      default: [''],
      locations: this.fb.array([]),
    });
  }

  get locations() {
    return this.form.get('locations') as FormArray;
  }

  ngOnInit() {
    if (this.sh) {
      this.form.patchValue(this.sh);
      // Patching locations if available
      if (this.sh.locations && this.sh.locations.length > 0) {
        this.sh.locations.forEach(location => {
          this.locations.push(this.fb.group({
            id: location.id,
            company_id: location.company_id,
            name: location.name,
            deleted: location.deleted,
            store_id: location.store_id,
            default: location.default,
          }));
        });
      }
      this.edit = true;
    }
  }

  addLocation() {
    this.locations.push(this.fb.group({
      id: null,
      company_id: null,
      name: null,
      deleted: null,
      store_id: null,
      default: false,
    }));
    setTimeout(() => {
      this.newLocationInput.last.nativeElement.focus();
    }, 0);
  }

  removeLocation(index: number) {
    let id = this.locations.value[index]?.id;

    if (id) {
      this.confirmD.show({
        title: "Eliminar almacén",
        body: "¿Está seguro de que quiere eliminar la ubicación? Esta acción se realizará inmediatamente",
        type: "danger"
      }).afterClosed().subscribe(res => {
        if (res == true) {
          this.apiS.deleteLocation(id).then(res => {
            this.simpleRemove(index);
          })
        }
      })
    }
    else {
      this.simpleRemove(index);
    }

  }

  simpleRemove(index: number) {
    this.locations.removeAt(index);
  }


  get formBookmark() {
    return this.form.get('bookmark')!.value
  }

  get formLordIcon() {
    return this.form.get('lordicon')!.value
  }

  setNewLordIcon(action: "more" | "less") {
    let finalVal = SotreHouseIcon.DEFAULT;

    if (action == "less") {
      if (this.formLordIcon == SotreHouseIcon.DEFAULT) { finalVal = SotreHouseIcon.OTHERS }
      else { finalVal = this.formLordIcon - 1 }
    }

    if (action == "more") {
      if (this.formLordIcon == SotreHouseIcon.OTHERS) { finalVal = SotreHouseIcon.DEFAULT }
      else { finalVal = this.formLordIcon + 1 }
    }

    this.form.get('lordicon')!.patchValue(finalVal);
  }

  changeBookmark(v: BookMarkEnum) {
    this.form.get('bookmark')?.patchValue(v);
  }
}