import { Injectable } from '@angular/core';
import { M_Vehicle } from '../models/Vehicles/M_Vehicle';
import { BUY_PDF_PARAMS, TemplateFieldsService } from './template-fields.service';
import { PreviewService } from './preview.service';
import { ApiService } from './Api/api.service';
import { ParamsService } from './params.service';
import { M_Purchase } from '../models/M_Purchase';
import { IPurchaseData } from '../components/pdf/pdf.component';
import { VnvoDetailsComponent } from '../views/vnvo-details/vnvo-details.component';
import { NUMPAD_DIVIDE } from '@angular/cdk/keycodes';

export function getPurchaseData(purchase: M_Purchase): IPurchaseData {
  return {
    client_id: purchase.client_id,
    vehicle_id: purchase.vehicle_id,
    buy_transac_id : purchase.id,
    price: purchase.total,
    tax: purchase.tax,
    num_purchase: purchase.num_purchase,
    type: purchase.type,
    docum_prov_num: purchase.docum_prov_num,
    token: "FC",
    comment: "",
  }
}

@Injectable({
  providedIn: 'root'
})
export class GenericBuyVehicleService {

  constructor(private templateS: TemplateFieldsService, private previewS: PreviewService, private apiS: ApiService, private params: ParamsService) { }

  genericBuy(vehicle: M_Vehicle | undefined, detailsComponent? : VnvoDetailsComponent) {
    
    if (!vehicle || vehicle.vehicle_entry == undefined) { return; }

    let params: BUY_PDF_PARAMS = {
      vehicle_id: vehicle?.vehicle_id,
      client_id: vehicle?.vehicle_entry.seller?.client_id,
      vehicle_mov_id: vehicle?.vehicle_entry.id,
      mode: 'view',
      token: 'CV',
      docum_prov_num: undefined,
      type_new: undefined,
    }

    this.templateS.showTemplateSteps("CV", vehicle.vehicle_entry.id, "CV", vehicle.type, undefined, params).afterClosed().subscribe(res => {
      if (res) {
        params.mode = "save";
        this.showCompraVenta(params);
        /** Refresh VN VO details */
        if (detailsComponent && detailsComponent.ve) {
          detailsComponent?.refresh(detailsComponent.ve?.vehicle_id);
        }
      }
    })
  }

  showCompraVenta(params : BUY_PDF_PARAMS){
    this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, params);
  }

  showBuyRegister(purchase : M_Purchase){
    this.previewS.showPreview("CV", "FC", undefined, undefined, false, undefined, undefined, undefined, getPurchaseData(purchase));
  }


}
