import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { templateTypedoc } from '../../models/M_TemplateField';
import { company_data } from '../preview-dialog/preview-dialog-component';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_ActiveReception } from '../../models/M_ActiveReception';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { M_Purchase } from 'src/app/models/M_Purchase';


export interface PDF_DATA {
  /* The preview type*/
  preview_type: templateTypedoc,
  /* Object ID */
  id?: number | undefined, //Object id
  /* Object token */
  token?: string | undefined,
  /* Company data. Uased on templates preview on 'Company' section */
  company_data?: company_data | undefined,
  /** Object with no ID */
  noIdObject?: M_ActiveReception

}

export interface IPurchaseData {
  client_id?: number
  vehicle_id?: number
  vehicle_mov_id?: number
  buy_transac_id? : number;
  price?: number
  tax?: number
  comment?: string
  num_purchase?: string
  type?: number
  docum_prov_num?: string
  mode?: string
  token?: string
  date?: Date
  type_new?: number
}

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.css'],
    standalone: false
})
export class PdfComponent implements OnInit {

  public file: Blob | undefined;
  url: string | undefined;
  @Input() pdf_data!: PDF_DATA;
  @Input() params: IPurchaseData | undefined;
  constructor(private apiS: ApiService, private chdRef: ChangeDetectorRef, private confirmD: ConfirmDialogService) { }

  ngOnInit(): void {
    this.getFile().then(res => {
      if (res) {
        this.file = res;
        this.url = URL.createObjectURL(this.file);
        this.chdRef.detectChanges();
      }
      else {
        this.confirmD.showError("Algo salió mal", "Previsualización no disponible")
      }
    })
  }

  getFile() {
    return new Promise<Blob | undefined>(resolve => {
      /** Get the pdf file by url */
      if (this.pdf_data.token && this.params === undefined) {
        this.apiS.loadRealPDFURL(this.pdf_data.preview_type, this.pdf_data.token).then(res => resolve(res))
      }
      /** Generate company template PDF (with colors and footer params) */
      else if (this.pdf_data.company_data) {
        this.apiS.generatCompanyTemplate(this.pdf_data.preview_type, this.pdf_data.company_data!).then(res => resolve(res))
      }
      else if (this.pdf_data.noIdObject) {
        this.apiS.loadOnFlyPDFURL(this.pdf_data.preview_type, this.pdf_data.noIdObject).then(res => resolve(res))
      }
      else if (this.params != undefined && this.params.token == "CV") {
        this.apiS.loadOnFlyPDFURL(this.pdf_data.preview_type, this.params).then(res => resolve(res))
      }
      else if (this.params != undefined && this.params.token == "FC") {
        this.apiS.loadOnFlyPDFURL('FC', this.params).then(res => resolve(res))
      }
      /** Probably, is the basic template. The basic template is a Angular compenent. */
      else {
        resolve(undefined);
      }
    })
  }

}
