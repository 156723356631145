<div [formGroup]="form">
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Precio</mat-label>
            <input type="number" min="0" matInput formControlName="price">
        </mat-form-field>
        <ng-container *ngIf="showDocumentAndRelated">
            <mat-form-field appearance="outline">
                <mat-label>Fecha de Compra</mat-label>
                <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                    (click)="picker_3.open()" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                <mat-datepicker #picker_3></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Número de Factura</mat-label>
                <input type="text" min="0" matInput formControlName="doc_prov_num">
            </mat-form-field>
        </ng-container>
    </div>

    <div eForm-section>
        <mat-form-field appearance="outline" *ngIf="showCost">
            <mat-label>Coste</mat-label>
            <input type="number" min="0" matInput formControlName="cost">
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="showIva">
            <mat-label>IVA</mat-label>
            <input #input type="number" matInput placeholder="IVA" formControlName="tax" type="number" DecimalInput>
            <mat-icon matSuffix>percent</mat-icon>
            <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">
                Valor máx. : 21
            </mat-error>
        </mat-form-field>
    </div>


    <div *ngIf="showDocumentAndRelated">
        <app-card-subtitle>Documentación de compra</app-card-subtitle>
        <div>
            <app-pdf-documents-upload></app-pdf-documents-upload>
        </div>
    </div>
</div>