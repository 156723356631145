import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { toFormData } from '../../utils/MultipartFormData';
import { buyFormControls, BuySimpleFormComponent } from '../buy-simple-form/buy-simple-form.component';
import { ApiService } from '../../services/Api/api.service';
import { M_Purchase } from '../../models/M_Purchase';
import { RouterService } from '../../services/router.service';
import { SnackService } from '../../services/snack.service';
import { ViewPath } from '../../app-routing.module';
import { PS_HIGHLIGHT } from '../../constants/constants';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { M_Contact } from '../../models/M_Contact';
import { IVehicleLineTableComponent } from '../../interfaces/IVehicleLineTableComponent';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { VehicleLineTableComponent } from '../vehicle-line-table/vehicle-line-table/vehicle-line-table.component';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { ActivatedRoute } from '@angular/router';
import { MemoryParamsService } from '../../services/memory-params.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { ConceptsLineTableComponent } from '../concepts-line-table/concepts-line-table.component';
import { M_Concept } from '../../models/M_Concept';
import { IConceptLineTableComponent } from '../../interfaces/IConceptLineTableComponent';
import { M_StoreHouseEntry } from '../../models/M_StoreHouseEntry';
import { M_VehicleEntry } from '../../models/M_VehicleEntry';

export const addVehicleOnParam = "newVehicleId";

enum BuyType {
  RECAMBIOS = 1,
  VEHICLE = 2
}

@Component({
    selector: 'app-create-purchase',
    templateUrl: './create-purchase.component.html',
    styleUrls: ['./create-purchase.component.css'],
    standalone: false
})
export class CreatePurchaseComponent extends ParameterStateComponent implements IVehicleLineTableComponent, IConceptLineTableComponent {

  v = ViewPath;
  client = MASTER_CLIENT_MINIFIY;
  BT = BuyType;

  allProvStoreHouseEntry: M_StoreHouseEntry[] = [];
  allVehicleEntries: M_VehicleEntry[] = [];

  vehicles: M_Vehicle[] = [];
  concepts: M_Concept[] = [];
  

  refreshing = false;

  form: UntypedFormGroup;
  @ViewChild(ClassSearcherComponent) cSearcher?: ClassSearcherComponent<M_Contact>;
  @ViewChild(BuySimpleFormComponent) buyForm?: BuySimpleFormComponent;


  @ViewChild(VehicleLineTableComponent) vehicleLineTable!: VehicleLineTableComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  constructor(routerS: RouterService, route: ActivatedRoute, private fb: FormBuilder, private apiS: ApiService, private snackS: SnackService,
    private momoryParams: MemoryParamsService, private chdRef: ChangeDetectorRef) {
    super(routerS, route, [addVehicleOnParam, 'seller'])
    this.form = this.fb.group({
      client_id: ['', Validators.required],
      buy_type: [null, Validators.required],
      ...buyFormControls(fb).controls,
    });
    this.form.get('price')?.addValidators(Validators.required);
  }

  override onParams(params: { param: string; value: string; }[]): void {

  }

  override onParam(param: string, value: string): void {
    if (param == addVehicleOnParam) {
      let val = value.getNumber();
      if (val) {
        this.apiS.vnvo.getVehicleById(val).then(res => {
          if (res && res.vehicle_entry?.seller) {
            this.cSearcher?.select(res.vehicle_entry.seller, { emitEvent: false });
            this.form.get('buy_type')?.setValue(this.BT.VEHICLE);
            this.chdRef.detectChanges();
            this.addVehicle(res);
            this.vehicleLineTable.setHighlighted(res.vehicle_id);
            this.momoryParams.add([addVehicleOnParam, undefined])
            this.apiS.pendBuyProdvider(res.vehicle_entry?.seller.client_id).then(res => {
              this.setNewScreenClient(res.entryProducts, res.entryVehicles)
            })
          }
        })
      }
    }
  }

  refreshLists(c: M_Contact) {
    this.refreshing = true;
    this.apiS.pendBuyProdvider(c.client_id).then(res => {
      this.vehicles = [];
      this.concepts = [];
      this.allProvStoreHouseEntry = [];
      this.allVehicleEntries = [];
      this.setNewScreenClient(res.entryProducts, res.entryVehicles)
      this.refreshing = false;
    })
  }

  setNewScreenClient(entryProducts: M_StoreHouseEntry[], entryVehicles: M_VehicleEntry[]) {
    this.allProvStoreHouseEntry = entryProducts;
    this.allVehicleEntries = entryVehicles;
    this.vehicleLineTable.reset(entryVehicles.map(entry => entry.vehicle!));
  }

  /** Backend need the vehicle entry array of selected vehicles */
  get vehiclesToEntries() {
    let toReturn: M_VehicleEntry[] = [];
    let selecteds = this.vehicleLineTable.getTableVehicles();
    this.allVehicleEntries.forEach(entry => {
      if (selecteds.some(ve => ve.vehicle_id == entry.vehicle_id)) {
        toReturn.push(entry);
      }
    })
    return toReturn;
  }

  createPurchase() {

    let req = this.form.value;
    req['vehicles'] = this.vehiclesToEntries;
    req['concepts'] = this.concepts;
    req['entries'] = this.allProvStoreHouseEntry.filter(sh => sh.checked)

    const formData = toFormData(req);

    if (this.buyForm?.file) {
      formData.append('pdf_file', this.buyForm.file);
    }


    this.apiS.storeBuyTransact(formData).then(res => {
      if (res instanceof M_Purchase) {
        this.routerS.goWithQueryParams(this.v.purchases, { [PS_HIGHLIGHT]: res.id });
        this.snackS.show("🧾 ¡Factura de compra registrada con éxito!");
      }
    })
  }


  get canPurchase() {
    return this.form.valid && ((this.buyTypeRecambios && this.someEntries) || (this.buyTypeVehicles && (this.someConcepts || this.someVehicles)));
  }

  get someEntries() { return this.allProvStoreHouseEntry.some(sh => sh.checked) }
  get someConcepts() { return this.conceptLineTable.concepts.length }
  get someVehicles() { return this.vehicleLineTable.getTableVehicles().length }

  get buyType() { return this.form.get('buy_type')!.value || undefined }
  get noBuyType() { return this.buyType == undefined }
  get someBuyType() { return this.buyType != undefined }
  get buyTypeRecambios() { return this.buyType == BuyType.RECAMBIOS }
  get buyTypeVehicles() { return this.buyType == BuyType.VEHICLE }

  setBuyType(by: BuyType | undefined) {
    this.form.get('buy_type')!.setValue(by);
  }


  /** Vechiles */
  addVehicle(v: M_Vehicle): void { this.vehicles.push(v);}
  removeVehicle(v: M_Vehicle): void { this.vehicles.removeElement(v); }
  getClientDiscountV(v: M_Vehicle): number | null { return null; }
  getClient(): M_Contact | undefined { return undefined; }

  /** Concepts */
  addConcept(c: M_Concept): void {
    this.concepts.push(c);
  }

}
