import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { ViewPath } from 'src/app/app-routing.module';
import { forkJoin } from 'rxjs';
import { WorkloadService } from 'src/app/services/workload.service';
import { PrevisionChartOtions, chartGenerationPrevision } from './chart-generation/chartPrevision';
import { SemiCircleChart, semiCircleChart } from './chart-generation/semiCircle';
import { environment } from 'src/environments/environment';
import { ORPageFiltesrEnum } from '../or/or.component';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { BillPageFiltesrEnum } from '../invoices/invoices.component';
import { PeriodEnum } from 'src/app/enums/PeriodEnum';
import { or_status_close, or_status_invoiced, or_status_open } from 'src/app/custom-classes/or_states';
import { isMobile } from 'src/app/utils/Browserutils';
import { RouterService } from 'src/app/services/router.service';
import { M_Dashboard } from 'src/app/models/M_Dashboard';
import { endpoints } from 'src/app/constants/Endpoints';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-workload-dashboard',
  templateUrl: './workload-dashboard.component.html',
  styleUrls: ['./workload-dashboard.component.css'],
  standalone: false
})

export class WorkloadDashboardComponent implements OnInit {

  @ViewChildren(ChartComponent) charts?: QueryList<ChartComponent>;

  inTab = false;
  e = endpoints;
  v = ViewPath;
  public previsionChart?: Partial<PrevisionChartOtions>;
  public semiCircleChart?: Partial<SemiCircleChart>;
  data?: M_Dashboard;
  loaded = false;
  pe = PeriodEnum;
  open = or_status_open;
  closed = or_status_close;
  invoiced = or_status_invoiced;
  isp = isMobile()
  env = environment;
  BPFE = BillPageFiltesrEnum;
  OPFE = ORPageFiltesrEnum;
  test = false;
  RE = RolesEnum;

  constructor(private apiS: ApiService, private routerS: RouterService, public wS: WorkloadService,
    public userS: UserService) {
  }

  ngOnInit(): void {
    let a = this.apiS.dashboard();
    forkJoin([a]).subscribe(res => {
      this.data = res[0];
      /** ----- CHART GENERATION ------ */
      // 1 -  Prevision chart
      this.previsionChart = chartGenerationPrevision(this.data);
      // 2 - Workload chart
      this.semiCircleChart = semiCircleChart(this.data);
    })
  }

  reRender() {
    this.charts?.forEach(c => {
      c.resetSeries();
    })
  }

}
