export enum RegistrationTaxEnum {
    Zero = 0,
    Low = 3.75,
    MediumLow = 4.75,
    MediumHigh = 9.75,
    High = 14.75
}

export function registrationText(ete: RegistrationTaxEnum) {
    return ete.toString().replace(".", ",").concat("%");
}