<div class="df aic">
    <app-class-searcher class="w100" #searcher [masterClass]="vehicleTable.MASTER_VEHICLE" [required]="false"
        [customData]="vehicleTable.vehicles_cs"
        [showLeftNumber]="false"
        [width100]="true"
        [searchPlaceHolder]="'Buscar vehículo'" (onSelect)="onSelectVehicle.emit($event);"
        [disableIf]="disabledIf.bind(this)"
        [createData]="canAddNew ? {
            text : 'Crear vehículo',
            by : 'dialog'
        } : undefined">
    </app-class-searcher>

    <button (click)="onDestroy.emit()" mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>

</div>