import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SETTINGS_TAB } from '../settings/settings.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { MatTabGroup } from '@angular/material/tabs';
import { MODULES_TABS } from './modulesTabs/modulesTabs';
import { dontShowModule } from '../settings/module-selector/module-selector.component';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';
import { SnackService } from 'src/app/services/snack.service';
import { FormControl, Validators } from '@angular/forms';
import { downloadBlob } from 'src/app/utils/FunctionUtils';
import { GenericReportsComponent } from '../generic-reports/generic-reports.component';
import { feature } from 'src/app/utils/FeaturesController';

@Component({
    selector: 'app-module-details',
    templateUrl: './module-details.component.html',
    styleUrls: ['./module-details.component.css'],
    standalone: false
})
export class ModuleDetailsComponent extends ParameterStateComponent {

  v = ViewPath;
  m: BaseModule | undefined;
  me = ModulesEnum;
  TABS = MODULES_TABS;
  tabOnLoad: number | undefined;

  @ViewChild(MatTabGroup) tabs?: MatTabGroup;
  @ViewChild(GenericReportsComponent) charts?: GenericReportsComponent;

  dataIni: FormControl<Date | null> = new FormControl(new Date().firstMonthDay(), Validators.required);
  dataEnd: FormControl<Date | null> = new FormControl(new Date().lastMonthDay(), Validators.required);

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public companyS: CompanyService,
    private chdRef: ChangeDetectorRef, private snackS: SnackService) {
    super(routerS, route, ["id", "tab"]);
  }

  get generalDesc() { return "Descripción general" }

  override onParams(params: { param: string; value: string; }[]): void {
    const id = params.find(p => p.param == "id")?.value.getNumber();
    const tab = params.find(p => p.param == "tab")?.value.getNumber();
    if (id && !dontShowModule.includes(id)) {
      this.apiS.getModuleById(id).then(res => {

        if (res.isIntegration && !feature.integrations) { return; }

        this.m = res;
        if (tab) {
          this.chdRef.detectChanges();
          if (this.tabs && !this.tabs._allTabs.get(tab)?.disabled) {
            this.tabs.selectedIndex = tab;
          }
        }
      })
    }
  }

  has(m: ModulesEnum) {
    return this.companyS.hasModule(m);
  }

  toggle() {
    if (!this.m) { return; }
    this.companyS.toggleModule(this.m.id);
    if (this.tabs) {
      this.tabs.selectedIndex = 0;
    }
  }

  goSettignsModule() {
    this.routerS.goWithQueryParams(this.v.settings, { tab: SETTINGS_TAB.MODULES })
  }

  /** HONDA  */
  exportPasosTaller() {
    this.apiS.pasosTaller(this.dataIni.value!, this.dataEnd.value!).then(res => {
      if (res) {
        var downloadName = "Pasos de taller - " + new Date().dayMonthYearFormat();
        downloadBlob(res, downloadName);
      }
    })
  }

  /** MTR */
  saveMtrConfig() {
    if (this.m?.isMTR()) {
      this.apiS.saveMTR(this.m).then(res => {
        this.snackS.show("Configuración guardada ⚙️")
      })
    }
  }

  refreshCharts() {
    this.charts?.reRender();
  }

}
