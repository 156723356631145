import { Component, Inject } from '@angular/core';
import { PageStructureComponent } from '../page-structure.component';

@Component({
    selector: 'app-page-structure-add-new',
    templateUrl: './page-structure-add-new.component.html',
    styleUrls: ['./page-structure-add-new.component.css'],
    standalone: false
})
export class PageStructureAddNewComponent {
  constructor(@Inject(PageStructureComponent) public ps : PageStructureComponent<any>){}
}
