import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from 'src/app/services/Api/api.service';
import { ReferenceService } from '../../services/onboarding.service';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';
import { ModuleComponent } from 'src/app/views/settings/module-selector/module/module.component';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css'],
    standalone: false
})
export class WelcomeComponent {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  index = 0;
  pages = [0, 1, 2];
  modules: BaseModule[] = [];

  @ViewChild(MatStepper) stepper?: MatStepper;
  @ViewChildren(ModuleComponent) moduleC?: QueryList<ModuleComponent>;

  constructor(private apiS: ApiService, private refS: ReferenceService, private responsiveS: ResponsiveService, public subS: SubscriptionService, private company: CompanyService) {
    this.getModules();
  }

  ngAfterViewInit() {
    if (!this.responsiveS.isPhone()) {
      this.refS?.menuComponent?.toggleTo(true);
    }
  }

  getModules() {
    this.apiS.getModules().then(res => {
      this.modules = res.filter(m => m.isModule);
    })
  }

  updateCompanyData() {
    this.toggleModule(...this.getSelectedModules());
  }

  getSelectedModules() {
    if (this.moduleC) {
      return this.moduleC
        .filter(m => m.welcomeFc.value === true)
        .map(m => m.module.id);
    }
    return [];
  }

  /**
   * Starts the 'hidden' onboarding process. Explains the user how to use the help button and start sections.
   * The hidden section, is the same as the first onboarding section but has 2 more previous steps to localize the
   * help button.
   * In this function, start the hidden section and when its finished, mark as compleated the first one (fill the company data).
   */
  openHelp() {
    if (this.refS.onboardingComponent) {
      this.refS.onboardingComponent.userOnboarding.sections[1].startOpened = true;
      let hiddenSection = this.refS.onboardingComponent.userOnboarding.sections[0];
      let realSection = this.refS.onboardingComponent.userOnboarding.sections[1];
      this.refS.onboardingComponent.startSection(hiddenSection);
      hiddenSection.onCompleteSection.subscribe(() => {
        realSection.completed = true;
      })
    }
  }

  calculateDay() {
    if (this.subS.status?.remaining_days) {
      return new Date().plusDays(this.subS.status?.remaining_days).dayMonthYearFormat();
    }
    else {
      return new Date().plusDays(14).dayMonthYearFormat();
    }
  }

  toggleModule(...modules: ModulesEnum[]) {
    modules.forEach(m => {
      this.company.toggleModule(m, false);
    })
  }
}
