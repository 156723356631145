import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { PayCometResultComponent } from './pay-comet-result/pay-comet-result.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { MemoryParamsService } from 'src/app/services/memory-params.service';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.css'],
    standalone: false
})
export class SubscriptionComponent extends ParameterStateComponent {

  warn = getWarnColor;

  constructor(private d: MatDialog, public subS: SubscriptionService, routerS: RouterService,
    route: ActivatedRoute, private memoryParamS: MemoryParamsService) {
    super(routerS, route, ["status", "showed"])
  }

  override onParams(params: { param: string; value: string; }[]): void {

    let subParam = params.find(p => p.param == "status");
    let showedParam = params.find(p => p.param == "showed");

    if (!subParam) { return; }

    let showDialog = subParam.value == "0" || subParam.value == "1";
    let alreadyShowed = showedParam?.value == "1";

    if (showDialog && !alreadyShowed) {
      this.memoryParamS.add(["showed", "1"]);
      this.openDialog(subParam.value);
    }
  }

  openDialog(value: string) {
    this.d.open(PayCometResultComponent, { width: "600px", autoFocus: false, data: value == "0" ? false : true });
  }
}
