import { Component, EventEmitter, Input, Output } from '@angular/core';
import { M_Action } from 'src/app/models/M_Action';
import { OrTimerService } from 'src/app/services/or-time.service';

@Component({
    selector: 'app-working-or-timer',
    templateUrl: './working-or-timer.component.html',
    styleUrls: ['./working-or-timer.component.css'],
    standalone: false
})
export class WorkingOrTimerComponent {

  @Input({ required: true }) goAction!: boolean;
  @Output() onclickTimer: EventEmitter<M_Action> = new EventEmitter();

  constructor(public timerS: OrTimerService) { }

  diffOnClick() {
    if (this.goAction && this.timerS.refOr) {
      this.timerS.goOr(this.timerS.refOr!)
    }
    
    if (this.timerS.refOr){
      this.onclickTimer.emit(this.timerS.refOr);
    }
  }

  refreshTimer() {
    this.timerS.makeCall();
  }
}
