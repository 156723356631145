import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';

@Component({
    selector: 'app-vehicleform-ficha',
    templateUrl: './vehicleform-ficha.component.html',
    styleUrls: ['./vehicleform-ficha.component.css'],
    standalone: false
})
export class VehicleformFichaComponent {
  minDate = new Date();
  @Input({required : true}) form! : UntypedFormGroup;
  @Input({required : true}) showChassis : boolean = true;
  @Input({required : true}) showKm : boolean = true;
  @Input({required : true}) currentVehicle : M_Vehicle | undefined;
}
