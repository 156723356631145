import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParamsService } from './params.service';
import { Views } from '../custom-classes/View';

export interface AlreadyExistInterface {
  title: string;
  message: string;
  message2: string;
  value: string;
  view: Views | undefined;
  param_id: number | undefined;
  accept_text: string;
}

@Injectable({
  providedIn: "root"
})

@Component({
    selector: 'already-exist-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <p>{{data.message}} <span class="fw500">{{data.value}}</span></p>
    </div>
    <div mat-dialog-actions style="justify-content: center;">
      <button mat-button [mat-dialog-close]="false">Cancelar</button>
      <button color="primary" mat-flat-button [mat-dialog-close]="true" (click)="onOk()">{{data.accept_text}}</button>
    </div>`,
    standalone: false
})
export class AlreadyExistsService {
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: AlreadyExistInterface, private paramS: ParamsService) { }


  /** Checks if a dialog is already open */
  private isDialogOpen(): MatDialogRef<any, any> | undefined {
    let d = this.dialog.openDialogs.find(dialog => dialog.componentInstance instanceof AlreadyExistsService);
    return d;
  }

  /**Muestra un diálogo con el error HTTP */
  show(data: AlreadyExistInterface) {
    let alreadyOpened = this.isDialogOpen();
    if (alreadyOpened) { return alreadyOpened; }
    return this.dialog.open(AlreadyExistsService, { data, autoFocus: false });
  }

  onOk() {
    if (this.data.view && this.data.param_id) {
      this.paramS.go(this.data.view, this.data.param_id)
      this.dialog.closeAll();
    }
  }

  ngOnInit(): void { }

}
