import { M_Center } from "./M_Center";
import { M_Company } from "./M_Company";
import { M_Subscription } from "./M_Subscription";
import { M_User } from "./M_User";

/**
 * Contains : 
 * The current session user
 * The current user subscription
 * The current session company
 */
export class M_EinaData {
    user: M_User;
    subscription: M_Subscription;
    company: M_Company;
    constructor(d: any) {
        this.user = new M_User(d.user);
        this.company = new M_Company(d.company);
        if (d.userCenter) { this.company.userCenter = new M_Center(d.userCenter) };
        this.subscription = new M_Subscription(d.subscription);
        console.log("🏠 Main data", this)
    }
}