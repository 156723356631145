import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { ClassSearcherFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { Views } from 'src/app/custom-classes/View';
import { M_Serie } from 'src/app/models/M_Serie';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { SerieDetailsComponent } from '../serie-details/serie-details.component';
import { CreateSerieComponent } from '../create-serie/create-serie.component';
import { MASTER_BRAND_BRANDMODEL } from 'src/app/constants/masters';
export enum PageSerieEnum {
  DATA_SERIE = 1,
  BRAND = 2,
  VEHICLE_TYPE
}
@Component({
    selector: 'app-series',
    templateUrl: './series.component.html',
    styleUrls: ['./series.component.css'],
    standalone: false
})
export class SeriesComponent implements OnInit {
  f = filter;
  v = ViewPath
  brand_filter = MASTER_BRAND_BRANDMODEL;
  loaded : boolean = true;
  series : M_Serie[] =[];
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Serie>;
  filtersToShow: Filter[] = [
    new TagFilter("Vehículo", undefined, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(PageSerieEnum.VEHICLE_TYPE),
    new ClassSearcherFilter("Marcas", this.brand_filter, "Buscar marca").setId(PageSerieEnum.BRAND),
  ];
  constructor(public apiS: ApiService, private d: MatDialog, public subS: SubscriptionService,
    public sessionS: SessionService, public params: ParamsService) {
  }
  ngOnInit(): void {
    this.apiS.series().then(res=>{
      this.series=res;
      this.ps.initTable(res);
    })
  }
  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageSerieEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }
  
  openDialog() { }
  onCreate() { 
    return 
  }
  viewClick(event: any): Views {

    return this.v.serieDetails
  }
  getIds(event: any): number {

    return event.id;

  }
  codeAccounting(serie:M_Serie){
    return serie.accounting?.name;
  }
  onDialogRef(serie:M_Serie){
    if(serie != undefined){
      return this.d.open(SerieDetailsComponent,{data:serie}).afterClosed().subscribe(res=>{
        this.reloadPage();
      });
    }
    return this.d.open(CreateSerieComponent,{data:serie}).afterClosed().subscribe(res=>{
      this.reloadPage();
    });
  }
  reloadPage(){
    this.apiS.series().then(res=>{
      this.series=res;
      this.ps.initTable(res);
    })
  }
}
