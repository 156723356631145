import { Component, Input, OnInit } from '@angular/core';
import { UserFilter } from '../../../custom-classes/Filter';
import { M_User } from 'src/app/models/M_User';
import { projectConfiguration } from 'src/app/app.module';

@Component({
    selector: 'app-user-filter',
    templateUrl: './user-filter.component.html',
    styleUrls: ['./user-filter.component.css'],
    standalone: false
})
export class UserFilterComponent implements OnInit {
  
  @Input() filter!: UserFilter;
  core = projectConfiguration;

  constructor() { }

  ngOnInit(): void { }

  getTotalOrByUser(u: M_User | undefined): number {
    var total: number = 0;
    this.filter.or.forEach(or => {
      if (u) {
        if (or.assigned?.id == u.id) {
          total += 1;
        }
      }
      else {
        if (or.assigned == undefined) {
          total += 1;
        }
      }

    })
    return total;
  }

}
