import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_ComercialModule extends BaseModule {
    get name() { return "Comercial"; }
    get corename() { return "comercial"; }
    get title() { return "El módulo perfecto para concesionarios"; }
    get desc() { return "Gestiona tu inventario, automatiza procesos y cierra más ventas de vehículos nuevos y de ocasión."; }
    override isMTR(): this is M_MTRModule { return false;}
    
    generateFuncionalities() {
        return [
            { icon: "work", title: "Agentes", tooltip: "Crear contactos de tipo 'agente'" },
            { icon: "garage", title: "VN y VO", tooltip: "Crear y vender vehículos nuevos y de ocasión" },
            { icon: "calculate", title: "Presupeuestos comercial", tooltip: "Crear presupuestos de comercial" },
            { icon: "style", title: "Marcas y modelos", tooltip: "Control de marcas y modelos" },
        ];
    }
    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: `Con el módulo de comercial tendrás un nuevo apartado en el menú llamado "Comercial", lleno de funcionalidades útiles. ¡Anímate a descubrirlo! 🏷️`,
                scrType: "img",
                src: `${this.basePath}/comercial1.png`
            },
            {
                text: `Podrás gestionar la venta de vehículos nuevos y de ocasión, crear presupuestos comerciales ¡y mucho más! 🤝`,
                scrType: "img",
                src: `${this.basePath}/comercial2.png`
            },
            {
                text: `Realiza presupuestos de vehículos con o sin stock y resérvalos a través de los presupuestos. Usa los presupuestos per informar con detalle de tu oferta de vehículos.`,
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            
            {
                text: `Compra y vende vehículos tanto nuevos como de segunda mano, bien sean de proveedores o de clientes que ya tengas entrados en la base de datos.`,
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            {
                text: `Vendes conceptos en tus facturas de vehículos como matriculaciones, transportes o descuentos, y ten controlados los costes de cada venta.`,
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            {
                text: `Abre ORs de comercial y detalla los costes que les vehículos te suponen para la puesta a punto antes de la venta.`,
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
        ]
    }
}