import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_User } from '../../models/M_User';
import { MatDialog } from '@angular/material/dialog';
import { RolesExplanationComponent } from './roles-explanation/roles-explanation.component';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { ViewPath } from 'src/app/app-routing.module';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';

@Component({
    selector: 'app-userform',
    templateUrl: './userform.component.html',
    styles: [':host ::ng-deep mat-form-field, ._customInput  {width : 100%}'],
    standalone: false
})
export class UserformComponent {
  v = ViewPath;
  ST = SETTINGS_TAB;
  RE = RolesEnum;
  public form: UntypedFormGroup;
  originalUser: M_User | undefined;
  superUser = false;

  constructor(private formBuilder: UntypedFormBuilder, private d: MatDialog, public einaDataS: EinaDataService) {
    this.form = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      email: [''],
      role: ['', Validators.required],
      center: ['', Validators.required],
    });
    this.superUser = this.einaDataS.user.superUser;
  }

  initForm(u: M_User | undefined) {
    if (u != undefined) {
      this.originalUser = u;
      this.form.patchValue({
        "id": u.id,
        "name": u.name,
        "email": u.email,
        "role": u.role,
        "center": u.center?.id
      })
      this.form.get('email')?.disable();

      if (u.admin) {
        this.form.get('role')?.disable();
      }

      if (this.superUser && u.admin){
        this.form.get('role')?.addValidators(Validators.required);
      }

      if (!this.superUser) {
        this.form.get('center')?.disable();
      }

    }
  }

  openRolesExplanation() {
    this.d.open(RolesExplanationComponent, { autoFocus: false });
  }

  get showRoleAlert() {
    const r: number = this.form.get('role')!.value;
    if (r == RolesEnum.RECAMBISTA && !this.einaDataS.company.hasModule(ModulesEnum.RECAMBIOS)) {
      return "¡Atención! Te recomendamos activar el módulo de recambios.";
    }
    else if (r == RolesEnum.COMERCIAL && !this.einaDataS.company.hasModule(ModulesEnum.VNVO)) {
      return "¡Atención! Te recomendamos activar el módulo de comercial.";
    }
    return undefined;
  }

}
