import { Component } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { BillPageFiltesrEnum } from '../../invoices/invoices.component';
import { ORPageFiltesrEnum } from '../../or/or.component';
import { AppoPageFiltesrEnum } from '../../appointments/appointments.component';
import { GaragePageFilterEnum } from '../../garage/garage.component';
import { SETTINGS_TAB } from '../../settings/settings.component';
import { feature } from 'src/app/utils/FeaturesController';

/** LAS FUNCIONES DE ESTE COMPONENTE ESTAN EN ESPAÑOL PARA EVITAR EL CAOS TOTAL */

@Component({
    selector: 'app-big-mamma-suggestions',
    templateUrl: './big-mamma-suggestions.component.html',
    styleUrls: ['./big-mamma-suggestions.component.css'],
    standalone: false
})
export class BigMammaSuggestionsComponent {

  v = ViewPath;
  RE = RolesEnum;
  userRole = this.userS.getRole();
  f = feature;

  BPFE = BillPageFiltesrEnum;
  OPFE = ORPageFiltesrEnum;
  APPFE = AppoPageFiltesrEnum;
  GPFE = GaragePageFilterEnum;
  SETT = SETTINGS_TAB;

  today = new Date();

  constructor(public userS: UserService) { }
}
