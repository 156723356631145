import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_TypePayment } from 'src/app/models/M_TypePayment';
import { DialogAdvancePayComponent } from '../dialog-advance-pay/dialog-advance-pay.component';
import { AdvancesArrayComponent } from 'src/app/components/advances-array/advances-array.component';

@Component({
    selector: 'app-advances-client-details-dialog',
    templateUrl: './advances-client-details-dialog.component.html',
    styleUrls: ['./advances-client-details-dialog.component.css'],
    standalone: false
})
export class AdvancesClientDetailsDialogComponent {
  typePayment: M_TypePayment[] = [];
  @ViewChild(AdvancesArrayComponent) tableAdv!: AdvancesArrayComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Contact, public dialogRef: MatDialogRef<AdvancesClientDetailsDialogComponent>,
    private apiS: ApiService, private d: MatDialog) {
    this.apiS.getPaymentType().then(res => {
      this.typePayment = res;
    });
  }

  addAdvancePayment() {
    this.d.open(DialogAdvancePayComponent, { data: { client: this.data, typePayment: this.typePayment, last_movement: this.data?.last_movement } }).afterClosed().subscribe(_res => {
      if (!_res) {
      } else {
        this.tableAdv.initTable(_res);
      }
    });
  }
}
