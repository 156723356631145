import { Component, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Action } from 'src/app/models/M_Action';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { action_type_or } from 'src/app/custom-classes/action_types';
import { Filter, DayFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { IMassiveType, MassiveInvoiceComponent } from 'src/app/components/massive-invoice/massive-invoice.component';
import { MatDialog } from '@angular/material/dialog';
import { OR_STATUS_FILTER, OR_TYPE_FILTER } from 'src/app/constants/SharedFilters';

export enum ORPageFiltesrEnum {
  OR_DELIVEY = 0,
  OR_STATUS = 1,
  OR_GROUP_STATUS = 2,
  OR_TYPE = 3
}

@Component({
    selector: 'app-or',
    templateUrl: './or.component.html',
    styleUrls: ['./or.component.css'],
    standalone: false
})
export class OrComponent {
  primary = getPrimaryColor;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;
  v = ViewPath;
  f = filter
  R = RolesEnum;
  filters: Filter[] = [
    new DayFilter("Fecha entrega").setId(ORPageFiltesrEnum.OR_DELIVEY),
    OR_STATUS_FILTER("Estado general OR").setId(ORPageFiltesrEnum.OR_STATUS),
    OR_STATUS_FILTER("Estado intervenciones").setId(ORPageFiltesrEnum.OR_GROUP_STATUS),
    OR_TYPE_FILTER().setId(ORPageFiltesrEnum.OR_TYPE)
  ]

  constructor(public params: ParamsService, public apiS: ApiService, public subS: SubscriptionService, private d: MatDialog, public companyS: CompanyService) {
    this.load();
  }

  load() {
    this.apiS.action.actions(action_type_or).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == ORPageFiltesrEnum.OR_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  openMassiveOr() {
    this.d.open<MassiveInvoiceComponent, IMassiveType>(MassiveInvoiceComponent, {
      data: {
        type: 'or',
      },
      autoFocus: false
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.load();
      }
    });

  }

  license(action: M_Action) { return action.vehicle?.license; }
  brand(action: M_Action) { return action.vehicle ? action.vehicle.getName() : ''; }
  state(action: M_Action) { return action.status.name; }
  delivery(action: M_Action) { return action.delivery ? action.delivery.dayMonthYearFormat() : "" }
  stateColor(action: M_Action) { return action.getStateColor() }
  preicon(action: M_Action) { return isSomethingMissing(action.faults) }
}
