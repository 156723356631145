import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { or_types_cargo_interno } from 'src/app/custom-classes/or_types';
import { M_Action } from 'src/app/models/M_Action';
import { ParamsService } from 'src/app/services/params.service';

@Component({
    selector: 'app-sell-vehicle-generated-or',
    templateUrl: './sell-vehicle-generated-or.component.html',
    styleUrls: ['./sell-vehicle-generated-or.component.css'],
    standalone: false
})
export class SellVehicleGeneratedOrComponent {

  c_i = or_types_cargo_interno;

  constructor(private paramsS: ParamsService, @Inject(MAT_DIALOG_DATA) public data: M_Action, public dRef: MatDialogRef<SellVehicleGeneratedOrComponent>) { }
  goAction() {
    this.paramsS.go(ViewPath.editOr, this.data.id);
    this.dRef.close();
  }
}
