import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { R_Zip } from 'src/app/models/R_Zip';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
    selector: 'app-full-address',
    templateUrl: './full-address.component.html',
    styleUrls: ['./full-address.component.css'],
    standalone: false
})
export class FullAddressComponent implements OnInit, OnChanges {

  loadingZip = false;
  selectedPayment: any;
  arrayZips: R_Zip[] | undefined = [];
  personalizadoSelected = false;
  finalsearh = "";

  @Input({ required: true }) required: boolean = true;
  @Input({ required: true }) initValue!: { zip: R_Zip[] | undefined, city: string | number | undefined };
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) component!: string;

  constructor(private chdRef: ChangeDetectorRef, private apiS: ApiService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initValue']) {
      let init = changes['initValue'].currentValue as { zip: R_Zip[] | undefined, city: string | number | undefined };
      console.log(init)
      if (init) {
        if (Array.isArray(init.zip) && init.zip.length) {
          this.arrayZips = init.zip;
          console.log(this.component, "All zips", this.arrayZips)
          this.form.get('province')?.patchValue(init.zip[0].provincia)
          if (init.city) {
            let finalCity = init.city.toString().getNumber();
            let finded = this.arrayZips.find(z => z.id == finalCity);
            if (!finalCity || !finded) {
              console.log(this.component, "Zip not finded, patching with the first of the array. ID", init.zip[0].id)
              this.initializeValue(init.zip[0]);
            }
            else {
              console.log(this.component, "Zip finded, patching with the ID", finded.id)
              this.initializeValue(finded);
            }
          }
        }
      }
    }
    if (changes['required']) {
      let req = changes['required'].currentValue;
      let fcNames = ['address', 'zip', 'city', 'ccaaObject', 'ccaa', 'province']
      if (req == false) {
        fcNames.forEach(n => {
          let v = this.form.get(n);
          v?.removeValidators(Validators.required);
          v?.updateValueAndValidity()
        })
      }
      else {
        fcNames.forEach(n => {
          let v = this.form.get(n);
          v?.addValidators(Validators.required);
          v?.updateValueAndValidity()
        })
      }
      this.form.updateValueAndValidity({ emitEvent: true });
      this.chdRef.detectChanges();
    }
  }


  ngOnInit(): void {
    console.log(this.initValue)
    this.form.get('province')?.disable();
    this.form.get('ccaaObject')?.disable();
  }

  private initializeValue(z: R_Zip) {
    this.form.get('city')?.patchValue(z.id)
    this.form.patchValue({ 'ccaaObject': z.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': z.ccaa.id })
    console.log(this.component, "Init citi id", z.id);
  }

  onZipChange(search?: string) {

    let searched = search ? search : this.form.get('zip')!.value as string;
    console.log("On zip changes")

    this.finalsearh = searched;
    let zipControl = this.form.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {
        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length >= 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          console.log("Invalid zip")
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }


  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;

    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );
    let zipControl = this.form.get('zip')!;

    if (selectedValue) {

      this.markZipAsValid(zipControl, this.selectedPayment)
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }

    this.form.get('city')?.updateValueAndValidity();
  }

  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    this.form.patchValue({ 'city': model.id })
    this.form.patchValue({ 'province': model.provincia })
    this.form.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }

  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.form.patchValue({ 'city': undefined })
    this.form.patchValue({ 'province': undefined })
    this.form.patchValue({ 'ccaaObject': undefined })
    this.form.patchValue({ 'ccaa': undefined })
  }

}
