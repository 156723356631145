<form *ngIf="isTotalPay && isDialogForm" [formGroup]="form" class="df fdc line_form form_pay">
    <div class="header_form">
        <app-card-title *ngIf="isDialogForm" [first]="true">{{ typePayName
            }}</app-card-title>
        <app-documentation-pdf-menu [invoice]="invoice"></app-documentation-pdf-menu>
        <!--falta poner la factura compra en el documentation-->
    </div>
    <app-card-subtitle *ngIf="isDialogForm" [first]="true">{{ PayName }} Nº
        {{TitleObject}}</app-card-subtitle>
    <mat-divider></mat-divider>
    <div>
        <div class="header_info">
            <app-invoice-totals [pending_total_invoice]="pendingTotalInv" [total_invoice]="total"></app-invoice-totals>
        </div>

    </div>


    <div class="form">

        <app-payment-chips [fc]="form.get('payment_id')!" (onSelect)="selectPayment()"></app-payment-chips>

        <div class="df">
            <div eForm-wrapper class="FormMargin">
                <div class="df" eForm-section>
                    <mat-form-field appearance="outline" class="w50 mr10">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dynamicDate">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="example-full-width w50 mr10" appearance="outline">
                        <mat-label>Importe</mat-label>
                        <input formControlName="import" #importHtml matInput type="number" DecimalInput
                            (input)="checkImport(importHtml)">
                        <mat-error
                            *ngIf="form.get('import')?.hasError('error_import') && form.get('payment_id')?.value !=1">El
                            importe supera el saldo pendiente.</mat-error>
                        <mat-hint *ngIf="cash && cashTotal" class="msg_hint">
                            Debes devolver : <span class=" ml5 fw600" money [val]="cashTotal"></span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <span *ngIf="showAdvances && !invoice.isAbono">
        <div *ngFor="let last_movement of advances">
            <app-show-advance-client 
              #lsmv
              [dataMostrada]="last_movement"
              (onSelect)="onSelectAdvance($event)"
              (ondisSelect)="onDeselectAdvance($event)"
              [pendingImportTotal]="pendingFactura"
              >
            </app-show-advance-client>
          </div>
    </span>

    <p *ngIf="isDialogForm && selectedAdvances.length" class="c_tw fsi fss">
        Se aplicará los anticipos seleccionados por el valor de 
        <span class="fw600" money [val]="getSelectedAdvancesTotal()"></span>
    </p>
    <div class="action mt10">
        <button [mat-dialog-close]="false" mat-button class="mr10 w50">Cancelar</button>
        <button color="primary" class="w50 onboarding-invoice-payment-finish"
            [disabled]="!form.valid || form.get('import')?.value <=0" mat-flat-button
            (click)="updateForm()">{{typePayName}}</button>
    </div>
</form>