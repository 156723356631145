import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-day-percentage-bar',
    templateUrl: './day-percentage-bar.component.html',
    styleUrls: ['./day-percentage-bar.component.css'],
    standalone: false
})
export class DayPercentageBarComponent {

  @Input({ required: true }) percentage!: number;

}
