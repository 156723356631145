import { Component } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { AlreadyExistsService } from 'src/app/services/already-exists.service';
import { asyncPlate } from 'src/app/validators/plateAsyncValidator';

@Component({
    selector: 'app-example-form',
    templateUrl: './example-form.component.html',
    styleUrls: ['./example-form.component.css'],
    standalone: false
})
export class ExampleFormComponent {

  form: UntypedFormGroup;

  constructor(fb: FormBuilder, private apiS: ApiService, private existsS: AlreadyExistsService) {
    this.form = fb.group({
      required_field: ['', Validators.required],
      license: ['', {
        validators: [Validators.required],
        asyncValidators: [asyncPlate(this.apiS, this.existsS, () => this.getCurrentVehicle())],
      }]
    });
  }

  getCurrentVehicle() { return undefined; }

  formExample: string = 
`
<form eForm [formGroup]="form">

    <app-card-subtitle [first]="true">Sección con 1 input</app-card-subtitle>
    <!-- Sección del formulario con 1 input -->
    <div eForm-wrapper>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Input requerido</mat-label>
                <input matInput formControlName="required_field">
            </mat-form-field>
        </div>
    </div>

    <app-card-subtitle>Sección con validador asíncrono</app-card-subtitle>
    <!-- Sección con un validador asíncrono -->
    <div eForm-wrapper>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Matricula</mat-label>
                <!-- Para que salga el 'loading', se tiene que poner este tag -->
                <app-async-validator-spinner matSuffix [control]="form.get('license')"></app-async-validator-spinner>
                <input matInput placeholder="Matricula" formControlName="license" />
                <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                    La matrícula ya existe.
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <app-card-subtitle>Sección con 2 inputs</app-card-subtitle>
    <!-- Sección del formulario con 2 inputs -->
    <div eForm-wrapper>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Test</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test2</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>

    <app-card-subtitle>Sección con 3 inputs</app-card-subtitle>
    <!-- Sección del formulario con 3 inputs -->
    <div eForm-wrapper>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Test</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test2</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test3</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>

    <app-card-subtitle>Sección con 4 inputs</app-card-subtitle>
    <!-- Sección del formulario con 4 inputs -->
    <div eForm-wrapper>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Test</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test2</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test3</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Test4</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>

    <!-- El botón de enviar el formulario-->
    <!-- Por defecto, DESHABILITADO si el formulario no es válido -->
    <div class="df jcc">
        <button mat-flat-button color="primary" [disabled]="!form.valid">Ok</button>
    </div>

</form>
`
;
}
