import { AfterViewInit, Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { Filter, TagFilter, SliderFilter, FilterOption, ButtonToggleFilter } from 'src/app/custom-classes/Filter';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { VehiclesPageFiltersEnum } from 'src/app/views/vehicles/vehicles.component';
import { GaragePageFilterEnum } from '../../garage.component';
import { VEHICLE_TYPE } from 'src/app/constants/SharedFilters';
import { ParamsService } from 'src/app/services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { M_GroupedVehicle } from 'src/app/models/Vehicles/M_GroupedVehicle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-single-garage',
    templateUrl: './single-garage.component.html',
    styleUrls: ['./single-garage.component.css'],
    standalone: false
})
export class SingleGarageComponent implements AfterViewInit {

  @Input({ required: true }) rightMenu!: TemplateRef<any>;
  @Input() groupBy?: TemplateRef<any>;

  @ViewChild(PageStructureComponent) ps?: PageStructureComponent<M_Vehicle>;

  v = ViewPath;

  sfPrice = new SliderFilter("Precio", 9999).setId(GaragePageFilterEnum.VEHICLE_PRICE);
  sfCost = new SliderFilter("Coste", 9999).setId(GaragePageFilterEnum.VEHICLE_COST);
  sfDaysStock = new SliderFilter("Días en stock", 9999).setId(GaragePageFilterEnum.VEHICLE_DAYS_STOCK);

  filters: Filter[] = [
    new TagFilter("Tipo", undefined, new FilterOption("VN"), new FilterOption("VO")).setId(GaragePageFilterEnum.VEHICLE_TYPE),
    VEHICLE_TYPE(),
    new ButtonToggleFilter("Reservado", undefined, true).setId(GaragePageFilterEnum.VEHICLE_RESERVED),
    new ButtonToggleFilter("Compra registrada", undefined, true).setId(GaragePageFilterEnum.VEHICLE_REGISTERED_BUY),
    this.sfPrice,
    this.sfCost,
    this.sfDaysStock,
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_GroupedVehicle | undefined, public dRef: MatDialogRef<any>, public paramsS: ParamsService) { }

  get hasData() {
    return this.data != undefined && this.data instanceof M_GroupedVehicle;
  }

  ngAfterViewInit(): void {
    if (this.ps && this.data && this.data instanceof M_GroupedVehicle) {
      this.ps?.initTable(this.data.vehicles);
      this.changeMaxs(this.data.vehicles);
    }
  }

  changeMaxs(vehicles: M_Vehicle[]) {
    this.sfPrice.changeMax(Math.max(...vehicles.map(v => v.price || 0)));
    this.sfCost.changeMax(Math.max(...vehicles.map(v => v.cost || 0)));
    this.sfDaysStock.changeMax(Math.max(...vehicles.map(v => v.daysOnStock || 0)));
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == GaragePageFilterEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  get listTitleText() {
    return !this.hasData ? "Listado de stock de vehículos" : "Stock de " + this.data?.bName + " " + this.data?.mName;
  }

  preIcon(v: M_Vehicle) {
    return v.icon;
  }

  daysOnStockTxt(v: M_Vehicle) {
    return v.daysOnStockTxt;
  }

  isReserved(v : M_Vehicle){
    return v.isReserved ? "Sí" : "No"
  }
  

  isBuyRegeistered(v: M_Vehicle) {
    return v.isBuyRegeistered ? "Sí" : "No"
  }

  preIconDaysStock(v: M_Vehicle) {
    let d = v.daysOnStock;

    if (!d) { return 'signal_cellular_alt_1_bar'; }

    if (d >= 5 && d <= 15) {
      return 'signal_cellular_alt_1_bar';
    }
    else if (d >= 16 && d <= 30) {
      return 'signal_cellular_alt_2_bar';
    }
    else if (d > 30) {
      return 'signal_cellular_alt';
    }
    else {
      return 'signal_cellular_alt_1_bar';
    }
  }

  filter(object: M_Vehicle, ...filters: Filter[]): boolean {
    var fitleredFilters = filters.filter(f => f.activated);
    let isVNVO = true;
    let isTypeOk = true;
    let isPriceOk = true;
    let isCostOk = true;
    let daysInStock = true;
    let registeredBuy = true;
    let isReserved = true;

    fitleredFilters.forEach(f => {
      if (f instanceof TagFilter && f.id == GaragePageFilterEnum.VEHICLE_TYPE) {
        isVNVO = f.checkFilter([object.comercialType == ComercialVehicleType.VN ? 0 : 1])
      }
      if (f instanceof TagFilter && f.id == VehiclesPageFiltersEnum.VEHICLE_TYPE) {
        isTypeOk = f.checkFilter([object.isBike ? 0 : object.isCar ? 1 : 2])
      }
      if (f instanceof SliderFilter && f.id == GaragePageFilterEnum.VEHICLE_PRICE) {
        isPriceOk = f.checkFilter(object.price);
      }
      if (f instanceof SliderFilter && f.id == GaragePageFilterEnum.VEHICLE_COST) {
        isCostOk = f.checkFilter(object.cost);
      }
      if (f instanceof SliderFilter && f.id == GaragePageFilterEnum.VEHICLE_DAYS_STOCK) {
        daysInStock = f.checkFilter(object.daysOnStock);
      }
      if (f instanceof ButtonToggleFilter && f.id == GaragePageFilterEnum.VEHICLE_REGISTERED_BUY) {
        registeredBuy = f.checkFilter(object.isBuyRegeistered);
      }
      if (f instanceof ButtonToggleFilter && f.id == GaragePageFilterEnum.VEHICLE_RESERVED) {
        isReserved = f.checkFilter(object.isReserved);
      }
    })

    return isVNVO && isTypeOk && isPriceOk && isCostOk && daysInStock && registeredBuy && isReserved;
  }

  get isOnDialog() {
    return Object.keys(this.dRef).length != 0;
  }

  onClickRow(v: M_Vehicle) {
    this.paramsS.go(this.v.vnvoDetails, v.vehicle_id)
    if (this.isOnDialog) {
      this.dRef.close();
    }
  }


}
