<div class="advanced-details-container">

    <app-view-title>
        Registrar compra
    </app-view-title>

    <app-card class="mt20">
        <form eForm [formGroup]="form">
            <app-card-subtitle>Datos básicos</app-card-subtitle>
            <div eForm-wrapper>

                <div eForm-section>
                    <app-class-searcher [showLeftNumber]="false" #ownerProvider [masterClass]="client" [allData]=""
                        [width100]="true" searchPlaceHolder="Propietario o proveedor" [extendedInfo]="false"
                        (onSelect)="refreshLists($event)" [form_]="form" [formCname]="'client_id'" [required]="true"
                        [formInitEmit]="false" [createData]="{
                            text : 'Nuevo contacto',
                            by : v.createContact
                        }">
                    </app-class-searcher>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de compra</mat-label>
                        <mat-select formControlName="buy_type">
                            <mat-select-trigger>
                                <ng-container *ngIf="buyTypeRecambios">
                                    <div class="df">
                                        <mat-icon class="vam mr5" smartIcon>barcode</mat-icon>
                                        Recambios
                                    </div>

                                </ng-container>
                                <ng-container *ngIf="buyTypeVehicles">
                                    <div class="df">
                                        <mat-icon class="vam mr5" smartIcon>wheel_outline</mat-icon>
                                        Vehículos
                                    </div>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option [value]="BT.RECAMBIOS">
                                <mat-icon smartIcon>barcode</mat-icon>
                                Recambios
                            </mat-option>
                            <mat-option [value]="BT.VEHICLE">
                                <mat-icon smartIcon>wheel_outline</mat-icon>
                                Vehículos
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <app-buy-simple-form [showOwner]="false" [showCost]="false" [showIva]="false"
                        [form]="form"></app-buy-simple-form>
                </div>
            </div>


            <!-- Final Step-->
            <div [dn_i]="noBuyType || cSearcher?.selected == undefined">

                <app-card [noStyle]="true" [contentLoaded]="!refreshing">
                    <!-- Enter recambios -->
                    <div [dn_i]="buyTypeVehicles">
                        <app-card-subtitle>Entadas de almacén</app-card-subtitle>
                        <p class="c_t2" *ngIf="!allProvStoreHouseEntry.length">Sin entradas de almacén</p>
                        <div *ngFor="let provEntry of allProvStoreHouseEntry">
                            <div eForm-wrapper>
                                <div class="df aic">
                                    <mat-checkbox (change)="provEntry.checked = $event.checked">
                                        <div class="df fdc">
                                            <div class="df gap10">
                                                <span class="fw600">{{provEntry.number}}</span>
                                                <ng-container *ngIf="provEntry.dnote_prov_num">
                                                    <span class="c_t2">|</span>
                                                    <span class="c_t2">Documento Nº{{provEntry.dnote_prov_num}}</span>
                                                </ng-container>
                                            </div>
                                            <p class="c_t2 fss nmb fsi"> {{provEntry.created_at?.dayMonthYearFormat()}}</p>
                                        </div>
                                    </mat-checkbox>
                                    <div class="df mla aic gap10" #trigger>
                                        <span>{{provEntry.products.length}}
                                            <span plural
                                                [val]="[provEntry.products.length, 'producto', 'productos']"></span>
                                        </span>
                                        <span class="c_t2" money [val]="provEntry.total"></span>
                                        <!-- <div *ngIf="provEntry.products.length">
                                            <mat-icon>arrow_drop_up</mat-icon>
                                        </div> -->
                                    </div>
                                </div>

                                <!-- <div *ngIf="provEntry.products.length" dropDown [starClosed]="true" [content]="content"
                                    #content [trigger]="trigger">
                                    <p *ngFor="let p of provEntry.products">
                                        <span>
                                            <mat-icon smartIcon>{{p.icon}}</mat-icon>
                                            <span>{{p.name}}</span>
                                            <span class="c_t2">{{p.reference}}</span>
                                            <span class="c_t2">{{p.quantity}}</span>
                                            <span class="c_t2">{{p.price}}</span>
                                        </span>
                                        
                                    </p>
                                </div> -->
                            </div>
                            <p></p>
                        </div>
                    </div>

                    <!-- Enter vehicles -->
                    <div [dn_i]="buyTypeRecambios">
                        <app-card-subtitle>Vehículos</app-card-subtitle>
                        <app-vehicle-line-table [showSave]="false" [vehicles]="vehicles"
                            [canAddNew]="false"></app-vehicle-line-table>
                        <div class="mb20"></div>
                        <app-card-subtitle>Conceptos</app-card-subtitle>
                        <app-concepts-line-table [concepts]="concepts" [showSave]="false" [isInfo]="false"
                            [canModifyTable]="true"></app-concepts-line-table>
                    </div>

                    <!-- Create button -->
                    <div class="df jcc mt20">
                        <button [disabled]="!canPurchase" mat-flat-button color="primary" (click)="createPurchase()">
                            Registrar
                        </button>
                    </div>
                </app-card>

            </div>
        </form>
    </app-card>
</div>