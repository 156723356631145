<mat-form-field appearance="outline">
    <mat-label>Seleccionar país</mat-label>
    <input [formControl]="aux" autocomplete="off" matInput [matAutocomplete]="countryAutocomplete" />
    <mat-progress-bar *ngIf="loading" mode="buffer"></mat-progress-bar>
    <div matSuffix>
        <img class="suffix-left" *ngIf="img" [src]="img">
    </div>
    <mat-autocomplete #countryAutocomplete="matAutocomplete" (optionSelected)="onOptionsSelected($event)"
        [displayWith]="displayName">
        <mat-option *ngFor="let country of filteredOptions | slice:0:MAX_OPTIONS" [value]="country">
            <img *ngIf="country.flag" [src]="country.flag">
            {{country.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>