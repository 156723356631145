import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelationAndReturnPolicyComponent } from 'src/app/components/cancelation-and-return-policy/cancelation-and-return-policy.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { FormControl } from '@angular/forms';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ViewPath } from 'src/app/app-routing.module';
import { RouterService } from 'src/app/services/router.service';

@Component({
    selector: 'app-subscription-option',
    templateUrl: './subscription-option.component.html',
    styleUrls: ['./subscription-option.component.css'],
    standalone: false
})
export class SubscriptionOptionComponent implements OnInit {
  @Input() price!: number;
  @Input() secondPrice!: number;
  @Input() discount!: number;
  @Input() label!: string;
  @Input() icon!: string;
  @Input() color!: string;
  @Input() granularity!: string;
  @Input() isLandingpage: boolean = false;
  loadingLink = false;
  code: FormControl<string> = new FormControl()

  constructor(private d: MatDialog, private apiS: ApiService, private subS: SubscriptionService, private routerS: RouterService) { }

  ngOnInit(): void { }

  get totalSavings(): number {
    return (this.price * Number('0.' + this.discount)).castDecimals(2);
  }


  subscribe(code?: string) {

    if (this.isLandingpage) { this.routerS.goTo(ViewPath.register); return; }
    if (this.loadingLink) { return; }

    this.loadingLink = true,
      this.apiS.payCometSubscribe(code).then(res => {
        if (res) {
          window.open(res, "_self");
        }
        else {
          this.subS.genericSubscriptionError("Error al obtener la URL de pago", "Ponte en contacto con nosotros para solventar este problema");
        }
        this.loadingLink = false;
      })
  }


  openCancelationDialog() {
    this.d.open(CancelationAndReturnPolicyComponent, { autoFocus: false });
  }
}
