
<ng-template #groupByBrandAndModel>
    <div class="groupBy">
        <mat-slide-toggle [(ngModel)]="grouped" (change)="onCheckBoxChange($event)">Agrupar marca y modelo</mat-slide-toggle>
    </div>    
</ng-template>

<app-grouped-garage [dn_i]="!grouped" [rightMenu]="rightMenu" [groupBy]="groupByBrandAndModel"></app-grouped-garage>
<app-single-garage [dn_i]="grouped" [rightMenu]="rightMenu" [groupBy]="groupByBrandAndModel"></app-single-garage>


<ng-template #rightMenu>
    <button mat-menu-item (click)="openBrand()">
        <mat-icon>style</mat-icon>
        Marcas
    </button>
    
    <button mat-menu-item (click)="openModel()">
        <mat-icon>transportation</mat-icon>
        Modelos
    </button>

    <button mat-menu-item (click)="openSeries()">
        <mat-icon>barcode_reader</mat-icon>
        Series
    </button>

    <button mat-menu-item (click)="openColors()" *ngIf="f.colors">
        <mat-icon>palette</mat-icon>
       Colores
    </button>

    <button mat-menu-item (click)="routerS.goTo(v.concepts)">
        <mat-icon>playlist_add_check_circle</mat-icon>
        Conceptos
    </button>
</ng-template>