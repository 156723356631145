import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack.service';
import { QRCodeComponent } from 'angularx-qrcode';
import { CloseDialogComponent } from '../close-dialog/close-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-qr-dialog',
    imports: [
      QRCodeComponent,
      CloseDialogComponent,
      MatIconModule,
      MatButtonModule,
      MatDialogModule
    ],
    templateUrl: './qr-dialog.component.html',
    styleUrls: ['./qr-dialog.component.css'],
})
export class QrDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<QrDialogComponent>, private snackS: SnackService) { }

  downloadQR() {
    var link = document.createElement('a');
    link.download = 'qr-pedir-citas.png';
    let canvas = document.getElementById('qrcode-tag-ref')!.getElementsByTagName('canvas')[0]! as HTMLCanvasElement;
    link.href = canvas.toDataURL();
    link.click();
    this.snackS.show("¡QR descargado con éxito!")
  }
}
