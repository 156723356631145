import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { TextFilter } from "../../../custom-classes/Filter";

@Component({
    selector: 'app-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.css'],
    standalone: false
})
export class TextFilterComponent implements OnInit {
  @Input() filter!: TextFilter;
  formControl: UntypedFormControl;
  filteredOptions: Observable<string[]> | undefined;

  constructor() {
    this.formControl = new UntypedFormControl("");
  }

  ngOnInit(): void {

    this.formControl.valueChanges.subscribe(val => {
      this.filter.searchedText = val;
    })

    if (this.filter.searchedText) {
      this.formControl.patchValue(this.filter.searchedText, { emitEvent: false });
    }

    /*this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );*/
  }
}
