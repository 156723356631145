import { ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Fault } from 'src/app/models/M_Fault';
import { M_PreOrder } from 'src/app/models/M_PreOrder';
import { M_Product } from 'src/app/models/Products/M_Product';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getBookMarkColorStyle } from 'src/app/enums/BookMarkEnum';
import { M_Order } from 'src/app/models/M_Order';
import { MatDialog } from '@angular/material/dialog';
import { AddProviderToProductOrFaultComponent } from './add-provider-to-product-or-fault/add-provider-to-product-or-fault.component';
import { getRandomNoFaultMessage } from 'src/app/constants/constants';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.css'],
    standalone: false
})
export class CreateOrderComponent implements IProductLineTableComponent {

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean {return false;}
  
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild("providerSearch") providerSearch!: ClassSearcherComponent<M_Contact>;
  @ViewChild(MatStepper) stepper!: MatStepper;

  v = ViewPath;
  MASTER_PROVIDER = MASTER_PROVIDER;

  orderFailedToLoad = false;
  loaded = false;
  products: M_Product[] = [];
  preOrder: M_PreOrder = new M_PreOrder({});
  faults: M_Fault[] = []
  createdOrders: M_Order[] = [];
  created: boolean = false;
  no_stock_message: string;

  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;
  getBookMarkColorStyle = getBookMarkColorStyle;

  firstStep = new FormControl<boolean>(this.preOrder.isOk(), this.firstStepOk());
  secondStep = new FormControl<boolean>(this.preOrder.isOk(), this.secondStepOk());

  constructor(public routerS: RouterService, public apiS: ApiService, private chdRef: ChangeDetectorRef,
    private d: MatDialog, private confirmD: ConfirmDialogService) {
    this.loaded = false;
    this.apiS.getFaults().then(res => {
      this.faults = res.filter(f => { return f.location != undefined });
      this.loaded = true;
    })
    this.no_stock_message = getRandomNoFaultMessage();
  }

  deleteFault(fault: M_Fault) {
    if (this.preOrder.contains(fault) || !fault.isManual) { return; }
    this.confirmD.show({
      title: "Eliminar falta",
      body: "¿Está seguro de que quieres eliminar la falta?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteFault(fault.id).then(res => {
          this.faults.removeElement(fault);
        })
      }
    })

  }

  removeProduct(p: M_Product | M_CustomProduct): void {
    if (p instanceof M_Product) { this.preOrder.remove(p); }
  }

  addTime(time: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    if (p instanceof M_Product) { this.preOrder.add(p); }
  }

  createOrder() {
    this.stepper.next();
    this.apiS.createOrder(this.preOrder.getBreakdown()).then(res => {
      this.created = true;
      this.createdOrders = res;
      this.chdRef.detectChanges();
      this.stepper.next();
    })
  }

  firstStepOk(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return this.preOrder.isOk() ? null : { isOk: false };
    }
  }

  secondStepOk(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return this.created ? null : { isOk: false };
    }
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    return null;
  }

  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  addNewProvider(fault: M_Fault) {
    this.d.open(AddProviderToProductOrFaultComponent, { data: fault, autoFocus: false }).afterClosed().subscribe(val => {
      if (val instanceof M_Contact) {
        this.faults.forEach(f => {
          if (f.product_id == fault.product_id) {
            f.product?.addNewProvider(val);
          }
        })
      }
    });
  }

  get faultsWithProviders() {
    return this.faults.filter(f => f.hasProviders)
  }


  getClient() {
    return undefined;
  }

}
