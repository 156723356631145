import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/constants/Endpoints';
import { M_Schedule } from 'src/app/models/M_Schdeule';
import { M_Signing } from 'src/app/models/Signing/M_Signing';
import { M_SigningTime } from 'src/app/models/Signing/M_SigningTime';
import { getArrayOf } from 'src/app/utils/FunctionUtils';
import { ConfirmDialogService } from '../../confirm-dialog.service';
import { M_SigningPause } from 'src/app/models/Signing/M_SigningPause';

@Injectable({
  providedIn: 'root'
})
export class ApiSigningService {

  constructor(private http: HttpClient, private confirmD: ConfirmDialogService) { }

  getUserSchedule(user_id: number) {
    return new Promise<{ accountForWorkload: boolean | null, schedule: M_Schedule[] }>(resolve => {
      this.http.post<any>(endpoints.getUserSchedule.url, { user_id: user_id }).subscribe(
        data => {
          const acount = data.accountForWorkload;
          const schedule = getArrayOf(M_Schedule, data.schedule);
          resolve({
            accountForWorkload: acount,
            schedule: schedule
          });
        }
      );
    })
  }

  updateSchedule(obj: { accountForWorkload: boolean; schedule: M_Schedule[], user_id?: number } | M_Schedule[]) {
    return new Promise<boolean>(resolve => {

      let s = Array.isArray(obj) ? obj : obj.schedule;
      s.map((day: M_Schedule, index: number) => (
        day.name = index.toString()
      ))

      this.http.post<any>(endpoints.updateUserSchedule.url, obj).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  getMyPresence() {
    return new Promise<M_Signing>(resolve => {
      this.http.post<any>(endpoints.myPresence.url, {}).subscribe(
        data => {
          resolve(new M_Signing(data));
        }
      );
    })
  }


  startEnd(signing: M_SigningTime) {
    return new Promise<M_SigningTime>(resolve => {
      this.http.post<any>(endpoints.startStopSigning.url, signing).subscribe(
        data => {
          resolve(new M_SigningTime(data));
        },
        error => {
          this.confirmD.show({
            title: "Algo salió mal en el fichaje :/",
            body: "Recomendamos actualizar la página para evitar errores",
            disableClose: true,
            confirmTxt: "Refrescar",
            showCancel: false,
            type: "info",
            action: "refresh"
          })
          resolve(new M_SigningTime(error));
        }
      );
    })
  }

  pauseResume(signing: M_SigningTime, pause : M_SigningPause){
    return new Promise<M_SigningTime>(resolve => {
      this.http.post<any>(endpoints.pauseResume.url, {
        id : signing.id,
        time_id : pause.id
      }).subscribe(
        data => {
          resolve(new M_SigningTime(data));
        },
        error => {
          this.confirmD.show({
            title: "Algo salió mal en el fichaje :/",
            body: "Recomendamos actualizar la página para evitar errores",
            disableClose: true,
            confirmTxt: "Refrescar",
            showCancel: false,
            type: "info",
            action: "refresh"
          })
          resolve(new M_SigningTime(error));
        }
      );
    })
  }

  editMyPresence(s: M_SigningTime) {
    return new Promise<M_SigningTime>(resolve => {
      this.http.post<M_SigningTime>(endpoints.createEditMyPresence.url, s).subscribe(
        data => {
          resolve(new M_SigningTime(data));
        }
      );
    })
  }

}
