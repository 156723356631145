import { Component, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { RouterService } from 'src/app/services/router.service';
import { feature } from 'src/app/utils/FeaturesController';
import { M_GroupedVehicle } from 'src/app/models/Vehicles/M_GroupedVehicle';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Model } from 'src/app/models/M_Model';
import { MatDialog } from '@angular/material/dialog';
import { GroupedGarageComponent } from './PageStructures/grouped-garage/grouped-garage.component';
import { SingleGarageComponent } from './PageStructures/single-garage/single-garage.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ActivatedRoute } from '@angular/router';
import { MemoryParamsService } from 'src/app/services/memory-params.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StorageService } from 'src/app/services/storage.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export const GARAGE_TYPE_NAME = "groupBy"

export enum GARAGE_TYPE {
  GROUPED = 1,
  INDIVIDUAL = 2
}

export enum GaragePageFilterEnum {
  VEHICLE_TYPE = 0,
  VEHICLE_PRICE = 1,
  VEHICLE_COST = 2,
  VEHICLE_DAYS_STOCK = 3,
  VEHICLE_REGISTERED_BUY = 4,
  VEHICLE_RESERVED = 5,
}

@Component({
    selector: 'app-garage',
    templateUrl: './garage.component.html',
    styleUrls: ['./garage.component.css'],
    standalone: false
})
export class GarageComponent extends ParameterStateComponent {

  grouped = true;

  @ViewChild(GroupedGarageComponent) groupedGarage?: GroupedGarageComponent;
  @ViewChild(SingleGarageComponent) singleGarage?: SingleGarageComponent;

  f = feature;
  v = ViewPath;

  constructor(routerS: RouterService, route: ActivatedRoute, public apiS: ApiService, public paramsS: ParamsService,
    public subS: SubscriptionService, private memoryParamsS: MemoryParamsService, private storageS: StorageService) {
    super(routerS, route, [GARAGE_TYPE_NAME])
    apiS.vnvo.vehicles().then(resp => {

      if (this.groupedGarage?.ps) {
        this.groupedGarage.ps.initTable(this.groupVehiclesByBrandAndModel(resp));
      }

      if (this.singleGarage?.ps) {
        this.singleGarage.ps.initTable(resp);
        this.singleGarage.changeMaxs(resp);
      }

    });
  }

  override noParams(): void {
    let groupedChecked = this.storageS.getBoolean(GARAGE_TYPE_NAME);
    if (groupedChecked == null) { return; }
    this.grouped = groupedChecked;
  }

  override onParam(param: string, value: string): void {
    this.grouped = value.getNumber() == GARAGE_TYPE.INDIVIDUAL ? false : true;
  }

  onCheckBoxChange(v: MatSlideToggleChange) {
    this.memoryParamsS.add([GARAGE_TYPE_NAME, v.checked ? GARAGE_TYPE.GROUPED.toString() : GARAGE_TYPE.INDIVIDUAL.toString()])
    this.storageS.save(GARAGE_TYPE_NAME, v.checked);
  }

  groupVehiclesByBrandAndModel(vehicles: M_Vehicle[]): M_GroupedVehicle[] {
    const groupedMap = new Map<string, M_GroupedVehicle>();

    vehicles.forEach(vehicle => {
      const brandId = vehicle.brand?.id || vehicle.brand_id;
      const modelId = vehicle.model?.id || vehicle.model_id;

      if (brandId === undefined || modelId === undefined) {
        // Opcional: manejar casos donde no haya marca o modelo definido.
        console.warn(`Vehículo sin marca o modelo: ${vehicle}`);
        return;
      }

      const key = `${brandId}-${modelId}`; // Combina brand_id y model_id como clave única.

      if (!groupedMap.has(key)) {
        const brand = vehicle.brand || new M_Brand({ id: brandId, name: vehicle.brand_name });
        const model = vehicle.model || new M_Model({ id: modelId, name: vehicle.model_name });
        groupedMap.set(key, new M_GroupedVehicle(brand, model));
      }

      groupedMap.get(key)?.addVehicle(vehicle);
    });

    let finalArray = Array.from(groupedMap.values());
    finalArray.sort((a, b) => a.brand.name.localeCompare(b.brand.name));
    return finalArray;
  }

  openModel() { return this.routerS.goTo(this.v.models); }
  openColors() { return this.routerS.goTo(this.v.colors); }
  openSeries() { return this.routerS.goTo(this.v.series); }
  openBrand() { return this.routerS.goTo(this.v.brands); }
}

