<app-page-structure class="single-ps-garage" [masterClass]="'vehicle'" pageTitle="Stock de vehículos" [autocompleteID]="'vehicle_garage'"
    searchLabel="Buscar vehículo" noDataCreateNew="Entrar vehículo en stock" [data]="[]"  noDataCreateNew="Entrar vehículo en stock"
    [listTitleText]="listTitleText"
    [filter]="filter" [filters]="filters"
    [displayedHeader]=" ['Marca',        'Modelo',       'Precio',   'Coste',  'Reservado',  'Compra registrada',    'Días en stock',    'Tipo'        ]"
    [displayedColumns]="['brand_name',   'model_name',   'price',    'cost',   'isReserved', 'isBuyRegeistered',     'daysOnStock',      'comercialType']"
    [specialText]="     [undefined,      undefined,      'money',    'money',   isReserved,  isBuyRegeistered,       daysOnStockTxt,     undefined      ]"
    [specialClass]="    [undefined,      undefined,      undefined,  undefined, undefined,   undefined,              preIconDaysStock,   undefined  ]"
    [cellZize]="        [undefined,      undefined,      undefined,  undefined, undefined,   undefined,              undefined,          'small']" 
    [preIcon]="         [preIcon,        undefined,      undefined,  undefined, undefined,   undefined,              preIconDaysStock,   undefined]"
    [onlyTable]="hasData"
    [filter]="filter" [rightMenu]="rightMenu" (onclickRow)="onClickRow($event)"
    [groupBy]="groupBy"
    [exportExcel22]=" {singular : 'stockvnvo', plural : 'stocksvnvo',name:'vnvo'}"
    [quickFilter]="quickFilter" [createButton]="
        {
            text : 'Entrar vehículo en stock' , 
            icon : 'garage', 
            view : v.createVnVo, 
            cssClass:'onboarding-create-garage'
        }">
</app-page-structure>