import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { ClassSearcherComponent } from '../../class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';

export interface DialogConfirmPlatetInterface {
  title: string;
  message: string;
  message2: string;
  error: boolean;
  param: string;
}

@Component({
    selector: 'app-dialog-confirm-plate',
    templateUrl: './dialog-confirm-plate.component.html',
    styleUrls: ['./dialog-confirm-plate.component.css'],
    standalone: false
})

export class DialogConfirmPlateComponent {
  v = ViewPath

  @ViewChild("csearcher") contact_asign?: ClassSearcherComponent<M_Contact>;
  constructor(public apiS: ApiService, public routerS: RouterService, public dialog: MatDialogRef<M_Contact>, @Inject(MAT_DIALOG_DATA) public data: DialogConfirmPlatetInterface) { }

  edit() {
    this.dialog.close(true);
  }

}
