<p mat-dialog-title mat-dialog-title-img>
    <img src="./assets/img/big-mama/{{data.resource}}">
</p>

<div mat-dialog-content>
    <p>{{data.text}}</p>
</div>

<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>