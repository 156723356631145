import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { getRandomBigMammaMessage } from 'src/app/constants/constants';
import { roleGroup } from 'src/app/enums/RolesEnum';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { RouterService } from 'src/app/services/router.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    standalone: false
})
export class DashboardComponent implements OnInit {

  @ViewChild('typingText', { static: true }) typingText!: ElementRef;
  deployDate: string | null = null;
  randomSentence = getRandomBigMammaMessage();

  constructor(private routerS: RouterService, private userS: UserService, private http: HttpClient) {
    if (this.isWorkloadDashboard) {
      this.routerS.goTo(ViewPath.cargataller)
    }
  }

  ngOnInit(): void {
    if (!environment.local && !environment.production) {
      this.http.get<{ deployDate: string }>('/assets/deploy-info.json')
        .subscribe(
          data => this.deployDate = data.deployDate,
          error => console.error('No se pudo cargar la fecha de despliegue', error)
        );
    }
    this.startTypingEffect(this.randomSentence, this.typingText.nativeElement);
  }

  startTypingEffect(text: string, element: HTMLElement) {
    let i = 0;
    const speed = 50;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        element.innerText += text[i];
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);
  }


  get isWorkloadDashboard() {
    let role = this.userS.getRole();
    if (!role) { return false; }
    return roleGroup.workloadAsDashboard.includes(role)
  }

}
