import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VehicleType } from 'src/app/enums/VehicleType';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Model } from 'src/app/models/M_Model';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { asyncPlate } from 'src/app/validators/plateAsyncValidator';
import { DialogConfirmPlateComponent } from './dialog-confirm-plate/dialog-confirm-plate.component';
import { ICreateVehicle } from 'src/app/interfaces/ICreateVehicle';
import { BrandsModelsSeriesComponent } from 'src/app/components/brands-models-series/brands-models-series.component';
import { EngineType } from 'src/app/enums/EngineType';
import { validatorTax } from 'src/app/validators/tax';
import { DEFAULT_IVA } from 'src/app/constants/constants';
import { RegistrationTaxEnum } from 'src/app/enums/RegistrationTaxEnum';

export function createVehicleForm(formBuilder: FormBuilder, apiS: any, existsS: any, companyS: CompanyService, getCurrentVehicle: () => any): FormGroup {

  return formBuilder.group({

    /** Vn and VO */
    comercialType: [],

    /** Normal vehicle */
    vehicle_id: [],
    type: [companyS.company.userCenter?.workshopConfig?.default_vehicle || VehicleType.bike, Validators.required],
    license: ['', {
      validators: [Validators.required],
      asyncValidators: [asyncPlate(apiS, existsS, getCurrentVehicle)],
    }],

    // serie: [''], // --> Serie if comercial module is disabled

    client: [''],
    brand_id: ['', Validators.required],
    model_id: ['', Validators.required],
    workshop_model: [''],
    serie_id: [''],
    brand_name: [''],
    model_name: [''],
    serie_name: [''],
    serie_vehicle: [],
    version: [''],
    registration_tax: [RegistrationTaxEnum.Low],

    /** Ficha */
    chassis: [''],
    km: ['', []],
    license_date: ['', []],
    last_itv: ['', []],
    next_itv: ['', []],
    end_warranty: [''],
    number_warranty: [''],
    observations: ['', []],

    /** Motor */
    electric: ['', []],
    engine_type: ['', []],
    battery_1_no: ['', []],
    battery_1_no_last: ['', []],
    battery_2_no: ['', []],
    battery_2_no_last: ['', []],
    gears_type: [''],
    cc: ['', []],

    /** Sells */
    clientInvoice_id: ['', []],

    /** Comercial */
    client_id: [],
    price: [''],
    cost: [''],
    tax: [DEFAULT_IVA],
    budget_id: [],


    accounting: [''], // ??
    color_id: [''], // ??
    // seller: [''],
    // num_motor: [''],
    // num_prov: [''],
    // exp_documentation: [''],
    // fabrication_date: ['', []],
    // insurance: ['', []],
    // color: ['', []],
    // in_deposit: [''],
    // deposit: [''],
    // financial_expenses: [''],
    // delivery_num: [''],
    // documentation_expiration: [''],
    // hire_date: ['', []],
    // year: [''],
  });
}



export function genericValueChange(form: UntypedFormGroup) {
  form.get("battery_1_no")?.disable();
  form.get("battery_2_no")?.disable();

  /** When the engine_type 'select' changes, diable or enable some fields */
  form.get('engine_type')?.valueChanges.subscribe(val => {

    let b1 = form.get("battery_1_no")!;
    let b2 = form.get("battery_2_no")!;

    let lastb1 = form.get("battery_1_no_last")
    let lastb2 = form.get("battery_2_no_last")

    /** If the motor type is gas, disable the electric vehicle fields */
    if (val == EngineType.GASOLINE) {
      lastb1?.patchValue(b1.value);
      b1.setValue('');
      b1.disable();
      lastb2?.patchValue(b2.value);
      b2.disable();
      b2.setValue('');
    }
    else {
      b1.enable();
      b1.patchValue(lastb1?.value)
      b2.enable();
      b2.patchValue(lastb2?.value)
    }
  })

  form.get("type")?.valueChanges.subscribe(val => {
    form.patchValue({ serie: null });
  })

  form.get('model')?.valueChanges.subscribe(val => {
    if (val instanceof M_Model && val.details) {
      form.patchValue({ price: val.details.price })
      form.patchValue({ cost: val.details.cost })
      form.patchValue({ registration_tax: val.details.tax_registration })
      form.patchValue({ brand_id: val.id_brand })
      form.patchValue({ model_id: val.id })

    }
    else if (val instanceof M_Model) {
      form.patchValue({ brand_id: val.id_brand })
      form.patchValue({ model_id: val.id })
    }
  })

  form.get('brand')?.valueChanges.subscribe(val => {
    if (val instanceof M_Brand) {
      form.patchValue({ brand_id: val.id });
    }
  });
}


export function enablePlateEdition(d: MatDialog, vehicleForm: ICreateVehicle) {
  d.open(DialogConfirmPlateComponent, {
    data: {
      title: "Edición de matrícula",
      message: "¿Estas seguro que quieres editar la matrícula?",
    }
  }).afterClosed().subscribe(res => {
    if (res != true) {
      vehicleForm.form.get('license')?.disable();
    } else {
      vehicleForm.freePlateEedit = true;
      vehicleForm.form.get('license')?.enable();
    }
  }
  );
}

export function onTypeChange(event: number, bms: BrandsModelsSeriesComponent) {
  if (bms) {
    bms.onFilterValueChange(event);
  }
}


/** Comercial Base Price */
export function calcPB(price_: number | undefined, iva_: number | undefined, im_: number | undefined): number {
  let price = price_ || 0;
  let iva = iva_ ? iva_ / 100 : 0;
  let im = im_ ? im_ / 100 : 0;
  let toReturn = price / (1 + iva + im);
  return toReturn.castDecimals(2);
}

/** Comercial Benefit */
export function calcBenefit(price_: number | undefined, cost_: number | undefined, iva_: number | undefined, im_: number | undefined): number {
  let pb = calcPB(price_, iva_, im_);
  let cost = cost_ || 0;
  let toReturn = pb - cost;
  return toReturn.castDecimals(2);
}

/** Comercial PVP */
export function calcPVP(price_: number | undefined, iva_: number | undefined, im_: number | undefined): number {
  let price = price_ || 0;
  let iva = iva_ ? iva_ / 100 : 0;
  let im = im_ ? im_ / 100 : 0;
  let toReturn = price * (1 + iva + im);
  return toReturn.castDecimals(2);
}