import { endpoints } from "../constants/Endpoints";
import { VehicleType } from "../enums/VehicleType";
import { IClassSearcher } from "../interfaces/IClassSearcher";
import { match } from "../services/search.service";
import { getArrayOf } from "../utils/FunctionUtils";
import { M_Serie } from "./M_Serie";

export class M_Brand implements IClassSearcher<M_Brand> {
    
    id: number
    name: string;
    created_at?: Date;
    updated_at?: Date;
    type: VehicleType;
    original: boolean;
    series: M_Serie[] = [];

    constructor(d: any) {
        this.id = d.id;
        this.name = d.name;
        this.type = d.type === 0 ? VehicleType.bike : VehicleType.car;
        this.updated_at = d.udpated_at ? new Date(d.udpated_at) : undefined;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.original = d.custom;
        if (d.series) {
            this.series = getArrayOf(M_Serie, d.series);
        }
    }

    createNew(d: any): M_Brand {
        return new M_Brand(d);
    }
    getInputText(): string {
        return this.name;
    }
    getOptionText(): [string, undefined] {
        return [this.name, undefined]
    }
    defaultSearchFilter(v: string): boolean {
        return match(v, this.name);
    }

    get nothing() { return " " }
    get nothing2() { return " " }

    get endpoint() { return endpoints.getBrands }
    get itemId() { return this.id; }
    get icon() {
        if (this.isBike) {
            return 'two_wheeler';
        }
        else if (this.isCar) {
            return 'directions_car';
        }
        return 'verified';
    }

    get type_() { return this.type }

    get isCar() {
        return this.type === VehicleType.car;
    }

    get isBike() {
        return this.type === VehicleType.bike;
    }

    get typeVehicle() {
        if (this.isBike) {
            return 'two_wheeler';
        }
        return 'directions_car_filled';
    }

    get isRegistred() {
        if (!this.original) {
            return true;
        }
        return false;
    }

    get typeof() { return M_Brand }

    /*
    minify?: boolean | undefined;
    onlyCopies?: boolean | undefined;
    extraOptionText?: string | undefined;*/
}