<div class="simple-preview preview-container">
    <div class="close">
        <button (click)="dRef.close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="donwload" [ngClass]="{'unique' :false}" prevent>
        <button (click)="download()" mat-icon-button>
            <mat-icon>download</mat-icon>
        </button>
    </div>
    <pdf-viewer *ngIf="f != undefined; else loadingpdf" [src]="f" [render-text]="true" [original-size]="false"
        [zoom-scale]="'page-width'" class="pdf-preview">
    </pdf-viewer>

    <ng-template #loadingpdf>
        <div class="pdf-preview">
            <span class="pdf-label loading-pdf"></span>
            <mat-progress-bar color="warn" class="pdf-progress-bar" [mode]="'indeterminate'"></mat-progress-bar>
        </div>
    </ng-template>

</div>