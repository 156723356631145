import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ConceptsLineTableComponent } from 'src/app/components/concepts-line-table/concepts-line-table.component';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { RouterService } from 'src/app/services/router.service';
import { ApiService } from 'src/app/services/Api/api.service';
import { AssignClientVnVoComponent } from 'src/app/components/assign-client-vn-vo/assign-client-vn-vo.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { MatDialog } from '@angular/material/dialog';
import { M_Address } from 'src/app/models/M_Address';
import { addVehicleOnParam } from 'src/app/components/create-purchase/create-purchase.component';
import { GenericBuyVehicleService } from 'src/app/services/generic-buy-vehicle.service';

@Component({
    selector: 'app-vnvo-details',
    templateUrl: './vnvo-details.component.html',
    styleUrls: ['./vnvo-details.component.css'],
    standalone: false
})
export class VnvoDetailsComponent extends ParameterStateComponent implements IConceptLineTableComponent {

  v = ViewPath;
  loaded = false;
  ve: M_Vehicle | undefined;
  public form: UntypedFormGroup;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  constructor(private chdRef: ChangeDetectorRef, routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    public params: ParamsService, private genericBuy: GenericBuyVehicleService,
    private fb: UntypedFormBuilder, private d: MatDialog) {
    super(routerS, route, ["vnvo"]);
    this.form = this.fb.group({
      concepts: this.fb.array([])
    })
  }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }

  override onParam(_k: string, v: string) {
    this.refresh(v.getNumber())
  }

  refresh(v : number){
    this.loaded = false;
    this.apiS.vnvo.getVehicleById(v).then(res => {
      if (res instanceof M_Vehicle) {
        this.ve = res;
        /** Ugly redirect. --> Contemplar hacer un ÚNICO componente de vehicle details. */
        if (!res.isVnVo) {
          this.params.go(ViewPath.vnvoDetails, res.vehicle_id!, undefined, false, true);
        }
        this.formConcepts.push(...this.ve!.concepts)
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }

  get formConcepts(): M_Concept[] {
    return this.form.get('concepts')?.value;
  }




  goCreatepurchase(v: M_Vehicle) {
    this.routerS.goWithQueryParams(this.v.createPurchase, { [addVehicleOnParam]: v.vehicle_id })
  }

  goAssignClient(v: M_Vehicle) {
    this.d.open(AssignClientVnVoComponent, {
      data: {
        client: v.client,
        vehicle: v
      },
    }).afterClosed().subscribe((res: M_Contact | undefined) => {
     
      if (res instanceof M_Contact) {
        this.apiS.assignClient(v, res).then(cont => {
          v.client = res;
          if (this.ve != undefined) {
            this.ve.client = cont;
            this.ve.location = new M_Address(cont?.billing_address);
          }
        })
      }else{
        if(res === "close"){return;}
        this.apiS.assignClient(v, res).then(cont => {
          v.client = undefined;
          if (this.ve != undefined) {
            this.ve.client = undefined;
            this.ve.location = undefined;
          }
        })
      }
    });
  }

  compraVenta(ve: M_Vehicle) {
    this.genericBuy.genericBuy(ve, this);
  }

  get location() {
    return this.ve?.location?.toText();
  }

  addConcept(_c: M_Concept): void { throw new Error('Method not implemented.'); }

}
