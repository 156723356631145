import { IClassSearcher } from "../interfaces/IClassSearcher";
import { endpoints } from "../constants/Endpoints";
import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { RolesEnum, superUsers } from "../enums/RolesEnum";
import { M_LastActivity } from "./M_Dashboard";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";
import { M_Center } from "./M_Center";
import { M_Schedule } from "./M_Schdeule";
import { getArrayOf } from "../utils/FunctionUtils";

export class M_User implements IPageStructureItem, IClassSearcher<M_User> {

   id: number;
   email: string;
   name: string;
   img: string | undefined;
   updated_at: Date;
   created_at: Date;
   holidays: Date[] = [];
   confirm_sent: boolean = false;
   company_code: string | undefined;
   actions: M_Action[] = [];
   invoices: M_Invoice[] = [];
   role: RolesEnum;
   logs: M_LastActivity[] = [];
   last_login_at: Date | undefined;
   showed_workload: boolean = false;
   center: M_Center | undefined;
   schedules : M_Schedule[] = [];
   exceptions : M_Schedule[] = [];

   /** master */
   onlyCenterUsers: boolean = false;

   constructor(d: any) {
      this.id = d.id ? d.id : d.user_id;
      this.name = d.name ? d.name : d.user_name ? d.user_name : d.NomOperario;
      this.email = d.email;
      this.created_at = d.created_at ? new Date(d.created_at) : new Date();
      this.updated_at = d.created_at ? new Date(d.updated_at) : new Date();
      this.img = d.image ? d.image : d.pict ? d.pict : d.profile_img ? d.profile_img : undefined;
      this.confirm_sent = d.confirm_sent ? d.confirm_sent : false;
      this.company_code = d.company_code;
      this.role = d.role ? d.role : RolesEnum.NOONE;
      this.last_login_at = d.last_login_at ? new Date(d.last_login_at) : undefined;
      this.showed_workload = this.last_login_at ? true : false;
      this.center = d.center ? new M_Center(d.center) : undefined;
      this.schedules = getArrayOf(M_Schedule, d.schedules);
      this.exceptions = getArrayOf(M_Schedule, d.exceptions);

      /** To review on implement appointments */
      if (d.holidays) {
         for (let i = 0; i < d.holidays.length; i++) {
            var currentObj = d.holidays[i];
            if (currentObj.day_holiday) {
               this.holidays.push(new Date(currentObj.day_holiday))
            }
         }
      }
      if (d.actions) {
         for (let i = 0; i < d.actions.length; i++) {
            this.actions.push(new M_Action(d.actions[i]));
         }
      }

      if (d.invoices) {
         for (let i = 0; i < d.invoices.length; i++) {
            this.invoices.push(new M_Invoice(d.invoices[i]));
         }
      }
      if (d.logs) {
         for (let i = 0; i < d.logs.length; i++) {
            this.logs.push(new M_LastActivity(d.logs[i]));
         }
      }
   }

   defaultSearchFilter(text: string): boolean {
      return match(text, this.name, this.email, this.center ? this.center.name : '');
   }

   get superUser() { return superUsers.some(r => r == this.role) }
   get admin() { return this.role == RolesEnum.ADMIN }
   get gestor() { return this.role == RolesEnum.GESTOR }
   get adviser() { return this.role == RolesEnum.ADVISER }
   get comercial() { return this.role == RolesEnum.COMERCIAL }
   get recambista() { return this.role == RolesEnum.RECAMBISTA }
   get mechanic() { return this.role == RolesEnum.MECHANIC }

   get roleName() {
      switch (this.role) {
         case RolesEnum.ADMIN:
            return "Administrador";
         case RolesEnum.GESTOR:
            return "Gestor";
         case RolesEnum.ADVISER:
            return "Asesor";
         case RolesEnum.MECHANIC:
            return "Mecánico";
         case RolesEnum.COMERCIAL:
            return "Comercial";
         case RolesEnum.RECAMBISTA:
            return "Recambista";
         case RolesEnum.NOONE:
            return "Sin rol";
      }
   }
   /** CLASS SEARCHER INTERFACE */
   get endpoint() { return this.onlyCenterUsers ? endpoints.usersCenters : endpoints.users }
   get itemId() { return this.id; }
   get icon() { return "build"; }
   get typeof() { return M_User }
   createNew(d: any) { return new M_User(d) }
   getInputText(): string { return this.name; }
   getOptionText(): [string, string] { return ([this.name, this.email]) }
}