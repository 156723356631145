import { Component, Input } from '@angular/core';
import { TextWithLink } from '../../custom-classes/TextWithLink';

@Component({
    selector: 'app-text-with-link',
    templateUrl: './text-with-link.component.html',
    styleUrls: ['./text-with-link.component.css'],
    standalone: false
})
export class TextWithLinkComponent {
  @Input() value!: TextWithLink;
  @Input() color?: string;
  constructor() { }
}
