import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_RecambiosModule extends BaseModule {
    get name() { return "Recambios"; }
    get corename() { return "recambios"; }
    get title() { return "El módulo que todo recambista necesita"; }
    get desc() { return "Simplifica la gestión de repuestos, reduce errores y mantén todo bajo control con nuestro módulo avanzado."; }
    override isMTR(): this is M_MTRModule { return false; }

    generateFuncionalities() {
        return [
            { icon: "local_shipping", title: "Proveedores", tooltip: "Crear contactos de tipo 'proveedor'" },
            { icon: "storefront", title: "Almacenes", tooltip: "Crear almacenes" },
            { icon: "production_quantity_limits", title: "Reservas/faltas", tooltip: "Gestionar reservas y faltas de productos" },
            { icon: "assignment", title: "Pedidos/albaranes", tooltip: "Generación de pedidos y albaranes" },
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: `Con el módulo de recambios, dispondrás de un nuevo apartado en el menú llamado "Almacén", que incluye una amplia variedad de funcionalidades. ¡No dudes en explorarlo! 👀`,
                scrType: "img",
                src: `${this.basePath}/recambios1.png`
            },
            {
                text: "Crea presupuestos de recambios y conviértelos en albaranes para vender recambio a tus clientes. Controla cuanto y a quien vendes recambio.",
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            {
                text: "Actualiza los precios de las referencias de tus proveedores a través de la importación de tarifas, preparando las posteriores entrades de almacén y tener todos los costes y precios de venta controlados. ",
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            {
                text: "Decide que referencias quieres que tengan control de stock, reserva material para no quedarte sin stock, y si llegas a cero se generan faltas automáticas para que puedas gestionar los pedidos de la forma más eficiente.",
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            },
            {
                text: "Realiza pedidos a tus proveedores para gestionar las faltas pendientes y tener siempre stocks disponibles. Conoce el estado de los pedidos, si están pedidos o no y si ya han entrado en almacén.",
                scrType: "video",
                src: `${this.basePath}/??.mp4`
            }
        ];
    }   
}