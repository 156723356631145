import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modules-explanation',
    templateUrl: './modules-explanation.component.html',
    styleUrls: ['./modules-explanation.component.css'],
    standalone: false
})
export class ModulesExplanationComponent {

  constructor(public dRef: MatDialogRef<ModulesExplanationComponent>){}
}
