import { ViewPath } from "src/app/app-routing.module";
import { RouterService } from "src/app/services/router.service";
import { ResultOption } from "./big-mama.component";
import { AlbaranPageFiltesrEnum } from "src/app/views/albaranes/albaranes.component";
import { AppoPageFiltesrEnum } from "src/app/views/appointments/appointments.component";
import { ComercialBudgetPageFiltesrEnum } from "src/app/views/budget/comercial-budget/comercial-budget.component";
import { BudgetPageFiltesrEnum } from "src/app/views/budget/workshop-budget/workshop-budget.component";
import { BillPageFiltesrEnum } from "src/app/views/invoices/invoices.component";
import { ORPageFiltesrEnum } from "src/app/views/or/or.component";
import { OrderPageFiltesrEnum } from "src/app/views/orders/orders.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateClientComponent } from "src/app/views/create-client/create-client.component";
import { BigMamaHelpDialogComponent } from "./big-mama-help-dialog/big-mama-help-dialog.component";


/** Big mama action results */
export function actionResults(rS: RouterService) {

    let v = ViewPath;
    let actionIcon = "start";

    return [

        /** Contacts */
        ...rS.userCanGo(v.createContact) ? [new ResultOption(actionIcon, "Crear un contacto", () => rS.goTo(v.createContact))] : [],

        /** Vehicles */
        ...rS.userCanGo(v.createvehicle) ? [new ResultOption(actionIcon, "Crear un vehiculo", () => rS.goTo(v.createvehicle))] : [],

        /** Products */
        ...rS.userCanGo(v.createProduct) ? [new ResultOption(actionIcon, "Crear un producto", () => rS.goTo(v.createProduct))] : [],

        /** Invoices */
        ...rS.userCanGo(v.createInvoice) ? [new ResultOption(actionIcon, "Crear factura", () => rS.goTo(v.createInvoice))] : [],
        ...rS.userCanGo(v.invoices) ? [new ResultOption(actionIcon, "Facturas a cobrar", () => rS.goWithQueryParams(v.invoices, { filter: BillPageFiltesrEnum.INVOICE_STATE, filtervalue: 0, filter1: BillPageFiltesrEnum.INVOICE_STATE, filtervalue1: 1 }))] : [],

        /** OR */
        ...rS.userCanGo(v.createOr) ? [new ResultOption(actionIcon, "Crear OR", () => rS.goTo(v.createOr))] : [],
        ...rS.userCanGo(v.or) ? [new ResultOption(actionIcon, "OR a facturar", () => rS.goWithQueryParams(v.or, { filter: ORPageFiltesrEnum.OR_GROUP_STATUS, filtervalue: 1 }))] : [],

        /** Presupuestos taller */
        ...rS.userCanGo(v.createEditWorkshopBudget) ? [new ResultOption(actionIcon, "Crear presupuesto de taller", () => rS.goTo(v.createEditWorkshopBudget))] : [],
        ...rS.userCanGo(v.workshopBudget) ? [new ResultOption(actionIcon, "Presupuestos de taller abiertos", () => rS.goWithQueryParams(v.workshopBudget, { filter: BudgetPageFiltesrEnum.BUDGET_STATUS, filtervalue: 0 }))] : [],

        /** Presupuestos comercial */
        ...rS.userCanGo(v.createComercialBudget) ? [new ResultOption(actionIcon, "Crear presupuesto de comercial", () => rS.goTo(v.createComercialBudget))] : [],
        ...rS.userCanGo(v.comercialBudget) ? [new ResultOption(actionIcon, "Presupuestos de comercial abiertos", () => rS.goWithQueryParams(v.comercialBudget, { filter: ComercialBudgetPageFiltesrEnum.BUDGET_STATUS, filtervalue: 0 }))] : [],

        /** Appointments */
        ...rS.userCanGo(v.createappointment) ? [new ResultOption(actionIcon, "Crear cita", () => rS.goTo(v.createappointment))] : [],
        ...rS.userCanGo(v.appointments) ? [new ResultOption(actionIcon, "Citas para hoy", () => rS.goWithQueryParams(v.appointments, { filter: AppoPageFiltesrEnum.APPOINTMENT_DAY, filtervalue: 0 }))] : [],

        /** Albaranes */
        ...rS.userCanGo(v.createEditAlbaran) ? [new ResultOption(actionIcon, "Crear albarán", () => rS.goTo(v.createEditAlbaran))] : [],
        ...rS.userCanGo(v.albaranes) ? [new ResultOption(actionIcon, "Albrán a cobrar", () => rS.goWithQueryParams(v.albaranes, { filter: AlbaranPageFiltesrEnum.STATUS, filtervalue: 1 }))] : [],

        /** Pedidos pendientes */
        ...rS.userCanGo(v.orders) ? [new ResultOption(actionIcon, "Pedidos pendientes", () => rS.goWithQueryParams(v.orders, { filter: OrderPageFiltesrEnum.ORDER_STATUS, filtervalue: 0 }))] : [],

    ]
}


/** Big mama Help results 
 * Resources at : "./assets/img/big-mama/foo.png"
*/
export function helpResults(rS: RouterService, d: MatDialog) {

    let v = ViewPath;
    let helpIcon = "help_outline";

    return [

        /** Contacts */
        ...rS.userCanGo(v.contactDetails) ? [
            new ResultOption(
                helpIcon,
                "Anticipos",
                () => d.open(BigMamaHelpDialogComponent, {
                    data: {
                        text: 'Para generar un anticipo, accede a los detalles avanzados del cliente correspondiente. Una vez dentro, haz clic en la opción "A cuenta".',
                        resource: 'anticipo.png'
                    }
                }),
                "¿Cómo puedo crear anticipos?"
            )] : [],


        /** Vehicles */
        ...rS.userCanGo(v.vehicleDetails) ? [
            new ResultOption(
                helpIcon,
                "Documentación vehículo",
                () => d.open(BigMamaHelpDialogComponent, {
                    data: {
                        text: 'Para subir documentación de un vehículo, accede a los detalles avanzados del vehículo correspondiente. Una vez dentro, abre la sección "Documentación".',
                        resource: 'vehicle-docu.png'
                    }
                }),
                "¿Cómo subir documentación de un vehiculo?"
            )] : [],
    ]
}