import { Directive, ElementRef, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SvgIconsService } from '../services/svg-icons.service';

/**
 * This directive allows us to avoid having to check if an icon is svg or not.
 * Let's imagine we have an svg icon called "bizum".
 * 
 * Normally we would do this:
 * <mat-icon [svgIcon]="'bizum'"></mat-icon>
 * 
 * However, with this directive we can do this:
 * <mat-icon smartIcon>bizum</mat-icon>
 * 
 * Don't worry, you can also use material icons with this directive:
 * <mat-icon smartIcon>person</mat-icon>
 * 
 */

@Directive({
    selector: '[smartIcon]',
    standalone: false
})
export class SmartIconDirective{

  iconName = "";
  constructor(@Inject(MatIcon) public icon: MatIcon, private elementRef: ElementRef<HTMLElement>, private svgS: SvgIconsService) { }


  ngAfterContentInit(): void {
    this.iconName = this.nameFromInnerText;
    if (this.svgS.exists(this.iconName)) {
      this.icon.svgIcon = this.iconName;
    }
  }

  /* Code not tested
  ngOnChanges(changes: SimpleChanges): void {
    let name = this.nameFromInnerText;
    if (name != this.iconName){
      if (this.svgS.exists(this.iconName)) {
        this.icon.svgIcon = this.iconName;
      }
      else {
        this.icon.svgIcon = "";
      }
    }
  }*/

  get nameFromInnerText() {
    return this.elementRef.nativeElement.innerText.trim();
  }

}
