import { Component } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { M_Center, type_payment_center } from 'src/app/models/M_Center';
import { TypePaymentService } from 'src/app/services/type-payment-service';

@Component({
    selector: 'app-center-accounting',
    templateUrl: './center-accounting.component.html',
    styleUrls: ['./center-accounting.component.css'],
    standalone: false
})
export class CenterAccountingComponent {

  form: UntypedFormGroup;

  constructor(private fb: FormBuilder, public typePaymentS: TypePaymentService) {
    this.form = this.fb.group({
      accounting1: [],
      accounting2: [],
      accounting3: [],
      accounting4: [],
      accounting5: []
    });
  }

  lowercase(v: string) {
    return v.toLocaleLowerCase();
  }

  setCenterAccounting(center: M_Center) {
    center.type_payment_center.forEach(p => {
      let control = this.form.get("accounting" + p.payment_id);
      if (control) {
        control.patchValue(p.accounting);
      }
    })
  }

  getAccounting(): type_payment_center[] {
    const accountingData: type_payment_center[] = [];
    for (let i = 1; i <= 5; i++) {
      const control = this.form.get(`accounting${i}`);
      if (control) {
        accountingData.push({
          payment_id : i,
          accounting: control.value
        });
      }
    }
    return accountingData;
  }

}
