import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Contact } from 'src/app/models/M_Contact';

export type invoiceDialogData = {
  contact : M_Contact | undefined,
}

@Component({
    selector: 'app-create-invoice-dialog',
    templateUrl: './create-invoice-dialog.component.html',
    styleUrls: ['./create-invoice-dialog.component.css'],
    standalone: false
})

export class CreateInvoiceDialogComponent {
  date: FormControl<Date | null>;
  expedient: FormControl<string | null>
  
  constructor(public dialogRef: MatDialogRef<CreateInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: invoiceDialogData) {
    this.date = new FormControl(new Date(), Validators.required);
    this.expedient = new FormControl(null, this.requiredExpedient ? Validators.required : []);
  }

  get requiredExpedient() {
    return this.data && this.data.contact?.cli_is_invoice_e ? true : false;
  }

}
