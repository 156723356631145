import { Component, EventEmitter, Input, Output } from "@angular/core";
import type { ClassSearcherFilter } from "../../../custom-classes/Filter";

@Component({
    selector: 'app-class-searcher-filter',
    templateUrl: './class-searcher-filter.component.html',
    styleUrls: ['./class-searcher-filter.component.css'],
    standalone: false
})
export class ClassSearcherFilterComponent {
    @Input() filter!: ClassSearcherFilter;
    @Input() label: boolean = true;
    @Output() onFilterChanges : EventEmitter<any> = new EventEmitter<any>(); 
}
