import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { M_GroupedVehicle } from 'src/app/models/Vehicles/M_GroupedVehicle';
import { SingleGarageComponent } from '../single-garage/single-garage.component';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
    selector: 'app-grouped-garage',
    templateUrl: './grouped-garage.component.html',
    styleUrls: ['./grouped-garage.component.css'],
    standalone: false
})
export class GroupedGarageComponent {

  @Input({ required: true }) rightMenu!: TemplateRef<any>;
  @Input() groupBy?: TemplateRef<any>;
  @ViewChild(PageStructureComponent) ps?: PageStructureComponent<M_GroupedVehicle>;

  v = ViewPath;

  constructor(private d: MatDialog) { }

  preIconGrouped(g: M_GroupedVehicle) {
    return g.brand.icon;
  }

  vehiclesDialog(g: M_GroupedVehicle) {
    this.d.open(SingleGarageComponent, { data: g, autoFocus: false });
  }

}
