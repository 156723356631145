import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-big-mama-help-dialog',
    templateUrl: './big-mama-help-dialog.component.html',
    styleUrls: ['./big-mama-help-dialog.component.css'],
    standalone: false
})

export class BigMamaHelpDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {text: string, resource : string }, public dRef: MatDialogRef<BigMamaHelpDialogComponent>) {}
}
