import { Injectable } from '@angular/core';
import { feature } from '../utils/FeaturesController';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Views } from '../custom-classes/View';
import { NavigationStart, Router } from '@angular/router';
import { RouterService } from './router.service';
import { ViewPath } from '../app-routing.module';

declare global {
  interface Window {
    HubSpotConversations: any; // Cambia `any` por un tipo específico si lo conoces
  }
}

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  notHupSpotView :  Views[] = [ViewPath.showAppointments];

  constructor(router : Router, routerS : RouterService, private http: HttpClient) {
    // if (feature.hubSpot) {

    //   this.loadHubSpotScript();

    // router.events.subscribe((val) => {
    //   if (val instanceof NavigationStart) {
    //     let v = routerS.getViewFromRoute(val.url);
    //     if (v) {
    //       if (routerS.hasGuard(v, AuthGuard)) {
    //         this.loadHubSpotScript();
    //       }
    //       else {
    //         this.removeHubSpotScript();
    //       }
    //     }
    //   }
    // });
    // }

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        let v = routerS.getRouteFromString(val.url);
        console.log("View", v);
        if (v && this.notHupSpotView.some(view => view.path == v.path)) {
          this.removeHubSpotScript();
        }
        else {
          this.loadHubSpotScript();
        }
      }
    });

  }

  loadHubSpotScript() {
    if (feature.hubSpot) {
      console.log("🟢 Loading Hubspot script...")
      const scriptId = 'hs-script-loader';
      // Evitar agregar el script varias veces
      if (document.getElementById(scriptId)) {
        console.log("🟢 HubsPot already added")
        return;
      }
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "//js-eu1.hs-scripts.com/145453541.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      console.log("🟢 HubsPot ADDED")
    }
  }

  removeHubSpotScript() {
    console.log("🟠 Removing HubSpot script...")
    const script = document.getElementById('hs-script-loader');
    if (script) {
      script.parentNode?.removeChild(script);
      console.log("🟠 HubSpot REMOVED")
    }
    else {
      console.log("🟠 HubSpot (Nothing to remove)")
    }
  }


  openHubSpotChat() {
    if (feature.hubSpot) {
      window.HubSpotConversations.widget.open();
    }
    else {
      alert("Hubspot no disponible en este entorno")
    }
  }

  formTest() {

    const portalId = "145453541" //Portal id is the same on each form
    const registerFormid = "a342d492-b9a3-4797-88fc-bbe2d617ee83" // Register form
    const paymentForm = "a6df64c1-72e5-45ce-9670-8f081ba4d5a2" //Pay comet form


    //URL per enviar el formulari
    const hubspotFormUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${paymentForm}`;

    const payloadJSON = {
      "fields": [
        {
          "name": "email",
          "value": "@"
        },
        {
          "name": "firstname",
          "value": "Mr."
        }
      ],
      "context": {
        "pageUri": "https://eina.sinigual.com/",
        "pageName": "Eina"
      }
    }


    this.http.post(hubspotFormUrl, payloadJSON).subscribe(res => {
      console.log(res);
    });

  }


}
