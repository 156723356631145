import { Component, ViewChild } from '@angular/core';
import { M_User } from '../../models/M_User';
import { UserformComponent } from '../userform/userform.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { SpaceRemoverService } from '../space-remover/space-remover.component';
import { ViewPath } from 'src/app/app-routing.module';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { ActivatedRoute } from '@angular/router';
import { M_Center } from 'src/app/models/M_Center';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';

@Component({
    selector: 'app-create-edit-user',
    templateUrl: './create-edit-user.component.html',
    styleUrls: ['./create-edit-user.component.css'],
    standalone: false
})
export class CreateEditUserComponent extends ParameterStateComponent {

  @ViewChild(UserformComponent) userFormComponent?: UserformComponent;
  edit = false;
  loaded = false;
  originalUser: M_User | undefined;
  isProfile = false;

  constructor(routerS: RouterService, route: ActivatedRoute, private paramsS: ParamsService, private spaceRemover: SpaceRemoverService, 
    private apiS: ApiService, private userS : UserService, public subS : SubscriptionService) {
    super(routerS, route, ["user"]);
    let routerData = route.snapshot.data['profile'];
    this.isProfile = routerData != undefined && routerData == true;
  }

  override onParam(param: string, value: string): void {
    let v = value.getNumber();
    this.apiS.getUserById(v).then(res => {
      this.originalUser = res;
      this.userFormComponent?.initForm(this.originalUser);
      this.loaded = true;
      this.edit = true;
    })
  }

  override noParams(): void {
    if (!this.isProfile) {
      this.edit = false;
      this.loaded = true;
    }
  }


  createEdit() {
    if (!this.userFormComponent) { return; }
    let formData = this.userFormComponent.form.getRawValue();
    let u = new M_User(formData);
    if (formData.center) { u.center = new M_Center({ id: formData.center });}
    this.edit ? this.editUser(u) : this.createUser(u);
  }

  editUser(u: M_User) {
    this.spaceRemover.check(u.name);
    this.apiS.updateUser(u).then(_res => {
      if (u.id && u.id == this.userS.userId){
        this.userS.user.name = u.name;
      }
      this.returnUserDetails();
    })
  }

  goBack() {
    this.edit ? this.isProfile ? this.returnProfile() : this.returnUserDetails() : this.returnSettings()
  }

  createUser(u: M_User) {
    this.apiS.createUser(u).then(_resp => {
      this.returnSettings();
    })
  }

  returnSettings() {
    this.routerS.goWithQueryParams(ViewPath.settings, { tab: SETTINGS_TAB.TEAM });
  }

  returnProfile() {
    this.routerS.goTo(ViewPath.profile);
  }

  returnUserDetails() {
    if (!this.originalUser) { return; }
    this.paramsS.go(ViewPath.userdetails, this.originalUser!.id)
  }

}
