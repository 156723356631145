<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title *ngIf="isOnDialog">Crear dirección de envío</p>

<!-- Dialog content -->
<div mat-dialog-content [ngClass]="!isOnDialog ? 'conditional-mat-dialog-content' : ''">
    <form [formGroup]="fg">
        <div class="df mt10 aic">
            <mat-form-field class="w100" appearance="outline">
                <mat-icon matPrefix>location_on</mat-icon>
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre de la dirección" formControlName="name">
                <button matSuffix mat-icon-button *ngIf="canDelete" (click)="onDelete.emit()" matTooltip="Eliminar dirección">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="df">
            <app-country-selector class="w50" [label]="'Seleccionar país'" [placeHolder]="'Seleccionar país'"
                [required]="true" [form]="fg" [fcName]="'country'">
            </app-country-selector>
            <app-phone-input class="w50" [form]="fg" [formCName]="'phone'"></app-phone-input>
        </div>
        <div class="df">
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Nombre y número de la calle" formControlName="address">
            </mat-form-field>
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Complemento dirección</mat-label>
                <input matInput placeholder="Apartamento, suite, unidad, edificio o piso" formControlName="address2">
            </mat-form-field>
        </div>
        <div class="w33p">
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Código Postal</mat-label>
                <input matInput placeholder="Código Postal" formControlName="zip">
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Población</mat-label>
                <input matInput placeholder="Población" formControlName="city">
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Provincia</mat-label>
                <input matInput placeholder="Provincia" formControlName="province">
            </mat-form-field>
        </div>
    </form>

</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions *ngIf="isOnDialog">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button [disabled]="!fg.valid" (click)="createAndClose()"
        cdkFocusInitial>Guardar</button>
</div>