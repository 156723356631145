import { Component, Input } from '@angular/core';
import { TypeOrder } from 'src/app/enums/TypeOrder';
import { M_PreOrderBreakdown } from 'src/app/models/M_PreOrderBreakdown';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
    selector: 'app-order-breakdown-section',
    templateUrl: './order-breakdown-section.component.html',
    styleUrls: ['./order-breakdown-section.component.css'],
    standalone: false
})
export class OrderBreakdownSectionComponent {
  TYPEORDER = TypeOrder;
  @Input({ required: true }) section!: M_PreOrderBreakdown;
  constructor(public responsiveS : ResponsiveService){}
}
