<video playsinline loop autoplay [muted]="true" id="loginVideo">
    <source src="./assets/video/login_back.mp4" type="video/mp4">
</video>

<div class="login-gradient"></div>

<div class="parent">

    <div class="register-content">
        <app-card class="scale-in-center">
            <div class="content">

                <!-- LEFT CONTENT -->
                <div class="left-welcome">
                    <div class="df jcc">
                        <img class="logo" src="./assets/img/logos/eina_logo_black.png">
                    </div>
                    <p class="register-quote">Gestiona tu taller desde dónde quieras</p>
                    <img class="responsive-img" src="./assets/img/landing/eina-landing.png">
                </div>

                <!-- RIGHT FORM-->
                <div class="right-form">
                    <img class="phone-logo" src="./assets/img/logos/eina_logo_black.png">
                    <form [formGroup]="form" class="single-rows">
                        <p class="create-account-title">Crear cuenta</p>
                        <div class="form">
                            <div class="df">
                                <mat-form-field class="w100" appearance="outline" class="white page-search">
                                    <mat-label>Nombre Empresa</mat-label>
                                    <input matInput placeholder="El nombre de la empresa" formControlName="name">
                                </mat-form-field>
                            </div>
                            <div class="df">
                                <app-email-input class="w100" [label]="'Correo'" class_='input-full-width' [form]="form"
                                    formCName="email">
                                </app-email-input>
                            </div>
                            <!-- <div class="df">
                                <app-password-input class="w100" #pass [form]="form" formCName="password">
                                </app-password-input>
                            </div>
                            <div class="df mb10">
                                <app-repeat-passowrd-input class="w100" #pass2 [otherPass]="pass" [form]="form"
                                    formCName="password_confirmation"></app-repeat-passowrd-input>
                            </div> -->
                            <div class="df mb20 jcc">
                                <mat-checkbox #ch>
                                    <p class="nmb">He leído y acepto los <span class="c_a tdu"
                                            (click)="openTermsAndConditions($event)">términos y condiciones de
                                            uso</span>
                                    </p>
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="df jcc w100 mt10 mb10">
                            <button class="w100" (click)="createCompany()" mat-flat-button color="primary"
                                [disabled]="!form.valid || !ch.checked">
                                Registrarse
                            </button>
                        </div>
                        <p class="tac" style="margin-top: 20px; margin-bottom: 20px;">
                            <span class="c_t2">¿Ya tienes cuenta?</span>
                            <span (click)="goLogin()" class="link_u_h ml5">Iniciar sesión</span>
                        </p>
                    </form>
                </div>

            </div>
        </app-card>
    </div>
</div>