import { Component, Input } from '@angular/core';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';

@Component({
    selector: 'app-module-price-tag',
    templateUrl: './module-price-tag.component.html',
    styleUrls: ['./module-price-tag.component.css'],
    standalone: false
})
export class ModulePriceTagComponent {
  @Input({required : true}) module! : BaseModule;
}
