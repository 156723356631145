import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { MASTER_CLIENT_MINIFIY } from 'src/app/constants/masters';
import { PdfDocumentsUploadComponent } from '../pdf-documents-upload/pdf-documents-upload.component';
import { validatorTax } from 'src/app/validators/tax';

export function buyFormControls(formBuilder: FormBuilder) {
  return formBuilder.group({
    price: [''],
    cost: [''],
    date: [new Date()],
    tax: ['', validatorTax()],
    doc_prov_num: [''],
  })
}


@Component({
    selector: 'app-buy-simple-form',
    templateUrl: './buy-simple-form.component.html',
    styleUrls: ['./buy-simple-form.component.css'],
    standalone: false
})
export class BuySimpleFormComponent {

  v = ViewPath;
  client = MASTER_CLIENT_MINIFIY;
  minDate = new Date();
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent?: PdfDocumentsUploadComponent;

  @Input({ required: true }) form!: UntypedFormGroup
  @Input({ required: false }) showCost: boolean = true;
  @Input({ required: false }) showIva: boolean = true;
  @Input({ required: false }) showDocumentAndRelated: boolean = true;

  constructor() { }

  get file() {
    return this.pdfUploadComponent?.selectedFile;
  }

}