import { M_Contact } from "./M_Contact";
import { M_Product } from "./Products/M_Product";
import { TypeOrder } from "../enums/TypeOrder";
import { M_StoreHouseEntry } from "./M_StoreHouseEntry";
import { getArrayOf } from "../utils/FunctionUtils";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { order_status, order_status_finished, order_status_pending } from "../custom-classes/order_states";
import { match } from "../services/search.service";

/** Base component. M_Order and M_PreOrder extends it */
export class M_BaseOrder {
    id: number;
    type: TypeOrder = TypeOrder.NORMAL;
    products: M_Product[] = []

    constructor(d: any) {
        this.id = d.id;
        this.type = d.type ? d.type : this.type;
        if (d.products) {
            this.products = getArrayOf(M_Product, d.products);
            this.products.forEach(p => {
                //The product line table quantity need to be the pend_quant.
                p.quantity = p.pend_quant ? p.pend_quant : p.quantity;
            })
        }
    }
}

export class M_Order extends M_BaseOrder implements IPageStructureItem {
    order_status: order_status;
    order: string; //Nº order
    // id_order_provider: string | undefined;
    company_id: number;
    finished: boolean;
    send: boolean;
    created_at: Date | undefined;
    prov_name: string
    prov_phone: string
    prov_email: string
    date: Date | undefined;
    provider: M_Contact;
    total_quantity: number;
    recived: number;

    constructor(d: any) {
        super(d);
        this.order_status = d.order_status ? new order_status(d.order_status) : this.dynamicStatus;
        this.order = d.order;
        // this.id_order_provider = d.id_order_provider;
        this.company_id = d.company_id;
        this.finished = d.finished;
        this.prov_name = d.prov_name
        this.prov_email = d.prov_email
        this.total_quantity = d.total_quantity
        this.prov_phone = d.prov_phone
        this.type = d.type ? d.type : this.type;
        this.send = d.send;
        this.recived = d.recived;
        this.created_at = d.created_at ? new Date(d.created_at) : d.created_at;
        if (d.order_provider) { this.provider = new M_Contact(d.order_provider); }
        else if (d.provider) { this.provider = new M_Contact(d.provider); }
        else { throw Error("No 'order_provider' object. Need backend intervention") }
    }

    get itemId() {
        return this.id;
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.order, this.provider.name, this.order_status.name)
    }

    get typeOrder() {
        if (this.type == 1) { return 'Urgente'; }
        else { return 'Normal'; }
    }

    get sendOrder() {
        if (this.send) { return 'Enviado'; }
        else { return 'No enviado'; }
    }

    get typeIcon() {
        return this.type == 1 ? 'bolt' : 'shopping_cart_checkout';
    }

    get icon() {
        return "inventory";
    }

    /** Ex : 10/15  ||  25/25*/
    get enteredDivision() {
        return this.alreadyEnteredTotal.toString() + "/" + this.total_quantity;
    }

    get dynamicStatus() {
        let allEntered = this.products.every(p => p.pend_quant == 0);
        this.order_status = allEntered ? order_status_finished : order_status_pending;
        return this.order_status;
    }

    get alreadyEnteredTotal() {
        return this.products.reduce((sum, current) => sum += current.received, 0)
    }

    get totalPrice() {
        return this.products.reduce((sum, current) => sum += current.quantity * current.price, 0)
    }

    get productsWithSomeChanges() {
        return this.products.filter(p => p.user_entered != 0);
    }

    get allProductsOrderDone() {
        return this.products.every(p => p.orderDone == true);
    }

    get nothingEntered() {
        return this.products.some(p => p.absoluteEntered);
    }

    get canEdit() {
        return this.products.every(p => p.received == 0 || p.received == undefined);
    }

    geTotal(storeHouseEntry: M_StoreHouseEntry) {
        return this.products.reduce((sum, p) => sum + p.getTotal(storeHouseEntry.getProductMainPrice(p)), 0);
    }

    /**
     * Construct the products array that need the backend on the endpoint "/api/receiveOrderProduct".
     * The array copy is a deep copy to ensure modifications don't affect the original array.
     */
    get getBackendObjects() {
        let arrayCopy = this.productsWithSomeChanges.map(obj => ({ ...obj }));
        arrayCopy.forEach(p => { p.received += p.user_entered; })
        // Map the array to create a new array containing objects with only "received" and "product_id"
        return arrayCopy.map(({ received, product_id, line_id }) => ({ received, product_id, line_id }));
    }

    /** Only call this function if the response from "/api/receiveOrderProduct" is OK */
    enter() {
        this.productsWithSomeChanges.forEach(p => {
            p.received += p.user_entered;
            p.user_entered = 0;
        })
    }

    /** When removing a product, we keep it in the products array but set the boolean "line_deleted" to true. 
     * This way, it is easier for the backend to determine what has been deleted. */
    remove(p: M_Product) {
        this.products.removeElement(p);
    }

    quantityOf(p: M_Product): number | undefined {
        let prods: M_Product[] = this.products.filter(product => product.product_id == p.product_id);
        if (!prods.length) { return undefined; }
        return prods.reduce((sum, current) => sum += current.quantity, 0)
    }
}