<p *ngIf="deployDate" class="tac">🚀 Last deploy : <span class="fw600">{{deployDate}}</span></p>


<!-- <div class="free-month">
  <app-free-month></app-free-month>
</div> -->

<div class="bigMamaDashboardParent">

  <div class="big-mama-parent" [ngClass]="{'is-focused' : bigmama.focus}">
    <img class="img-1"src="./assets/img/decoration/star_red.png">
    <img class="img-2"src="./assets/img/decoration/planets.png">
    <img class="img-3"src="./assets/img/decoration/big_star_blue.png">
    <img class="img-4"src="./assets/img/decoration/star_green.png">
    <p #typingText style="white-space: pre-wrap; min-height: 20px;" class="fsxl viewtitle tac mb20"></p>
    <app-big-mama #bigmama></app-big-mama>
    <app-big-mamma-suggestions></app-big-mamma-suggestions>
  </div>
</div>

<ng-template #customSkeleton>
  <div class="custom-loader">
    <app-skeleton [width]="180" [height]="50"></app-skeleton>
  </div>
</ng-template>