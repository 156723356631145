import { M_Contact } from "./M_Contact"
import { M_GroupTask } from "./M_GroupTask";
import { M_User } from "./M_User";
import { M_Vehicle } from "./Vehicles/M_Vehicle"
import { M_RAGroup } from "./M_RAGroup";
import { M_PDFTemplate } from "./M_PDFTemplate";
import { M_Appointment } from "./M_Appointment";
import { M_ORTime } from "./M_ORTime";
import { M_Reservation } from "./M_Reservation";
import { M_Product } from "./Products/M_Product";
import { M_Fault } from "./M_Fault";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { action_types } from "../custom-classes/action_types";
import { or_types, or_types_todefine } from "../custom-classes/or_types";
import { or_status, or_status_close, or_status_open } from "../custom-classes/or_states";
import { CustomFile } from "../custom-classes/CustomFile";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";
import { M_Timelapse } from "./M_Timelapse";
import { M_Schedule } from "./M_Schdeule";

export class M_Action implements IPageStructureItem {

    id: number;
    action_id: number;
    type_id: action_types;
    client: M_Contact | undefined;
    clientInvoice: M_Contact | undefined;
    client_id: number;
    company_id: number;
    description: string;
    origen: null
    schedule: Date;
    title: string;
    type: or_types[] = [];
    created_at: Date;
    updated_at: Date;
    estimated_time_hours: number | undefined;
    estimated_time_minutes: number | undefined;
    user_id: number;
    vehicle: M_Vehicle | undefined;
    vehicle_id: number;
    assigned_to: number | undefined;
    groups: M_GroupTask[] = [];
    status: or_status = new or_status("Abierta");
    delivery: Date | undefined;
    notes: string | undefined;
    km: number | undefined;
    signature: CustomFile | undefined;
    images: CustomFile[] = [];
    assigned: M_User | undefined;
    total: number | undefined;
    token: string | undefined;
    has_or: number | undefined;
    appointment: M_Appointment | undefined;
    /** Prevent the dialog from asking if you want to save before exiting */
    deleted: boolean = false;
    /** TODO */
    has_budget: boolean = false;
    fuel: number = 1;
    title_id: string | undefined;
    urlpdf: M_PDFTemplate[] = [];
    totalToInvoice: number = 0;
    timers: M_ORTime[] = [];
    reservations: M_Reservation[] = [];
    faults: M_Fault[] = [];
    timelapse: M_Timelapse[] = [];

    constructor(d: any) {
        this.id = d.id;
        this.action_id = d.action_id;
        this.type_id = d.type_id ? new action_types(d.type_id) : new action_types("Orden de reparación");
        this.status = d.state ? new or_status(d.state) : new or_status("Abierta");
        this.client_id = d.client_id;
        this.company_id = d.company_id;
        this.description = d.description;
        this.appointment = d.appoitment ? new M_Appointment(d.appoitment) : undefined
        this.origen = d.origen;
        this.schedule = new Date(d.schedule);
        this.delivery = d.delivery ? new Date(d.delivery) : undefined;
        this.title = d.title;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        this.estimated_time_hours = d.estimated_time_hours;
        this.estimated_time_minutes = d.estimated_time_minutes;
        this.user_id = d.user_id;
        this.vehicle_id = d.vehicle_id;
        this.assigned_to = d.assigned_to;
        this.client = d.client ? new M_Contact(d.client) : undefined;
        this.notes = d.notes;
        this.km = d.km;
        this.signature = d.signature;
        this.token = d.token;
        this.has_or = d.has_or ? d.has_or : undefined;
        this.has_budget = d.has_budget;
        this.fuel = d.fuel != undefined ? d.fuel : 1;
        this.title_id = d.title_id || d.title;
        this.totalToInvoice = d.totalToInvoice ? d.totalToInvoice : 0;

        if (d.urlpdf) {
            this.urlpdf = getArrayOf(M_PDFTemplate, d.urlpdf);
        }
        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name == "action_image") {
                    this.images.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id))
                }
                else if (d.images[i].name == "signature") {
                    this.signature = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id)
                }
            }
        }
        if (d.vehicle) {
            this.vehicle = new M_Vehicle(d.vehicle);
        }
        if (d.client) {
            this.client = new M_Contact(d.client);
        }
        if (d.clientInvoice) {
            this.clientInvoice = new M_Contact(d.clientInvoice);
        }
        if (d.groups) {
            for (let i = 0; i < d.groups.length; i++) {
                this.addGroupTask(new M_GroupTask(d.groups[i]));
            }
        }
        if (d.assigned && !Array.isArray(d.assigned)) {
            this.assigned = new M_User(d.assigned);
        }
        if (d.timers) {
            this.timers = getArrayOf(M_ORTime, d.timers);
        }
        if (d.reservations) {
            this.reservations = getArrayOf(M_Reservation, d.reservations);
        }
        if (d.faults) {
            this.faults = getArrayOf(M_Fault, d.faults);
        }

        this.refreshType();
        this.total = d.total ? d.total : this.calculateTotal();
        this.timelapse = d.timelapse ? getArrayOf(M_Timelapse, d.timelapse) : [];

    }

    get itemId() {
        return this.id;
    }

    get id_to_show() {
        return this.title_id;
    }

    get title_to_show() {
        return this.title_id ? this.title_id : "Sin título";
    }

    get closedTotal() {
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed()) {
                t++;
            }
        })
        return t;
    }

    get totalInternosClosed() {
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed() && g.type.interno) {
                t++;
            }
        })
        return t;
    }

    /** Mirar con el productItemQuants? */
    get hasSomeFault() {
        if (!this.groups.length) { return false; }
        return this.groups.some(group => {
            const products = group.getAllProducts();
            return products.some(product =>
                product instanceof M_Product && product.reservation && product.reservation.fault && product.reservation.fault.isPending
            );
        });
    }

    /** Mirar con el productItemQuants? */
    get hasSomeFaultRequested() {
        if (!this.groups.length) { return false; }
        return this.groups.some(group => {
            const products = group.getAllProducts();
            return products.some(product =>
                product instanceof M_Product && product.reservation?.fault?.requested == true
            );
        });
    }

    get canDelete() {
        if (this.isOr()) {
            return !this.someProductRequested
        }
        else {
            return !this.hasSomeFaultRequested;
        }
    }

    get isCompletedOrdersGroup() {
        if (this.reservations) {
            let isCompleted = this.reservations.every(reserve => reserve.productsQuants?.quant_pend === 0);
            if (isCompleted) {
                return 'Reserva en Almacén';
            }
        }
        return false;
    }

    /** Get the action invoice client. **/
    get defaultInvoiceClient(): M_Contact | undefined {
        return this.clientInvoice || this.client
    }

    /** Is the client associated with the OR the same as the client to invoice?*/
    get sameClientAndInvoiceClient() {
        return this.clientInvoice == undefined || this.clientInvoice.client_id == this.client?.client_id
    }

    /* To do : Refactor totals. Need to know the client invoiced to */
    get closedTotalMoney() {
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed()) {
                t += g.getTotalBreakdown(this.defaultInvoiceClient).total;
            }
        })
        return t;
    }

    hasClient() {
        return this.client != undefined;
    }

    hasImages() {
        return this.images.length != 0;
    }

    addGroupTask(gt: M_GroupTask) {
        gt.action_id = this.id;
        this.groups.push(gt);
        this.refreshStatus();
        this.refreshType();
    }

    hasVehicle() {
        return this.vehicle != undefined;
    }

    refreshType() {
        this.type = [];
        if (this.groups) {
            for (let i = 0; i < this.groups.length; i++) {
                const index = this.type.findIndex(tipo => tipo.num == this.groups[i].type.num);
                if (index === -1) {
                    this.type.push(this.groups[i].type);
                }
            }
        }

        if (this.type.length == 0) {
            this.type.push(or_types_todefine);
        }
    }

    refreshStatus() {
        if (this.groups.length == 0) {
            this.status = or_status_open;
        }
        else {
            let lowestStatus: number | undefined = undefined;
            for (let i = 0; i < this.groups.length; i++) {
                if (lowestStatus == undefined) {
                    lowestStatus = this.groups[i].state.num;

                }
                else {
                    if (this.groups[i].state.num < lowestStatus) {
                        lowestStatus = this.groups[i].state.num;
                    }
                }

            }
            this.status = new or_status(lowestStatus!);
        }
    }

    getGroupsByInvoice(): M_GroupTask[] {
        let groupsGroupedByToken: M_GroupTask[] = []
        this.groups.forEach(g => {
            if (g.token) {
                if (groupsGroupedByToken.every(grouped => grouped.token != g.token)) {
                    groupsGroupedByToken.push(g);
                }
            }
        })
        return groupsGroupedByToken;
    }


    hasGroupsAndTasks() {
        return this.hasGroups() && this.hasTasks();
    }

    isBudgetClosable(): boolean {
        let closable = this.isBudget() && !this.isClosed() && this.hasGroups() && this.allGroupsCanBeInvoiced();
        return closable && (this.isRecambiosBudget() ? !this.hasSomeFault : true);
    }

    allGroupsCanBeInvoiced() {
        return this.groups.every(g => { return g.hasTasks() })
    }

    hasClosedGroups() {
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].isClosed()) {
                return true;
            }
        }
        return false;
    }

    hasPendingToInvoiceGroups() {
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].isClosed() && !this.groups[i].type.interno) {
                return true;
            }
        }
        return false;
    }

    allInvoiced() {
        if (this.groups.length == 0) { return false; }
        return this.groups.every(g => g.isInvoiced())
    }

    hasGroups() {
        return this.groups.length != 0;
    }

    hasTasks() {
        let g = false;
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].hasTasks()) {
                g = true;
            }
        }
        return g;
    }

    getStateColor() {
        if (this.status.name == "Abierta") { return 'red' }
        else if (this.status.name == "Cerrada") { return 'orange' }
        else { return 'green' }
    }

    getTypeColor() {
        if (this.type.length == 1 && this.type[0].num == -1) {
            return "gray"
        }
        return ""
    }

    getStrTypes() {
        let val = "";
        this.type.forEach(v => {
            val += v.name + " "
        })
        return val;
    }

    /* To do : Refactor totals. No puedo calcular el total si no se indica a que cliente se a facturdo el group task */
    calculateTotal() {
        if (!this.client) {
            return 0;
        }
        else {
            let total: number = 0;
            this.groups.forEach(g => {
                total += g.getTotalBreakdown(this.client).total;
            })
            return total;
        }
    }

    addTime(t: M_ORTime) {
        this.timers.push(t);
    }

    closeTime(time_id: number) {
        var t = this.timers.find(time => time.id == time_id);
        if (t) {
            t.end_time = new Date();
        }
    }

    removeTime(id: number | M_ORTime) {
        var v = typeof id == "number" ? id : id.id;
        this.timers.forEach(t => {
            if (t.id == v) {
                this.timers.removeElement(t);
            }
        })
    }

    isOperatorWorking(user_id: number | undefined): { id: number, elapsed: string } | undefined {
        if (this.timers.length == 0 || user_id == undefined) { return undefined }
        else {
            var filtered = this.timers.filter(time => time.user_id == user_id && time.end_time == undefined);
            if (filtered.length <= 0) { return undefined }

            var startTime = filtered[0].start_time;
            var dateNow = new Date();

            // get total seconds between the times
            var delta = Math.abs(startTime.getTime() - dateNow.getTime()) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

            this.format(hours);

            var elapsedTime = this.format(hours) + ":" + this.format(minutes) + ":" + this.format(seconds);
            return { id: filtered[0].id, elapsed: elapsedTime };
        }
    }

    format(value: number) {
        return '' + (value < 10 ? '0' : '') + value;
    }

    get groupsTaskToTabbedComments() {
        let tc: M_RAGroup[] = [];
        this.groups.map(g => {
            tc.push(new M_RAGroup(g.title))
        })
        return tc;
    }

    get isSomeGroupSiniestro() {
        return this.type.some(e => e.siniestro);
    }

    get isSomeGroupOpened() {
        return this.groups.some(g => g.state.open);
    }

    get hasCustomTime(): boolean {
        return this.groups.some(g => {
            return g.products.some(p => p.isTime)
        })
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        if (!this.isBudget) {
            return match(text,
                this.id,
                this.title_id,
                this.client ? this.client.getName() : '',
                this.vehicle ? this.vehicle.model?.name : '',
                this.vehicle ? this.vehicle.brand?.name : '',
                this.vehicle ? this.vehicle.license : '',
                this.total ? this.total : '',
                this.status ? '' : '',
                this.type ? '' : '',
                this.schedule ? this.schedule.dayMonthYearFormat() : '')
        }
        else {
            return match(text,
                this.id,
                this.title_id,
                this.client ? this.client.getName() : '',
                this.vehicle ? this.vehicle.model?.name : '',
                this.vehicle ? this.vehicle.brand?.name : '',
                this.vehicle ? this.vehicle.license : '',
                this.created_at ? this.created_at.dayMonthYearFormat() : '',
                this.total ? this.total : '',
                this.status ? '' : '',
                this.type ? '' : '')
        }

    }

    isOr() {
        return this.type_id.isOr;
    }

    isBudget() {
        return this.type_id.isBudget;
    }

    isRecambiosBudget() {
        return this.type_id.isRecambiosBudget;
    }


    isOpen() {
        return this.status.num == or_status_open.num;
    }

    isClosed() {
        return this.status.num == or_status_close.num;
    }

    isDone() {
        if (this.isBudget()) {
            return this.isRecambiosBudget() ? this.allInvoiced() || this.isClosed() : this.isClosed();
        }
        return this.allInvoiced()
    }

    canAddTime() {
        return this.isBudget() ? false : this.isSomeGroupOpened;
    }

    /** Interface implementation */

    vehicleName(): string | undefined {
        return this.vehicle?.getName();
    }
    vehicleId(): number | undefined {
        return this.vehicle?.vehicle_id;
    }
    vehicleBrand(): string | undefined {
        return this.vehicle?.brand?.name;
    }
    vehicleLicense(): string | undefined {
        return this.vehicle?.license;
    }
    clientName(): string | undefined {
        return this.client?.getName();
    }
    clientNif(): string | undefined {
        return this.client?.getName();
    }
    clientId(): number | undefined {
        return this.client?.client_id;
    }
    clientEmail(): string | undefined {
        return this.client?.email;
    }
    clientPhone(): string | undefined {
        return this.client?.getPhoneIfExists();
    }

    get isVnVoOr() {
        return this.vehicle?.isVnVo;
    }

    get someProductRequested() {
        return this.groups.some(g => {
            return g.products.some(p => {
                return p instanceof M_Product && p.isRequested;
            })
        })
    }

    getAssignedAvaliableHours(expetional?: M_Schedule) {
        const userS = this.assigned?.schedules[this.schedule.weekDay()];
        const finalSchedule = expetional ? expetional : userS?.account_work ? userS : undefined;
        if (!finalSchedule) { return 0; }
        return (finalSchedule.getTotalTimeInMinutes() / 60);
    }
}
