
<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{data.bName + " " + data.mName}}</p>
<div mat-dialog-content>
    <app-vehicle-row (onClick)="dRef.close()" *ngFor="let v of data.vehicles" [item]="v"></app-vehicle-row>
</div>
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>

