import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COUNTRIES_DB_ES, Country } from './COUNTRIES_DB_ES';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { match } from 'src/app/services/search.service';

@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrl: './country-selector.component.css',
    standalone: false
})
export class CountrySelectorComponent implements OnInit {

  loading = false;
  loadingDB = false;
  MAX_OPTIONS = 30;
  ALL = COUNTRIES_DB_ES;
  filteredOptions = [...this.ALL];
  @Input({ required: true }) fcName!: string;
  @Input({ required: true }) form!: UntypedFormGroup;
  @Output() onCountrySelected: EventEmitter<Country> = new EventEmitter<Country>();

  aux = new FormControl<string | Country>("");

  ngOnInit(): void {

    if (this.form.get(this.fcName)?.hasValidator(Validators.required)) {
      this.aux.addValidators(Validators.required);
    }

    /** Child form control */
    this.aux.valueChanges.subscribe(v => {
      console.log("Child value", v);
      if (typeof v != "string") {
        this.form.get(this.fcName)?.patchValue(v);
      }
      else if (v == "" || v == null) {
        this.form.get(this.fcName)?.patchValue(null);
      }
      this.newSuggestions(this.aux.value);
    })

    /** Parent form control */
    const parentFc = this.form.get(this.fcName)!;

    parentFc.valueChanges.subscribe(v => {
      this.replicateToChild(parentFc.value);
    })

    if (parentFc.value) {
      this.replicateToChild(parentFc.value);
    }
  }

  replicateToChild(v: any) {
    this.aux.setValue(v, { emitEvent: false });
    this.newSuggestions(this.aux.value);
  }

  newSuggestions(v: string | null | Country) {
    this.filteredOptions = [];
    if (typeof v == "string") {
      this.filteredOptions = [...this.ALL].filter(country => match(v, country.name))
    }
    else if (v == null) { this.filteredOptions = [...this.ALL] }
    else { this.filteredOptions = [v] }
  }

  get value(): Country | undefined {
    return this.form.get(this.fcName)?.value;
  }

  onOptionsSelected(event: MatAutocompleteSelectedEvent) {
    this.onCountrySelected.emit(event.option.value)
  }

  displayName(c: Country | null): string {
    return c?.name || "";
  }

  get img() {
    let v = this.form.get(this.fcName)?.value;
    if (v && "flag" in v) { return v.flag; }
  }

}
