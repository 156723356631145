import { Component, ComponentRef, Inject, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Product } from 'src/app/models/Products/M_Product';
import { AlreadyExistsService } from 'src/app/services/already-exists.service';
import { ParamsService } from 'src/app/services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { SIGAUS_PRICE_LITER, SIGNUS_ARRAY, max_buy_price, max_price } from 'src/app/constants/constants';
import { ProductCategory } from 'src/app/enums/ProductCategory';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { MASTER_PRODUCT, MASTER_PROVIDER } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { ContactEnum } from 'src/app/enums/ContactEnum';
import { M_DiscountGroup, M_DiscountGroupLine } from 'src/app/models/M_DiscountGroupLine';
import { ProviderSearcherComponent } from './provider-searcher/provider-searcher.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { referenceAsyncValidator } from 'src/app/validators/referenceAsyncValidator';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { Title } from '@angular/platform-browser';
import { feature } from 'src/app/utils/FeaturesController';

export interface I_ProductEdit { product: M_Product | undefined, action_id?: number, draft_id?: number };

@Component({
    selector: 'app-create-product',
    templateUrl: './create-product.component.html',
    styleUrls: ['./create-product.component.css'],
    standalone: false
})

export class CreateProductComponent extends ParameterStateComponent {

  master_provider = MASTER_PROVIDER;
  product = MASTER_PRODUCT;

  isEdit = false;
  loaded = false;
  v = ViewPath;
  p: M_Product | undefined;
  productCategory = ProductCategory;
  existingReference: string | undefined;
  SIGNUS_ARRAY = SIGNUS_ARRAY;
  SIGAUS = SIGAUS_PRICE_LITER;
  public form: UntypedFormGroup;
  allDiscountGroups: M_DiscountGroup[] = [];

  /*PROVEEDORES*/
  @ViewChild('searchProviderContainerHTML', { read: ViewContainerRef }) searchProviderContainer!: ViewContainerRef;
  searchers: ComponentRef<ClassSearcherComponent<M_Contact>>[] = [];
  clientId: number = 0
  discountEntries: { productId: number, companyId: number, clientId: number, discount: number }[] = [];

  /*Productos Alternativos*/
  showAlternativeInput: boolean = false;
  productControl = new FormControl();
  alternatives: M_Product[] = [];
  @ViewChild("productSearch") productSearch?: ClassSearcherComponent<M_Product>;

  /** Discount table */
  linesOfSelectedGroup: M_DiscountGroupLine[] = []

  features = feature;

  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService, public responsiveS: ResponsiveService,
    private apiS: ApiService, routerS: RouterService, route: ActivatedRoute, private titleS : Title,
    private params: ParamsService, private existsS: AlreadyExistsService, public companyS: CompanyService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: I_ProductEdit | null,
    @Optional() public dialogRef: MatDialogRef<CreateProductComponent>) {

    super(routerS, route, ["product"]);

    this.form = this.formBuilder.group({
      product_id: [],
      reference: ['', {
        validators: [Validators.required],
        asyncValidators: [referenceAsyncValidator(this.apiS, this.existsS, () => this.getProduct())], updateOn: 'blur'
      }],
      name: ['', [Validators.required]],
      price: ['', [Validators.required, Validators.min(0), Validators.max(max_price)]],
      pvp_t: ['', [Validators.min(0), Validators.max(max_price)]],
      buy_price: ['', [Validators.required, Validators.min(0), Validators.max(max_buy_price)]],
      tax: [companyS.centerDefaultTax],
      stock: ['', [Validators.min(0)]],
      quantity: ['', [Validators.min(0)]],
      category: [''],
      extra_field: [''],
      control_stock: [true, ''],
      obsolete: ['', []],
      preobsolete: ['', []],
      accounting_id: ['', []],
      stock_min: ['', []],
      stock_max: ['', []],
      totalFaults: ['', []],
      ref_factory: ['', []],
      providers: [[], []],
      providerUsual: ['', []],
      alternatives: ['', []],
      group_discount: [undefined, []],
      group_discount_line: [undefined, []],

      /** New fields */
      min_order: [''],
      discount_code: [''],
      rate: [''],
      rate_ant: [''],
      intrastat: [''],
      teileart: [''],
      update_date: [''],
      export_web: [false],
      fuco: [''],
      reference_ant: [''],
      aps: [''],
      type: [''],
      extra_table_1: [''],
      extra_table_2: [''],
      extra_table_3: ['']

    });


    this.form.get('rate')?.disable();
    this.form.get('rate_ant')?.disable();
    this.form.get('update_date')?.disable();
    
    if(feature.groupDiscount){
      this.apiS.getAllDiscountGroups().then(res => {
        this.allDiscountGroups = res;
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.isOnDialog) {
      if (this.data && this.data.product != undefined) {
        this.onParam("product", this.data.product.product_id);
      } else {
        this.noParams();
      }
    }

    this.form.get('stock')?.valueChanges.subscribe(value => {
      if (value < 0) {
        this.form.get('stock')?.setValue(0, { emitEvent: false });
      }
    });

    this.detectDiscountGroupChanges();

  }

  getProduct(): M_Product | undefined {
    return this.p;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get title() {
    return this.isEdit ? 'Editar producto' : 'Crear producto'
  }

  /** Detects changes in discount group selector and generates the discount group lines selector. */
  detectDiscountGroupChanges() {
    this.form.get('group_discount')?.valueChanges.subscribe((v: number | undefined) => {
      if (v == undefined) {
        this.form.get('group_line')?.patchValue(undefined);
        this.linesOfSelectedGroup = [];
      }
      else {
        this.apiS.getDiscountGroupById(this.form.get('group_discount')?.value).then(res => {
          this.linesOfSelectedGroup = res.discount_group_lines;
          if (this.linesOfSelectedGroup.length) {
            this.form.get('group_line')?.patchValue(this.linesOfSelectedGroup[0].id);
          }
        })
      }
    })
  }

  resetExtraField() {
    this.form.patchValue({ extra_field: null });
  }

  getSignus() {
    let val = this.form.get('extra_field')?.value;
    if (val) { return SIGNUS_ARRAY.find(s => s.id == val); }
    return undefined;
  }

  controlStockSelected(event: any): void {
    this.form.get('control_stock')?.setValue(event.checked ? 1 : 0);
  }

  isCategory(cat: ProductCategory) {
    let currentVal = this.form.get('category')?.value;
    return currentVal == cat;
  }

  override onParam(_k: any, v: any) {
    this.titleS.setTitle("Editar producto");
    this.apiS.getProductById(v).then(res => {
      this.isEdit = true;
      if (res) {
        this.fillDataWithProduct(res);
      }
    })
    this.form.get('price')?.hasError('required')
  }

  override noParams(): void {
    if (!this.isOnDialog) {
      this.loaded = true;
    }
    if (this.isOnDialog && this.data?.product == undefined) {
      this.loaded = true;
    }
  }


  fillDataWithProduct(p: M_Product) {
    this.p = p;
    this.form.patchValue(p);
    this.form.patchValue({
      extra_field: p.extra_field ? typeof p.extra_field == "number" ? p.extra_field : p.extra_field.id : undefined,
      providerUsual: p.providerUsual ? p.providerUsual.client_id : undefined
    })
    this.isEdit = true;
    this.loaded = true;
  }

  /** SAVE PRODUCT */
  create() {
    if (this.fs.isOk(this.form)) {
      if (this.isEdit) {
        let p = new M_Product(this.form.getRawValue());
        let productId = this.p?.product_id;
        p.line_id = this.p?.line_id;
        this.form.value['product_id'] = productId;

        if (this.data) {
          this.apiS.editProduct(p, this.data.action_id, this.data.draft_id).then(_resp => {
            if (this.isOnDialog) {
              this.dialogRef.close(p);
            }
            else {
              if (this.discountEntries.length) {
                this.apiS.editProductProviderDiscount(this.discountEntries).then(resp => { });
              }
              this.params.go(this.v.productDetails, productId!);
            }
          })
        }
      }
      else {
        this.apiS.createProduct(new M_Product(this.form.value)).then(_resp => {
          if (this.isOnDialog) {
            if (_resp instanceof M_Product) {
              this.dialogRef.close(_resp);
            }
          } else {
            this.params.go(this.v.productDetails, _resp.id!);
          }
        })
      }
    }
    this.p?.alternatives.filter(alternative => alternative instanceof M_Product).forEach((product) => {
      const product_id = this.p?.product_id;
      const data: any = {
        action: "create",
        alternative_id: product.product_id,
        product_id: product_id,
      };
      if (data) {
        this.apiS.linkAlternativeProduct(data).then(response => {
          this.p?.alternatives.push(product);
          this.productSearch?.remove();
        })
      }
    });
  }

  /*Productos Alternativos*/
  showAlternativeInputDiv() {
    this.showAlternativeInput = !this.showAlternativeInput;
  }

  displayProductName(product: any): string {
    return product ? product.name : '';
  }

  addAlterProd(product: M_Product) {
    this.p?.alternatives.push(product);
    this.productSearch?.remove();
  }

  deleteAlterProd(alternative: any) {
    this.p?.alternatives.removeElement(alternative);
    if (alternative.alternative_id) {
      const product_id = this.p?.product_id;
      const data: any = {
        action: "delete",
        alternative_id: alternative.alternative_id !== undefined ? alternative.alternative_id : alternative.product_id,
        product_id: product_id
      };
      if (data) {
        this.apiS.linkAlternativeProduct(data).then(response => {
          console.log('link Alternative Product deleted:', response);
        }).catch(error => {
          console.error('Error deleting link to Alternative Product:', error);
          return false
        });
      }
    }
  }

  disabledIfProduct(product: M_Product) {
    // Busca en la lista de alternativas si el producto ya está como alternativa
    const isAlternative = this.p?.alternatives.find(alter => alter.alternative_id == product.product_id) != undefined;
    // Verifica si el producto actual ya está en la lista de alternativas
    const isCurrentProduct = this.p?.alternatives.find(alter => alter.product_id == product.product_id) != undefined;
    // Si el producto ya está en la lista de alternativas o es el producto actual, lo deshabilita
    return isAlternative || isCurrentProduct;
  }

  onProductSelected(p: M_Product): void {
    this.addAlterProd(p)
  }


  /*ROUTER*/
  goCreateClient() {
    this.routerS.goWithQueryParams(this.v.createContact, { client_type: ContactEnum.PROVIDER })
  }

  goCreateProduct() {
    this.routerS.goTo(this.v.createProduct)
  }

  goBackProduct() {
    if (this.p && this.p.product_id) {
      this.params.go(ViewPath.productDetails, this.p.product_id);
    }
  }

  addProviderSearcher() {
    let ref = this.searchProviderContainer.createComponent(ProviderSearcherComponent);
    ref.instance.ref = ref;

    ref.instance.onSelectProvider.subscribe(v => {
      this.formProviders.push(v);
      ref.destroy();
    })

    ref.instance.onDestroy.subscribe(v => {
      ref.destroy();
    })
  }

  disabledIfProvider(client: M_Contact) {
    return this.p?.providers.find(c => c.client_id == client.client_id) != undefined;
  }

  get formProviders(): M_Contact[] {
    return this.form.get('providers')?.value;
  }

  removeProvider(c: M_Contact) {
    c.usual = false;
    this.formProviders.removeElement(c);
  }

  toggleUsual(event: MatSlideToggleChange, provider: M_Contact) {
    if (event.checked) {
      this.formProviders.forEach(p => {
        p.usual = false;
      })
      provider.usual = event.checked;
    }
  }

  get group_discount_line_value(): M_DiscountGroupLine | undefined {
    return this.linesOfSelectedGroup.find(v => v.id == this.form.get('group_discount_line')?.value);
  }

}