import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { OnBoarding } from '../../custom-classes/Onboarding/OnBoarding';
import { Section } from '../../custom-classes/Onboarding/Section';
import { Faqs } from '../../custom-classes/Onboarding/Faqs';
import { RouterService } from '../../services/router.service';
import { IRefService } from 'src/app/interfaces/IRefService';


/** SPEECH BUUBLE COMPONENT */

@Component({
    selector: 'app-speech-bubble',
    templateUrl: './speech-bubble.component.html',
    styleUrls: ['./speech-bubble.component.css'],
    standalone: false
})
export class SpeechBubbleComponent implements OnInit {

  @Input() section!: Section;
  @ViewChild("bubble") bubble: ElementRef | undefined;

  constructor(@Inject(OnboardingComponent) public onboardingParent: OnboardingComponent) { }
  @HostListener('scroll', ['$event'])
  @HostListener('window:scroll', ['$event.target'])

  ngOnInit(): void { }

  getOffset() {
    if (this.bubble && this.section.target) {
      var pos = this.checkBestPosition(this.section.target);
      return {
        visibility: 'visible',
        top: this.checkTopByPos(this.section.target, this.bubble, pos),
        left: this.chechLeftByPos(this.section.target, this.bubble, pos),
        "border-bottom-right-radius": pos == "top-l" ? "0px" : "",
        "border-bottom-left-radius": pos == "top-r" ? "0px" : "",
        "border-top-right-radius": pos == "bottom-l" ? "0px" : "",
        "border-top-left-radius": pos == "bottom-r" ? "0px" : ""
      }
    }
    else {
      return {
        visibility: "hidden"
      }
    }

  }

  checkTopByPos(stepFocusElement: Element, bubble: ElementRef, pos: "top-l" | "top-r" | "bottom-l" | "bottom-r") {
    var num = 0;
    var r = stepFocusElement.getBoundingClientRect();
    var br = bubble.nativeElement.getBoundingClientRect();
    if (pos == "top-l" || pos == "top-r") {
      num = (r.top - br.height) + scrollY - 10
    }
    else {
      num = (r.top + r.height) + scrollY + 10
    }
    if (num < 0) { num = 0 }
    return num + "px";
  }

  chechLeftByPos(stepFocusElement: Element, bubble: ElementRef,
    pos: "top-l" | "top-r" | "bottom-l" | "bottom-r") {
    var num = 0;
    var r = stepFocusElement.getBoundingClientRect();
    var br = bubble.nativeElement.getBoundingClientRect();
    if (pos == "top-l" || pos == "bottom-l") {
      num = (r.left - br.width)
    }
    else {
      num = (r.left + r.width)
    }

    if (num + br.width > window.innerWidth) {
      if (r.width > br.width) {
        var diff = r.width - br.width;
        num = r.left + diff / 2;
      }
      else {
        num = r.left;
      }
    }
    if (num < 0) { num = 0 }
    return num + "px";
  }

  checkBestPosition(stepFocusElement: Element): "top-l" | "top-r" | "bottom-l" | "bottom-r" {
    var sw = window.innerWidth;
    var sh = window.innerHeight;
    var rect = stepFocusElement.getBoundingClientRect();
    if (rect.left > sw / 2 && rect.top + (rect.height / 2) > sh / 2) {
      return "top-l"
    }
    else if (rect.left > sw / 2 && rect.top + (rect.height / 2) < sh / 2) {
      return "bottom-l"
    }
    else if (rect.left < sw / 2 && rect.top + (rect.height / 2) > sh / 2) {
      return "top-r"
    }
    else {
      return "bottom-r"
    }
  }

}



/** ONBOARDING COMPONENT */

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.css'],
    standalone: false
})
export class OnboardingComponent implements OnInit {
  @ViewChild(MatDrawerContainer) sideMenu!: MatDrawerContainer;
  @ViewChild(SpeechBubbleComponent) speechBubble!: SpeechBubbleComponent;
  @Input() userOnboarding!: OnBoarding;
  @Input() faqs: Faqs[] = [];
  @Input() showProgress: boolean = true;
  @Input() refService?: IRefService;
  @Output() onStartSection : EventEmitter<any> = new EventEmitter();
  currentSection: Section | undefined;

  constructor(router: Router, private chdRef: ChangeDetectorRef, private routerS : RouterService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentSection?.onChangeScreen(val.url)
      }
    })
  }

  ngOnInit(): void {
    /** When the onboarding inits, aut oassigns itself to the service input parameter.
     *  Like this, is possible to call this component inside the service of 'refService',
     */
    if (this.refService) {
      this.refService.onboardingComponent = this;
    }
  }

  /** Filter the onboarding sections checking if the user can acces all the steps on the section */
  get filteredSections() {
    return this.userOnboarding.sections.filter(section =>
      section.steps.every(step => !step.view || (step.view && this.routerS.userCanGo(step.view))))
  }

  onOpen() {
    document.body.style.overflow = "hidden";
  }

  onClose() {
    document.body.style.removeProperty("overflow");
  }

  open() {
    this.sideMenu.open();
  }

  close() {
    this.sideMenu.close();
  }

  startSection(section: Section) {
    /** Se ha dado click a "EN CURSO" */
    if (this.currentSection == section) {
      this.continueSection(section);
    }
    else {

      /** Hidden section, priority */
      if (this.currentSection != undefined && this.currentSection.hidden) {
        return;
      }

      if (this.currentSection != undefined) {
        this.currentSection.closeSection();
      }

      this.currentSection = section;

      this.currentSection.onElementFound.subscribe(res => {
        this.chdRef.detectChanges();
      })

      this.currentSection.onCloseSection.subscribe(res => {
        this.currentSection = undefined;
      })

      this.sideMenu.close();
      this.currentSection.startSection();
      this.onStartSection.emit();

    }

  }

  continueSection(s: Section) {
    this.sideMenu.close();
    s.setElementFocus();
  }

  nextStep() {
    if (this.currentSection) {
      this.currentSection.nextStep();
      //Check again current section. Possibly undefined because the next step
      if (!this.currentSection || (this.currentSection && this.currentSection.step.closSideMenu)) {
        this.sideMenu.close();
      }
    }
  }

  closeSection() {
    if (this.currentSection) {
      this.currentSection.closeSection();
    }
  }

}


