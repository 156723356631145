import { M_RAGroup } from "./M_RAGroup";
import { M_Contact } from "./M_Contact";
import { M_Vehicle } from "./Vehicles/M_Vehicle";

interface optionalAttributes {
    estimated_time_hours?: number | undefined;
    estimated_time_minutes?: number | undefined;
    assigned_to?: number | undefined;
    schedule?: Date | undefined;
    delivery?: Date | undefined;
    km?: number | undefined;
    appointment_id?: number | undefined;
    notes?: string | undefined;
    title?: string | undefined;
    fuel: number;
}

export class M_ActiveReception implements optionalAttributes {
    client_id?: number;
    vehicle_id?: number;
    client?: M_Contact;
    vehicle?: M_Vehicle;
    fuel: number;
    groups: M_RAGroup[];
    schedule: Date | undefined;
    delivery: Date | undefined;
    km: number | undefined;
    notes: string | undefined;
    assigned_to: number | undefined;
    title: string | undefined;
    id: number | undefined;
    clientInvoice_id: number | undefined;
    appointment_id: number | undefined;
    estimated_time_hours: number | undefined;
    estimated_time_minutes: number | undefined;
    comercial_type: number | undefined;


    constructor(client_id: number | undefined, clientInvoice_id: number | undefined, vehicle_id: number | undefined, optional: optionalAttributes, groups: M_RAGroup[], client?: M_Contact, vehicle?: M_Vehicle, comercial_type?: number | undefined) {
        this.client_id = client_id;
        this.clientInvoice_id = clientInvoice_id;
        this.client = client;
        this.vehicle = vehicle;
        this.vehicle_id = vehicle_id;
        this.fuel = optional.fuel;
        this.schedule = optional.schedule;
        this.delivery = optional.delivery;
        this.appointment_id = optional.appointment_id;
        this.estimated_time_hours = optional.estimated_time_hours;
        this.estimated_time_minutes = optional.estimated_time_minutes;
        this.km = optional.km;
        this.notes = optional.notes;
        this.assigned_to = optional.assigned_to;
        this.title = optional.title;
        this.groups = groups;
        this.comercial_type = comercial_type;
    }

    parseGroupsForBackend() {
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].parse();;
        }
        return this;
    }
}