import { Component, ViewChild } from '@angular/core';
import { WorkloadDashboardComponent } from '../workload-dashboard/workload-dashboard.component';
import { GenericReportsComponent } from '../generic-reports/generic-reports.component';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    standalone: false
})
export class ReportsComponent {

    @ViewChild(GenericReportsComponent) generic? : GenericReportsComponent;
    @ViewChild(WorkloadDashboardComponent) workload?: WorkloadDashboardComponent;

    onChangeTab(tab: number) {
        if (this.generic) { this.generic.inTab = tab == 0 }
        if (this.workload) { this.workload.inTab = tab == 1 }
    }
}
