<div class="advanced-details-container" *ngIf="c != undefined || (c == undefined && !loaded); else notfound"
    [ngClass]="{'isPerson' : c?.isPerson}">
    <app-go-back class="back" [text]="'Listado contactos'" [v]="v.contacts" [show]="loaded"></app-go-back>

    <!-- First section -->
    <div class="Client">
        <app-card [contentLoaded]="loaded" class="bc_b1">
            <app-advanced-details *ngIf="c">

                <app-advanced-title class="df aic fww">
                    {{c.getName()}}
                    <app-contact-buttons *ngIf="c != undefined" class="ml10">
                        <app-whatsapp [phone]="c.phone"></app-whatsapp>
                        <app-email-button [email]="c.email"></app-email-button>
                        <app-phone-button [phone]="c.phone"></app-phone-button>
                    </app-contact-buttons>
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{c.nif}}
                </app-advanced-subtitle>

                <app-advanced-heading *ngIf="c.email">
                    {{c.email}}
                </app-advanced-heading>

                <app-advanced-main-actions>
                    <!-- Main actions -->
                    <app-main-or-button [roles]="[R.ADMIN, R.GESTOR, R.ADVISER]" [client]="c"
                        [vehicle]="undefined"></app-main-or-button>

                    <!-- Adviser-->
                    <app-main-appointment-button adviser [client]="c"
                        [vehicle]="undefined"></app-main-appointment-button>

                    <!-- Recambista -->
                    <app-main-normal-budget-button recambista *ngIf="!c.isProvider" [client]="c"
                        [vehicle]="undefined"></app-main-normal-budget-button>
                    <app-main-albaran-button recambista *ngIf="!c.isProvider" [c]="c"></app-main-albaran-button>
                    <app-main-storehouse-entry-button recambista *ngIf="c.isProvider"
                        [c]="c"></app-main-storehouse-entry-button>

                    <!-- Comercial -->
                    <app-main-budget-comercial-button comercial *ngIf="c" [c]="c"></app-main-budget-comercial-button>

                </app-advanced-main-actions>

                <app-advanced-menu-actions>
                    <!-- Edit client -->
                    <button mat-menu-item (click)="params.go(v.createContact, c!.client_id)">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>

                    <mat-divider></mat-divider>


                    <!-- Adviser-->
                    <app-main-appointment-button [client]="c" [vehicle]="undefined"
                        [style]="'menu-option'"></app-main-appointment-button>

                    <!-- Recambista -->
                    <app-main-normal-budget-button *ngIf="!c.isProvider" [client]="c" [vehicle]="undefined"
                        [style]="'menu-option'"></app-main-normal-budget-button>

                    <app-main-or-button [client]="c" [vehicle]="undefined" [style]="'menu-option'"></app-main-or-button>

                    <mat-divider></mat-divider>

                    <app-main-albaran-button *ngIf="!c.isProvider" [c]="c"
                        [style]="'menu-option'"></app-main-albaran-button>
                    <app-main-storehouse-entry-button *ngIf="c.isProvider" [c]="c"
                        [style]="'menu-option'"></app-main-storehouse-entry-button>

                    <!-- Comercial -->
                    <app-main-budget-comercial-button [c]="c"
                        [style]="'menu-option'"></app-main-budget-comercial-button>

                    <mat-divider></mat-divider>

                    <!-- Delete-->
                    <button super-user mat-menu-item (click)="deleteS.delete(c)">
                        <mat-icon>delete</mat-icon>
                        Eliminar
                    </button>
                </app-advanced-menu-actions>

            </app-advanced-details>

            <!-- Bottom contents-->
            <app-advanced-details-footer>
                <app-footer-title>Ver más datos de cliente</app-footer-title>
                <app-footer-right class="df fdr jcsa aifs fww gap10" *ngIf="c">
                    <button mat-flat-button [color]="c.paymentsTotal ? 'ice' : 'ice'"
                        (click)="openPendingPaymentDialog()">
                        A cobrar
                        <span class="fw600" money [val]="c.paymentsTotal"></span>
                    </button>
                    <button mat-flat-button [color]="c.to_invoice ? 'ice' : 'ice'" (click)="openFlotasOrDialog()">
                        A facturar
                        <!-- ver el pendiente del cliente a facturar del vehiculo (dueño de la flota actions.clientInvoice_id)  -->
                        <span class="fw600" money [val]="c.to_invoice"></span>
                    </button>
                    <button mat-flat-button color="ice" [disabled]="companyS.companyMissingInfo"
                        (click)="openAdvancesDialog()"
                        [matTooltip]="companyS.companyMissingInfo ? 'Debes rellenar los datos de empresa para poder hacer un anticipo':'Crea anticipos para el cliente'">
                        A cuenta
                        <span class="fw600" money [val]="c.advancesTotal"></span>
                    </button>
                </app-footer-right>
                <app-footer-content>
                    <app-section>
                        <app-section-title>Ficha</app-section-title>
                        <app-section-content>
                            <app-card-row-content *ngIf="c" [data]="[
                                ['Teléfono', c.phone],
                                ['Teléfono 2', c.phone2],
                                [!c.isPerson ? 'invisible' : 'Fecha nacimiento', c.dob?.dayMonthYearFormat(), c.dob? {dob : c.dob} : undefined],
                                ['Contacto desde', getClientSince],
                                ['Última modificación', c.updatedAt?.shortFormat()]]">
                            </app-card-row-content>
                            <app-card-row-content *ngIf="c" class="fullW"
                                [data]="[['Observaciones', c.observations, {modifiable : true}]]" [hiddenOnLoad]="true"
                                (onClickModify)="modifyObservations()">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Dirección</app-section-title>
                        <app-section-content>
                            <p *ngIf="c" [ngClass]="{'c_t2' : !c.billing_address}">{{c.billing_address?
                                c.billing_address.toText() : "Sin dirección"}}</p>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Ventas</app-section-title>
                        <app-section-content>
                            <app-card-row-content *ngIf="c" [data]="[
                                    ['Ventas', c.isClient ? 'Activas' : 'Desactivadas'],
                                    [!c.isClient ? 'invisible' : 'Descuento', c.getDiscount()]]">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Datos adicionales y documentación</app-section-title>
                        <app-section-content class="documentos">
                            <!-- If is not person, display none on this div-->
                            <div class="dni-section" [ngClass]="{'dn_i' : !c?.isPerson}">
                                <app-card-subtitle class="df aic" [first]="true" [noMarginBottom]="true">DNI o
                                    NIF
                                    <button mat-icon-button>
                                        <mat-icon [filled]="true">badge</mat-icon>
                                    </button>
                                </app-card-subtitle>
                                <div class="df fww jcsa">
                                    <div>
                                        <lib-file-upload #dniFrontComponent
                                            (stateChanged)="dniUpload($event, 'dni_front')" [showMax]="false"
                                            [maxSize]="6" [crop]="true" [dni]="true"></lib-file-upload>
                                        <p class="nmb c_t2 tac fsi">Parte frontal</p>
                                    </div>
                                    <div>
                                        <lib-file-upload #dniBackComponent
                                            (stateChanged)="dniUpload( $event, 'dni_back')" [showMax]="false"
                                            [crop]="true" [maxSize]="6" [dni]="true"></lib-file-upload>
                                        <p class="nmb c_t2 tac fsi">Parte trasera</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Documentación
                                    <button mat-icon-button (click)="documentation.clickInputTrigger()" color="primary">
                                        <mat-icon [filled]="true">add_circle</mat-icon>
                                    </button>
                                </app-card-subtitle>
                                <div>
                                    <p *ngIf="!documentation.hasFiles()" class="c_t2">Aún no has subido imágenes o documentos
                                    </p>
                                    <lib-file-upload [phoneMultipleUpload]="true" #documentation class="hidde_add"
                                        [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                                        [acceptedTypes]="[FT.image, FT.PDF]"
                                        (stateChanged)="uploadDocumentImages($event)"
                                        (onRemoveImage)="removeImage($event)">
                                    </lib-file-upload>
                                </div>
                            </div>
                        </app-section-content>
                    </app-section>
                </app-footer-content>
            </app-advanced-details-footer>
        </app-card>
    </div>

    <div class="Vehicles">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]=" c && !c.isProvider ? add_vehicle : add_product">
            <div *ngIf="c != undefined">
                <!-- Not provider-->
                <ng-container *ngIf="!c.isProvider">
                    <app-card-title>{{c.vehicles.length == 1 ?'Vehículo' : 'Vehículos'}}</app-card-title>
                    <div class="card-contents-150 card-wrapper">
                        <app-client-details-vehicle-table [c]="c"></app-client-details-vehicle-table>
                    </div>
                </ng-container>

                <!-- Provider-->
                <ng-container *ngIf="c.isProvider">
                    <app-card-title>{{c.products_providers.length == 1 ? 'Producto' : 'Productos'}}
                    </app-card-title>
                    <div class="card-contents-150 card-wrapper">
                        <app-providers-details-product-table class="client-details-provider-table"
                            [client]="c"></app-providers-details-product-table>
                    </div>
                </ng-container>
            </div>
        </app-card>

        <ng-template #add_vehicle>
            <button mat-mini-fab color="primary" (click)="params.go(v.createvehicle, c!.client_id, 'client')">
                <mat-icon>add</mat-icon>
            </button>
        </ng-template>

        <ng-template #add_product>
            <button mat-mini-fab color="primary" (click)="routerS.goTo(this.v.createProduct)">
                <mat-icon>add</mat-icon>
            </button>
        </ng-template>

    </div>

    <!-- EINA-1598 -->
    <!-- <div class="Reserves" *ngIf="c && companyS.recambiosModule && c.c_client">
        <div class="reserves">
            <app-card [contentLoaded]="loaded" [cornerMatMenu]="add_reserva">
                <app-card-title [first]="true">{{c.reservations.length == 1 ? 'Reserva' : 'Reservas'}}</app-card-title>
                <app-reserves-product-table [client]="c" [reservations]="c.reservations"></app-reserves-product-table>
            </app-card>
            <ng-template #add_reserva>
                <button mat-mini-fab color="primary" (click)="openManualReservation(c)">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
        </div>
    </div> -->


    <!-- If is provider, it's different details-->
    <div class="LastInterventionOrOrders">
        <app-card [contentLoaded]="loaded" *ngIf="!c?.isProvider; else providerDetails">
            <!-- CLIENT (PERSON OR COMPANY) -->
            <app-card-title [first]="true">Última intervención</app-card-title>
            <div class="card-contents-150 card-wrapper" *ngIf="c != undefined && c.isClient">
                <app-last-intervention-or [or]="c.lastOr"></app-last-intervention-or>
            </div>
        </app-card>
        <ng-template #providerDetails>
            <div class="incomingOrders " *ngIf="c">
                <!-- [ngStyle]="{'display': c.orders.length > 0 ? 'grid' : 'none'}" -->
                <app-card [contentLoaded]="loaded">
                    <app-card-title [first]="true" class="df aic">
                        Pedidos en trámite
                    </app-card-title>
                    <div class="card-contents-150 card-wrapper">
                        <p *ngIf="!companyS.recambiosModule" class="c_r">Módulo de recambios no activado</p>
                        <app-incoming-orders-provider [orders]="c.orders"
                            *ngIf="companyS.recambiosModule"></app-incoming-orders-provider>
                    </div>
                </app-card>
            </div>
        </ng-template>
    </div>


    <div class="df fdc abc" style="gap: 20px;">
        <div class="OR">
            <app-card [contentLoaded]="loaded" [noData]="!clientHasHistory">
                <div *ngIf="c != undefined">
                    <app-card-title>Historial</app-card-title>
                    <app-action-history #history [clientSide]="false" [actions]="c.actions" [invoices]="c.invoices"
                        [purchases]="c.purchases"
                        [notShowFiler]="!clientHasHistory"></app-action-history>
                </div>
            </app-card>
        </div>
    </div>
</div>


<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el contacto'" [goText]="'Ir al listado de contactos'"
        [view]="v.contacts">
    </app-model-not-found>
</ng-template>