import { Injectable } from '@angular/core';
import { CustomLog } from '../custom-classes/CustomLog';
import { CustomErrorHandler } from '../custom-classes/CustomErrorHandler';
import { StorageService } from './storage.service';
import { projectConfiguration } from '../app.module';
import { ApiService } from './Api/api.service';


/**
 *  Reloads the browser cache if the user has a old frontend version 
 *  (window.location.reload()).
 * 
 *  @description
 *  Checks the version of the app by calling and endpoint.
 *  The endpoint to call is defeined in CoreConfiguration
 * 
 * @example
 * By default, the 'entry-point-service' calls the checkvrsion function.
 * 
 * Example implementation:
 * ```ts
 * constructor(private checkV : CheckVersionService) {
 *  this.checkV.checkVersion();
 * }
 * ```
 */

const EINA_VERSION_KEY = "eVersion"

@Injectable({
  providedIn: 'root'
})
export class CheckVersionService {

  version : string = "--";

  constructor(private storageS: StorageService, private errorS: CustomErrorHandler, private apiS: ApiService) { }

  checkVersion() {
    this.apiS.noauth.checkVersion().then(res => {
      let current = res;
      let saved = this.storageS.get(EINA_VERSION_KEY);

      if (saved == null){
        this.storageS.save(EINA_VERSION_KEY, current);
        saved = current;
      }
      else if (current != saved) {
        this.storageS.save(EINA_VERSION_KEY, current);
        console.log("Changing version!")
        this.refresh();
      }

      this.version = current;

    })
  }

  refresh() {
    window.location.reload()
  }

}