<table class="week-schedule-table" [ngClass]="{'full-width-table' : fullW}" [dn_i]="disabled != undefined">
    <tr>
        <th class="day-cell" *ngIf="showDay">Día</th>
        <th class="period-cell">Periodo(s)</th>
        <th class="subtotal-cell">Subtotal</th>
    </tr>

    <tr *ngFor="let schdeule of horario; let index = index;">
        <td *ngIf="showDay">{{dayName(index)}}</td>
        <td>
            <div class="df aic gap5 time-cells-container">
                <ng-container *ngIf="schdeule.validationBreakdown() as breakdown">
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!breakdown.morningStart">
                        <input type="time" matInput [(ngModel)]="schdeule.morningStart">
                    </mat-form-field>
                    <mat-form-field appearance="outline" [class.mat-form-field-custom-invalid]="!breakdown.morningEnd">
                        <input type="time" matInput [(ngModel)]="schdeule.morningEnd">
                    </mat-form-field>
                    -
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!breakdown.afternoonStart">
                        <input type="time" matInput [(ngModel)]="schdeule.afternoonStart">
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!breakdown.afternoonEnd">
                        <input type="time" matInput [(ngModel)]="schdeule.afternoonEnd">
                    </mat-form-field>
                </ng-container>
            </div>
        </td>
        <td>{{schdeule.getTotalTimeString()}}</td>
    </tr>
</table>
<mat-divider [dn_i]="disabled != undefined"></mat-divider>
<div class="total-time-container" [dn_i]="disabled != undefined">
    <span class="total-label">Horas Totales:</span>
    <span class="total-value">{{getTotalTimeStringForAllSchedules()}}</span>
</div>

<div [dn_i]="disabled == undefined">
    <p class="c_t2 displ">{{disabled}}</p>
</div>