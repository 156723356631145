import { Component, Input } from '@angular/core';

/** 
  <app-advanced-details-footer>
  
     <!-- Title-->
     <app-footer-title>Ver más datos</app-footer-title>
  
     <!-- Content on title right -->
     <app-footer-right>
       <p>Example</p>
     </app-footer-right>
  
     <!-- Footer content !-->
     <app-footer-content>
  
        <!-- Example : Sections -->
        <app-section>
            <app-section-title>Example title</app-section-title>
            <app-section-content>Example content</app-section-content>
        </app-section>
  
     </app-footer-content>
  
  </app-advanced-details-footer> 
*/
@Component({
    selector: 'app-advanced-details-footer',
    template: `
  <div class="df jcsb ffwr aic mt20" style="row-gap: 10px;">
        <div>
            <p class="fw600 nmb" #title [ngClass]="{'dn' : !showTitle}">
                <mat-icon dropDown [content]="content" [trigger]="title" [starClosed]="!starOpen"
                    class="vam">arrow_drop_up</mat-icon>
                <ng-content select="app-footer-title"></ng-content>
            </p>
        </div>
        <ng-content select="app-footer-right"></ng-content>
  </div>
  <div #content>
      <ng-content select="app-footer-content"></ng-content>
  </div>`,
    standalone: false
})

export class AdvancedDetailsFooterComponent {
    @Input() showTitle: boolean = true;
    @Input() starOpen: boolean = false;
}
