import { ChangeDetectorRef, Component, Input, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { VehicleType } from '../../enums/VehicleType';
import { ImageToggleComponent } from '../image-toggle/image-toggle.component';
import { MASTER_CLIENT, MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { ComercialVehicleType } from '../../enums/ComercialVehicleType';
import { RouterService } from 'src/app/services/router.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Brand } from 'src/app/models/M_Brand';
import { BrandsModelsSeriesComponent } from 'src/app/components/brands-models-series/brands-models-series.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { createVehicleForm, enablePlateEdition, genericValueChange, onTypeChange } from './vehicleGenericLogic';
import { ICreateVehicle } from 'src/app/interfaces/ICreateVehicle';

@Component({
    selector: 'app-vehicleform',
    templateUrl: './vehicleform.component.html',
    styleUrls: ['./vehicleform.component.css'],
    standalone: false
})
export class VehicleformComponent implements ICreateVehicle {

  M_CLIENT = MASTER_CLIENT;
  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;
  typeVehicle = VehicleType;
  onTypeChange = onTypeChange;
  enablePlateEdition = enablePlateEdition;
  registrationText = registrationText;

  /** Show only the requiered fields */
  @Input() onlyRequired = false;
  @Input() requiredFieldsTitle?: string
  @Input() showOwnerSelector : boolean = true;

  @ViewChild('brandsModelSerie') brandsModelSerie?: BrandsModelsSeriesComponent;
  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild("clientSearcher") ownerSearcher?: ClassSearcherComponent<M_Contact>;

  v = ViewPath;
  public form: UntypedFormGroup;
  selectedType: VehicleType;
  VT = VehicleType;
  vehicle: M_Vehicle | undefined
  client_select: M_Contact | undefined
  freePlateEedit = false;
  allData: M_Brand[] | undefined;

  constructor(private fb: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, public d: MatDialog,
    @Optional() public dialogRef: MatDialogRef<any>, public companyS: CompanyService) {
    this.form = createVehicleForm(this.fb, this.apiS, this.existsS, this.companyS, () => this.getCurrentVehicle());
    if(this.companyS.company.userCenter?.workshopConfig){
      this.selectedType = this.companyS.company.userCenter?.workshopConfig?.default_vehicle;
    }else{
      //por defecto
      this.selectedType = VehicleType.bike;
    }
    genericValueChange(this.form);
    if (!this.vehicle) {
      if (this.companyS.company.userCenter?.workshopConfig?.default_vehicle != undefined) {
        let default_type = this.companyS.company.userCenter?.workshopConfig?.default_vehicle || VehicleType.bike;
        this.brandsModelSerie?.onFilterValueChange(default_type);
      }
    }
  }

  ngOnInit() {

  }

  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  /** Patch the current form with a vehicle. */
  patchFormWithVehicle(vehicle: M_Vehicle) {
    this.vehicle = vehicle;
    this.selectedType = vehicle.type;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
    }

    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  get loaded() {
    return true;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
}