import { Component } from '@angular/core';

@Component({
    selector: 'app-delete-company',
    templateUrl: './delete-company.component.html',
    styleUrls: ['./delete-company.component.css'],
    standalone: false
})
export class DeleteCompanyComponent {

}
