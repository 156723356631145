import { Component } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_AccountingGroupRow } from 'src/app/models/AccountingGroups/M_AccountingGroupRow';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { ActivatedRoute } from '@angular/router';
import { M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountingGroupType } from 'src/app/enums/AccountingGroupType';
import { MatSelectChange } from '@angular/material/select';
import { AC_COMERCIAL, AC_PRODUCTS, AC_TIME } from 'src/app/constants/AccountingGroupsDefault';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { PS_HIGHLIGHT } from 'src/app/constants/constants';

export type accountingType = "Comercial" | "Time" | "Products";

@Component({
    selector: 'app-accounting-group-tables',
    templateUrl: './accounting-group-tables.component.html',
    styleUrls: ['./accounting-group-tables.component.css'],
    standalone: false
})
export class AccountingGroupTablesComponent extends ParameterStateComponent {

  v = ViewPath;
  loaded = false;
  isEdit = false;
  ACT = AccountingGroupType;

  form: UntypedFormGroup;

  ag: M_AccountingGroup | undefined;
  dataF: M_AccountingGroupRow[] = [];
  dataA: M_AccountingGroupRow[] = [];
  dataC: M_AccountingGroupRow[] = [];
  AC_TIME: any;
  AC_PRODUCTS: any;


  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, private snackS: SnackService,
    private fb: FormBuilder, public einaDataS: EinaDataService, private confirmD: ConfirmDialogService) {
    super(routerS, route, ["ag"]);
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      center_id: [''],
      type: [AccountingGroupType.COMERCIAL]
    });
  }

  override onParam(param: string, value: string): void {
    this.apiS.accountingGroup(value.getNumber()).then(res => {
      this.isEdit = true;
      this.form.patchValue(res);
      this.distribute(res);
      this.loaded = true;
    })
  }

  override noParams(): void {
    this.isEdit = false;
    this.form.get('center_id')?.addValidators(Validators.required);
    this.distribute(AC_COMERCIAL);
    this.loaded = true;
  }

  onChangeSelector(act: MatSelectChange) {
    this.distribute(this.getTableByNumber(act.value))
  }

  getTableByNumber(agt: AccountingGroupType) {
    switch (agt) {
      case AccountingGroupType.COMERCIAL:
        return AC_COMERCIAL;
      case AccountingGroupType.TIME:
        return AC_TIME;
      case AccountingGroupType.PRODUCTS:
        return AC_PRODUCTS;
    }

  }

  distribute(ag: M_AccountingGroup) {
    this.ag = ag;
    this.dataF = ag.lines.filter(ag => ag.type_FAC == "F");
    this.dataA = ag.lines.filter(ag => ag.type_FAC == "A");
    this.dataC = ag.lines.filter(ag => ag.type_FAC == "C");
  }

  deleteAccountingGroup() {
    if (!this.ag) { return; }
    this.confirmD.show({
      title: "Eliminar grupo contable",
      body: "¿Estás seguro de que quieres eliminar el grupo contable?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.deleteAccountingGroup(this.ag!).then(res => {
          this.routerS.goTo(ViewPath.accountingGroups);
          this.snackS.show("Grupo eliminado");
        })
      }
    })
  }

  save() {
    if (this.ag) {
      let allData = this.dataF.concat(this.dataA).concat(this.dataC);

      this.ag.name = this.form.get('name')?.value;
      this.ag.code = this.form.get('code')?.value;
      this.ag.type = this.form.get('type')?.value;
      this.ag.center_id = this.form.get('center_id')?.value;

      this.ag.lines = allData;
      if (this.isEdit) {
        this.apiS.saveAccountingGroup(this.ag).then(res => {
          this.routerS.goTo(ViewPath.accountingGroups);
          this.snackS.show("Cambios guardados");
        })
      }
      else {
        this.apiS.createAccountingGroup(this.ag).then(res => {
          this.routerS.goWithQueryParams(ViewPath.accountingGroups, { [PS_HIGHLIGHT]: res.id });
          this.snackS.show("Grupo creado");
        })
      }

    }
  }

  show(d: M_AccountingGroupRow[]) {
    return d.length != 0;
  }

}
