import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';

@Component({
    selector: 'app-main-storehouse-entry-button',
    templateUrl: './main-storehouse-entry-button.component.html',
    styleUrls: ['./main-storehouse-entry-button.component.css'],
    standalone: false
})
export class MainStorehouseEntryButtonComponent {
  v = ViewPath;
  @Input({ required: true }) c!: M_Contact;
  @Input() style: "flat" | "menu-option" = "flat";
}
