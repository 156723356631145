import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { M_LastMovements } from '../../models/M_LastMovement';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-show-advance-client',
    templateUrl: './show-advance-client.component.html',
    styleUrls: ['./show-advance-client.component.css'],
    standalone: false
})
export class ShowAdvanceClientComponent implements OnInit {

  @Input() dataMostrada?: M_LastMovements;
  @Input() disabled: boolean = false;
  @Input() preSelected?: boolean;
  @Output() onSelect: EventEmitter<ShowAdvanceClientComponent> = new EventEmitter();
  @Output() ondisSelect: EventEmitter<ShowAdvanceClientComponent> = new EventEmitter();
  @Input() pendingImportTotal: number = 0;
  @ViewChild(MatCheckbox) checkbox?: MatCheckbox;
  elementosSeleccionados: M_LastMovements[] = [];
  isDisabled: boolean = false;

  ngOnInit(): void {
    this.checkIfExceedsPendingImport();
  }

  switchSelected(event: Event, stopPropagation: boolean) {
    if (!this.checkbox || this.disabled) return;
  
    this.checkbox.toggle();
  
    if (this.checkbox.checked) {
      this.onSelect.emit(this);
    } else {
      this.ondisSelect.emit(this);
    }
  
    if (this.dataMostrada && this.pendingImportTotal) {
      const totalSelectedImports = this.elementosSeleccionados.reduce((sum, el) => sum + el.import, 0);
  
      if (totalSelectedImports > this.pendingImportTotal) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  }

  get selected() {
    return this.checkbox?.checked;
  }

  checkIfExceedsPendingImport() {
    if (this.dataMostrada && this.pendingImportTotal) {
      const totalSelectedImports = this.elementosSeleccionados.reduce(
        (sum, el) => sum + el.import, 0
      );
  
      const anticipatedSum = totalSelectedImports + this.dataMostrada.import;
  
      if (anticipatedSum > this.pendingImportTotal) {
        this.isDisabled = true; // Marca como deshabilitado si supera el importe
      } else {
        this.isDisabled = false; // Caso contrario, habilitado
      }
    }
  }
 
}
