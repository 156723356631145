import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuComponent } from '../menu.component';

@Component({
    selector: 'app-menu-trigger',
    templateUrl: './menu-trigger.component.html',
    styleUrls: ['./menu-trigger.component.css'],
    standalone: false
})
export class MenuTriggerComponent {
  @Input({required : true}) isOn!: 'toolbar' | 'menu';
  @Input({required : true}) mc!: MenuComponent;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
}
