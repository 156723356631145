/** 
 * Any filter that is in more than one page-structure, has to be here, in the hashed filters
 */

import { FilterOption, TagFilter } from "../custom-classes/Filter";
import { invoice_type } from "../custom-classes/invoice_types";
import { or_status } from "../custom-classes/or_states";
import { or_types } from "../custom-classes/or_types";
import { VehiclesPageFiltersEnum } from "../views/vehicles/vehicles.component";

/** CONTACT TYPE */
export function CONTACT_TYPE(addAgente: boolean) {
    return new TagFilter("Tipo", undefined,
        new FilterOption("Persona", "person"),
        new FilterOption("Compañía", "business"),
        new FilterOption("Proveedor", "local_shipping"),
        ...(addAgente ? [new FilterOption("Agente", "work")] : []));
}

/** VEHICLE TYPE */
export function VEHICLE_TYPE() {
    return new TagFilter("Vehículo", undefined, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(VehiclesPageFiltersEnum.VEHICLE_TYPE)
}

/** OR TYPE */
export function OR_TYPE_FILTER(includeInterno : boolean = true, includeSiniestro : boolean = true) {
    return new TagFilter("Tipo de OR", or_types,
        new FilterOption("Normal"),
        new FilterOption("Interno", undefined, !includeInterno),
        new FilterOption("Garantia"),
        new FilterOption("Siniestro", undefined, !includeSiniestro),
        new FilterOption("Mantenimiento"),
        new FilterOption("Sin tipo")
    );
}

/** OR STATUS */
export function OR_STATUS_FILTER(name: string) {
    return new TagFilter(name, or_status,
        new FilterOption("Abierta"),
        new FilterOption("Cerrada"),
        new FilterOption("Facturada")
    );
}
export function TYPE_PAYMENT_FILTER(){
    return new TagFilter("Tipo de cobro",invoice_type,
        new FilterOption("Efectivo",'payments'),
        new FilterOption("Tarjeta",'credit_card'),
        new FilterOption("Bizum",'bizum'),
        new FilterOption("Transferencia",'sync_alt'),
    );
}
/** INVOICE TYPE */
export const INVOICE_TYPE_FILTER = new TagFilter("Tipo", invoice_type,
    new FilterOption("Recambios", "receipt_long"),
    new FilterOption("Abono", "currency_exchange"),
    new FilterOption("Comercial", "garage")
);
