import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Views } from 'src/app/custom-classes/View';

@Component({
    selector: 'app-confirm-exit-dialog',
    templateUrl: './confirm-exit-dialog.component.html',
    styleUrls: ['./confirm-exit-dialog.component.css'],
    standalone: false
})
export class ConfirmExitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public redirectUrl: Views) { }
}