<form [formGroup]="form" eForm>
    <!-- tener en cuenta al actualizar -->
    <app-card-subtitle [first]="true">Datos obligatorios</app-card-subtitle>

    <div eForm-wrapper>
        <div eForm-section>
          <app-brands-models-series [form]="form" [notShowModel]="true" [notShowSerie]="true" (onSelectBrandSearcher)="onSelectBrand($event)"></app-brands-models-series>
            <mat-form-field appearance="outline">
                <mat-label>Código serie</mat-label>
                <input matInput formControlName="serie" placeholder="Código serie">
            </mat-form-field>
        </div>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Nombre Serie</mat-label>
                <input matInput formControlName="name" placeholder="Nombre serie">
            </mat-form-field>

            <app-accounting-group-selector *ngIf="features.accountingGroups" [form]="form" [type]="'C'" (selectionChange)="selectAcc($event)"></app-accounting-group-selector>

        </div>
    </div>
    <div *ngIf="features.accountingGroups">
        <app-card-subtitle>Datos opcionales</app-card-subtitle>
        <div eForm-wrapper>
            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Grupo contable detalle</mat-label>
                    <input matInput formControlName="accounting_detail" placeholder="Grupo contable detalle">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Grupo contable agente</mat-label>
                    <input matInput formControlName="accounting_agent" placeholder="Grupo contable agente">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>