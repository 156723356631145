import { Component, HostListener } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DobiDialogComponent } from './dobi-dialog/dobi-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-entry-point-listener',
    templateUrl: './dobi.component.html',
    styleUrls: ['./dobi.component.css'],
    standalone: false
})
export class DobiComponent {
  userinput: string[] = []
  key1 = "siniegg";
  constructor(private bs: MatBottomSheet) { }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (environment.dev || environment.local) {
      if (this.userinput.length == this.key1.length) {
        this.userinput.shift();
      }
      this.userinput.push(event.key.toLocaleLowerCase())
      if (this.userinput.every((k, index) => k == this.key1[index]) && this.userinput.length == this.key1.length) {
        this.bs.open(DobiDialogComponent, { disableClose: true })
      }
    }
  }


}
