import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { SnackService } from 'src/app/services/snack.service';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { PreviewService } from 'src/app/services/preview.service';
import { M_Model } from 'src/app/models/M_Model';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';
import { VnVoFormComponent } from 'src/app/components/vehicleform/vn-vo-form/vn-vo-form.component';
import { GenericBuyVehicleService } from 'src/app/services/generic-buy-vehicle.service';
import { ComercialVehicleType } from 'src/app/enums/ComercialVehicleType';

export const preSelectedVO = "preSelectedVO"

export type CreateVNVObyBudetg = {
  budget: M_ComercialBudget
}

@Component({
    selector: 'app-create-vn-vo',
    templateUrl: './create-vn-vo.component.html',
    styleUrls: ['./create-vn-vo.component.css'],
    standalone: false
})

export class CreateVNVOcomponent extends ParameterStateComponent implements OnInit {
  v = ViewPath;
  @ViewChild(VnVoFormComponent, { static: true }) vehicleForm!: VnVoFormComponent;
  loaded = false;
  isEdit = false;
  ve: M_Vehicle | undefined;
  formData_ = new FormData();
  voPreSelected: number | undefined;

  constructor(private apiS: ApiService, private snackS: SnackService,
    routerS: RouterService, public previewS: PreviewService, public confirmD: ConfirmDialogService,
    route: ActivatedRoute, private params: ParamsService, private genericBuyS: GenericBuyVehicleService,
    private chdRedf: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<CreateVNVOcomponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CreateVNVObyBudetg | null) {
    super(routerS, route, ["vnvo", preSelectedVO]);
  }

  ngOnInit(): void {
    if (this.data?.budget) {
      this.patchFormWithComercaialBudget(this.data.budget);
    }
  }

  //todocomercial
  patchFormWithComercaialBudget(cBudget: M_ComercialBudget) {
    this.vehicleForm.provisionalConcepts = cBudget.concepts;
    setTimeout(() => {
      if (cBudget.brand_id) {
        this.vehicleForm.brandsModelSerie?.brandSearcherComponent?.select(cBudget.brand_id);
      }
      if (cBudget.serie_id) {
        this.vehicleForm.brandsModelSerie?.serieSearcherComponent?.select(cBudget.serie_id, { emitEvent: false });
      }
      if (cBudget.model_id) {
        this.vehicleForm.brandsModelSerie?.modelSearcherComponent?.select(cBudget.model_id);
      }
      this.chdRedf.markForCheck();
    }, 0);
    this.vehicleForm.form.patchValue(
      {
        brand: cBudget.brand,
        model: cBudget.model,
        price: cBudget.price,
        budget_id: cBudget.id,
      }
    )
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get title() {
    return this.isEdit ? 'Editar VN/VO' : 'Crear VN/VO'
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.params.go(ViewPath.vnvoDetails, this.ve.vehicle_id);
  }

  goBackStockList() {
    if (!this.ve) { this.routerS.goTo(ViewPath.garage); }
  }

  override onParam(_k: string, v: string) {
    if (_k == "vnvo") {
      this.apiS.vnvo.getVehicleById(v.getNumber()).then(res => {
        if (res) {
          this.ve = res;
          this.vehicleForm.patchFormWithVehicle(this.ve);
        }
        this.isEdit = true;
        this.loaded = true;
      })
    }
    if (_k == preSelectedVO) {
      this.vehicleForm.form.get('comercialType')?.setValue(ComercialVehicleType.VO);
      this.voPreSelected = v.getNumber();
      this.chdRedf.detectChanges();
      this.vehicleForm.VOClientSearcher?.select(this.voPreSelected);
      this.loaded = true;
    }
  }

  override noParams(): void {
    this.loaded = true;
  }

  getConceptsFromForm(): M_Concept[] {
    return this.vehicleForm.getConcepts();
  }


  get formVehicle(): M_Vehicle | undefined {
    if (this.vehicleForm.isCreate && this.vehicleForm.isVo) {
      let vehicle = this.vehicleForm.VOClientSearcher?.selected;
      if (vehicle) {
        vehicle.cost = this.vehicleForm.newVOform.get('cost')?.value;
        vehicle.client_id = vehicle.client?.client_id;
        vehicle.comercialType = ComercialVehicleType.VO;
        return vehicle;
      }
    }
    else {
      let v = new M_Vehicle(this.vehicleForm.form.getRawValue());
      if (this.vehicleForm.brandsModelSerie?.modelSearcherComponent?.selected instanceof M_Model) {
        let m = this.vehicleForm.brandsModelSerie.modelSearcherComponent?.selected
        v.model = m;
        v.model_name = m.name;
        v.model_id = m.id;
      }
      if (this.vehicleForm.brandsModelSerie?.brandSearcherComponent?.selected instanceof M_Brand) {
        let b = this.vehicleForm.brandsModelSerie.brandSearcherComponent?.selected
        v.brand = b;
        v.brand_name = b.name;
        v.brand_id = b.id;
      }
      if (this.vehicleForm.brandsModelSerie?.serieSearcherComponent?.selected instanceof M_Serie) {
        let s = this.vehicleForm.brandsModelSerie.serieSearcherComponent?.selected
        v.serie = s;
        v.serie_name = s.name;
        v.serie_id = s.id;
      }
      v.concepts = this.getConceptsFromForm();
      return v;
    }
    return undefined;
  }

  goToVehicle(ve: M_Vehicle) {
    this.params.go(ViewPath.vnvoDetails, ve.vehicle_id);
  }

  createEdit() {
    if (this.vehicleForm.isFormValid) {
      let v = this.formVehicle;
      if (v) {
        this.apiS.vnvo.addStock(v).then(res => {
          if (res instanceof M_Vehicle) {
            this.onCreationFinish(res);
          }
        })
      }
    }
  }

  onCreationFinish(res: M_Vehicle) {
    this.snackS.show(this.isEdit ? "Vehículo editado con éxito" : "Vehículo creado con éxito");
    if (this.isOnDialog) {
      res.budget_id = this.vehicleForm.form.get('budget_id')?.value;
      this.dialogRef.close(res);
    }
    else {
      this.goToVehicle(res);
    }
  }

  get pageTitle() {
    return this.isEdit ? "Editar vehículo en stock" : "Entrar vehículo en stock";
  }

}