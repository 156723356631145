import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductStorehouseControlstockDetailsComponent } from './product-storehouse-controlstock-details/product-storehouse-controlstock-details.component';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_Location } from 'src/app/models/M_Location';
import { ResponsiveService } from 'src/app/services/responsive.service';


@Component({
    selector: 'app-product-storehouse-controlstock',
    templateUrl: './product-storehouse-controlstock.component.html',
    styleUrls: ['./product-storehouse-controlstock.component.css'],
    standalone: false
})

export class ProductStorehouseControlstockComponent implements OnInit {

  @Input() product!: M_Product;
  locations: M_Location[] = []
  groupedLocation: any[] = []
  totalData: any

  displayedColumns: string[] = ['name', 'physical', 'disp', 'engaged', 'reserved', 'fault', 'last_in', 'last_out'];


  constructor(public responsiveS: ResponsiveService, private d : MatDialog) {
  }

  ngOnInit() {
    this.locations = this.product.locations;

    const groupedLocations: { [key: string]: any } = {};

    // Agrupar por store_name y agregar todas las ubicaciones al mismo array
    this.locations.forEach(location => {
      const storeName = location.store_name;

      if (!groupedLocations[storeName]) {
        groupedLocations[storeName] = [];
      }

      // Agregar la ubicación al almacén correspondiente
      groupedLocations[storeName].push(location);
    });
    const groupedLocationArray = Object.keys(groupedLocations).map((storeName) => ({
      storeName,
      storeLocations: groupedLocations[storeName]
    }));
    this.groupedLocation = groupedLocationArray;

    this.totalData = [
      { name: 'Todas', physical: this.product.totalPhysical, disp: this.product.totalDisp, engaged: this.product.totalEngaged, reserved: this.product.totalReserved, fault: this.product.totalFaults, last_in: this.product.last_entry, last_out: this.product.last_exit },

    ];

    this.updateDisplayedColumns();

  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDisplayedColumns();
  }

  updateDisplayedColumns(): void {
    if (this.responsiveS.w < 500) {
      this.displayedColumns = ['name', 'physical', 'disp', 'fault'];
    } else if (this.responsiveS.w < 750) {
      this.displayedColumns = ['name', 'physical', 'disp', 'engaged', 'reserved', 'fault'];
    } else {
      this.displayedColumns = ['name', 'physical', 'disp', 'engaged', 'reserved', 'fault', 'last_in', 'last_out'];
    }
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  openDialog() {
    this.d.open(ProductStorehouseControlstockDetailsComponent, {data : this.product});
  }

}
