<div eForm-wrapper [formGroup]="form">
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Tipo de motor</mat-label>
            <mat-select formControlName="engine_type" #et>
                <mat-option [value]="ET.GASOLINE">Gasolina</mat-option>
                <mat-option [value]="ET.ELECTRIC">Eléctrico</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Nº serie batería </mat-label>
            <input matInput placeholder="Nº serie batería" formControlName="battery_1_no">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Nº serie batería extra</mat-label>
            <input matInput placeholder="Nº serie batería extra" formControlName="battery_2_no">
        </mat-form-field>
    </div>
    <div eForm-section>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Cilindrada</mat-label>
                <input type="number" matInput placeholder="Cilindrada" formControlName="cc">
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Tipo de cambio</mat-label>
            <input matInput formControlName="gears_type" placeholder="Tipo de cambio">
        </mat-form-field>
    </div>
</div>