import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomLog } from '../custom-classes/CustomLog';

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  /** Errors */
  onError: Subject<CustomLog> = new Subject();
  onClearError: Subject<any> = new Subject();

  constructor() { }

}
