import { Component, Inject, ViewChild } from '@angular/core';
import { M_Action } from '../../models/M_Action';
import { M_Contact } from '../../models/M_Contact';
import { MASTER_C_CLIENT } from '../../constants/masters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { OrFormComponent } from '../or-form/or-form.component';
import { OrService } from '../../services/or.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';

export interface transferData {
  action: M_Action,
  from: "or" | "budget",
  to: "or" | "budget"
}

/**
 * Transfer OR to Buget, OR to OR, or Budget to OR
 */
@Component({
    selector: 'app-trasnfer-action',
    templateUrl: './trasnfer-action.component.html',
    styleUrls: ['./trasnfer-action.component.css'],
    standalone: false
})
export class TrasnferActionComponent {

  client = MASTER_C_CLIENT;
  v = ViewPath;
  @ViewChild(ClassSearcherComponent) clienSearcher!: ClassSearcherComponent<M_Contact>;
  @ViewChild(OrFormComponent) formComponent?: OrFormComponent;

  constructor(public dialogRef: MatDialogRef<TrasnferActionComponent, transferData>, @Inject(MAT_DIALOG_DATA) public data: transferData,
    private routerS: RouterService, private apiS: ApiService, private orS : OrService) {

    if (this.specialSiniestroCase) {
      //this.transferSiniestroBudget();
    }
  }

  ngAfterViewInit() {
    if (this.data.to == "or" && this.formComponent) {
      this.formComponent.vehicleSearcher?.select(this.data.action.vehicle?.vehicle_id!);
      this.formComponent.clientInvoice?.select(this.data.action.vehicle!.clientInvoice?.client_id!);
    }
  }

  get specialSiniestroCase(){
    return this.data.action.isSomeGroupSiniestro && this.data.from == "budget" && this.data.to == "or";
  }

  get orFormLoaded() {
    return this.formComponent && this.formComponent.vehicleSearcher?.loaded && this.formComponent?.clientInvoice?.loaded
  }

  startRA() {
    if (!this.formComponent) { return; }
    this.orS.create({
      orForm : this.formComponent,
      raGroups : this.data.action.groupsTaskToTabbedComments,
      dialog : this.dialogRef,
      transferData : {
        budget_id : this.data.action.id,
        to : this.data.to
      }
    })
  }

  trasnfer() {
    if (!this.clienSearcher.selected) { return; }
    this.apiS.transferAction(this.data.action.id, this.data.to, { client: this.clienSearcher.selected! }).then(res => {
      this.routerS.goWithQueryParams(ViewPath.createEditWorkshopBudget, { budget: res })
      this.dialogRef.close();
    })
  }


  /** From budget to OR, if some group is 'Siniestro' 
   * To do : Recepció activa ?
  */
  transferSiniestroBudget() {
    this.apiS.transferAction(this.data.action.id, this.data.to, { client: this.data.action.client }).then(res => {
      this.routerS.goWithQueryParams(ViewPath.createEditWorkshopBudget, { budget: res })
      this.dialogRef.close();
    })
  }

}
