<div class="advanced-details-container" *ngIf="ve != undefined || (ve == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado vehículos'" [v]="v.garage" [show]="loaded"></app-go-back>
    <div class="vehicle">
        <app-card [contentLoaded]="loaded" class="bc_b1">

            <app-advanced-details *ngIf="ve">

                <app-advanced-title>
                    <div class="df aic gap5">
                        {{ve.getName()}}
                        <app-vn-vo-label *ngIf="ve.comercialType" [type]="ve.comercialType"></app-vn-vo-label>
                        <app-reserve-budget *ngIf="ve.budget_reserve"
                            [reserve]="ve.budget_reserve.reserve"></app-reserve-budget>
                    </div>
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{ve.chassis}}
                </app-advanced-subtitle>

                <app-advanced-main-actions *ngIf="ve">

                    <button mat-flat-button color="primary" *ngIf="!ve.isBuyRegeistered"
                        (click)="goCreatepurchase(ve)">
                        <mat-icon>shopping_basket</mat-icon>
                        Registrar compra
                    </button>

                    <button mat-flat-button color="primary"
                        (click)="params.go(v.sellVehicle, ve.vehicle_id, 'vehicle')">
                        <mat-icon>sell</mat-icon>
                        Vender
                    </button>

                    <button mat-flat-button color="primary" [go]="v.createOr" [param]="ve!.vehicle_id">
                        <mat-icon>build</mat-icon>
                        Abrir OR Comercial
                    </button>

                    <app-documentation-pdf-menu [vehicle]="ve"></app-documentation-pdf-menu>

                </app-advanced-main-actions>


                <app-advanced-menu-actions>
                    <button mat-menu-item color="primary" (click)="params.go(v.createVnVo, ve!.vehicle_id!)">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                    <button mat-menu-item (click)="goAssignClient(ve)">
                        <mat-icon>passkey</mat-icon>
                        Poseedor temporal
                    </button>
                    <button mat-menu-item (click)="compraVenta(ve)" *ngIf="ve.isVo">
                        <mat-icon [filled]="true">handshake</mat-icon>
                        <span>{{ve.vehicle_entry?.urldpf ? 'Regenerar contrato compra venta' : 'Generar contrato compra venta'}}</span>
                    </button>
                </app-advanced-menu-actions>

            </app-advanced-details>

            <app-advanced-details-footer>
                <app-footer-title>Ver más datos de vehículo</app-footer-title>
                <app-footer-right *ngIf="ve">
                    <div class="df gap5 fww">
                        <button mat-flat-button [color]="'ice'">
                            Precio <span class="fw600" money [val]="ve.price"></span>
                        </button>
                        <button mat-flat-button [color]="'ice'">
                            Coste <span class="fw600" money [val]="ve.cost"></span>
                        </button>
                        <app-contact-client-rectangle *ngIf="ve.client" [client]="ve.client"
                            [temporalOwner]="true"></app-contact-client-rectangle>
                    </div>
                </app-footer-right>
                <app-footer-content>
                    <app-section>
                        <app-section-title>Ficha</app-section-title>
                        <app-section-content>
                            <app-card-row-content *ngIf="ve != undefined" [data]="
                        [
                            ['Chasis', ve.chassis],
                            ['Precio', ve.price, {money : true}],
                            ['Coste',  ve.cost, {money : true} ],
                        ]">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>
                    <app-section>
                        <app-section-title>Ubicación</app-section-title>

                        <app-section-content>
                            <div class="df mt10" *ngIf="ve?.location != undefined; else notLocation">
                                <span><mat-icon class="vab">location_on</mat-icon>
                                    {{location}}</span>

                            </div>
                            <ng-template #notLocation>
                                <div class="mt10" *ngIf="ve">
                                    <span class="c_t2">
                                        <mat-icon class="c_t2 vab mr5">wrong_location</mat-icon>
                                        No hay ubicación. Ésta se deduce del
                                        <span class="c_b underline_hover" (click)="goAssignClient(ve)">poseedor
                                            temporal</span>
                                        del vehículo.
                                    </span>
                                </div>
                            </ng-template>
                        </app-section-content>
                    </app-section>
                    <app-section *ngIf="ve && ve.vehicle_entry && ve.vehicle_entry.seller">
                        <app-section-title>Vendedor</app-section-title>
                        <app-section-content>
                            <app-contact-client-rectangle [client]="ve.vehicle_entry.seller"></app-contact-client-rectangle>
                        </app-section-content>
                    </app-section>
                </app-footer-content>
            </app-advanced-details-footer>
        </app-card>
    </div>
    <div class="BottomCard">
        <app-card [contentLoaded]="loaded" [noData]="ve && !ve.concepts.length">
            <app-card-title>Conceptos</app-card-title>
            <ng-container *ngIf="ve && ve.concepts.length">
                <app-concepts-line-table [concepts]="ve.concepts" [vehicle]="ve" [isInfo]="true"
                    [canModifyTable]="false"></app-concepts-line-table>
            </ng-container>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.vehicles">
    </app-model-not-found>
</ng-template>