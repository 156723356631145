import { Injectable } from '@angular/core';
import { CompanyService } from './EinaMainData/company.service';
import { M_Action } from '../models/M_Action';
import { CALENDAR_MINI_COMPANY } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  constructor(public companyS: CompanyService) { }
  today = new Date();

  /**Deshabilita los domingos en el calendario */
  sundayFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }
      const day = date.getDay();
      return day != 0; //Domingo
    };

  /**Deshabilita los domingos en el calendario */
  weekendFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }
      const day = date.getDay();
      return day != 0 && day != 6; //Domingo
    };

  /**
   * Deshabilita los días anteriores a 'hoy'
   * Deshabilita los domingos en el calendario
   */
  maxTodayAndSunday: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) {
        return false;
      }
      const day = date.getDay();
      const cd: Date = new Date();
      cd.minusDays(1);
      return date > cd && day != 0;
    };

  /** Day color on OR calendar 
   * @param total : Total OR on the specific day
   * @param maxOnDay : Max OR that can be done in one day (company_places)
  */
  getDayColorByOrLength(total: number, maxOnDay: number | undefined) {
    if (maxOnDay != undefined) {
      var half = maxOnDay / 2;
      if ((total >= maxOnDay) || (maxOnDay == 0 && total)) {
        return 'high-work';
      } else if (total >= half) {
        return 'mid-work'
      }
      else if (total) {
        return 'low-work'
      }
    }
    else if (total) {
      return 'low-work';
    }
    return ""
  }
  getsClassCenter(d: Date, workload: M_Action[] | undefined) {
    if (!this.companyS.userCenter || workload == undefined) { return ""; }
    const date = new Date(d);
    const toalOrOnThisDay = workload.filter(or => {
      or.schedule.isEquals(date)
      return or.schedule?.isEquals(date) && !or.status.invoiced
    }).length;
    let class1 = this.getDayColorByOrLength(toalOrOnThisDay, this.companyS.userCenter.workshopConfig?.places);
    let class2 = this.companyS.userCenter.holidays.some((d) => d.isEquals(date)) ? CALENDAR_MINI_COMPANY : ''
    return class1 + " " + class2;
  }

  disableHolidaysCenter = (date: Date | null): boolean => {
    if (!date || !this.companyS.userCenter) return true;
    const isHoliday = this.companyS.userCenter.holidays.some((holiday) =>
      holiday.isEquals(date)
    );
    const isSunday = date.getDay() === 0;
    const result = !(isHoliday || isSunday);
    return result;
  };
}