import { Component, EventEmitter, Inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Invoice } from '../../models/M_Invoice';
import { MatSelectChange } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ShowAdvanceClientComponent } from '../show-advance-client/show-advance-client.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_TypePayment } from '../../models/M_TypePayment';
import { TypePaymentService } from '../../services/type-payment-service';
import { M_LastMovements } from '../../models/M_LastMovement';
import { PreviewService } from '../../services/preview.service';
import { DialogCashTotalReturnComponent } from 'src/app/views/invoices/dialog-cash-total-return/dialog-cash-total-return.component';
import { FormService } from 'src/app/services/form.service';
import { invoice_states_paid, invoice_states_payed } from 'src/app/custom-classes/invoice_states';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { SnackService } from 'src/app/services/snack.service';

/**
 * Invoices side detail and dialog form of payment movements.
 */
@Component({
    selector: 'app-invoice-payement-form',
    templateUrl: './invoice-payement-form.component.html',
    styleUrls: ['./invoice-payement-form.component.css'],
    standalone: false
})

export class InvoicePayementFormComponent {
  @Input() invoice!: M_Invoice;
  @Input() purchase!: M_Purchase;
  @Input() isDialogForm: boolean = false;
  @Input() resetImport: boolean = false;
  @Output() onDeleteTypePayment: EventEmitter<M_TypePayment> = new EventEmitter();
  @Output() onPayAll: EventEmitter<M_LastMovements> = new EventEmitter();
  @ViewChildren(ShowAdvanceClientComponent) lsmv?: QueryList<ShowAdvanceClientComponent>;
  selectedPayment: any;
  form: UntypedFormGroup;
  dOpened = false;
  cash = false;
  cashTotal: number | undefined;
  importAdvance: number | undefined;
  selectedAdvances: ShowAdvanceClientComponent[] = [];
  constructor(private fb: UntypedFormBuilder, private d: MatDialog, private apiS: ApiService, private fs: FormService, public typePaymentS: TypePaymentService,
    @Inject(MAT_DIALOG_DATA) public data: { invoice: M_Invoice, isDialog: boolean, purchase: M_Purchase},
    public previewS: PreviewService, public responsiveS: ResponsiveService,public snackS:SnackService) {
    if (data) {
      if (data.invoice) {
        this.invoice = data.invoice;
      }
      if (data.purchase) {
        this.purchase = data.purchase;
      }

      this.isDialogForm = data.isDialog;

    }
    if (this.invoice) {
      this.form = this.fb.group({
        payment_id: [1, Validators.required],
        import: ['', Validators.required],
        invoice_id: [''],
        name: [''],
        dynamicDate: [new Date().datePickerFormat()],
        pending_import_total: ['']
      });
    }
    else {
      this.form = this.fb.group({
        payment_id: [1, Validators.required],
        import: ['', Validators.required],
        buy_num: [''],
        name: [''],
        dynamicDate: [new Date().datePickerFormat()],
        pending_import_total: ['']
      });
    }

    this.importControl?.valueChanges.subscribe(res => {
      if (this.form.get('payment_id')?.value != 1) {
        this.checkDisableOkButton();
      }
    });
    this.typePaymentS.getTypePayments().then(res => { });
  }

  ngOnInit(): void {
    if (this.isDialogForm) {
      if (this.invoice) {
        this.importControl?.patchValue(this.invoice.pending_import);
        const pendingImportControl = this.form.get('pending_import_total');
        pendingImportControl?.patchValue(this.invoice.pending_import);
        if (this.invoice.isAbono) {
          this.importControl?.disable();
        }
      }
      if (this.purchase) {
        this.importControl?.patchValue(this.purchase.pending_import);
        const pendingImportControl = this.form.get('pending_import_total');
        pendingImportControl?.patchValue(this.purchase.pending_import);
      }

    }
  }
  /** Error of 'The amount exceeds the import. */
  checkDisableOkButton() {
    let payment_id = this.form.get('payment_id')?.value;
    if (this.importControl == undefined) { return; }

    // hacer que cuando el payment_id sea 1, se  habilite el campo de nuevo
    if (payment_id === 1) {
      // Limpiar cualquier error previo
      this.importControl.setErrors(null);
      this.importControl.markAsPristine();
      this.importControl.markAsUntouched();
      this.importControl.updateValueAndValidity();
      return;
    }else{
      this.importControl.setErrors({ error_import: true });
      this.importControl.markAsTouched();
    }
    const invoicePendingImport = this.invoice?.pending_import ?? 0;
    const purchasePendingImport = this.purchase?.pending_import ?? 0; // antes comprobaba siempre purchase pero no siempre esta con valor.
    const totalSelectedImport = Array.isArray(this.selectedAdvances) && this.selectedAdvances.length > 0
    ? this.selectedAdvances.reduce((sum, selected) => {
        return sum + (selected?.dataMostrada?.import ? parseFloat(selected.dataMostrada.import.toFixed(2)) : 0);
    }, 0)
    : 0;
    const sumSelectAdvanceImportInput = this.importControl.value + totalSelectedImport;
    let isOk = false;
    if (Array.isArray(this.selectedAdvances) && this.selectedAdvances.length > 0) {
      isOk = sumSelectAdvanceImportInput <= invoicePendingImport
    } else {
      isOk = this.importControl.value <= invoicePendingImport
        || this.importControl.value <= purchasePendingImport;
    }
    if (isOk) {
      this.importControl.setErrors(null);
     
    } else {
      this.importControl.setErrors({ error_import: true });
      this.importControl.markAsTouched();
    }
  }


  resetForm() {
    this.nameControl?.removeValidators(Validators.required);
    this.form.reset({}, { onlySelf: true, emitEvent: false });
    this.form.patchValue({ dynamicDate: new Date().datePickerFormat() });
    this.importControl?.markAsPending();
  }

  selectPayment() {
    this.checkDisableOkButton();
  }

  onPaymentTypeChanges(event: MatSelectChange) {
    const selectedValue = event.value;
    this.selectedPayment = this.typePaymentS.typePaymentArray.find(payment => payment.id === selectedValue);
    
    if (event.value == 'personalizado') {
      this.nameControl?.addValidators(Validators.required);
    } else {
      this.nameControl?.removeValidators(Validators.required);
    }
    this.nameControl?.updateValueAndValidity();
  }

  checkImport(importHtml: HTMLInputElement) {
    let valueIimport = 0;

    if (this.invoice && this.purchase == undefined) {
      if (this.invoice.pending_import) {
        valueIimport = this.invoice.pending_import;
      }
    } else if (this.purchase.pending_import && this.invoice == undefined) {
      valueIimport = this.purchase.pending_import;
    }

    // Verificar si el importe supera el límite pendiente
    if ((this.importControl && this.importControl.value > valueIimport) ||
      (this.importControl && this.form.get('payment_id')?.value == 1 &&
        Array.isArray(this.selectedAdvances) &&
        this.selectedAdvances.length > 0 &&
        this.selectedAdvances.reduce((sum, selected) => sum + (selected?.dataMostrada?.import ? parseFloat(selected.dataMostrada.import.toFixed(2)) : 0), 0) + this.importControl?.value > valueIimport)) {

      const changeAmount = (this.importControl.value - valueIimport).toFixed(2);
      const dynamicDateControl = this.form.get('pending_import_total');

      if (this.invoice && this.purchase == undefined) {
        dynamicDateControl?.patchValue(this.invoice.pending_import);
      } else if (this.purchase.pending_import && this.invoice == undefined) {
        dynamicDateControl?.patchValue(this.purchase.pending_import);
      }

      const selected = this.form.get('payment_id');
      if (selected?.value == 1) {
        this.cash = true;

        const changeAmountNum = parseFloat(changeAmount);

        if (Array.isArray(this.selectedAdvances) && this.selectedAdvances.length > 0) {
          // Sumar todos los import existentes en el array selectedAdvances
          const totalImport = this.selectedAdvances.reduce((sum, selected) => {
            return sum + (selected?.dataMostrada?.import ? parseFloat(selected.dataMostrada.import.toFixed(2)) : 0);
          }, 0);

          this.cashTotal = parseFloat((changeAmountNum + totalImport).toFixed(2));
        } else {
          this.cashTotal = changeAmountNum;
        }
      }else{
        this.importControl.setErrors({ error_import: true });
      }
      importHtml.blur();
    } else {
      this.cash = false;
      this.cashTotal = 0;
    }
  }

  openChangeDialog(changeAmount: string) {
    if (!this.dOpened) {
      this.dOpened = true;
      this.d.open(DialogCashTotalReturnComponent, { data: { changeAmount }, }).afterClosed().subscribe(v => {
        this.dOpened = false;
      });
    }
  }

  updateForm() {
    if (this.invoice) {
      this.form.patchValue({ invoice_id: this.invoice.id });
    }
    else if (this.purchase) {
      this.form.patchValue({ buy_num: this.purchase.id });
    }
    this.createPayment();
  }

  createPayment() {
    // Verifica si la factura o compra existe
    if (this.invoice || this.purchase) {
      // Obtén el importe pendiente
      let pendingImport = this.invoice ? this.invoice.pending_import : this.purchase.pending_import;

      // Obtén el payment_id y el importe actual
      const paymentIdControl = this.form.get('payment_id');
      const importControl = this.form.get('import');
      const paymentId = paymentIdControl?.value;
      const importValue = importControl?.value;

      // Si el payment_id es 1, y el importe es superior al importe pendiente
      if (paymentId === 1) {
        const newPendingImport = pendingImport - importValue;

        // si el nuevo importe pendiente es negativo,  el importe pasa ser el  total de la factura o compra
        if (newPendingImport < 0) {
          const importToSet = this.invoice ? (this.invoice.last_movement.length === 0 ? this.invoice.total : pendingImport) : (this.purchase?.last_movement.length === 0 ? this.purchase?.total : pendingImport);
          this.form.patchValue({ import: importToSet });
          pendingImport = 0;  // el importe pendiente se convierte en 0 porque se esta pagando la totalidad pendiente
        } else {
          this.form.patchValue({ import: importValue });  // mantiene el valor actual del importe en el formulario
        }
      } else {
        this.form.patchValue({ import: importValue });  // si el payment_id no es 1, sigue con la lógica normal
      }

      // Verifica si hay anticipos seleccionados (selectedAdvances) y si el formulario está listo
      if (this.selectedAdvances.length > 0 && this.selectedAdvances.every(a => !a.disabled) || this.fs.isOk(this.form)) {

        // Filtrar los anticipos seleccionados que no han sido desactivados
        const selectedValidAdvances = this.selectedAdvances.filter(advance => !advance.disabled);

        // Calcular el total de los anticipos seleccionados
        const totalSelectedAdvances = selectedValidAdvances.reduce(
          (sum, item) => sum + (item?.dataMostrada?.import || 0),
          0
        );

        // Verificar si el total de los anticipos seleccionados supera el importe pendiente
        if (totalSelectedAdvances <= pendingImport) {
          // Si no excede el importe pendiente, preparar el objeto para enviar
          const paymentData = {
            ...this.form.getRawValue(), // Obtener todos los valores del formulario
            advances: selectedValidAdvances.map(advance => advance.dataMostrada) // Añadir los anticipos seleccionados como array
          };

          // Realizar la solicitud para crear el pago
          this.typePaymentS.createPayment(paymentData, this.invoice || this.purchase, paymentData.advances).then(res => {
            if (res) {
              // Resetear el formulario si el pago es exitoso
              this.resetForm();
             
              this.form.patchValue({ payment_id: 1 });

              // Verificar si se pagó todo
              if ((this.invoice?.pending_import === 0 || pendingImport <= 0) || (this.purchase?.pending_import === 0 || pendingImport <= 0)) {
                this.onPayAll.emit(this.selected?.dataMostrada);
                // Cambiar el estado de la factura o compra a "pagado"
                this.apiS
                  .changeStateInvoice(this.invoice || this.purchase, this.invoice ? invoice_states_payed : invoice_states_paid)
                  .then(() => {
                    if (this.invoice) {
                      this.invoice.state = invoice_states_payed;
                    } else {
                      this.purchase.state = invoice_states_paid;
                    }
                  });
              }
              this.onPayAll.emit(this.selected?.dataMostrada);
              if( this.invoice && !this.invoice.isAbono && this.purchase == undefined){
                this.snackS.show("El movimiento ha sido procesado y aplicado correctamente  a la factura.");
              }
              else if(this.invoice && this.invoice.isAbono && this.purchase == undefined){
                this.snackS.show("El movimiento ha sido procesado y aplicado correctamente al abono.");
              }
              else {
                this.snackS.show("El movimiento ha sido procesado y aplicado correctamente a la compra.");
              }
             
            }
          });
        } else {
          // Si el total de anticipos seleccionados supera el importe pendiente, mostrar mensaje de error
          return;
        }
      }
    }
  }


  disableOthers(advacne: ShowAdvanceClientComponent) {
    this.lsmv?.forEach(lsvm_ => {
      lsvm_.checkbox!.checked = false;

    })
    advacne.checkbox!.checked = true;
    this.cash = false;
    this.cashTotal = 0;
    const remainingAmount = this.pendingTotalInv;
    this.form.patchValue({ import: remainingAmount });
  }
  // Seleccionar anticipos
  onSelectAdvance(advance: ShowAdvanceClientComponent) {
    if (!advance || !advance.dataMostrada) {
      console.error('Anticipo inválido');
      return;
    }
  
    // Calcular el total de anticipos seleccionados actualmente
    const totalSelected = this.selectedAdvances.reduce(
      (sum, item) => sum + (item?.dataMostrada?.import || 0),
      0
    );
  
    // Calcular el nuevo total si seleccionamos el anticipo actual
    const newTotalSelected = totalSelected + advance.dataMostrada.import;
  
    // Comprobar si el total seleccionado excede el importe pendiente
    if (newTotalSelected > this.invoice.pending_import) {
      advance.disabled = true;
      advance.checkbox!.checked = false;
      return;
    }
  
    // Si no excede, agregar el anticipo a la lista de seleccionados
    this.selectedAdvances.push(advance);
  
    // Calcular el importe restante después de seleccionar el anticipo actual
    const remainingAmount = this.invoice.pending_import - newTotalSelected;
  
    // Recalcular todos los anticipos y habilitar/deshabilitar según el total
    this.lsmv?.forEach(item => {
      if (this.selectedAdvances.includes(item)) return; // Saltar anticipos ya seleccionados
  
      const totalIfSelected = newTotalSelected + (item?.dataMostrada?.import || 0);
  
      // Desactivar anticipos que no se puedan seleccionar sin exceder el total
      if (totalIfSelected > this.invoice.pending_import) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    });
  
    // **Verificar anticipos adicionales que podrían hacer el importe restante igual a 0**
    this.lsmv?.forEach(item => {
      if (this.selectedAdvances.includes(item)) return;
  
      const totalIfSelectedIncludingItem = newTotalSelected + (item?.dataMostrada?.import || 0);
      const amountAfterIncludingItem = this.invoice.pending_import - totalIfSelectedIncludingItem;
  
      if (amountAfterIncludingItem === 0) {
        // Desactivar anticipos que harían el importe restante igual a 0
        item.disabled = true;
      }
    });
  
    // Actualizar el formulario con el importe restante
    this.form.patchValue({ import: parseFloat(remainingAmount.toFixed(2)) });
  
    this.checkImport(document.querySelector('input[name="import"]') as HTMLInputElement);
  }
  

  onDeselectAdvance(advance: ShowAdvanceClientComponent) {
    //  recalcular el total seleccionado
    const index = this.selectedAdvances.indexOf(advance);
    if (index !== -1) {
      this.selectedAdvances.splice(index, 1); // Eliminar del array de seleccionados
    }

    // Volver a calcular el total seleccionado 
    const totalSelected = this.selectedAdvances.reduce(
      (sum, item) => sum + (item?.dataMostrada?.import || 0),
      0
    );

    // Recalcular el estado de habilitación de los anticipos disponibles
    this.lsmv?.forEach(item => {
      const totalIfSelected = totalSelected + (item?.dataMostrada?.import || 0);

      // Volver a habilitar anticipos que ya no exceden el total
      if (totalIfSelected <= this.invoice.pending_import) {
        item.disabled = false;
      }
    });
    // Actualizar el importe restante en el formulario

    const remainingAmount = this.invoice.pending_import - totalSelected;
    this.form.patchValue({ import: parseFloat(remainingAmount.toFixed(2)) });

    this.checkImport(document.querySelector('input[name="import"]') as HTMLInputElement);

  }


  isDisabled(lastMovement: M_LastMovements): boolean {
    const totalSelected = this.selectedAdvances.reduce((sum, item) => sum + (item?.dataMostrada?.import || 0), 0);
    return totalSelected + lastMovement.import > this.invoice.pending_import;
  }
  disableAdvance(advance: ShowAdvanceClientComponent) {
    this.importControl?.enable();
    advance.checkbox!.checked = false;
    this.form.patchValue({ import: this.invoice.pending_import });
  }
  get selected() {
    return this.lsmv?.filter((sac) => sac.selected)[0];
  }
  get pendingTotalInv() {
    let valuepending = 0;
    if (this.invoice) {
      valuepending = this.invoice.pending_import;
    }
    if (this.purchase) {
      valuepending = this.purchase.pending_import!;
    }

    if (this.selected) {
      const sumtotal = valuepending - this.selected.dataMostrada!.import;
      const total_import = sumtotal >= 0 ? sumtotal : 0;

      return parseFloat(total_import.toFixed(2));
    } else {
      return valuepending;
    }
  }
  get pendingFactura() {
    return this.invoice.pending_import;
  }
  get nameControl() {
    return this.form.get('name');
  }

  get importControl() {
    return this.form.get('import');
  }

  getSelectedAdvancesTotal(): number {
    return this.selectedAdvances
      .filter(advance => !advance.disabled)
      .reduce((total, advance) => total + (advance.dataMostrada?.import || 0), 0); // sumar los importes
  }
  /** Only show advances if the import of the advance is less than the total of the invoice */
  get showAdvances() {
    if (this.invoice) {
      return this.isDialogForm && this.invoice.client?.last_movement.some(m => m.import < this.invoice.pending_import);
    } else {
      return this.isDialogForm && this.purchase.contact?.last_movement.some(m => m.import < this.purchase.total!);
    }
  }

  get advances() {
    if (this.invoice) {
      return this.invoice.client?.last_movement.filter(lm => lm.import < this.invoice.pending_import && !lm.deleted)
    }
    else {
      return this.purchase.contact?.last_movement.filter(lm => lm.import <this.purchase.total! && !lm.deleted)
    }
  }
  get isTotalPay() {
    if (this.invoice) {
      return !this.invoice.isTotalPay;
    }
    if (this.purchase.last_movement) {
      return true;
    }
    return false
  }
  get total() {
    let valueTotal = 0;
    if (this.invoice) {
      valueTotal = this.invoice.total;
    }
    else if (this.purchase.total) {
      valueTotal = this.purchase.total;
    }
    return valueTotal;
  }
  get typePayName() {
    if (this.invoice) {
      return this.invoice.type == 5 ? 'Pagar' : 'Cobrar'
    }
    else {
      return 'Pagar';
    }
  }
  get PayName() {
    if (this.invoice) {
      return this.invoice.type == 5 ? 'Factura' : 'Abono'
    }
    else {
      return 'Factura Compra';
    }
  }
  get TitleObject() {
    if (this.invoice) {
      return this.invoice.company_scope_id
    }
    else {
      return this.purchase.num_purchase
    }
  }
}
