<app-page-structure class="grouped-ps-garage" [masterClass]="'vehicle'" pageTitle="Stock de marcas y modelos" [autocompleteID]="'vehicle_garage'"
    searchLabel="Buscar vehículo" noDataCreateNew="Entrar vehículo en stock" [data]="[]"  noDataCreateNew="Entrar vehículo en stock"
    listTitleText="Listado de marcas y modelos" [filter]="undefined" [filters]="undefined"
    [displayedHeader]=" ['Marca', 'Modelo', 'Disponible', 'Reservado']"
    [displayedColumns]="['bName', 'mName',  'disp',       'reserved']"
    [specialText]="     [undefined, undefined, undefined]"
    [specialClass]="    [undefined, undefined, undefined]"
    [cellZize]="        [undefined, undefined, 'small']" 
    [preIcon]="[preIconGrouped]"
    [rightMenu]="rightMenu"
    (onclickRow)="vehiclesDialog($event)"
    [groupBy]="groupBy"
    [exportExcel22]=" {singular : 'stock', plural : 'stocks',name:'groupV'}"
    [quickFilter]="undefined" [createButton]="
        {
            text : 'Entrar vehículo en stock' , 
            icon : 'garage', 
            view : v.createVnVo, 
            cssClass:'onboarding-create-garage'
        }">
</app-page-structure>